import React from "react";
import BasicLayout from "../../../components/layouts/basic.layout/BasicLayout";
import Translate from "../../../components/common/translate/Translate";
import OpportunitiesForm from "../../../components/opportunities/opportunities.form/OpportunitiesForm";
import {API_PATHS, makeJSONPostRequest} from "../../../services/ajax/ajax";
import {useDispatch} from "react-redux";
import {displaySuccess} from "../../../components/message.panel/ducks/actions";
import createDefaultOpportunitiesFormData
    from "../../../components/opportunities/opportunities.form/OpportunitiesForm.library";
import InfoLink from "../../../components/common/info.link/InfoLink";
import AppLinks from "../../../resources/data/links";

export default function OpportunitiesSubmit(props) {
    const dispatch = useDispatch();

    async function onSubmit(values) {
        await makeJSONPostRequest(
            API_PATHS.OPPORTUNITY_SUBMIT,
            dispatch,
            values
        );
        dispatch(displaySuccess([{
            label: "opportunity_submit_success_label"
        }]));
    }

    return (
        <BasicLayout
            {...props}
        >
            <div className={"opportunities-submit"}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <h1>
                        <Translate label={"opportunities_submit_header"}/>
                    </h1>
                    <InfoLink to={AppLinks.help_opportunities_submit} />
                </div>
                <OpportunitiesForm
                    submitLabel={"opportunity_submit_submit_label"}
                    onSubmit={onSubmit}
                    formData={createDefaultOpportunitiesFormData()}
                />
            </div>
        </BasicLayout>
    )
}
