import React from "react";
import {AddMentor, RemoveMentor, RequestReference} from "../../common/svg/svg";
import Translate from "../../common/translate/Translate";
import "./MentorItemButtons.css"
import {makeAlert} from "../../common/alert/Alert";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";

export default function MentorItemButtons(props) {

    const language = useSelector(getLanguage);

    function removeMentor(event) {
        event.stopPropagation();
        makeAlert(language,
            "mentor_remove_alert_title",
            "relationship_remove_alert_message",
            async () => {await props.onMentorRemove()}
        );
    }

    function getMentorButtons() {
        if (props.isMentor) {
            return (
                <>
                    <button className="mentor-request-reference" onClick={(event) => {
                        event.stopPropagation();
                        props.onRequestLetterOfReference(props.id);
                    }}>
                        <RequestReference />
                        <div>
                            <Translate label={"request_reference_button"}/>
                        </div>
                    </button>
                    <button className="mentor-item-remove" onClick={(event) => {removeMentor(event)}}
                            disabled={props.disableRemoveMentor}>
                        <RemoveMentor />
                        <div>
                            <Translate label={"remove_mentor_button"}/>
                        </div>
                    </button>
                </>
            );
        }

        return (
            <button className="mentor-item-add" onClick={(event) => {
                props.onMentorAdd();
                event.stopPropagation();
            }} disabled={props.disableAddMentor}>
                <AddMentor />
                <div>
                    <Translate label={"add_mentor_button"}/>
                </div>
            </button>
        );
    }

    return (
        <div className="mentor-buttons">
            { getMentorButtons() }
        </div>
    )
}