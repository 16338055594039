export function throttle(func, delay) {
    let timeoutId;

    return function (...args) {
        if (!timeoutId) {
            func.apply(this, args);
            timeoutId = setTimeout(() => {
                timeoutId = null;
            }, delay);
        }
    };
}