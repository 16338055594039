import React from "react";
import "./ProjectsEditHeader.css";
import ProfileHeaderText from "../../profile.header.text/ProfileHeaderText";
import BackLink from "../../../common/back.link/BackLink";
import InfoLink from "../../../common/info.link/InfoLink";
import AppLinks from "../../../../resources/data/links";

export default function ProjectsEditHeader(props) {
  return (
    <div className={"projects-edit-header"}>
      <BackLink to={props.backTo} />
      <ProfileHeaderText
        className={"projects-edit-header-text"}
        label={props.titleLabel}
        params={props.titleLabelParams}
      />
      <InfoLink to={AppLinks.help_projects_add} />
    </div>
  );
}