import React from "react";
import "./PortfolioViewSeriesItem.css"
import PortfolioViewSeriesItemTitle from "../portfolio.view.seriers.item.title/PortfolioViewSeriesItemTitle";
import FileThumbnail from "../../../common/file.thumbnail/FileThumbnail";
import {classFileThumbnailLarge} from "../../../common/file.thumbnail/FileThumbnail.library";
import {createViewPath, RouteConstants} from "../../../../App.library";
import {makeFileViewer} from "../../../common/file.viewer/FileViewer";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage} from "../../../../store/rootReducer";

export default function PortfolioViewSeriesItem(props) {

    const language = useSelector(getLanguage);
    const dispatch = useDispatch();

    function getSeriesFiles() {
        if (props.files) {
            return props.files;
        }
        return [];
    }

    const onThumbnailClick = (file) => () => {
        makeFileViewer(language, dispatch, file, getSeriesFiles(), props.canEditFiles, props.canMarkFilesSensitive, props.onFileViewerClose);
    }

    return (
        <div className={"portfolio-view-series-item"}>
            <PortfolioViewSeriesItemTitle name={props.name} seriesLink={createViewPath(RouteConstants.PORTFOLIO_VIEW_SERIES, {userId: props.userId, portfolioId: props.portfolioId, seriesId: props.id})}/>
            <div className={"portfolio-view-series-files"}>
                {
                    getSeriesFiles().map((file, index) => {
                        return <FileThumbnail
                            file={file}
                            onThumbnailClick={onThumbnailClick(file)}
                            size={classFileThumbnailLarge}
                            key={file.id}
                        />
                    })
                }
            </div>
        </div>
    )
}