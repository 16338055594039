import React from "react";
import Header from "../../header/Header";
import "./RegistrationLoginLayout.css";
import MessagePanel from "../../message.panel/MessagePanel";
import LoginRegistrationCarousel from "../../login.registration.carousel/LoginRegistrationCarousel";
import Footer from "../../footer/Footer";

export default function RegistrationLoginLayout(props) {
    return (
        <div className="registration-login-layout">
            <section>
                <Header {...props} authRoute={props.authRoute} authLabel={props.authLabel} authOnClick={props.authOnClick} />
                <div className="registration-login-content">
                    <MessagePanel className={"registration-message-panel"} />
                    <div className={"registration-content"}>
                        {props.children}
                    </div>
                </div>
                <Footer/>
            </section>
            <section>
                <LoginRegistrationCarousel />
            </section>
        </div>
    );
}
