import {API_PATHS, makeJSONPostRequest, makeLoginRequest} from "../../services/ajax/ajax";
import {setToken} from "../../services/auth/auth";
import {displayError} from "../../components/message.panel/ducks/actions";
import {handleResponseNotOkay} from "../../services/error/error";
import {loadUserDetails} from "../../services/user.details/userDetails";

async function loginUser(values, dispatch) {
    const response = await makeLoginRequest(
        API_PATHS.LOGIN,
        dispatch,
        JSON.stringify({email: values.email, password: values.password}),
    );
    if (response.ok) {
        setToken(response);
        return response;
    }
    return Promise.reject(response);
}

export async function login(values, dispatch) {
    await loginUser(values, dispatch);
    await loadUserDetails(dispatch);
    await makeJSONPostRequest(API_PATHS.SET_USER_AVAILABLE, dispatch, {available: true});
}

export async function handleSignInError(response, dispatch) {
    if (response.status === 403) {
        dispatch(displayError([{label: "login_failed_text"}]));
        return;
    }
    await handleResponseNotOkay(response, dispatch);
}