import React from "react";
import "./ListItemRectangular.css";

export default function ListItemRectangular(props) {
    const {className, ...rest} = props;

    function getClassName() {
        let newClassName = "list-item-rectangular";
        if (props.className) {
            newClassName += ' ' + className;
        }
        return newClassName;
    }
    return (
        <div className={getClassName()} {...rest}>
            {props.children}
        </div>
    )
}