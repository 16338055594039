import React, {useState} from "react";
import "./MentorItem.css";
import UserImage from "../../user.image/UserImage";
import MentorItemButtons from "../mentor.item.buttons/MentorItemButtons";
import MentorItemNameRole from "../mentor.item.name.role/MentorItemNameRole";
import {useDispatch} from "react-redux";
import {API_PATHS, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import ListItemRounded from "../../common/list/list.item.rounded/ListItemRounded";


export default function MentorItem(props) {
    const [isMentor, updateIsMentor] = useState(props.isMentor);
    const [disableAddMentor, updateDisableAddMentor] = useState(false);
    const [disableRemoveMentor, updateDisableRemoveMentor] = useState(false);

    const dispatcher = useDispatch();

    function onMentorAdd() {
        updateDisableAddMentor(true);
        return makeJSONPostRequest(makeUrl(API_PATHS.MENTOR_ADD, {mentorUserId: props.id}), dispatcher)
            .then((response) => {
                updateIsMentor(true);
            }).finally(() => {
            updateDisableAddMentor(false);
        });
    }

    function onMentorRemove() {
        updateDisableRemoveMentor(true);
        return makeJSONPostRequest(makeUrl(API_PATHS.MENTOR_REMOVE, {mentorUserId: props.id}), dispatcher)
            .then((response) => {
                updateIsMentor(false);
            }).finally(() => {
            updateDisableRemoveMentor(false);
        });
    }

    return (
        <ListItemRounded className={"mentor-item"} onClick={() => {
            props.onItemClick(props.id)
        }}>
            <UserImage image={props.userImage} alt={"Mentor user image for " + props.name} name={props.name} />
            <MentorItemNameRole name={props.name} roles={props.roles} isMentor={isMentor}/>
            {
                props.canBeMentor &&
                    <MentorItemButtons
                        id={props.id}
                        isMentor={isMentor}
                        onMentorAdd={onMentorAdd}
                        onMentorRemove={onMentorRemove}
                        disableAddMentor={disableAddMentor}
                        disableRemoveMentor={disableRemoveMentor}
                        onRequestLetterOfReference={props.onRequestLetterOfReference}
                    />
            }
        </ListItemRounded>
    );
}
