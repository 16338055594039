import React, {useState} from "react";
import "./SkillTagging.css";
import Translate from "../../translate/Translate";
import TextSelect from "../../text.select/TextSelect";
import TextInput from "../../text.input/TextInput";
import ButtonAccept from "../../button.accept/ButtonAccept";
import {getSkillToAdd} from "./SkillTagging.library";
import {onEnterClickCreator} from "../../../../services/util/util";
import SkillTaggingList from "../skill.tagging.list/SkillTaggingList";
import InfoLink from "../../info.link/InfoLink";
import AppLinks from "../../../../resources/data/links";

export default function SkillTagging(props) {

    const [dropdownSkill, updateDropdownSkill] = useState('');
    const [textSkill, updateTextSkill] = useState('');

    function onAddSkillTag() {
        const skillAdding = getSkillToAdd(dropdownSkill, textSkill, props.skillsOptions, props.skillTags);
        if (skillAdding) {
            props.onAddSkillTag(skillAdding);
            updateTextSkill('');
            updateDropdownSkill('');
        }
    }

    const OnKeyDown = onEnterClickCreator(onAddSkillTag);

    return (
        <div className={"skill-tagging"}>
            <SkillTaggingList
                showTitle={true}
                skillTags={props.skillTags}
                onRemoveSkillTag={props.onRemoveSkillTag}
            />
            <div className={"skill-tagging-add"} onKeyDown={OnKeyDown}>
                <div className={"skill-add-tag-title"}>
                    <Translate label={"skills_edit_add_tags"} />
                    <InfoLink to={AppLinks.help_tags} style={{ marginLeft: '1rem' }} />
                </div>
                <div className={"skill-tagging-add-input"}>
                    <TextSelect
                        options={props.skillsOptions || []}
                        value={dropdownSkill}
                        disabled={props.isSubmitting}
                        onChange={(event) => {
                            updateDropdownSkill(event.target.value);
                            updateTextSkill('')
                        }}
                        placeholder={"skill_tag_dropdown_placeholder"}
                    />
                    <TextInput
                        value={textSkill}
                        disabled={props.isSubmitting}
                        onChange={(event) => {
                            updateTextSkill(event.target.value)
                            updateDropdownSkill('');
                        }}
                    />
                    <div style={{height: '50%', marginTop: '0.8rem'}}>
                        <ButtonAccept
                            type={"button"}
                            onClick={onAddSkillTag}
                            label={"skill_add_button_label"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}