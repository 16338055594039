import React from "react";
import CheckboxInput from "../checkbox.input/CheckboxInput";
import "./CheckboxGroup.css";
import Label from "../label/Label";

export default function CheckboxGroup(props) {
    return (
        <div className={"checkbox-group"}>
            <Label htmlFor={props.id} label={props.label} params={props.labelParams} />
            <div className={"checkbox-options"}>
                {
                    props.options.map((option) => {
                        return (
                            <>
                                <CheckboxInput
                                    label={option.label}
                                    disabled={props.disabled}
                                    id={option.value}
                                    checked={props.values.indexOf(option.value) !== -1}
                                    onChange={(event) => {props.onChange(option.value, event)} }
                                />
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}