import React, {useState} from "react";
import TextInput from "../../common/text.input/TextInput";
import TextKeyword from "../../common/text.keyword/TextKeyword";
import CheckboxInput from "../../common/checkbox.input/CheckboxInput";
import SearchButton from "../../common/search.button/SearchButton";
import "./SearchPrivateParams.css";
import ListParamsContainer from "../../common/list/list.params.container/ListParamsContainer";
import {onEnterClickCreator} from "../../../services/util/util";

export default function SearchPrivateParams(props) {
    const [areFiltersHidden, updateAreFiltersHidden] = useState(false);
    const onEnter = onEnterClickCreator(props.onSearch);

    return (
        <ListParamsContainer
            isHidden={areFiltersHidden}
            onHiddenClick={() => {
                updateAreFiltersHidden(!areFiltersHidden)
            }}
            showLabel={"search_view_show_search_filters"}
            hideLabel={"search_view_hide_search_filters"}
            className={"search-params-private-container"}
            onKeyDown={onEnter}
        >
            <div className={"search-params-private"}>
                <TextInput
                    id={"search-name"}
                    label={"search_parameter_name_label"}
                    placeholder={"search_parameter_name_label_placeholder"}
                    onChange={(event) => {
                        props.onNameChange(event.target.value);
                    }}
                    value={props.name}
                />
                <TextInput
                    id={"search-email"}
                    label={"search_parameter_email_label"}
                    placeholder={"search_parameter_email_label_placeholder"}
                    onChange={(event) => {
                        props.onEmailChange(event.target.value)
                    }}
                    value={props.email}
                />
                <TextKeyword
                    id={"search-keywords"}
                    label={"search_keywords_label"}
                    placeholder={"search_keywords_label_placeholder"}
                    onChange={(event) => {
                        props.onKeywordsChange(event.target.value)
                    }}
                    onClear={props.onKeywordClear}
                    value={props.keywords}
                />
                <div className={"search-params-private-checkboxes"}>
                    <CheckboxInput
                        id={"search-params-show-only-mine-peer"}
                        label={"search_parameter_show_my_peers"}
                        onChange={(event) => {
                            props.onShowOnlyMyPeersChange(event.target.checked)
                        }}
                        checked={props.showOnlyMyPeers}
                    />
                    <CheckboxInput
                        id={"search-params-has-image"}
                        label={"search-param-has-image"}
                        onChange={(event) => {
                            props.onHasImageChanged(event.target.checked)
                        }}
                        checked={props.hasProfileImage}
                    />
                    <CheckboxInput
                        id={"search-params-has portfolio-works"}
                        label={"search-param-has-portfolio-works"}
                        onChange={(event) => {
                            props.onHasPortfolioWorksChanged(event.target.checked)
                        }}
                        checked={props.hasPortfolioWorks}
                    />
                    <CheckboxInput
                        id={"search-params-open-to-collaboration"}
                        label={"search-param-open-to-collaboration"}
                        onChange={(event) => {
                            props.onOpenToCollaborationChanged(event.target.checked)
                        }}
                        checked={props.openToCollaboration}
                    />
                </div>
            </div>
            <SearchButton onClick={props.onSearch}/>
        </ListParamsContainer>
    )
}
