import React from "react";
import {InfoIcon} from "../svg/svg";

export default function InfoLink(props) {

    function getClassName() {
        let className = 'info-link';
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    function getHref() {
        if (props.to) {
            return process.env.REACT_APP_HELP_BASE + props.to
        }
        return '#';
    }

    return (
        <a 
            className={getClassName()} 
            href={getHref()} 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="Link to Sketch Documentation"
            style={props.style}
        >
            <InfoIcon/>
        </a>
    )
}
