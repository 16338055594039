import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import {createViewPath, RouteConstants} from "../../../App.library";
import {makeAlert} from "../../../components/common/alert/Alert";
import {getLanguage} from "../../../store/rootReducer";
import BasicLayout from "../../../components/layouts/basic.layout/BasicLayout";
import ListResult from "../../../components/common/list.result/ListResult";
import ProgramRequestParticipationButton from "../../../components/programs/program.request.participation.button/ProgramRequestParticipationButton";
import EditDeleteLink from "../../../components/common/edit.delete.link/EditDeleteLink";
import Translate from "../../../components/common/translate/Translate";
import {formatLocalDate} from "../../../services/date/date";
import LineSplit from '../../../components/common/linesplit/LineSplit';
import SkillTaggingList from "../../../components/common/skill/skill.tagging.list/SkillTaggingList";
import ViewProgramEvents from "../../../components/programs/program.events/ViewProgramEvents";
import "./ProgramView.css";

export default function ProgramView(props) {
    const dispatch = useDispatch();
    const language = useSelector(getLanguage);
    const [program, updateProgram] = useState({});

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PROGRAM_GET, {id: props.match.params.id}), dispatch)
            .then((response) => {
                updateProgram(response.body);
            })
    }, [props.match.params.id, dispatch]);

    function goToListResult(id) {
        props.history.push({
            pathname: createViewPath(RouteConstants.PROGRAM_VIEW, {id}),
            state: props.location.state
        });
    }

    async function deleteProgram() {
        await makeJSONPostRequest(makeUrl(API_PATHS.PROGRAM_DELETE, {id: props.match.params.id}), dispatch);
        props.history.push(RouteConstants.PROGRAMS)
    }

    function updateParticipationStatus(programId, status) {
        updateProgram({...program, participationStatus: status});
    }

    function onDelete() {
        makeAlert(
            language,
            "program_delete_modal_title",
            "program_delete_alert_body",
            async () => {await deleteProgram()}
        )
    }

    return (
        <BasicLayout
            {...props}
        >
            <div className={"program-view"}>
                {
                    props.location.state &&
                    <ListResult
                        className={'programs-list-result'}
                        label={"program_next_label"}
                        resultIds={props.location.state.programIds}
                        currentId={props.match.params.id}
                        onForwardClick={goToListResult}
                        onBackClick={goToListResult}
                    />
                }
                <div>
                    <h1>{program.name}</h1>
                </div>
                {
                    Object.keys(program).length > 0 &&
                    <div className={'program-view-body'}>
                        {
                            program.canParticipate &&
                            <ProgramRequestParticipationButton
                                className={"program-item-participation-button"}
                                programId={props.match.params.id}
                                status={program.participationStatus}
                                updateParticipationStatus={updateParticipationStatus}
                            />
                        }
                        {
                            program.canEdit &&
                            <EditDeleteLink
                                className={"program-item-edit-link"}
                                editTo={{pathname: createViewPath(RouteConstants.PROGRAM_EDIT, {id: props.match.params.id}), state: props.location.state}}
                                onDelete={onDelete}
                            />
                        }
                        <div className={'program-view-body-text'}>
                            <div className={"program-dates"}>
                                <div>
                                    <div className="section-header"><Translate label={"program_start_date_label"}/></div>
                                    <p>{formatLocalDate(program.startDate)}</p>
                                </div>
                                <div>
                                    <div className="section-header"><Translate label={"program_end_date_label"}/></div>
                                    <p>{formatLocalDate(program.endDate)}</p>
                                </div>
                            </div>
                            <div>
                                <div className="section-header"><Translate label={"program_description_label"}/></div>
                                <LineSplit string={program.description} separator={'\n'} />
                            </div>
                            {
                                program.processDocumentation &&
                                <div>
                                    <div className="section-header"><Translate label={"program_how_to_apply_label"}/></div>
                                    <LineSplit string={program.processDocumentation} separator={'\n'} />
                                </div>
                            }
                            {
                                program.programEvents && program.programEvents.length > 0 &&
                                <div>
                                    <div className="section-header"><Translate label={"program_events_label"}/></div>
                                    <p>
                                        <ViewProgramEvents events={program.programEvents}/>
                                    </p>
                                </div>
                            }
                            {
                                program.skillTags && program.skillTags.length > 0 &&
                                <SkillTaggingList skillTags={program.skillTags} />
                            }
                        </div>
                    </div>
                }
            </div>
        </BasicLayout>
    )
}