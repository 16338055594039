import React, {useState} from "react";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {translate} from "../translate/Translate.library";
import "./Alert.css"

function AlertComponent({onClose, alertTitle, alertMessage, onConfirm, language, alertBodyParams}) {
    const [confirming, updateConfirming] = useState(false);
    return (
        <div className="popup-alert">
            <h1>{translate(alertTitle, language)}</h1>
            <p>{translate(alertMessage, language, alertBodyParams)}</p>
            <div className="buttons">
                <button className="confirm-button" onClick={async () => {
                    try {
                        updateConfirming(true)
                        await onConfirm();
                    } catch (error) {

                    } finally {
                        updateConfirming(false);
                        onClose();
                    }
                }}>
                    {translate('confirm_button', language)}
                </button>
                <button className="cancel-button" onClick={onClose} disabled={confirming}>
                    {translate('cancel_button', language)}
                </button>
            </div>
        </div>
    );
}

export function makeAlert(language, alertTitle, alertMessage, onConfirm, alertBodyParams) {
    confirmAlert({
        customUI:({onClose}) => {
            return (
                <AlertComponent language={language} alertTitle={alertTitle} alertMessage={alertMessage} onConfirm={onConfirm} onClose={onClose} alertBodyParams={alertBodyParams}/>
            )
        }
    });
}