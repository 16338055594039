import React, { useState, useCallback } from 'react'
import { Field, Form, Formik } from 'formik'
import PortfolioEditHeader from '../portfolio.edit.header/PortfolioEditHeader'
import './PortfolioEditForm.css'
import * as Yup from 'yup'
import ProfileTextInput from '../../profile.text.input/ProfileTextInput'
import ProfileTextArea from '../../profile.text.area/ProfileTextArea'
import Translate from '../../../common/translate/Translate'
import PortfolioEditThumbnailInfo from '../portfolio.edit.thumbnail.info/PortfolioEditThumbnailInfo'
import ButtonAccept from '../../../common/button.accept/ButtonAccept'
import PortfolioEditSeries from '../portfolio.edit.series/PortfolioEditSeries'
import {
  API_PATHS,
  makeJSONGetRequest,
  makeUrl,
} from '../../../../services/ajax/ajax'
import { useDispatch } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import AppLinks from '../../../../resources/data/links'
import { createOnBlurFunction } from '../../../../services/formik/formik'
import { trackPromise } from 'react-promise-tracker'
import FormikUnsavedChangesPrompt from '../../../common/formik.unsaved.changes.prompt/FormikUnsavedChangesPrompt'
import ButtonSaveCancel from '../../../common/button.save.cancel/ButtonSaveCancel'
import FileUpload from '../../../common/file.upload/FileUpload'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import CheckboxInput from '../../../common/checkbox.input/CheckboxInput'
import { isMusicPlatform } from '../../../../services/file/file'

const PortfolioSchema = Yup.object().shape({
  name: Yup.string().required(
    <Translate label={'validation_message_required'} />
  ),
  description: Yup.string(),
  artisticStatement: Yup.string(),
  series: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(
        <Translate label={'validation_message_required'} />
      ),
    })
  ),
  isDraft: Yup.bool()
})

export default function PortfolioEditForm(props) {
  const isMobileScreen = useMediaQuery('(min-width: 600px)')
  const dispatcher = useDispatch()
  const [files, setFiles] = useState(props.formData.files)
  const [newFileId, setNewFileId] = useState(-1)

  async function getArtistStatement(formProps) {
    makeJSONGetRequest(
      makeUrl(API_PATHS.PROFILE_ARTIST_STATEMENT, { userId: props.userId }),
      dispatcher
    ).then((response) => {
      formProps.setFieldValue(
        'artisticStatement',
        response.body.artistStatement || ''
      )
    })
  }

  async function getPersonalInfo(formProps) {
    makeJSONGetRequest(
      makeUrl(API_PATHS.PROFILE_PERSONAL_INFO, { userId: props.userId }),
      dispatcher
    ).then((response) => {
      formProps.setFieldValue('artisticStatement', response.body.personalInfo || '')
    })
  }

  function markSelectedFile(formProps, selectedFile) {
    const portfolioSeries = cloneDeep(formProps.values.series)
    portfolioSeries.forEach((series) => {
      series.files.forEach((file) => {
        file.isSelected = file.id === selectedFile.id
      })
    })
    formProps.setFieldValue('series', portfolioSeries)
  }

  const onSubmit = useCallback(
    (values, actions) => {
      trackPromise(
        props
          .onSubmit({ ...values, files })
          .then(() => {
            actions.resetForm()
          })
          .finally(() => {
            actions.setSubmitting(false)
          })
      )
    },
    [files]
  )

  const styles = {
    container: (isMobileScreen) => ({
      display: 'flex',
      flexDirection: isMobileScreen ? 'row' : 'column',
      justifyContent: 'stretch',
      gap: '1rem',
    }),
    child: (isMobileScreen) => ({ width: isMobileScreen ? '50%' : '100%' }),
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      marginBottom: '1rem',
    },
    portfolioEditHeader: { marginBottom: '1rem' },
  }

  function newfilesOnChange(newFiles, formProps) {

    const portfolioSeries = cloneDeep(
      formProps.values.series.length > 0 ? formProps.values.series : [{name: 'Miscellaneous', files: []}]
    );

    if (newFiles !== 'array' && (newFiles.type === 'video' || isMusicPlatform(newFiles.type))) {
      const newFile = { id: newFileId, ...newFiles };
      setFiles([...files, newFile]);
      portfolioSeries[0].files.unshift({ ...newFile, isSelected: false });
    } else {
      const addedFiles = [];
      for (let i = 0; i < newFiles.length; i++) {
        const newFile = { id: newFileId - i, ...newFiles[i] };
        addedFiles.push(newFile);
        portfolioSeries[0].files.unshift({ ...newFile, isSelected: false });
      }
      setFiles([...files, ...addedFiles]);
    }

    formProps.setFieldValue('series', portfolioSeries);

    setNewFileId((prevId) => {
      const newId = prevId - (newFiles.length ?? 1);
      return newId;
    });
  }

  return (
    <Formik
    initialValues={props.formData}
    validationSchema={PortfolioSchema}
    onSubmit={onSubmit}
    validateOnChange={false}
    validateOnBlur={false}
    >
      {(formProps) => (
        <div style={styles.container(isMobileScreen)}>
          <div style={styles.child(isMobileScreen)}>
            <Form className={'portfolio-edit-form'}>
              <div style={styles.portfolioEditHeader}>
                <PortfolioEditHeader
                  titleLabel={props.titleLabel}
                  titleLabelParams={props.titleLabelParams}
                  backTo={props.backTo}
                />
              </div>
              <Field name="name">
                {(fieldProps) => (
                  <ProfileTextInput
                    {...fieldProps.field}
                    id="name"
                    name={'name'}
                    label="portfolio_edit_title_label"
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    disabled={formProps.isSubmitting}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <Field name={'description'}>
                {(fieldProps) => (
                  <ProfileTextArea
                    {...fieldProps.field}
                    id={'description'}
                    name={'description'}
                    label={'portfolio_edit_description_label'}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    disabled={formProps.isSubmitting}
                    infoLink={AppLinks.help_portfolio_description}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <Field name={'artisticStatement'}>
                {(fieldProps) => (
                  <ProfileTextArea
                    {...fieldProps.field}
                    id={'artisticStatement'}
                    name={'artisticStatement'}
                    label={'portfolio_edit_statement_label'}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    disabled={formProps.isSubmitting}
                    infoLink={AppLinks.help_artist_statement}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <div style={styles.buttonContainer}>
                <ButtonAccept
                  buttonStyle={{ padding: '1rem' }}
                  type={'button'}
                  disabled={formProps.isSubmitting}
                  label="portfolio_edit_copy_personal_info"
                  onClick={() => {
                    getPersonalInfo(formProps)
                  }}
                />
                <ButtonAccept
                  buttonStyle={{ padding: '1rem' }}
                  type={'button'}
                  disabled={formProps.isSubmitting}
                  label="portfolio_edit_copy_artist_statement"
                  onClick={() => {
                    getArtistStatement(formProps)
                  }}
                />
              </div>
              <PortfolioEditThumbnailInfo
                label={'portfolio_edit_thumbnail_label'}
                thumbnail={formProps.values.thumbnail}
                thumbnailText={'portfolio_edit_thumbnail_text'}
              />
              <PortfolioEditSeries
                label={'portfolio_edit_series_label'}
                series={formProps.values.series}
                disabled={formProps.isSubmitting}
                updateThumbnail={(file) => {
                  markSelectedFile(formProps, file)
                  formProps.setFieldValue('thumbnail', file)
                }}
                onRemoveFile={(fileIds) => {
                  const filesCopy = cloneDeep(files);
                  for (const fileId of fileIds) {
                    const fileIndex = filesCopy.findIndex(file => file.id === fileId);
                    if (fileIndex > -1) {
                      filesCopy.splice(fileIndex, 1);
                    }  
                  }
                  setFiles(filesCopy);
                }}
                formProps={formProps}
              />
              <CheckboxInput
                className={"portfolio-edit-save-draft"}
                label={"portfolio_edit_save_draft"}
                id={"save-draft-checkbox"}
                onChange={(event) => {
                  formProps.setFieldValue('draft', event.target.checked);
                }}
                checked={formProps.values.draft}
                disabled={formProps.isSubmitting}
              />
              <ButtonSaveCancel
                onCancel={props.onCancel}
                disabled={formProps.isSubmitting}
              />
              <FormikUnsavedChangesPrompt formikProps={formProps} />
            </Form>
          </div>
          <div style={styles.child(isMobileScreen)}>
            <FileUpload
              multiple
              showAddVideo
              showAddMusicPlatform
              onChange={(newFiles) => newfilesOnChange(newFiles, formProps)}
              showFileUploadList={false}
              buttonLabel="upload_files_heading"
              buttonWrapperClassName="portfolio-edit-button-wrapper"
              buttonClassName="portfolio-edit-button-accept"
            />
          </div>
        </div>
      )}
    </Formik>
  )
}
