import English from '../../../resources/data/en.json'
import French from '../../../resources/data/fr.json'

// Using a home grown way of doing translations, since nothing complicated is really necessary
// This can always be swapped out with a library if necessary

export const Language = {
    EN: 'en',
    FR: 'fr'
};

function Translate() {
    const translatedValues = {
        en: {
            ...English
        },
        fr: {
            ...French
        }
    };

    return (key, language, params) => {
        language = language || Language.EN;
        language = language.toLowerCase();
        let label = translatedValues[language][key];

        if (!label) {
            return key;
        }

        if (!params) {
            return label;
        }

        Object.keys(params).forEach(paramKey => {
            label = label.replace('${' + paramKey + '}', params[paramKey]);
        });
        return label;
    }
}

const translation = Translate();

export function translate(key, language, params) {
    return translation(key, language, params);
}
