import React from 'react';
import ListItemRounded from '../../common/list/list.item.rounded/ListItemRounded';
import UserImage from '../../user.image/UserImage';
import SearchItemNameStatement from '../../search/search.item.name.statement/SearchItemNameStatement';
import ProgramParticipationAssociationButton from '../program.participation.association.button/ProgramParticipationAssociationButton';
import './ProgramParticipationListItem.css';

export default function ProgramParticipationListItem(props) {
    return (
        <ListItemRounded className={"program-participation-list-item"}>
            <UserImage image={props.userImage} alt={"User image for " + props.name} name={props.name} />
            <SearchItemNameStatement name={props.name} />
            <ProgramParticipationAssociationButton
                programId={props.programId}
                userId={props.id}
                participationStatus={props.participationStatus}
                updateSearchResultParticipation={props.updateSearchResultParticipation}
            />
        </ListItemRounded>
    )
}