import React from "react";
import "./FileViewDocument.css";
import {FileDocumentIcon} from "../../svg/svg";
import {translate} from "../../translate/Translate.library";

export default function FileViewDocument(props) {

    function getClassName() {
        let className = "file-view-document";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <label>{translate('file_view_document_text', props.language)}</label>
            <FileDocumentIcon/>
        </div>
    )
}