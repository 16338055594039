import React from "react";
import * as Yup from 'yup';
import Translate from "../../../components/common/translate/Translate";
import {trackPromise} from "react-promise-tracker";
import {createOnBlurFunction} from "../../../services/formik/formik";
import {Field, Form, Formik} from "formik";
import TextInput from "../../common/text.input/TextInput";
import TextDatePicker from "../../common/text.date.picker/TextDatePicker";
import {getDateValue} from "../../../services/date/date";
import TextArea from "../../common/text.area/TextArea";
import SkillTagging from '../../common/skill/skill.tagging/SkillTagging'
import cloneDeep from 'lodash/cloneDeep'
import ButtonAccept from "../../common/button.accept/ButtonAccept";
import FormikUnsavedChangesPrompt from '../../common/formik.unsaved.changes.prompt/FormikUnsavedChangesPrompt'
import "./ProgramForm.css";

const ProgramSchema = Yup.object().shape({
  name: Yup.string()
    .required(<Translate label={"validation_message_required"}/>),
  startDate: Yup.date()
    .typeError(<Translate label={"validation_message_required"}/>),
  endDate: Yup.date()
    .typeError(<Translate label={"validation_message_required"}/>)
    .test({
      name: 'min',
      exclusive: false,
      params: { },
      message: <Translate label={"validation_invalid_date_order"}/>,
      test: function (value) {
        return value >= this.parent.startDate
      }
    }),
  description: Yup.string()
    .required(<Translate label={"validation_message_required"}/>),
  processDocumentation: Yup.string()
});

export default function ProgramForm(props) {
  return (
    <Formik
      initialValues={props.formData}
      validationSchema={ProgramSchema}
      onSubmit={(values, actions) => {
        trackPromise(
          props.onSubmit(values)
            .finally(() => {
              actions.setSubmitting(false);
            })
        )
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {
        formProps => (
          <Form className={"program-form"}>
            <Field name="name">
              {(fieldProps) => (
                  <TextInput
                    {...fieldProps.field}
                    id={"name"}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    name={"name"}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    disabled={formProps.isSubmitting}
                    label={"program_name_label"}
                    formikProps={formProps}
                  />
                )
              }
            </Field>
            <div className={"program-form-dates"}>
              <Field name={'startDate'}>
                {(fieldProps) => (
                  <TextDatePicker
                    selected={fieldProps.field.value && getDateValue(fieldProps.field.value)}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    label={'program_start_date_label'}
                    id={'startDate'}
                    name={'startDate'}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    disabled={formProps.isSubmitting}
                    onChange={(value) => {
                      formProps.setFieldValue(fieldProps.field.name, value)
                    }}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <Field name={'endDate'}>
                {(fieldProps) => (
                  <TextDatePicker
                    selected={fieldProps.field.value && getDateValue(fieldProps.field.value)}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    label={'program_end_date_label'}
                    id={'endDate'}
                    name={'endDate'}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    disabled={formProps.isSubmitting}
                    minDate={formProps.values.startDate}
                    onChange={(value) => {
                      formProps.setFieldValue(fieldProps.field.name, value)
                    }}
                    formikProps={formProps}
                  />
                )}
              </Field>
            </div>
            <Field name="description">
              {(fieldProps) => (
                <TextArea
                  {...fieldProps.field}
                  className={"program-form-description"}
                  id={"description"}
                  hasError={fieldProps.meta.error}
                  errorMessage={fieldProps.meta.error}
                  name={"description"}
                  onBlur={createOnBlurFunction(formProps, fieldProps)}
                  disabled={formProps.isSubmitting}
                  label={"program_description_label"}
                  formikProps={formProps}
                />
                )
              }
            </Field>
            <Field name="processDocumentation">
              {(fieldProps) => (
                <TextArea
                  {...fieldProps.field}
                  className={"program-form-process-documentation"}
                  id={"processDocumentation"}
                  hasError={fieldProps.meta.error}
                  errorMessage={fieldProps.meta.error}
                  name={"processDocumentation"}
                  onBlur={createOnBlurFunction(formProps, fieldProps)}
                  disabled={formProps.isSubmitting}
                  label={"program_how_to_apply_label"}
                  formikProps={formProps}
                />
                )
              }
            </Field>
            <SkillTagging
              skillTags={formProps.values.skillTags}
              skillsOptions={props.skillsOptions}
              onAddSkillTag={(skillTag) => {
                formProps.setFieldValue(
                  'skillTags',
                  formProps.values.skillTags.concat([skillTag])
                )
              }}
              onRemoveSkillTag={(_, index) => {
                const newSkillTags = cloneDeep(formProps.values.skillTags)
                newSkillTags.splice(index, 1)
                formProps.setFieldValue('skillTags', newSkillTags)
              }}
            />
            <div className={"program-save"}>
              <ButtonAccept
                className={"submit-button"}
                disabled={formProps.isSubmitting}
                label={props.submitLabel}
              />
            </div>
            <FormikUnsavedChangesPrompt formikProps={formProps} />
          </Form>
        )
      }
    </Formik>
  )
}