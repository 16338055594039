import React from "react";
import CheckboxInput from "../../../common/checkbox.input/CheckboxInput";
import "./AboutEditHeader.css"
import BackLink from "../../../common/back.link/BackLink";

export default function AboutEditHeader(props) {

    return (
        <div className="about-edit-header-container">
            <BackLink to={props.backTo}/>
            {
                props.canEditOpenToCollaborate &&
                <div className="checkboxes">
                    <CheckboxInput
                        id="about-edit-open-to-collaborate"
                        label="about_open_to_collaborate_label"
                        onChange={(event) => {
                            props.onIsOpenToCollaborateChange(event.target.checked)
                        }}
                        checked={props.isOpenToCollaborate}
                        disabled={props.disabled}/>
                </div>
            }

        </div>
    )
}