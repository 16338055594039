export const displaySuccess = (messages) => {
    window.scrollTo(0,0); // Scrolling to the top of the page when displaying a message. Probably should be moved to a better place.
    return {
        type: 'DISPLAY_SUCCESS',
        payload: {
            messages
        }
    };
};

export const displayError = (messages) => {
    window.scrollTo(0,0); // Scrolling to the top of the page when displaying a message. Probably should be moved to a better place.
    return {
        type: 'DISPLAY_ERROR',
        payload: {
            messages
        }
    };
};

export const clearMessage = () => {
    return {
        type: 'CLEAR_MESSAGE'
    };
};
