import React, {useState} from "react";
import "./UserManagementEditForm.css";
import {Field, Form, Formik} from "formik";
import TextInput from "../../common/text.input/TextInput";
import {createOnBlurFunction} from "../../../services/formik/formik";
import * as Yup from 'yup';
import Translate from "../../common/translate/Translate";
import PhoneNumberInput from "../../common/phone.number.input/PhoneNumberInput";
import CheckboxInput from "../../common/checkbox.input/CheckboxInput";
import {createPasswordSchema} from "../../../services/yup/yup";
import useFetchOrStore from "../../../hooks/useFetchOrStore";
import {getUserRoleOptions} from "../../../store/rootReducer";
import {API_PATHS, makeJSONGetRequest} from "../../../services/ajax/ajax";
import {useDispatch} from "react-redux";
import {setUserRoleOptions} from "../../../services/select.options/ducks/actions";
import CheckboxGroup from "../../common/checkbox.group/CheckboxGroup";
import cloneDeep from "lodash/cloneDeep";
import {UserManagementButtonType} from "./UserManagementEditForm.library";
import ButtonAccept from "../../common/button.accept/ButtonAccept";
import {trackPromise} from "react-promise-tracker";

const UserManagementSchema = Yup.object().shape({
    name: Yup.string()
        .required(<Translate label={"validation_message_required"}/>),
    email: Yup.string()
        .email(<Translate label={"validation_message_invalid_email"}/>)
        .required(<Translate label={"validation_message_required"}/>),
    ...createPasswordSchema()
});

export default function UserManagementEditForm(props) {
    const dispatch = useDispatch();
    const [userRoles] = useFetchOrStore(getUserRoleOptions, getUserRoles);
    const [submitType, updateSubmitType] = useState(UserManagementButtonType.SAVE);

    async function getUserRoles() {
        const response = await makeJSONGetRequest(API_PATHS.GET_USER_ROLES, dispatch);
        dispatch(setUserRoleOptions(response.body.options));
    }

    function getClassName() {
        let className = "user-management-edit-form";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    function getSubmitFunction() {
        switch (submitType) {
            case UserManagementButtonType.SAVE:
                return props.onSave;
            case UserManagementButtonType.APPROVE:
                return props.onApprove;
            case UserManagementButtonType.REJECT:
                return props.onReject;
            default:
                throw new Error('No approve function found');
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={props.formData}
            validationSchema={UserManagementSchema}
            onSubmit={(values, actions) => {
                const submitFunction = getSubmitFunction();
                trackPromise(
                    submitFunction(values).then(() => {
                        actions.resetForm();
                    }).finally(() => {
                        actions.setSubmitting(false);
                    })
                )
            }}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {
                formProps => (
                    <Form className={getClassName()}>
                        <Field name="name">
                            {
                                fieldProps => (
                                    <TextInput
                                        {...fieldProps.field}
                                        id="name" label="name_label" placeholder="name_label_placeholder"
                                        onBlur={createOnBlurFunction(formProps, fieldProps)}
                                        hasError={fieldProps.meta.error} errorMessage={fieldProps.meta.error}
                                        disabled={formProps.isSubmitting}
                                        formikProps={formProps}
                                    />
                                )
                            }
                        </Field>
                        <Field name="email">
                            {
                                fieldProps => (
                                    <TextInput
                                        {...fieldProps.field}
                                        id="email" label="email_label" placeholder="email_label_placeholder"
                                        onBlur={createOnBlurFunction(formProps, fieldProps)}
                                        hasError={fieldProps.meta.error} errorMessage={fieldProps.meta.error}
                                        disabled={formProps.isSubmitting}
                                        formikProps={formProps}
                                    />
                                )
                            }
                        </Field>
                        <PhoneNumberInput
                            id="phoneNumber" label="phone_number_label" placeholder="phone_number_placeholder"
                            name="phoneNumber"
                            value={formProps.values.phoneNumber}
                            onChange={formProps.handleChange}
                            disabled={formProps.isSubmitting}
                        />
                        <Field name="password">
                            {
                                fieldProps => (
                                    <TextInput
                                        {...fieldProps.field}
                                        id="password" label="password_label" placeholder="changeable_password_placeholder"
                                        type="password"
                                        onBlur={createOnBlurFunction(formProps, fieldProps)}
                                        hasError={fieldProps.meta.error} errorMessage={fieldProps.meta.error}
                                        disabled={formProps.isSubmitting}
                                        formikProps={formProps}
                                    />
                                )
                            }
                        </Field>
                        <Field name="confirmPassword">
                            {
                                fieldProps => (
                                    <TextInput
                                        {...fieldProps.field}
                                        id="confirmPassword" label="confirm_password_label" placeholder="changeable_password_placeholder"
                                        type="password"
                                        onBlur={createOnBlurFunction(formProps, fieldProps)}
                                        hasError={fieldProps.meta.error} errorMessage={fieldProps.meta.error}
                                        disabled={formProps.isSubmitting}
                                        formikProps={formProps}
                                    />
                                )
                            }
                        </Field>
                        <CheckboxGroup
                            label={"user_management_role"}
                            values={formProps.values.roles}
                            options={userRoles || []}
                            disabled={formProps.isSubmitting}
                            onChange={(value, event) => {
                                if (event.target.checked) {
                                    formProps.setFieldValue("roles", formProps.values.roles.concat([value]));
                                } else {
                                    const index = formProps.values.roles.indexOf(value);
                                    const newRoles = cloneDeep(formProps.values.roles);
                                    newRoles.splice(index, 1);
                                    formProps.setFieldValue("roles", newRoles);
                                }
                            }}
                        />
                        <CheckboxInput
                            id="shouldSendEmailNotifications" label="email_notification_label"
                            name={"shouldSendEmailNotifications"}
                            checked={formProps.values.shouldSendEmailNotifications}
                            onChange={formProps.handleChange}
                            disabled={formProps.isSubmitting}
                        />
                        {
                            !props.needsApproval &&
                                <CheckboxInput
                                    id={"inActive"}
                                    name={"inActive"}
                                    label={"user_management_mark_as_inactive"}
                                    checked={formProps.values.inActive}
                                    onChange={formProps.handleChange}
                                    disabled={formProps.isSubmitting}
                                />
                        }

                        {
                            props.needsApproval ?
                                <div className={"user-management-approve-reject"}>
                                    <div>
                                        <ButtonAccept type={"button"} label={"user_management_approve_button"} onClick={() => {
                                            updateSubmitType(UserManagementButtonType.APPROVE);
                                            formProps.handleSubmit();
                                        }}/>
                                    </div>
                                    <div className={"user-management-reject"}>
                                        <ButtonAccept type={"button"} label={"user_management_reject_button"} onClick={() => {
                                            updateSubmitType(UserManagementButtonType.REJECT);
                                            formProps.handleSubmit();
                                        }}/>
                                    </div>
                                </div>
                                :
                                <div className={"user-management-save"}>
                                    <ButtonAccept type={"button"} label={"user_management_save_button"} onClick={() => {
                                        updateSubmitType(UserManagementButtonType.SAVE);
                                        formProps.handleSubmit();
                                    }}/>
                                </div>
                        }
                    </Form>
                )
            }
        </Formik>
    )
}
