import {Language} from "../Translate.library";

function toggleLanguage(currentLanguage) {
    if (currentLanguage === Language.EN) {
        return Language.FR
    }
    return Language.EN
}

export const language = (state = Language.EN, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            return action.payload.language.toLowerCase();
        case 'TOGGLE_LANGUAGE':
            return toggleLanguage(state);
        default:
            return state;
    }
}

export const getLanguageFromLanguageState = (state) => state;