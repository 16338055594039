export function createOnChangeFunction(formikProps, fieldProps) {
    return (event) => {
        fieldProps.field.onChange(event);
        formikProps.validateField(fieldProps.field.name);
    }
}

export function createOnBlurFunction(formikProps, fieldProps) {
    return (event) => {
        fieldProps.field.onBlur(event);
        formikProps.validateField(fieldProps.field.name);
    }
}
