import React, {useCallback, useEffect} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import Login from "./views/login/Login";
import SignUp from "./views/sign.up/SignUp";
import {LOGGED_OUT_HOME_PAGE, PrivilegeConstants, RouteConstants} from "./App.library";
import PrivateRoute from "./components/common/private.route/PrivateRoute";
import RegistrationExplanation from "./views/registration.explanation/RegistrationExplanation";
import {useDispatch, useSelector} from "react-redux";
import {clearMessage} from "./components/message.panel/ducks/actions";
import Mentors from "./views/mentors/Mentors";
import {getLanguage, getMessages, isLoggedOut} from "./store/rootReducer";
import {logOut} from "./services/auth/auth";
import {destroySession} from "./store/rootActions";
import Search from "./views/search/Search";
import Invite from "./views/invite/Invite";
import Opportunities from "./views/opportunities/Opportunities";
import OpportunitiesSubmit from "./views/opportunities/opportunities.submit/OpportunitiesSubmit";
import OpportunitiesView from "./views/opportunities/opportunities.view/OpportunitiesView";
import OpportunitiesCreate from "./views/opportunities/opportunities.create/OpportunitiesCreate";
import OpportunitiesEdit from "./views/opportunities/opportunities.edit/OpportunitiesEdit";
import UserManagement from "./views/user.management/UserManagement";
import UserManagementEdit from "./views/user.management/user.management.edit/UserManagementEdit";
import Preferences from "./views/preferences/Preferences";
import ProfileLayout from "./components/layouts/profile.layout/ProfileLayout";
import {loadNotifications} from "./services/notification/notification";
import useRouteChange from "./components/common/list/hooks/useRouteChange";
import {loadUserDetails} from "./services/user.details/userDetails";
import RequestReferenceLetter from "./views/request.reference.letter/RequestReferenceLetter";
import SkillsManagementGlobal from "./views/skills.management/skills.management.global/SkillsManagementGlobal";
import ForgotPassword from "./views/forgot.password/ForgotPassword";
import ForgotPasswordReset from "./views/forgot.password/forgot.password.reset/ForgotPasswordReset";
import TermsAndConditions from "./views/terms.and.conditions/TermsAndConditions";
import {setGoogleAnalyticsPage} from "./services/google.analytics/GoogleAnalytics";
import NotFound from "./views/not.found/NotFound";
import Reports from "./views/reports/Reports";
import Programs from './views/programs/Programs';
import ProgramCreate from './views/programs/program.create/ProgramCreate';
import ProgramEdit from './views/programs/program.edit/ProgramEdit';
import ProgramView from './views/programs/program.view/ProgramView';

const App = (props) => {
    const dispatch = useDispatch();
    const loggedOut = useSelector(isLoggedOut);
    const messages = useSelector(getMessages);
    const language = useSelector(getLanguage);

    useEffect(() => {
        loadUserDetails(dispatch);
    }, [dispatch]);

    const onRouteChange = useCallback(() => {
        loadNotifications(dispatch, language);
        setGoogleAnalyticsPage(props.location.pathname);
    }, [dispatch, language, props.location.pathname]);

    useRouteChange(onRouteChange);

    useEffect(() => {
       return props.history.listen((location, action) => {
           if (messages && messages.length > 0) {
               dispatch(clearMessage());
           }
       });
    }, [props.history, dispatch, messages]);

    if (loggedOut) {
        logOut();
        dispatch(destroySession());
        return (
            <Redirect push to={LOGGED_OUT_HOME_PAGE} />
        );
    }

    return (
        <Switch>
            <Route exact path={RouteConstants.ROOT_ROUTE} render={props => <Login {...props} />}/>
            <Route path={RouteConstants.LOGIN} render={props => <Login {...props} />} />
            <Route path={RouteConstants.SIGN_UP} render={props => <SignUp {...props} />} />
            <Route path={RouteConstants.TERMS_AND_CONDITIONS} render={props => <TermsAndConditions {...props}/> } />
            <Route path={RouteConstants.REGISTRATION_EXPLANATION} render={props => <RegistrationExplanation {...props} />} />
            <Route path={RouteConstants.FORGOT_YOUR_PASSWORD} render={props => <ForgotPassword {...props}/>}/>
            <Route path={RouteConstants.FORGOT_YOUR_PASSWORD_RESET} render={props => <ForgotPasswordReset {...props}/>}/>
            <Route path={RouteConstants.SEARCH} render={props => <Search {...props} />}/>
            <PrivateRoute path={RouteConstants.MENTORS} component={Mentors}/>
            <PrivateRoute exact path={RouteConstants.OPPORTUNITIES} component={Opportunities} unAuthenticatedRedirect={RouteConstants.OPPORTUNITIES_SUBMIT}/>
            <PrivateRoute exact path={RouteConstants.OPPORTUNITIES_CREATE} component={OpportunitiesCreate} privilege={PrivilegeConstants.CAN_CREATE_OPPORTUNITY} />
            <PrivateRoute exact path={RouteConstants.OPPORTUNITIES_EDIT} component={OpportunitiesEdit}/>
            <Route exact path={RouteConstants.OPPORTUNITIES_SUBMIT} render={props => <OpportunitiesSubmit {...props}/>}/>
            <PrivateRoute exact path={RouteConstants.OPPORTUNITIES_VIEW} component={OpportunitiesView} />
            <PrivateRoute exact path={RouteConstants.PROGRAMS} component={Programs}/>
            <PrivateRoute exact path={RouteConstants.PROGRAM_CREATE} component={ProgramCreate} privilege={PrivilegeConstants.CAN_CREATE_PROGRAM}/>
            <PrivateRoute exact path={RouteConstants.PROGRAM_EDIT} component={ProgramEdit}/>
            <PrivateRoute exact path={RouteConstants.PROGRAM_VIEW} component={ProgramView} />
            <PrivateRoute exact path={RouteConstants.USER_MANAGEMENT} component={UserManagement} />
            <PrivateRoute path={RouteConstants.USER_MANAGEMENT_EDIT} component={UserManagementEdit} privilege={PrivilegeConstants.CAN_MANAGE_USERS}/>
            <PrivateRoute path={RouteConstants.INVITE} component={Invite} privilege={PrivilegeConstants.CAN_INVITE_USER}/>
            <PrivateRoute path={RouteConstants.PREFERENCES} component={Preferences}/>
            <PrivateRoute path={RouteConstants.REQUEST_REFERENCE_LETTER} component={RequestReferenceLetter}/>
            <PrivateRoute path={RouteConstants.SKILLS_MANAGEMENT_GLOBAL} component={SkillsManagementGlobal} privilege={PrivilegeConstants.CAN_EDIT_GLOBAL_SKILLS}/>
            <PrivateRoute path={RouteConstants.REPORTS} component={Reports} privilege={PrivilegeConstants.CAN_SEE_REPORTS}/>
            <Route path={RouteConstants.PROFILE_BASE} render={props => <ProfileLayout {...props} />}/>
            <Route render={props => <NotFound {...props} />}/>
        </Switch>
    );
};

export default withRouter(App);
