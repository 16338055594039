import React, {useRef} from "react";
import "./HeaderHamburgerMenu.css";
import {HamburgerIcon} from "../../common/svg/svg";
import useOutsideClick from "../../../hooks/useOutsideClick";

export default function HeaderHamburgerMenu(props) {
    const hamburgerRef = useRef();
    const NavLinks = props.navLinks;
    const AuthLink = props.authLink;

     useOutsideClick(hamburgerRef, () => {
         props.closeHamburger();
     });

    return (
      <div className={"header-hamburger-menu"} ref={hamburgerRef}>
        <div className="icon" onClick={props.onHamburgerClick}>
          <HamburgerIcon />
        </div>
        {props.isHamburgerMenuOpen && (
          <nav className="sketch-nav-mobile">
            <div>
              <NavLinks />
              <AuthLink />
            </div>
          </nav>
        )}
      </div>
    );
}
