export function getCurrentResultNumber(id, resultIds) {
    return resultIds.indexOf(id) + 1;
}

export function getNextId(id, resultIds) {
    const newIndex = resultIds.indexOf(id) + 1;
    if (newIndex >= resultIds.length) {
        return resultIds[resultIds.length - 1];
    }
    return resultIds[newIndex];
}

export function getPreviousId(id, resultIds) {
    const newIndex = resultIds.indexOf(id) - 1;
    if (newIndex < 0) {
        return resultIds[0]
    }
    return resultIds[newIndex];
}