import * as Yup from "yup";
import Translate from "../../components/common/translate/Translate";
import React from "react";

const VALIDATION_CONSTANTS = {
    MIN_PASSWORD_LENGTH: 6
};

function equalTo(ref, msg) {
    return this.test({
        name: 'equalTo',
        exclusive: false,
        message: msg || "Value doesn't match",
        params: {
            reference: ref.path
        },
        test: function(value) {
            return value === this.resolve(ref)
        }
    })
}


export function initializeYupHelpers() {
    Yup.addMethod(Yup.string,'equalTo', equalTo);
}

export function createPasswordSchema(required) {
    initializeYupHelpers();

    const defaultSchema = {
        password: Yup.string()
            .min(VALIDATION_CONSTANTS.MIN_PASSWORD_LENGTH, <Translate label={"validation_invalid_password_length"}
                                                                      params={{characterLength:  VALIDATION_CONSTANTS.MIN_PASSWORD_LENGTH}}/>),
        confirmPassword: Yup.string()
            .equalTo(Yup.ref('password'), <Translate label={"validation_message_password_mismatch"}/>)
    };

    if (required) {
        defaultSchema['password'] = defaultSchema['password'].required(<Translate label={"validation_message_required"}/>);
        defaultSchema['confirmPassword'] = defaultSchema['confirmPassword'].required(<Translate label={"validation_message_required"}/>);
    }

    return defaultSchema;
}