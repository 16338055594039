import React, {useCallback, useState} from "react";
import "./Projects.css"
import {useDispatch} from "react-redux";
import useRouteChange from "../../components/common/list/hooks/useRouteChange";
import {buildListProjectsRequest} from "./Projects.library";
import {API_PATHS, makeJSONGetRequest, makeUrl} from "../../services/ajax/ajax";
import ProfileAddEntry from "../../components/profile/profile.add.entry/ProfileAddEntry";
import {createViewPath, RouteConstants} from "../../App.library";
import List from "../../components/common/list/List";
import ProjectsListItem from "../../components/profile/projects/projects.list.item/ProjectsListItem";

export default function Projects(props) {
    const dispatcher = useDispatch();
    const [formData, updateFormData] = useState(buildListProjectsRequest(props.location.search));
    const [projectData, updateProjectData] = useState({});

    function onPageClick(newPage) {
        search(newPage);
    }

    function search(page) {
        const searchQueryParams = {
            ...formData,
            pageNumber: page
        };
        props.history.push({
            pathname: props.history.pathname,
            search: makeUrl('', null, searchQueryParams)
        });
    }

    const onRouteChange = useCallback((searchParams) => {
        const newFormData = buildListProjectsRequest(searchParams);
        makeJSONGetRequest(makeUrl(API_PATHS.PROJECT_LIST_SEARCH, {userId: props.match.params.userId}, {
            ...newFormData,
            pageNumber: newFormData.pageNumber - 1,
            pageSize: 8
        }), dispatcher).then((response) => {
            updateFormData(newFormData);
            updateProjectData(response.body);
        })
    }, [dispatcher, props.match.params.userId]);

    useRouteChange(onRouteChange);

    function onItemClick(projectId) {
        props.history.push({
            pathname: createViewPath(RouteConstants.PROJECTS_VIEW, {userId: props.match.params.userId, projectId: projectId})
        });
    }

    return (
        <div className="projects-container">
            <ProfileAddEntry
                canAddEntry={projectData.canAddProject}
                to={createViewPath(RouteConstants.PROJECTS_CREATE, {userId: props.match.params.userId})}
                label={"projects_add_entry"}
                className={"add-projects-button"}
            />
            <div className={"projects-results"}>
                <List
                    singularAmountLabel={"projects_entries_amount_singular"}
                    pluralAmountLabel={"projects_entries_amount_plural"}
                    onPageClick={onPageClick}
                    currentPage={formData.pageNumber}
                    numberOfPages={projectData.numberOfPages}
                    items={projectData.projects}
                    totalResultsCount={projectData.totalResultsCount}
                    itemComponent={ProjectsListItem}
                    additionalItemProps={{
                        onItemClick
                    }}
                />
            </div>
        </div>
    )
}