import React from "react";
import "./ProfileHeaderText.css";
import Translate from "../../common/translate/Translate";

export default function ProfileHeaderText(props) {

    function getClassName() {
        let className = "profile-header-text";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <Translate label={props.label} params={props.params}/>
        </div>
    );
}