import React, {useState} from "react";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./FileViewer.css";
import {InputClearRemove, LeftArrow, RightArrow} from "../svg/svg";
import {translate} from "../translate/Translate.library";
import {MediaView} from "../media.view/MediaView";
import {getFileIndex, getNextFile, getPreviousFile} from "./FileViewer.library";
import {API_PATHS, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import LoadingSpinner from "../loading.spinner/LoadingSpinner";

const FIRST_SERIES_INDEX = 0;

function FileComponent({onClose, language, dispatch, originalFile, files= [], canEdit, canMarkFilesSensitive}) {
    const [file, updateFile] = useState(originalFile);
    const [isSaving, updateIsSaving] = useState(false);

    function isLeftArrowEnabled() {
        return getFileIndex(file, files) > FIRST_SERIES_INDEX;
    }

    function isRightArrowEnabled() {
        return getFileIndex(file, files) < files.length-1;
    }

    function onLeftClick() {
        if (isLeftArrowEnabled()) {
            updateFile(getPreviousFile(file, files));
        }
    }

    function onRightClick() {
        if (isRightArrowEnabled()) {
            updateFile(getNextFile(file, files));
        }
    }

    function getIndexInfo() {
        return (getFileIndex(file, files)+1) + '/' + files.length;
    }

    async function toggleMarkSensitive() {
        updateIsSaving(true);
        const updatedFile = {...file, markedAsSensitive: !file.markedAsSensitive};
        await makeJSONPostRequest(makeUrl(API_PATHS.FILE_UPDATE_SENSITIVITY_STATUS, {id: updatedFile.id}), dispatch,
            {markedAsSensitive: updatedFile.markedAsSensitive}).finally(() => {
            updateIsSaving(false);
        });
        const currentFileIndex = getFileIndex(updatedFile, files);
        files[currentFileIndex] = updatedFile;
        updateFile(updatedFile);
    }

    async function onBlurTitle(title) {
        const updatedFile = {...file, title: title};
        await updateFileInfo(updatedFile);
        updateFile(updatedFile);
    }

    async function onBlurDescription(description) {
        const updatedFile = {...file, description: description};
        await updateFileInfo(updatedFile);
        updateFile(updatedFile);
    }

    async function updateFileInfo(file) {
        updateIsSaving(true);
        await makeJSONPostRequest(makeUrl(API_PATHS.FILE_INFO_UPDATE, {id: file.id}), dispatch, file).finally(() => {
            updateIsSaving(false);
        });
        const currentFileIndex = getFileIndex(file, files);
        files[currentFileIndex] = file;
    }

    return (
        <div className="file-viewer">
            <div className={"file-viewer-close"} onClick={onClose}><InputClearRemove/></div>
            <div className={"file-viewer-body"}>
                <div className={"nav-button" + (isLeftArrowEnabled() ? ' enabled' : '')} onClick={onLeftClick} tabIndex={0}>
                    <LeftArrow/>
                </div>
                <div className={"file-display"}>
                    <div className={"file-info-top"}>
                        {
                            canMarkFilesSensitive &&
                            <button className="mark-as-sensitive-button" onClick={toggleMarkSensitive} disabled={isSaving}>
                                {translate(file.markedAsSensitive ? 'unmark_sensitive_button' : 'mark_sensitive_button', language)}
                            </button>
                        }
                        <p className="file-index-info">{getIndexInfo()}</p>
                    </div>
                    <div className={"file-content"}>
                        <MediaView file={file} dispatch={dispatch} onLoadComponent={LoadingSpinner} language={language}/>
                    </div>
                    <div className={"file-info-bottom"}>
                        <div className={"file-name"}>
                            {
                                canEdit
                                    ? <div className={"file-name-editable"}>
                                        <label>{translate('file_title_label', language)}</label>
                                        <input
                                            id={"file_name"}
                                            type={"text"}
                                            value={file.title || ''}
                                            placeholder={translate("file_title_placeholder", language)}
                                            onChange={(event) => {updateFile({...file, title: event.target.value})}}
                                            onBlur={(event) => {onBlurTitle(event.target.value)}}
                                            disabled={isSaving}
                                        />
                                    </div>
                                    : file.title
                            }
                        </div>
                        <div className={"file-description"}>
                            {
                                canEdit
                                    ? <div className={"file-description-editable"}>
                                        <label>{translate('file_description_label', language)}</label>
                                        <textarea
                                            id={"file_description"}
                                            value={file.description || ''}
                                            placeholder={translate("file_description_placeholder", language)}
                                            onChange={(event) => {updateFile({...file, description: event.target.value})}}
                                            onBlur={(event) => {onBlurDescription(event.target.value)}}
                                            disabled={isSaving}
                                        />
                                    </div>
                                    : <p>{file.description}</p>
                            }
                        </div>

                    </div>
                </div>
                <div className={"nav-button" + (isRightArrowEnabled() ? ' enabled' : '')} onClick={onRightClick} tabIndex={0}>
                    <RightArrow/>
                </div>
            </div>
        </div>
    );
}

export function makeFileViewer(language, dispatch, file, files, canEdit, canMarkFilesSensitive, onViewerClose) {
    confirmAlert({
        afterClose: onViewerClose,
        customUI:({onClose}) => {
            return (
                <FileComponent
                    onClose={onClose}
                    language={language}
                    dispatch={dispatch}
                    originalFile={file}
                    files={files}
                    canEdit={canEdit}
                    canMarkFilesSensitive={canMarkFilesSensitive}
                />
            )
        }
    });
}