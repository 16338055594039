import React, { useState, useCallback } from 'react'
import './JournalEditForm.css'
import { Field, Form, Formik } from 'formik'
import JournalEditHeader from '../journal.edit.header/JournalEditHeader'
import * as Yup from 'yup'
import ProfileTextArea from '../../profile.text.area/ProfileTextArea'
import { createOnBlurFunction } from '../../../../services/formik/formik'
import SkillTagging from '../../../common/skill/skill.tagging/SkillTagging'
import cloneDeep from 'lodash/cloneDeep'
import ProfileTextDatePicker from '../../profile.text.date.picker/ProfileTextDatePicker'
import Translate from '../../../common/translate/Translate'
import ProfileShareMentorsPeers from '../../profile.share.mentors.peers/ProfileShareMentorsPeers'
import AppLinks from '../../../../resources/data/links'
import ProfileUploadMedia from '../../profile.upload.media/ProfileUploadMedia'
import { trackPromise } from 'react-promise-tracker'
import FormikUnsavedChangesPrompt from '../../../common/formik.unsaved.changes.prompt/FormikUnsavedChangesPrompt'
import ButtonSaveCancel from '../../../common/button.save.cancel/ButtonSaveCancel'
import { getDateValue } from '../../../../services/date/date'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import {isMusicPlatform} from "../../../../services/file/file";

const JournalSchema = Yup.object().shape({
  work: Yup.string(),
  progress: Yup.string(),
  challenges: Yup.string(),
  goal: Yup.string(),
  connections: Yup.string(),
  reflection: Yup.string(),
  feelings: Yup.string(),
  journalDate: Yup.date()
    .nullable()
    .required(<Translate label={'validation_message_required'} />),
})

export default function JournalEditForm(props) {
  const isMobileScreen = useMediaQuery('(min-width: 600px)')
  
  const [files, setFiles] = useState(props.formData.files)
  const [peerValues, setPeerValues] = useState(props.formData.peers)
  const [mentorValues, setMentorValues] = useState(props.formData.mentors)

  const onSubmit = useCallback(
    (values, actions) => {
      trackPromise(
        props
          .onSubmit({
            ...values,
            peers: peerValues,
            mentors: mentorValues,
            files,
          })
          .then(() => {
            actions.resetForm()
          })
          .finally(() => {
            actions.setSubmitting(false)
          })
      )
    },
    [peerValues, mentorValues, files]
  )

  const styles = {
    container: (isMobileScreen) => ({
      display: 'flex',
      flexDirection: isMobileScreen ? 'row' : 'column',
      justifyContent: 'stretch',
      gap: '1rem',
    }),
    child: (isMobileScreen) => ({ width: isMobileScreen ? '50%' : '100%' }),
  }

  return (
    <div style={styles.container(isMobileScreen)}>
      <div style={styles.child(isMobileScreen)}>
        <Formik
          initialValues={props.formData}
          validationSchema={JournalSchema}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formProps) => (
            <Form>
              <JournalEditHeader
                titleLabel={props.titleLabel}
                titleLabelParams={props.titleLabelParams}
                backTo={props.backTo}
              />
              <Field name={'journalDate'}>
                {(fieldProps) => (
                  <ProfileTextDatePicker
                    selected={
                      fieldProps.field.value &&
                      getDateValue(fieldProps.field.value)
                    }
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    className={'journal-entry-date'}
                    label={'journal_edit_date_label'}
                    id={'journalDate'}
                    name={'journalDate'}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    disabled={formProps.isSubmitting}
                    showTimeSelect
                    maxDate={getDateValue()}
                    dateFormat={'MM/dd/yyyy h:mm aa'}
                    onChange={(value) => {
                      formProps.setFieldValue(fieldProps.field.name, value)
                    }}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <Field name={'work'}>
                {(fieldProps) => (
                  <ProfileTextArea
                    {...fieldProps.field}
                    id={'work'}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    name={'work'}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    disabled={formProps.isSubmitting}
                    label={'journal_edit_work_label'}
                    infoLink={AppLinks.help_work}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <Field name={'progress'}>
                {(fieldProps) => (
                  <ProfileTextArea
                    {...fieldProps.field}
                    id={'progress'}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    name={'progress'}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    disabled={formProps.isSubmitting}
                    label={'journal_edit_progress_label'}
                    infoLink={AppLinks.help_progress}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <Field name={'challenges'}>
                {(fieldProps) => (
                  <ProfileTextArea
                    {...fieldProps.field}
                    id={'challenges'}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    name={'challenges'}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    disabled={formProps.isSubmitting}
                    label={'journal_edit_challenges_label'}
                    infoLink={AppLinks.help_challenges}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <Field name={'goal'}>
                {(fieldProps) => (
                  <ProfileTextArea
                    {...fieldProps.field}
                    id={'goal'}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    name={'goal'}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    disabled={formProps.isSubmitting}
                    label={'journal_edit_goal_label'}
                    infoLink={AppLinks.help_goal}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <Field name={'connections'}>
                {(fieldProps) => (
                  <ProfileTextArea
                    {...fieldProps.field}
                    id={'connections'}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    name={'connections'}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    disabled={formProps.isSubmitting}
                    label={'journal_edit_connections_label'}
                    infoLink={AppLinks.help_connections}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <Field name={'reflection'}>
                {(fieldProps) => (
                  <ProfileTextArea
                    {...fieldProps.field}
                    id={'reflection'}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    name={'reflection'}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    disabled={formProps.isSubmitting}
                    label={'journal_edit_reflection_label'}
                    infoLink={AppLinks.help_reflection}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <Field name={'feelings'}>
                {(fieldProps) => (
                  <ProfileTextArea
                    {...fieldProps.field}
                    id={'feelings'}
                    hasError={fieldProps.meta.error}
                    errorMessage={fieldProps.meta.error}
                    name={'feelings'}
                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                    disabled={formProps.isSubmitting}
                    label={'journal_edit_feelings_label'}
                    infoLink={AppLinks.help_feelings}
                    formikProps={formProps}
                  />
                )}
              </Field>
              <SkillTagging
                skillTags={formProps.values.skillTags}
                skillsOptions={props.skillsOptions}
                onAddSkillTag={(skillTag) => {
                  formProps.setFieldValue(
                    'skillTags',
                    formProps.values.skillTags.concat([skillTag])
                  )
                }}
                onRemoveSkillTag={(_, index) => {
                  const newSkillTags = cloneDeep(formProps.values.skillTags)
                  newSkillTags.splice(index, 1)
                  formProps.setFieldValue('skillTags', newSkillTags)
                }}
              />
              <ButtonSaveCancel
                onCancel={props.onCancel}
                disabled={formProps.isSubmitting}
              />
              <FormikUnsavedChangesPrompt formikProps={formProps} />
            </Form>
          )}
        </Formik>
      </div>
      <div style={styles.child(isMobileScreen)}>
        <ProfileUploadMedia
          multiple
          files={files}
          onChange={(newFiles) => setFiles(
            typeof newFiles !== 'array' && (newFiles.type === 'video' || isMusicPlatform(newFiles.type))
              ? [...files, newFiles] 
              : [...files, ...newFiles]
            )}
          onRemoveFile={(index) => {
            const filesCopy = cloneDeep(files)
            filesCopy.splice(index, 1)
            setFiles(filesCopy)
          }}
          infoLinks={[AppLinks.help_files, AppLinks.help_video, AppLinks.help_music_platform]}
        />
        <ProfileShareMentorsPeers
          peerValues={peerValues}
          mentorValues={mentorValues}
          setPeerValues={setPeerValues}
          setMentorValues={setMentorValues}
        />
      </div>
    </div>
  )
}
