import React from "react";
import "./LoadingOverlay.css"
import LoadingSpinner from "../loading.spinner/LoadingSpinner";
import {usePromiseTracker} from "react-promise-tracker";

export default function LoadingOverlay(props) {
    const {promiseInProgress} = usePromiseTracker({delay: 500});

    return (
        <>
            {
                promiseInProgress &&
                <div className={"loading-overlay"}>
                    <LoadingSpinner/>
                </div>
            }
        </>
    )
}