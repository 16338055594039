import {getDateValue} from "../../../../services/date/date";

export default function createDefaultJournalFormData(values = {}) {
    return {
        journalDate: values.journalDate || getDateValue(),
        work: values.work || '',
        progress: values.progress || '',
        challenges: values.challenges || '',
        goal: values.goal || '',
        connections: values.connections || '',
        reflection: values.reflection || '',
        feelings: values.feelings || '',
        files: values.files || [],
        skillTags: values.skillTags || [],
        peers: values.peers || [],
        mentors: values.mentors || []
    }
}