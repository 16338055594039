import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import {
  API_PATHS,
  makeJSONGetRequest,
  makeJSONPostRequest,
  makeUrl,
} from '../../../services/ajax/ajax'
import { useDispatch } from 'react-redux'

export default function useProjectsComments() {
  const [comments, setComments] = useState([])
  const dispatch = useDispatch()
  const { projectId } = useParams()

  useEffect(() => {
    if (projectId) makeJSONGetRequest(
          makeUrl(API_PATHS.PROJECT_COMMENTS, { projectId }),
          dispatch
      ).then((response) => {
          setComments(response.body.comments)
      })
    }, [dispatch, projectId])

  const onAddComment = async (comment) => {
      if (!comment) return

      const response = await makeJSONPostRequest(
          makeUrl(API_PATHS.PROJECT_COMMENTS_ADD, { projectId }),
          dispatch,
          { comment }
      )
      setComments(response.body.comments)
  }

  const onDeleteComment = async (commentId) => {
      const response = await makeJSONPostRequest(
          makeUrl(
              API_PATHS.PROJECT_COMMENTS_DELETE,
              { projectId, commentId },
          ), dispatch)
      setComments(response.body.comments)
  }

  return { comments, onAddComment, onDeleteComment }
}