import React from "react";
import moment from "moment";
import 'add-to-calendar-button';

function formatDate(value){
    return moment(value).format("YYYY-MM-DD");
}

function formatTime(value){
    return moment(value).format("HH:mm");
}

export default function AddToCalendar(props) {
    return (
        <add-to-calendar-button
            hideTextLabelButton
            hideBranding
            hideCheckmark
            trigger="click"
            options="'Apple','Google','iCal','Outlook.com','Yahoo'"
            listStyle="modal"
            buttonStyle="round"
            timeZone="America/Toronto"
            name={props.title}
            location={props.location}
            startDate={formatDate(props.startTime)}
            startTime={formatTime(props.startTime)}
            endDate={formatDate(props.endTime)}
            endTime={formatTime(props.endTime)}
            description={props.description}
        />
    );
}