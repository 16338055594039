import React, {useEffect, useState} from "react";
import {createViewPath, RouteConstants} from "../../../App.library";
import "./AboutEdit.css"
import {getUserId} from "../../../services/auth/auth";
import {useDispatch} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeMultiPartFormPostRequest, makeUrl} from "../../../services/ajax/ajax";
import AboutEditForm from "../../../components/profile/about/about.edit.form/AboutEditForm";
import {getOldAndNewFiles} from "../../../components/common/file.upload/FileUpload.library";
import {loadUserDetails} from "../../../services/user.details/userDetails";
import {createDefaultAboutEditData} from "./AboutEdit.library";

export default function AboutEdit(props) {
    const profileUserId = getUserId();
    const dispatcher = useDispatch();
    const [formData, updateFormData] = useState({});

    function goToProfile() {
        props.history.push(createViewPath(RouteConstants.ABOUT, {userId: profileUserId}));
    }

    async function saveProfile(values, selectedProgramSkills) {
        const {userImageFiles, cv, availableProgramSkills, ...rest} = values;
        const oldAndNewUserImages = getOldAndNewFiles(userImageFiles);
        const oldAndNewCV = getOldAndNewFiles(cv);

        const formData = new FormData();
        formData.append(
            'profileData',
            JSON.stringify({
                ...rest,
                userImage: oldAndNewUserImages.oldFiles[0],
                cv: oldAndNewCV.oldFiles[0],
                selectedProgramSkills: selectedProgramSkills
            })
        );
        const newImageFile = oldAndNewUserImages.newFiles[0];
        if (newImageFile) {
            formData.append('file', newImageFile, newImageFile.name);
        }
        if (oldAndNewCV.newFiles[0]) {
            formData.append('cvFile', oldAndNewCV.newFiles[0], oldAndNewCV.newFiles[0].name);
        }

        await makeMultiPartFormPostRequest(
            makeUrl(API_PATHS.PROFILE_SAVE, {userId: profileUserId}),
            dispatcher,
            formData
        );
        goToProfile();
        await loadUserDetails(dispatcher);
        props.refreshSocialData();
    }

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PROFILE_EDIT, {userId: profileUserId}), dispatcher)
            .then((response) => {
                updateFormData(response.body);
            });
    }, [profileUserId, dispatcher]);

    return (
        Object.keys(formData).length > 0 &&
        <AboutEditForm formData={createDefaultAboutEditData(formData)}
                       onSubmit={saveProfile}
                       backTo={createViewPath(RouteConstants.ABOUT, {userId: profileUserId})}
                       onCancel={goToProfile}
                       canEditOpenToCollaborate={formData.canEditOpenToCollaborate}
        />
    )
}