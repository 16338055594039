const TypeMapping = {
    OPEN_CALL: {
        label: 'open_call',
        className: 'open-call'
    },
    SKETCH_HOSTED: {
        label: 'sketch_hosted',
        className: 'sketch-hosted'
    }
};

export function getOpportunityTypeClassName(type) {
    return type ? TypeMapping[type].className : ''
}

export function getOpportunityTypeLabel(type) {
    return type ? TypeMapping[type].label : '';
}