import React, {useEffect, useState} from "react";
import "./OpportunitiesView.css";
import BasicLayout from "../../../components/layouts/basic.layout/BasicLayout";
import {createViewPath, RouteConstants} from "../../../App.library";
import ListResult from "../../../components/common/list.result/ListResult";
import {useDispatch, useSelector} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import OpportunitiesViewSection
    from "../../../components/opportunities/opportunities.view.section/OpportunitiesViewSection";
import OpportunitiesViewLeft from "../../../components/opportunities/opportunities.view.left/OpportunitiesViewLeft";
import EditDeleteLink from "../../../components/common/edit.delete.link/EditDeleteLink";
import {makeAlert} from "../../../components/common/alert/Alert";
import {getLanguage} from "../../../store/rootReducer";
import Translate from "../../../components/common/translate/Translate";
import LinkToDetails from "./LinkToDetails";

export default function OpportunitiesView(props) {
    const language = useSelector(getLanguage);
    const dispatch = useDispatch();
    const [opportunity, updateOpportunity] = useState({});

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.OPPORTUNITY_GET, {id: props.match.params.id}), dispatch)
            .then((response) => {
                updateOpportunity(response.body);
            })
    }, [props.match.params.id, dispatch]);

    function goToListResult(id) {
        props.history.push({
            pathname: createViewPath(RouteConstants.OPPORTUNITIES_VIEW, {id}),
            state: props.location.state
        });
    }

    async function deleteOpportunity() {
        await makeJSONPostRequest(makeUrl(API_PATHS.OPPORTUNITY_DELETE, {opportunityId: props.match.params.id}), dispatch);
        props.history.push(RouteConstants.OPPORTUNITIES)
    }

    function onDelete() {
        makeAlert(
            language,
            "opportunity_delete_modal_title",
            "opportunity_delete_alert_body",
            async () => {await deleteOpportunity()}
        )
    }

    return (
        <BasicLayout
            {...props}
        >
            <div className={"opportunities-view"}>
                {
                    props.location.state &&
                    <ListResult
                        className={'opportunities-list-result'}
                        label={"opportunity_next_label"}
                        resultIds={props.location.state.opportunityIds}
                        currentId={props.match.params.id}
                        onForwardClick={goToListResult}
                        onBackClick={goToListResult}
                    />
                }
                <div>
                    <h1>{opportunity.title}</h1>
                </div>
                {
                    Object.keys(opportunity).length > 0 &&
                        <div className={'opportunities-view-body'}>
                            {
                                opportunity.canEdit &&
                                <EditDeleteLink
                                    className={"opportunities-item-edit-link"}
                                    editTo={{pathname: createViewPath(RouteConstants.OPPORTUNITIES_EDIT, {id: props.match.params.id}), state: props.location.state}}
                                    onDelete={onDelete}
                                />
                            }
                            <OpportunitiesViewLeft
                                type={opportunity.type}
                                deadline={opportunity.deadline}
                                opportunityDate={opportunity.opportunityDate}
                                files={opportunity.files}
                            />
                            <div className={'opportunities-view-body-sections'}>
                                <OpportunitiesViewSection
                                    title={'opportunity_view_description_title'}
                                    content={opportunity.description}
                                    highlight={true}
                                />
                                <OpportunitiesViewSection
                                    title={'opportunity_view_eligibility_title'}
                                    content={opportunity.eligibility}
                                />
                                <OpportunitiesViewSection
                                    title={'opportunity_view_submission_requirements_title'}
                                    content={opportunity.submissionRequirements}
                                />
                                {
                                    opportunity.detailsUrl
                                        ? (
                                            <div className="details-url">
                                                <Translate label="opportunities_view_section_label" />
                                                <div className="link-container">
                                                    <LinkToDetails url={opportunity.detailsUrl} />
                                                </div>
                                            </div>
                                        ) : null
                                }
                            </div>
                        </div>
                }
            </div>
        </BasicLayout>
    )
}
