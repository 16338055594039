import React, {useRef} from "react";
import "./TextArea.css"
import Label from "../label/Label";
import FieldErrorFeedback from "../field.error.feedback/FieldErrorFeedback";
import {translate} from "../translate/Translate.library";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";
import InfoLink from "../info.link/InfoLink";
import FormikScrollToFirstError from "../formik.on.submit.scroll/FormikScrollToFirstError";

export default function TextArea(props) {
    const ref = useRef();
    const language = useSelector(state => getLanguage(state));

    function getClassName() {
        let className = "text-area";
        if (props.className) {
            className += " " + props.className
        }

        if (props.hasError) {
            className += " input-error";
        }
        return className;
    }

    return (
        <div ref={ref} className={getClassName()} onKeyPress={props.onKeyPress}>
            <div>
                <Label htmlFor={props.id} label={props.label} params={props.labelParams} />
                {
                    props.infoLink &&
                        <InfoLink
                            className={"text-area-info-link"}
                            to={props.infoLink}
                        />
                }
            </div>
            <textarea
                id={props.id}
                name={props.name}
                value={props.value}
                placeholder={translate(props.placeholder, language)}
                disabled={props.disabled}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
            <FieldErrorFeedback errorMessage={props.hasError ? props.errorMessage : null}/>
            {
                props.formikProps &&
                <FormikScrollToFirstError formikProps={props.formikProps} fieldId={props.id} fieldRef={ref}/>
            }
        </div>
    );
}