import React from "react";
import "./ListItemViewLink.css";
import {Link} from "react-router-dom";
import {RightArrow} from "../../svg/svg";
import Translate from "../../translate/Translate";

export default function ListItemViewLink(props) {
    function getClassName() {
        let className = "list-item-view-link";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }
    return (
        <div className={getClassName()}>
            <Link to={props.to}>
                <div>
                    <Translate label={props.label}/>
                </div>
                <RightArrow/>
            </Link>
        </div>
    )
}