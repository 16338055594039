import React, {useCallback, useState} from "react";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import useRouteChange from "../../components/common/list/hooks/useRouteChange";
import {buildUserManagementSearchObject, DEFAULT_PAGE} from "./UserManagement.library";
import {API_PATHS, makeJSONGetRequest, makeUrl} from "../../services/ajax/ajax";
import {useDispatch} from "react-redux";
import List from "../../components/common/list/List";
import Translate from "../../components/common/translate/Translate";
import UserManagementItem
    from "../../components/user.management/user.management.item/UserManagementItem";
import UserManagementSearchParams
    from "../../components/user.management/user.management.search.params/UserManagementSearchParams";
import useFetchOrStore from "../../hooks/useFetchOrStore";
import {getUserManagementTypeOptions} from "../../store/rootReducer";
import {setUserManagementTypeOptions} from "../../services/select.options/ducks/actions";
import {createViewPath, RouteConstants} from "../../App.library";

export default function UserManagement(props) {
    const dispatcher = useDispatch();

    const [userManagementTypes] = useFetchOrStore(getUserManagementTypeOptions, fetchUserManagementTypes);
    const [formData, updateFormData] = useState(buildUserManagementSearchObject(props.location.search));
    const [usersNeedingApproval, updateUsersNeedingApproval] = useState({});

    const onRouteChange = useCallback((searchParams) => {
        const newFormData = buildUserManagementSearchObject(searchParams);
        makeJSONGetRequest(makeUrl(API_PATHS.GET_USERS_TO_MANAGE, null, {
            ...newFormData,
            pageNumber: newFormData.pageNumber - 1,
            pageSize: 8
        }), dispatcher).then((response) => {
            updateFormData(newFormData);
            updateUsersNeedingApproval(response.body);
        });
    }, [dispatcher]);

    useRouteChange(onRouteChange);

    async function fetchUserManagementTypes() {
        const response = await makeJSONGetRequest(API_PATHS.GET_USER_MANAGEMENT_TYPES, dispatcher);
        dispatcher(setUserManagementTypeOptions(response.body.options));
    }

    function onSearch() {
        getUsers(DEFAULT_PAGE);
    }

    function getUsers(page) {
        const searchQueryParams = {
            ...formData,
            pageNumber: page
        };
        props.history.push({
            pathname: props.history.pathname,
            search: makeUrl('', null, searchQueryParams)
        });
    }

    function onFormDataChange(key, value) {
        const newFormData = {
            ...formData,
        };
        newFormData[key] = value;
        updateFormData(newFormData);
    }

    function onItemClick(id) {
        props.history.push({
            pathname: createViewPath(RouteConstants.USER_MANAGEMENT_EDIT, {id: id}), state: {userIds: usersNeedingApproval.userIds}
        })
    }

    return (
        <BasicLayout
            {...props}
        >
            <div>
                <h1><Translate label={'user_management_header'}/></h1>
            </div>
            <UserManagementSearchParams
                userTypes={userManagementTypes || []}
                onNameChange={(value) => onFormDataChange('name', value)}
                onEmailChange={(value) => onFormDataChange('email', value)}
                onUserTypeChange={(value) => onFormDataChange('userType', value)}
                name={formData.name}
                userType={formData.userType}
                email={formData.email}
                onSearch={onSearch}
            />
            <List
                onPageClick={getUsers}
                currentPage={formData.pageNumber}
                numberOfPages={usersNeedingApproval.numberOfPages}
                singularAmountLabel={"user_management_users_number_singular"}
                pluralAmountLabel={"user_management_users_number_plural"}
                itemComponent={UserManagementItem}
                items={usersNeedingApproval.users}
                totalResultsCount={usersNeedingApproval.totalResultsCount}
                additionalItemProps={{
                    onItemClick
                }}
            />
        </BasicLayout>
    );
}
