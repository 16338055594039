import React from "react";
import "./SkillsStatisticsCell.css";

export default function SkillsStatisticsCell(props) {
    function getClassName() {
        let className = "skills-statistics-cell";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    return (
        <>
            {
                props.value ?
                <div className={getClassName()}>
                    {props.value}
                </div>
                    :<></>
            }
        </>
    )
}