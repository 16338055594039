import React from "react";
import "./FileViewError.css"
import {translate} from "../../translate/Translate.library";
import {FileErrorIcon} from "../../svg/svg";

export default function FileViewError(props) {

    function getClassName() {
        let className = "file-view-error";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <label>{translate('file_view_error_text', props.language)}</label>
            <FileErrorIcon/>
        </div>
    )
}