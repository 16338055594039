import React from "react";
import "./OpportunitiesViewLeft.css";
import OpportunitiesItemType from "../opportunities.item.type/OpportunitiesItemType";
import Translate from "../../common/translate/Translate";
import OpportunitiesDateLabel from "../opportunities.date.label/OpportunitiesDateLabel";
import SketchFileLink from "../../common/sketch.file.link/SketchFileLink";
import {useDispatch} from "react-redux";

export default function OpportunitiesViewLeft(props) {

    const dispatch = useDispatch()

    function Files(props) {
        return (
            <>
                <div>
                    <Translate label={"opportunity_view_attached_file"}/>
                </div>
                <div className={"opportunity-view-attached-file-container"}>
                    {
                        props.files.map((file) => {
                            return(
                                <div key={file.id} className={"opportunity-view-attached-file"}>
                                    <SketchFileLink file={file} dispatch={dispatch}>{file.name}</SketchFileLink>
                                </div>
                            );
                        })
                    }
                </div>
            </>
        )
    }
    return (
        <div className={'opportunities-view-body-left'}>
            <div className={'opportunities-view-body-type'}>
                <OpportunitiesItemType
                    type={props.type}
                />
            </div>
            <div>
                <Translate label={'opportunities-deadline-label'} />
                <OpportunitiesDateLabel
                    date={props.deadline}
                    fallbackLabel={'opportunities_no_deadline'}
                />
            </div>
            <div>
                <Translate label={'opportunities_opportunity_date'} />
                <OpportunitiesDateLabel
                    date={props.opportunityDate}
                    fallbackLabel={'opportunities_no_opportunity_date'}
                />
            </div>
            <div className={'opportunities-view-files'}>
                {
                    props.files && props.files.length > 0 &&
                        <Files files={props.files}/>
                }
            </div>
        </div>
    )
}