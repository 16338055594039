import React from "react";
import "./FileUploadList.css";

export default function FileUploadList(props) {
    const FileUploadItem = props.fileUploadItem;

    function getClassName() {
        let className = "file-upload-list";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    function getOnRemoveFile(index) {
        if (!props.onRemoveFile) {
            return;
        }
        return () => {
            props.onRemoveFile(index);
        }
    }

    function getOnSelectFileChange(index) {
        if (!props.onSelectFileChange) {
            return;
        }
        return (isFileSelected) => {
            props.onSelectFileChange(index, isFileSelected);
        }
    }

    function getOnThumbnailClick(file) {
        if (!props.onThumbnailClick) {
            return;
        }
        return props.onThumbnailClick(file);
    }

    return (
        <div className={getClassName()}>
            {
                props.files && props.files.length > 0 &&
                props.files.map((file, index) => {
                    return (
                        <div key={index} className={"file-upload-item"}>
                            <FileUploadItem
                                file={file}
                                index={index}
                                onRemoveFile={getOnRemoveFile(index)}
                                onSelectFileChange={getOnSelectFileChange(index)}
                                onThumbnailClick={getOnThumbnailClick(file)}
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}