import {convertStringToBoolean} from "../../services/util/util";
import queryString from "query-string";

export const DEFAULT_PAGE = 1;
const DEFAULT_NAME_VALUE = '';
const DEFAULT_ROLE_VALUE = '';
const DEFAULT_SHOW_ONLY_MY_MENTOR = false;

export function buildMentorsSearchObject(searchString) {
    const queryParameters = queryString.parse(searchString);
    return {
        name: queryParameters.name || DEFAULT_NAME_VALUE,
        roleId: queryParameters.roleId || DEFAULT_ROLE_VALUE,
        showOnlyMyMentors: convertStringToBoolean(queryParameters.showOnlyMyMentors) || DEFAULT_SHOW_ONLY_MY_MENTOR,
        pageNumber: parseInt(queryParameters.pageNumber || DEFAULT_PAGE)
    };
}