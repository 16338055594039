import React, {useCallback, useEffect, useState} from "react";
import "./Journal.css";
import {useDispatch} from "react-redux";
import {createViewPath, onFormDataChange, RouteConstants} from "../../App.library";
import useFetchOrStore from "../../hooks/useFetchOrStore";
import {getJournalSectionOptions} from "../../store/rootReducer";
import {API_PATHS, makeJSONGetRequest, makeUrl} from "../../services/ajax/ajax";
import {setJournalSectionOptions} from "../../services/select.options/ducks/actions";
import List from "../../components/common/list/List";
import {buildListJournalsRequest, getKeywordsForAPICall, loadUserTags} from "./Journal.library";
import JournalListItem from "../../components/profile/journal/journal.list.item/JournalListItem";
import useRouteChange from "../../components/common/list/hooks/useRouteChange";
import JournalSearch from "../../components/profile/journal/journal.search/JournalSearch";
import ProfileAddEntry from "../../components/profile/profile.add.entry/ProfileAddEntry";

export default function Journal(props) {
    const dispatcher = useDispatch();
    const [formData, updateFormData] = useState(buildListJournalsRequest(props.location.search));
    const [journalData, updateJournalData] = useState({});

    const [sectionOptions] = useFetchOrStore(getJournalSectionOptions, fetchJournalSections);
    const [skillsOptions, updateSkillsOptions] = useState([]);
    const updateSearchData = onFormDataChange(formData, updateFormData);

    async function fetchJournalSections() {
        const response = await makeJSONGetRequest(API_PATHS.GET_JOURNAL_SECTION_OPTIONS, dispatcher);
        dispatcher(setJournalSectionOptions(response.body.options));
    }

    function onPageClick(newPage) {
        search(newPage);
    }

    function onSearchClick() {
        search(1);
    }

    function search(page) {
        const searchQueryParams = {
            ...formData,
            pageNumber: page
        };
        props.history.push({
            pathname: props.history.pathname,
            search: makeUrl('', null, searchQueryParams)
        });
    }

    useEffect(() => {
        loadUserTags(props.match.params.userId, dispatcher).then((options) => {
            updateSkillsOptions(options);
        })
    }, [dispatcher, props.match.params.userId]);

    const onRouteChange = useCallback((searchParams) => {
        const newFormData = buildListJournalsRequest(searchParams);
        makeJSONGetRequest(makeUrl(API_PATHS.JOURNAL_LIST_SEARCH, {userId: props.match.params.userId}, {
            ...newFormData,
            keywords: getKeywordsForAPICall(newFormData.keywords),
            pageNumber: newFormData.pageNumber - 1, // subtract 1 since the paging in the backend is 0 indexed
            pageSize: 8
        }), dispatcher).then((response) => {
            updateFormData(newFormData);
            updateJournalData(response.body);
        });
    }, [dispatcher, props.match.params.userId]);

    useRouteChange(onRouteChange);

    function onItemClick(journalId) {
        props.history.push({
            pathname: createViewPath(RouteConstants.JOURNAL_VIEW, {userId: props.match.params.userId, journalId: journalId})
        })
    }

    return (
        <div className="journal-container">
            <ProfileAddEntry
                canAddEntry={journalData.canAddJournal}
                to={createViewPath(RouteConstants.JOURNAL_CREATE, {userId: props.match.params.userId})}
                label={"journal_add_entry"}
                className={"add-journal-button"}
            />
            <JournalSearch
                sectionOptions={sectionOptions}
                skillOptions={skillsOptions}
                keywords={formData.keywords}
                section={formData.section}
                skillTag={formData.skillTag}
                userSkillTag={formData.userSkillTag}
                onKeywordsChange={(value) => {updateSearchData('keywords', value)}}
                onSectionChange={(value) => {updateSearchData('section', value)}}
                onUserSkillTagChange={(value) => {updateSearchData('userSkillTag', value)}}
                onSearchClick={onSearchClick}
            />
            <div className="journal-results">
                <List
                    singularAmountLabel={"journal_entries_amount_label_singular"}
                    pluralAmountLabel={"journal_entries_amount_label_plural"}
                    onPageClick={onPageClick}
                    currentPage={formData.pageNumber}
                    numberOfPages={journalData.numberOfPages}
                    itemComponent={JournalListItem}
                    items={journalData.journals}
                    totalResultsCount={journalData.totalResultsCount}
                    additionalItemProps={{
                        userId: props.match.params.userId,
                        onItemClick
                    }}
                />
            </div>
        </div>
    )
}