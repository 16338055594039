import React from 'react'
import {hasPrivilege, isAuthenticated} from "../../../services/auth/auth";
import {Route, Redirect} from "react-router-dom";
import {getAuthenticatedHomePage, RouteConstants} from "../../../App.library";

export default function PrivateRoute(props) {
    const {component: Component, unAuthenticatedRedirect, privilege, renderProps, ...rest} = props;

    const renderRoute = props => {
        if (isAuthenticated()) {
            if (privilege && !hasPrivilege(privilege)) {
                return (
                    <Redirect to={{pathname: getAuthenticatedHomePage(), state: {from: props.location}}}/>
                );
            }
            return (
                <Component {...props} {...renderProps}/>
            );
        }
        return (
            <Redirect to={{pathname: unAuthenticatedRedirect || RouteConstants.LOGIN, state: {from: props.location}}}/>
        );
    };

    return (
        <Route {...rest} render={renderRoute} />
    );
}