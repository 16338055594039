import React, {useEffect, useState} from "react";
import Translate from "../../components/common/translate/Translate";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import "./Preferences.css";
import CheckboxInput from "../../components/common/checkbox.input/CheckboxInput";
import ButtonSaveCancel from "../../components/common/button.save.cancel/ButtonSaveCancel";
import {getHomePage} from "../../App.library";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest} from "../../services/ajax/ajax";
import {useDispatch, useSelector} from "react-redux";
import {logOutAction} from "../../services/auth/ducks/actions";
import {getLanguage} from "../../store/rootReducer";
import {makeAlert} from "../../components/common/alert/Alert";
import {Field, Form, Formik} from "formik";
import TextInput from "../../components/common/text.input/TextInput";
import {createOnBlurFunction} from "../../services/formik/formik";
import * as Yup from "yup";
import {createPasswordSchema} from "../../services/yup/yup";
import {trackPromise} from "react-promise-tracker";

export default function Preferences(props) {

    const language = useSelector(getLanguage);
    const dispatcher = useDispatch();
    const [initialValues, updateInitialValues] = useState({});

    function goToHomePage() {
        props.history.push(getHomePage());
    }

    async function savePreferences(values, postSaveAction) {
        trackPromise(
            makeJSONPostRequest(API_PATHS.PREFERENCES_SAVE, dispatcher, values)
        ).then(() => {
            postSaveAction();
        });
    }

    async function handleSave(values) {
        const {confirmPassword, ...data} = values;
        if (data.available) {
            await savePreferences(data, goToHomePage);
        } else {
            makeAlert(language,
                "preferences_alert_title",
                "preferences_alert_message",
                async () => {
                    await savePreferences(data, () => dispatcher(logOutAction()))
                }
            );
        }
    }

    useEffect(() => {
        makeJSONGetRequest(API_PATHS.PREFERENCES, dispatcher)
            .then((response) => {
                updateInitialValues(response.body);
            });
    }, [dispatcher]);

    return (
        <BasicLayout {...props}>
            <div className="preferences-container">
                <div className="preferences-container-heading">
                    <h1><Translate label={"preferences_header"}/></h1>
                </div>
                {
                    Object.keys(initialValues).length > 0 &&
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values, actions) => {
                            handleSave(values).finally(() => {
                                actions.setSubmitting(false);
                            })
                        }}
                        validationSchema={Yup.object().shape({
                            available: Yup.bool(),
                            shouldReceiveEmailNotifications: Yup.bool(),
                            ...createPasswordSchema()
                        })}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {
                            formProps => (
                                <Form className={"user-settings"}>
                                    <div className="settings-panel">
                                        <div className={"settings-input"}>
                                            <Field name={"available"}>
                                                {
                                                    fieldProps => (
                                                        <CheckboxInput
                                                            {...fieldProps.field}
                                                            id="available"
                                                            name={"available"}
                                                            label="preferences_available_label"
                                                            checked={fieldProps.field.value}
                                                            hasError={fieldProps.meta.error}
                                                            errorMessage={fieldProps.meta.error}
                                                            disabled={formProps.isSubmitting}
                                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                            formikProps={formProps}
                                                        />
                                                    )
                                                }
                                            </Field>
                                            <Field name={"shouldReceiveEmailNotifications"}>
                                                {
                                                    fieldProps => (
                                                        <CheckboxInput
                                                            {...fieldProps.field}
                                                            id="shouldReceiveEmailNotifications"
                                                            label="preferences_email_notifications_label"
                                                            checked={fieldProps.field.value}
                                                            hasError={fieldProps.meta.error}
                                                            errorMessage={fieldProps.meta.error}
                                                            disabled={formProps.isSubmitting}
                                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                            formikProps={formProps}
                                                        />
                                                    )
                                                }
                                            </Field>
                                            <Field
                                                name={"password"}
                                            >
                                                {
                                                    fieldProps => (
                                                        <TextInput
                                                            {...fieldProps.field}
                                                            id={"password"}
                                                            label={"password_label"}
                                                            type={"password"}
                                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                            hasError={fieldProps.meta.error}
                                                            errorMessage={fieldProps.meta.error}
                                                            disabled={formProps.isSubmitting}
                                                            placeholder={"changeable_password_placeholder"}
                                                            formikProps={formProps}
                                                        />
                                                    )
                                                }
                                            </Field>
                                            <Field
                                                name={"confirmPassword"}
                                            >
                                                {
                                                    fieldProps => (
                                                        <TextInput
                                                            {...fieldProps.field}
                                                            id={"confirmPassword"}
                                                            label={"confirm_password_label"}
                                                            type={"password"}
                                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                            hasError={fieldProps.meta.error}
                                                            errorMessage={fieldProps.meta.error}
                                                            disabled={formProps.isSubmitting}
                                                            placeholder={"changeable_password_placeholder"}
                                                            formikProps={formProps}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                    </div>
                                    <ButtonSaveCancel disabled={formProps.isSubmitting} onCancel={goToHomePage}/>
                                </Form>
                            )
                        }
                    </Formik>
                }
            </div>
        </BasicLayout>
    )
}
