import {API_PATHS, makeJSONGetRequest} from "../ajax/ajax";
import {setUserDetails} from "./ducks/actions";
import {isAuthenticated} from "../auth/auth";

export async function loadUserDetails(dispatcher) {
    if (isAuthenticated()) {
        const response = await makeJSONGetRequest(API_PATHS.GET_USER_DETAILS, dispatcher, null, true);
        dispatcher(setUserDetails(response.body));
    }
}

