import React from "react";
import "./TermsAndConditions.css";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import Translate from "../../components/common/translate/Translate";

export default function TermsAndConditions(props) {
    return (
        <BasicLayout
            {...props}
        >
            <div className={"terms-conditions-container"}>
                <div>
                    <h1><Translate label={"terms_conditions_header"}/></h1>
                </div>
                <div className={"terms-conditions-body"}>
                    <div>
                        <Translate label={"terms_conditions"}/>
                    </div>
                </div>
            </div>
        </BasicLayout>
    )
}