import React, {useLayoutEffect} from 'react';
import TextInput from "../../components/common/text.input/TextInput";
import Translate from "../../components/common/translate/Translate";
import {Link} from "react-router-dom";
import {getAuthenticatedHomePage, RouteConstants} from "../../App.library";
import "./Login.css";
import RegistrationLoginLayout from "../../components/layouts/registration.login/RegistrationLoginLayout";
import {useDispatch} from "react-redux";
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {createOnBlurFunction} from "../../services/formik/formik";
import {handleSignInError, login} from "./Login.library";
import {isAuthenticated} from "../../services/auth/auth";
import ButtonAccept from "../../components/common/button.accept/ButtonAccept";

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .required(<Translate label={"validation_message_required"}/>)
        .email(<Translate label={"validation_message_invalid_email"}/>),
    password: Yup.string()
        .required(<Translate label={"validation_message_required"}/>)
});

export default function Login(props) {
    const redirectPath = props?.history?.location?.state?.from?.pathname
    const dispatch = useDispatch();

    async function signIn(values) {
        try {
            await login(values, dispatch);
            redirectPath
                ? props.history.push(redirectPath)
                : props.history.push(getAuthenticatedHomePage())
        } catch (errorResponse) {
            await handleSignInError(errorResponse, dispatch);
        }
    }

    useLayoutEffect(() => {
        if (isAuthenticated()) {
            props.history.push(getAuthenticatedHomePage());
        }
    }, [props.history]);

    return (
        <RegistrationLoginLayout
            {...props}
            authRoute={RouteConstants.SIGN_UP}
            authLabel={"header_sign_up"}
            //override the default it should always go to the sign in page
            authOnClick={() => {}}
        >
            <div className="log-in-container">
                <section>
                    <Formik
                        initialValues={{email: '', password: ''}}
                        validationSchema={LoginSchema}
                        onSubmit={(values, actions) => {
                            signIn(values).finally(() => {
                                actions.setSubmitting(false);
                            });
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {
                            props => (
                                <Form className="login-form-blocks">
                                    <h1><Translate label={"sign_in_header"}/></h1>
                                    <Field name="email">
                                        {
                                            fieldProps => (
                                                <TextInput
                                                    {...fieldProps.field}
                                                    id="email" hasError={fieldProps.meta.error} errorMessage={fieldProps.meta.error}
                                                    name='email'
                                                    onBlur={createOnBlurFunction(props, fieldProps)}
                                                    disabled={props.isSubmitting}
                                                    label="email_label" placeholder="email_label_placeholder"
                                                    formikProps={props}
                                                />
                                            )
                                        }
                                    </Field>

                                    <Field name="password">
                                        {
                                            fieldProps => (
                                                <TextInput
                                                    {...fieldProps.field}
                                                    id="password" hasError={fieldProps.meta.error} errorMessage={fieldProps.meta.error}
                                                    name='password'
                                                    onBlur={createOnBlurFunction(props, fieldProps)}
                                                    disabled={props.isSubmitting}
                                                    label="password_label" placeholder="password_label_placeholder"
                                                    type="password"
                                                    formikProps={props}
                                                />
                                            )
                                        }
                                    </Field>

                                    <div className="forgot-password-link-container">
                                        <Link to={RouteConstants.FORGOT_YOUR_PASSWORD}><Translate
                                            label={"forgot_your_password_link"}/></Link>
                                    </div>

                                    <ButtonAccept className={"sign-in-button"} disabled={props.isSubmitting} label="sign_in_button"/>
                                    <div className={"sign-up-info"}>
                                        <Translate label={"sign_up_text"}/>
                                        <Link to={RouteConstants.SIGN_UP}><Translate
                                            label={"sign_up_link_text"}/></Link>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </section>
            </div>
        </RegistrationLoginLayout>
    );
}
