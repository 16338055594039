import React from "react";
import "./PortfolioEditSeriesThumbnail.css";
import {classFileThumbnailLarge} from "../../../common/file.thumbnail/FileThumbnail.library";
import FileThumbnail from "../../../common/file.thumbnail/FileThumbnail";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragIcon } from "../../../common/svg/svg";

export default function PortfolioEditSeriesThumbnail(props) {

    const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
        id: props.id,
        data: {
            type: "thumbnail",
            seriesId: props.seriesId
        }
    });
    const style = {
        transition,
        transform: CSS.Transform.toString(transform)
    }

    function getClassName() {
        let className = "portfolio-edit-series-thumbnail";
        if (props.className) {
            className += ' ' + props.className;
        }
        if (isDragging) {
            className += ' ' + "portfolio-edit-series-thumbnail-is-dragging";
        }
        return className;
    }

    return (
        <div className={getClassName()} ref={setNodeRef} style={style}>
            <div className={"portfolio-edit-series-thumbnail-drag-handle"} {...attributes} {...listeners}>
                <DragIcon />
            </div>
            <FileThumbnail
                file={props.file}
                size={classFileThumbnailLarge}
                onSelectFileChange={isDragging ? undefined : props.onSelectFileChange}
                onRemoveFile={isDragging ? undefined :  props.onRemoveFile}
            />
        </div>
    )
}