import React from "react";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import NotFoundHeading from "./not.found.heading/NotFoundHeading";
import "./NotFound.css";

export default function NotFound(props) {
    return (
        <BasicLayout {...props}>
            <section className="not-found-container">
                <NotFoundHeading/>
            </section>
        </BasicLayout>
    )
}