export function convertStringToBoolean(stringValue) {
    return stringValue === 'true';
}

export function onEnterClickCreator(onEnterClick) {
    return (event) => {
        if (event.key === 'Enter') {
            onEnterClick();
            event.preventDefault();
        }
    }
}