import React from "react";
import AppleMusicEmbed from "./apple.music.embed/AppleMusicEmbed";
import SpotifyEmbed from "./spotify.embed/SpotifyEmbed";
import BandcampEmbed from "./bandcamp.embed/BandcampEmbed";
import SoundcloudEmbed from "./soundcloud.embed/SoundcloudEmbed";
import TidalEmbed from "./tidal.embed/TidalEmbed";

export default function SketchFileMusicPlatform(props) {

    function embed() {
        if (props.file.type === "APPLE_MUSIC") {
            return <AppleMusicEmbed uri={props.file.location} />
        }
        if (props.file.type === "BANDCAMP") {
            return <BandcampEmbed location={props.file.location} />
        }
        if (props.file.type === "SOUNDCLOUD") {
            return <SoundcloudEmbed uri={props.file.location} />
        }
        if (props.file.type === "SPOTIFY") {
            return <SpotifyEmbed uri={props.file.location} />
        }
        if (props.file.type === "TIDAL") {
            return <TidalEmbed uri={props.file.location} />
        }
    }
    return (
        embed()
    )
}