import React from 'react'

const LineSplit = ({ string, separator }) =>
  string.split(separator).map((line, index) => (
    !line 
      ? <br key={index} /> 
      : <p key={index}>{line}</p>
  ))

export default LineSplit
