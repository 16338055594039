export function getFileIndex(file, files) {
    const index =  files.findIndex(varFile => varFile.id === file.id);
    if (index > -1) {
        return index;
    }
}

export function getNextFile(file, files) {
    const newIndex = getFileIndex(file, files) + 1;
    if (newIndex >= files.length) {
        return files[files.length - 1];
    }
    return files[newIndex];
}

export function getPreviousFile(file, files) {
    const newIndex = getFileIndex(file, files) - 1;
    if (newIndex < 0) {
        return files[0]
    }
    return files[newIndex];
}