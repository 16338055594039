import React, {useEffect, useState} from "react";
import {getClassName, getDefaultThumbnail, getExistingThumbnail, getImageThumbnail} from "./FileThumbnail.library";
import "./FileThumbnail.css"
import {useDispatch} from "react-redux";
import {InputClearRemove} from "../svg/svg";
import CheckboxInput from "../checkbox.input/CheckboxInput";
import {isImage, isVideo} from "../../../services/file/file";
import ThumbnailError from "../../../resources/images/thumbnail-error.png";
import Spinner from "../spinner/Spinner";

export default function FileThumbnail(props) {

    const dispatch = useDispatch();
    const [thumbnailFile, updateThumbnailFile] = useState({});

    useEffect(() => {
        getExistingThumbnail(props.file, dispatch, props.fullSize).then((existingThumbnail) => {
            if (existingThumbnail) {
                if (isImage(props.file.type) || isVideo(props.file.type)) {
                    updateThumbnailFile(existingThumbnail);
                } else {
                    updateThumbnailFile(getDefaultThumbnail(props.file));
                }
            } else {
                const contentType = props.file.type;
                if (contentType) {
                    if (isImage(contentType)) {
                        getImageThumbnail(props.file).then((newThumbnail) => updateThumbnailFile(newThumbnail));
                    } else {
                        updateThumbnailFile(getDefaultThumbnail(props.file));
                    }
                } else {
                    updateThumbnailFile(ThumbnailError);
                }
            }
        })
    }, [props.file, props.fullSize, dispatch]);

    return (
        <div className={getClassName(props)}>
            {
                Object.keys(thumbnailFile).length > 0 ?
                <>
                    {
                        props.onSelectFileChange &&
                        <CheckboxInput
                            className={"file-thumbnail-select"}
                            id={"file-thumbnail-select-checkbox"}
                            onChange={(event) => {
                                props.onSelectFileChange(event.target.checked)
                            }}
                            checked={props.file.isSelected}
                        />
                    }
                    <img className={props.onThumbnailClick ? 'clickable' : ''} src={thumbnailFile} alt={"Thumbnail image for " + props.file.name} onClick={props.onThumbnailClick}/>
                    {
                        props.onRemoveFile &&
                        <div className={"file-thumbnail-clear"} onClick={props.onRemoveFile}><InputClearRemove/></div>
                    }
                </>
                : <Spinner />
            }
        </div>
    )
}