import React from "react";
import UserImage from "../../user.image/UserImage";
import {formatLocalDateTime} from "../../../services/date/date";
import "./CommentItem.css";
import DeleteLink from "../../common/delete.link/DeleteLink";
import {makeAlert} from "../../common/alert/Alert";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";

export default function CommentItem(props) {

    const language = useSelector(getLanguage);

    function onDeleteComment(event) {
        event.stopPropagation();
        makeAlert(language,
            "comment_delete_alert_title",
            "comment_delete_alert_body",
            async () => {
                await props.onDeleteComment(props.comment.id)
            })
    }

    function getClassName() {
        let className = "comment-item";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <UserImage image={props.comment.userImage} name={props.comment.userName}/>
            <div className={"comment-item-name"}>{props.comment.userName}</div>
            <div className={"comment-item-date"}>{formatLocalDateTime(props.comment.createdDate)}</div>
            <div className={"comment-item-content"}>
                <p>{props.comment.comment}</p>
            </div>
            {
                props.comment.canDelete &&
                <DeleteLink className={"comment-item-delete"} onClick={onDeleteComment}/>
            }
        </div>
    )
}