import React, {useState} from 'react'
import {Link} from "react-router-dom";
import Translate from "../common/translate/Translate";
import "./Header.css";
import {getActiveClass, getHomePage, PrivilegeConstants, RouteConstants} from "../../App.library";
import {getAuthLinkProperties} from "./Header.library";
import {useDispatch} from "react-redux";
import {hasPrivilege, isAuthenticated} from "../../services/auth/auth";
import UserNotification from "../user.notification/UserNotification";
import HeaderHamburgerMenu from "./header.hamburger.menu/HeaderHamburgerMenu";
import InfoLink from "../common/info.link/InfoLink";
import AppLinks from "../../resources/data/links";

export default function Header(props) {

    // TODO: Store this state somewhere else, that way, it can be closed by the whole app
    const [isHamburgerMenuOpen, updateIsHamburgerMenuOpen] = useState(false);
    const dispatcher = useDispatch();

    function AuthLink() {
        const authLinkProps = getAuthLinkProperties(props.authRoute, props.authOnClick, props.authLabel, dispatcher);
        return (
            <>
                {
                    isAuthenticated() &&
                    <Link className={getActiveClass(props.location, RouteConstants.PREFERENCES)}
                          to={RouteConstants.PREFERENCES}>
                        <Translate label="preferences_nav_heading"/>
                    </Link>
                }
                <ResourcesLink/>
                <Link to={authLinkProps.authRoute} onClick={authLinkProps.authOnClick}>
                    <Translate label={authLinkProps.authLabel}/>
                </Link>
            </>
        );
    }

    function NavLinks() {
        return (
            <>
                <Link className={getActiveClass(props.location, RouteConstants.SEARCH)} to={RouteConstants.SEARCH}>
                    <Translate label="search_nav_heading"/>
                </Link>
                {
                    isAuthenticated() &&
                    <Link className={getActiveClass(props.location, RouteConstants.MENTORS)}
                          to={RouteConstants.MENTORS}>
                        <Translate label="mentors_nav_heading"/>
                    </Link>
                }
                <Link className={getActiveClass(props.location, RouteConstants.OPPORTUNITIES)}
                      to={RouteConstants.OPPORTUNITIES}>
                    <Translate label="opportunities_nav_heading"/>
                </Link>
                {
                    isAuthenticated() &&
                    <Link className={getActiveClass(props.location, RouteConstants.PROGRAMS)}
                          to={RouteConstants.PROGRAMS}>
                        <Translate label="programs_nav_heading"/>
                    </Link>
                }
                {
                    hasPrivilege(PrivilegeConstants.CAN_INVITE_USER) &&
                    <Link className={getActiveClass(props.location, RouteConstants.INVITE)} to={RouteConstants.INVITE}>
                        <Translate label="invite_nav_heading"/>
                    </Link>
                }
                {
                    hasPrivilege(PrivilegeConstants.CAN_MANAGE_USERS) &&
                    <Link className={getActiveClass(props.location, RouteConstants.USER_MANAGEMENT)}
                          to={RouteConstants.USER_MANAGEMENT}>
                        <Translate label="user_management_nav_heading"/>
                    </Link>
                }
                {
                    hasPrivilege(PrivilegeConstants.CAN_EDIT_GLOBAL_SKILLS) &&
                    <Link className={getActiveClass(props.location, RouteConstants.SKILLS_MANAGEMENT_GLOBAL)}
                          to={RouteConstants.SKILLS_MANAGEMENT_GLOBAL}>
                        <Translate label={"skills_management_global_nav_heading"}/>
                    </Link>
                }
                {
                    hasPrivilege(PrivilegeConstants.CAN_SEE_REPORTS) &&
                    <Link className={getActiveClass(props.location, RouteConstants.REPORTS)}
                        to={RouteConstants.REPORTS}>
                        <Translate label={"reports_nav_heading"}/>
                    </Link>
                }
            </>
        );
    }

    function HelpLink() {
        const RouteMap = [
            {pathName: RouteConstants.LOGIN, helpTarget: AppLinks.help_sign_in},
            {pathName: RouteConstants.FORGOT_YOUR_PASSWORD, helpTarget: AppLinks.help_sign_in},
            {pathName: RouteConstants.SEARCH, helpTarget: AppLinks.help_search},
            {pathName: RouteConstants.MENTORS, helpTarget: AppLinks.help_mentors},
            {pathName: RouteConstants.OPPORTUNITIES_SUBMIT, helpTarget: AppLinks.help_opportunities_submit},
            {pathName: RouteConstants.OPPORTUNITIES_CREATE, helpTarget: AppLinks.help_opportunities_create},
            {pathName: "/opportunities/[0-9]*/edit", helpTarget: AppLinks.help_opportunities_create},
            {pathName: "/opportunities/[0-9]*", helpTarget: AppLinks.help_opportunities_view},
            {pathName: RouteConstants.OPPORTUNITIES, helpTarget: AppLinks.help_opportunities_list},
            {pathName: RouteConstants.PROGRAM_CREATE, helpTarget: AppLinks.help_programs_create_edit},
            {pathName: "/programs/[0-9]*/edit", helpTarget: AppLinks.help_programs_create_edit},
            {pathName: RouteConstants.PROGRAMS, helpTarget: AppLinks.help_programs_list},
            {pathName: RouteConstants.SIGN_UP, helpTarget: AppLinks.help_sign_up},
            {pathName: RouteConstants.REGISTRATION_EXPLANATION, helpTarget: AppLinks.help_sign_up},
            {pathName: RouteConstants.INVITE, helpTarget: AppLinks.help_invite},
            {pathName: RouteConstants.USER_MANAGEMENT, helpTarget: AppLinks.help_user_management},
            {pathName: "/users/[0-9]*/edit", helpTarget: AppLinks.help_user_management},
            {pathName: RouteConstants.PREFERENCES, helpTarget: AppLinks.help_preferences},
            {pathName: RouteConstants.REPORTS, helpTarget: AppLinks.help_reports},
            {pathName: "/profile/[0-9]*/about", helpTarget: AppLinks.help_profile_view},
            {pathName: "/profile/[0-9]*/about/edit", helpTarget: AppLinks.help_profile_edit},
            {pathName: "/profile/[0-9]*/journal/create", helpTarget: AppLinks.help_journal_entry_edit},
            {pathName: "/profile/[0-9]*/journal/[0-9]*/edit", helpTarget: AppLinks.help_journal_entry_edit},
            {pathName: "/profile/[0-9]*/journal/[0-9]*", helpTarget: AppLinks.help_journal_entry_view},
            {pathName: "/profile/[0-9]*/journal", helpTarget: AppLinks.help_journal_view},
            {pathName: "/profile/[0-9]*/portfolio/create", helpTarget: AppLinks.help_portfolio_edit},
            {pathName: "/profile/[0-9]*/portfolio/[0-9]*/edit", helpTarget: AppLinks.help_portfolio_edit},
            {pathName: "/profile/[0-9]*/portfolio/[0-9]*", helpTarget: AppLinks.help_portfolio_view},
            {pathName: "/profile/[0-9]*/portfolio", helpTarget: AppLinks.help_portfolio_list},
            {pathName: "/profile/[0-9]*/projects/create", helpTarget: AppLinks.help_projects_add},
            {pathName: "/profile/[0-9]*/projects/[0-9]*/edit", helpTarget: AppLinks.help_projects_add},
            {pathName: "/profile/[0-9]*/projects/[0-9]*", helpTarget: AppLinks.help_projects_view},
            {pathName: "/profile/[0-9]*/projects", helpTarget: AppLinks.help_projects_list},
            {pathName: "/profile/[0-9]*", helpTarget: AppLinks.help_profile_view},
            {pathName: "/request-reference-letter", helpTarget: AppLinks.help_request_letter_of_reference},
            {pathName: "default", helpTarget: AppLinks.help}
        ];

        let target = RouteMap[RouteMap.length - 1].helpTarget;
        for (const mapping of RouteMap) {
            let regex = new RegExp(mapping.pathName);
            if (regex.test(props.location.pathname)) {
                target = mapping.helpTarget;
                break;
            }
        }

        return (
            <InfoLink to={target}/>
        );
    }

    function SketchHeader() {
        return (
            <Link to={getHomePage()} className="sketch-header">
                <div>
                    <Translate label="sketch_header_first"/><Translate label="sketch_header_second"/>
                </div>
            </Link>
        );
    }

    function ResourcesLink() {
        return (
            <a
                href={AppLinks.resources}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="link to Sketch Resources"
            >
                <div>
                    <Translate label="resources"/>
                </div>
            </a>
        );
    }

    return (
        <header>
            <div className="non-mobile">
                <SketchHeader/>
                <nav className="sketch-nav">
                    <NavLinks/>
                </nav>
                <div className="header-right">
                    {
                        isAuthenticated() &&
                        <UserNotification className={"header-user-notification"}/>
                    }
                    <AuthLink/>
                    <HelpLink/>
                </div>
            </div>
            <div className="mobile">
                <HeaderHamburgerMenu
                    closeHamburger={() => updateIsHamburgerMenuOpen(false)}
                    onHamburgerClick={() => updateIsHamburgerMenuOpen(!isHamburgerMenuOpen)}
                    isHamburgerMenuOpen={isHamburgerMenuOpen}
                    navLinks={NavLinks}
                    authLink={AuthLink}
                />
                <SketchHeader/>
                {
                    isAuthenticated() &&
                    <UserNotification/>
                }
            </div>
        </header>
    );
}
