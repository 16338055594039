import React from "react";
import "./PortfolioViewSeriesHeader.css";
import BackLink from "../../../common/back.link/BackLink";
import ProfileHeaderText from "../../profile.header.text/ProfileHeaderText";

export default function PortfolioViewSeriesHeader(props) {
    return (
        <div className={"portfolio-view-series-header"}>
            <BackLink
                to={props.backTo}
            />
            <ProfileHeaderText
                label={props.titleLabel}
                params={props.titleLabelParams}
            />
        </div>
    )
}