import React from "react";
import "./SkillsStatisticsRow.css";
import SkillsStatisticsTag from "../skills.statistics.tag/SkillsStatisticsTag";
import SkillsStatisticsCell from "../skills.statistics.cell/SkillsStatisticsCell";

export default function SkillsStatisticsRow(props) {

    function getClassName() {
        let className = "skills-statistics-row";
        if (props.showLinearGradient) {
            className +=  ' linear-gradient';
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <div className={"tag"}>
                <SkillsStatisticsTag
                    {...props.tag}
                    className={"cell tag"}
                />
            </div>

            {
                props.tag.statistics && props.tag.statistics.map((statistic, index) => {
                    return (
                        <div className={"stats-cell"} key={index}>
                            <SkillsStatisticsCell
                                {...statistic}
                                className={"cell"}
                            />
                        </div>

                    );
                })
            }
        </div>
    );
}