import React, {useRef} from 'react';
import Label from "../label/Label";
import Select from "../select/Select";
import FieldErrorFeedback from "../field.error.feedback/FieldErrorFeedback";
import "./TextSelect.css";
import FormikScrollToFirstError from "../formik.on.submit.scroll/FormikScrollToFirstError";

export default function TextSelect(props) {
    const ref = useRef();

    function getClassName() {
        let className = "text-select";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    return (
        <div ref={ref} className={getClassName()}>
            <Label htmlFor={props.id} label={props.label} params={props.labelParams}/>
            <Select
                hasError={props.hasError}
                disabled={props.disabled}
                name={props.name}
                id={props.id}
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.onChange}
                onBlur={props.onBlur}
                options={props.options}
            />
            <FieldErrorFeedback errorMessage={props.hasError ? props.errorMessage : null}/>
            {
                props.formikProps &&
                <FormikScrollToFirstError formikProps={props.formikProps} fieldId={props.id} fieldRef={ref}/>
            }
        </div>
    )
}