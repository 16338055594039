import React from "react";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { DeleteIcon } from "../../common/svg/svg";
import { Field } from "formik";
import { getDateValue } from "../../../services/date/date";
import TextDatePicker from "../../common/text.date.picker/TextDatePicker";
import TextInput from "../../common/text.input/TextInput";
import { createOnBlurFunction } from "../../../services/formik/formik";
import "./ProgramEventRow.css"

export default function ProgramEventRow(props) {
    const isMobileScreen = useMediaQuery('(min-width: 600px)');

    function getClassName() {
        let className = "program-event-row";
        if (props.index !== 0) {
            className += " not-first-event";
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <div className="program-events-row-delete" onClick={props.deleteEvent}><DeleteIcon/></div>
            <div className="program-events-row-details">
                <Field name={`events.${props.index}.title`}>
                    {(fieldProps) => (
                        <TextInput
                            {...fieldProps.field}
                            id={`events.${props.index}.title`}
                            label={!isMobileScreen ? "program_events_header_title_label" : null}
                            hasError={fieldProps.meta.error}
                            errorMessage={fieldProps.meta.error}
                            name={`events.${props.index}.title`}
                            onBlur={createOnBlurFunction(props.formProps, fieldProps)}
                            disabled={props.formProps.isSubmitting}
                            formikProps={props.formProps}
                        />
                    )}
                </Field>
                <Field name={`events.${props.index}.startTime`}>
                    {(fieldProps) => (
                        <TextDatePicker
                            selected={fieldProps.field.value && getDateValue(fieldProps.field.value)}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                            onBlur={createOnBlurFunction(props.formProps, fieldProps)}
                            id={`events.${props.index}.startTime`}
                            name={`events.${props.index}.startTime`}
                            label={!isMobileScreen ? "program_events_header_start_time_label" : null}
                            hasError={fieldProps.meta.error}
                            errorMessage={fieldProps.meta.error}
                            disabled={props.formProps.isSubmitting}
                            onChange={(value) => {
                                props.formProps.setFieldValue(fieldProps.field.name, value)
                            }}
                            formikProps={props.formProps}
                        />
                    )}
                </Field>
                <Field name={`events.${props.index}.endTime`}>
                    {(fieldProps) => (
                        <TextDatePicker
                            selected={fieldProps.field.value && getDateValue(fieldProps.field.value)}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                            onBlur={createOnBlurFunction(props.formProps, fieldProps)}
                            id={`events.${props.index}.endTime`}
                            name={`events.${props.index}.endTime`}
                            label={!isMobileScreen ? "program_events_header_end_time_label" : null}
                            hasError={fieldProps.meta.error}
                            errorMessage={fieldProps.meta.error}
                            disabled={props.formProps.isSubmitting}
                            onChange={(value) => {
                                props.formProps.setFieldValue(fieldProps.field.name, value)
                            }}
                            formikProps={props.formProps}
                        />
                    )}
                </Field>
                <Field name={`events.${props.index}.location`}>
                    {(fieldProps) => (
                        <TextInput
                            {...fieldProps.field}
                            id={`events.${props.index}.location`}
                            label={!isMobileScreen ? "program_events_header_location_label" : null}
                            hasError={fieldProps.meta.error}
                            errorMessage={fieldProps.meta.error}
                            name={`events.${props.index}.location`}
                            onBlur={createOnBlurFunction(props.formProps, fieldProps)}
                            disabled={props.formProps.isSubmitting}
                            formikProps={props.formProps}
                        />
                    )}
                </Field>
                <Field name={`events.${props.index}.description`}>
                    {(fieldProps) => (
                        <TextInput
                            {...fieldProps.field}
                            id={`events.${props.index}.description`}
                            label={!isMobileScreen ? "program_events_header_description_label" : null}
                            hasError={fieldProps.meta.error}
                            errorMessage={fieldProps.meta.error}
                            name={`events.${props.index}.description`}
                            onBlur={createOnBlurFunction(props.formProps, fieldProps)}
                            disabled={props.formProps.isSubmitting}
                            formikProps={props.formProps}
                        />
                    )}
                </Field>
            </div>
        </div>
    );
}