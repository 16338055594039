import React from "react";
import BasicLayout from "../../../components/layouts/basic.layout/BasicLayout";
import Translate from "../../../components/common/translate/Translate";
import OpportunitiesForm from "../../../components/opportunities/opportunities.form/OpportunitiesForm";
import createDefaultOpportunitiesFormData
    from "../../../components/opportunities/opportunities.form/OpportunitiesForm.library";
import {useDispatch} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeMultiPartFormPostRequest} from "../../../services/ajax/ajax";
import {displaySuccess} from "../../../components/message.panel/ducks/actions";
import useFetchOrStore from "../../../hooks/useFetchOrStore";
import {getOpportunityTypeOptions} from "../../../store/rootReducer";
import {setOpportunityTypeOptions} from "../../../services/select.options/ducks/actions";
import {RouteConstants} from "../../../App.library";
import {
    createFileUploadFormData, createNewExternallyLinkedFileData, processMediaFiles
} from "../../../components/common/file.upload/FileUpload.library";
import InfoLink from "../../../components/common/info.link/InfoLink";
import AppLinks from "../../../resources/data/links";

export default function OpportunitiesCreate(props) {
    const dispatch = useDispatch();
    const [opportunityTypes] = useFetchOrStore(getOpportunityTypeOptions, fetchOpportunityTypes);

    async function fetchOpportunityTypes() {
        const response = await makeJSONGetRequest(API_PATHS.GET_OPPORTUNITY_TYPE_OPTIONS, dispatch);
        dispatch(setOpportunityTypeOptions(response.body.options));
    }

    async function onSubmit(values) {
        const {files, ...rest} = values;
        const {newSketchHostedFiles, newExternallyLinkedFiles} = await processMediaFiles(files);
        const formData = createFileUploadFormData(newSketchHostedFiles);

        const requestData = {
            ...rest,
            externallyLinkedFiles: createNewExternallyLinkedFileData(newExternallyLinkedFiles)
        };

        formData.append('requestData', JSON.stringify(requestData));
        await makeMultiPartFormPostRequest(
            API_PATHS.OPPORTUNITY_CREATE,
            dispatch,
            formData
        );
        dispatch(displaySuccess([{
            label: "opportunity_create_success_label"
        }]));
        props.history.push(RouteConstants.OPPORTUNITIES);
    }

    return (
        <BasicLayout
            {...props}
        >
            <div className={"opportunities-create"}>
                <div style={{
                        display: 'flex',
                        alignItems: 'center'
                }}>
                    <h1>
                        <Translate label={"opportunities_create_header"}/>
                    </h1>
                    <InfoLink to={AppLinks.help_opportunities_create} />
                </div>
                <OpportunitiesForm
                    opportunityTypes={opportunityTypes || []}
                    onSubmit={onSubmit}
                    submitLabel={"opportunity_create_submit_label"}
                    formData={{...createDefaultOpportunitiesFormData(), type: ''}}
                    showFileUpload={true}
                />
            </div>
        </BasicLayout>
    )
}
