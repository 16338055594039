import React, {useState} from "react";
import "./UserManagementSearchParams.css";
import ListParamsContainer from "../../common/list/list.params.container/ListParamsContainer";
import TextInput from "../../common/text.input/TextInput";
import TextSelect from "../../common/text.select/TextSelect";
import SearchButton from "../../common/search.button/SearchButton";
import {onEnterClickCreator} from "../../../services/util/util";

export default function UserManagementSearchParams(props) {
    const [areFiltersHidden, updateAreFiltersHidden] = useState(false);
    const onEnterClick = onEnterClickCreator(props.onSearch);

    return (
        <ListParamsContainer
            isHidden={areFiltersHidden}
            onHiddenClick={() => {
                updateAreFiltersHidden(!areFiltersHidden)
            }}
            showLabel={"user_management_view_show_search_filters"}
            hideLabel={"user_management_view_hide_search_filters"}
            className={"user-management-search-params"}
            onKeyDown={onEnterClick}
        >
            <TextInput
                id={"user-management-search-name"}
                label={"user_management_search_name_label"}
                placeholder={"user_management_search_name_placeholder"}
                value={props.name}
                onChange={(event) => {
                    props.onNameChange(event.target.value)
                }}
            />
            <TextInput
                id={"user-management-search-email"}
                label={"user_management_search_email_label"}
                placeholder={"user_management_search_email_placeholder"}
                value={props.email}
                onChange={(event) => {
                    props.onEmailChange(event.target.value)
                }}
            />
            <TextSelect
                id={"user-management-search-user-type"}
                label={"user_management_search_user_type_label"}
                options={props.userTypes}
                value={props.userType}
                onChange={(event) => {
                    props.onUserTypeChange(event.target.value)
                }}
            />
            <SearchButton label={"user_management_search_button"} onClick={props.onSearch}/>
        </ListParamsContainer>
    )
}