export default function createDefaultProjectsData(values = {}) {
    return {
        title: values.title || '',
        description: values.description || '',
        timeline: values.timeline || '',
        budget: values.budget || 0,
        developmentAtSketch: values.developmentAtSketch || '',
        developmentOutsideSketch: values.developmentOutsideSketch || '',
        milestonesTimeline: values.milestonesTimeline || '',
        files: values.files || [],
        skillTags: values.skillTags || [],
        peers: values.peers || [],
        mentors: values.mentors || [],
    }
}