import React, {useRef} from 'react'
import "./TextInput.css"
import Input from "../input/Input";
import Label from "../label/Label";
import FieldErrorFeedback from "../field.error.feedback/FieldErrorFeedback";
import FormikScrollToFirstError from "../formik.on.submit.scroll/FormikScrollToFirstError";
import InfoLink from '../info.link/InfoLink';

export default function TextInput(props) {
    const ref = useRef();

    function getClassName() {
        let className = "text-input";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div ref={ref} className={getClassName()}>
            <div>
                <Label htmlFor={props.id} label={props.label} params={props.labelParams} />
                {
                    props.infoLink &&
                        <InfoLink
                            className={"text-input-info-link"}
                            to={props.infoLink}
                        />
                }
            </div>
            <Input
                hasError={props.hasError}
                disabled={props.disabled}
                name={props.name}
                id={props.id}
                value={props.value}
                placeholder={props.placeholder}
                type={props.type || "text"}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
            <FieldErrorFeedback errorMessage={props.hasError ? props.errorMessage : null}/>
            {
                props.formikProps &&
                <FormikScrollToFirstError formikProps={props.formikProps} fieldId={props.id} fieldRef={ref}/>
            }
        </div>
    );
}