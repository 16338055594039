import React, {useEffect, useState} from "react";
import "./JournalCreate.css";
import JournalEditForm from "../../../components/profile/journal/journal.edit.form/JournalEditForm";
import {createViewPath, RouteConstants} from "../../../App.library";
import {
    createFileUploadFormData,
    createNewExternallyLinkedFileData,
    processMediaFiles
} from "../../../components/common/file.upload/FileUpload.library";
import {API_PATHS, makeMultiPartFormPostRequest} from "../../../services/ajax/ajax";
import {useDispatch} from "react-redux";
import createDefaultJournalFormData
    from "../../../components/profile/journal/journal.edit.form/JournalEditForm.library";
import {loadUserTags} from "../Journal.library";

export default function JournalCreate(props) {
    const dispatcher = useDispatch();
    const [skillsOptions, updateSkillsOptions] = useState([]);

    useEffect(() => {
        loadUserTags(props.match.params.userId, dispatcher).then((options) => {
            updateSkillsOptions(options);
        })
    }, [dispatcher, props.match.params.userId]);

    async function onSubmit(values) {
        const {files, ...rest} = values;
        const {newSketchHostedFiles, newExternallyLinkedFiles} = await processMediaFiles(files);
        const formData = createFileUploadFormData(newSketchHostedFiles);

        const requestData = {
            ...rest,
            externallyLinkedFiles: createNewExternallyLinkedFileData(newExternallyLinkedFiles)
        };

        formData.append('requestData', JSON.stringify(requestData));
        await makeMultiPartFormPostRequest(
            API_PATHS.JOURNAL_CREATE,
            dispatcher,
            formData
        );
        props.history.push(createViewPath(RouteConstants.JOURNAL, {userId: props.match.params.userId}));
    }

    return (
        <div>
            <JournalEditForm
                formData={createDefaultJournalFormData()}
                titleLabel={"journal_create_title"}
                backTo={createViewPath(RouteConstants.JOURNAL, {userId: props.match.params.userId})}
                onCancel={() => {
                    props.history.push(createViewPath(RouteConstants.JOURNAL, {userId: props.match.params.userId}));
                }}
                skillsOptions={skillsOptions}
                onSubmit={onSubmit}
                userId={props.match.params.userId}
            />
        </div>
    )
}