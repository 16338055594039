import React from "react";
import Label from "../label/Label";
import Input from "../input/Input";
import FieldErrorFeedback from "../field.error.feedback/FieldErrorFeedback";
import {InputClearRemove} from "../svg/svg";
import "./TextKeyword.css";

export default function TextKeyword(props) {
    return (
        <div className="text-keyword">
            <Label htmlFor={props.id} label={props.label} params={props.labelParams} />
            <div className="text-keyword-input-wrapper">
                <Input
                    hasError={props.hasError}
                    disabled={props.disabled}
                    name={props.name}
                    id={props.id}
                    value={props.value}
                    placeholder={props.placeholder}
                    type={"text"}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                />
                <div onClick={props.onClear}>
                    <InputClearRemove />
                </div>
            </div>
            <FieldErrorFeedback errorMessage={props.hasError ? props.errorMessage : null}/>
        </div>
    )
}