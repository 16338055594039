import {useSelector} from "react-redux";
import {useEffect} from "react";
import isEmpty from 'lodash/isEmpty';

export default function useFetchOrStore(selector, promiseFunction) {
    const value = useSelector(selector);

    useEffect(() => {
        if (!value || isEmpty(value)) {
            const asyncTask = async () => {
                await promiseFunction();
            }
            asyncTask();
        }
    }, [value, promiseFunction]);

    return [value];
}