import {useState} from "react";
import {API_PATHS, makeJSONPostRequest, makeUrl} from "../../../../services/ajax/ajax";
import {useDispatch} from "react-redux";

export function useUpdatePeer(updateIsPeer, userId) {
    const dispatch = useDispatch();
    const [disableAddPeer, updateDisableAddPeer] = useState(false);
    const [disableRemovePeer, updateDisableRemovePeer] = useState(false);

    function onPeerAdd() {
        updateDisableAddPeer(true);
        return makeJSONPostRequest(makeUrl(API_PATHS.PEER_ADD, {peerUserId: userId}), dispatch)
            .then((response) => {
                updateIsPeer(true);
            }).finally(() => {
            updateDisableAddPeer(false);
        });
    }

    function onPeerRemove() {
        updateDisableRemovePeer(true);
        return makeJSONPostRequest(makeUrl(API_PATHS.PEER_REMOVE, {peerUserId: userId}), dispatch)
            .then((response) => {
                updateIsPeer(false);
            }).finally(() => {
            updateDisableRemovePeer(false);
        });
    }
    return [disableAddPeer, disableRemovePeer, onPeerAdd, onPeerRemove];
}