import {useDispatch} from "react-redux";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest} from "../../services/ajax/ajax";
import React from "react";
import * as Yup from "yup";
import Translate from "../../components/common/translate/Translate";
import {Field, Form, Formik} from "formik";
import TextInput from "../../components/common/text.input/TextInput";
import {createOnBlurFunction} from "../../services/formik/formik";
import TextSelect from "../../components/common/text.select/TextSelect";
import TextArea from "../../components/common/text.area/TextArea";
import "./Invite.css"
import {displaySuccess} from "../../components/message.panel/ducks/actions";
import {getRoleOptions} from "../../store/rootReducer";
import useFetchOrStore from "../../hooks/useFetchOrStore";
import {setRoleOptions} from "../../services/select.options/ducks/actions";
import ButtonAccept from "../../components/common/button.accept/ButtonAccept";
import {trackPromise} from "react-promise-tracker";
import FormikUnsavedChangesPrompt from "../../components/common/formik.unsaved.changes.prompt/FormikUnsavedChangesPrompt";

export default function Invite(props) {
    const dispatcher = useDispatch();
    const [roleOptions] = useFetchOrStore(getRoleOptions, fetchRoles);

    async function fetchRoles() {
        const response = await makeJSONGetRequest(API_PATHS.GET_INVITE_ROLE_OPTIONS, dispatcher);
        dispatcher(setRoleOptions(response.body.options));
    }

    async function invite(values, actions) {
        const data = {
            email: values.email,
            roleId: values.role,
            message: values.message
        }
        await makeJSONPostRequest(API_PATHS.INVITE, dispatcher, data).finally(() => {
            actions.setSubmitting(false);
        });
        dispatcher(displaySuccess([{label: 'success_message_invite_sent'}]));
        actions.resetForm();
    }

    return (
        <BasicLayout {...props}>
            <div className="invite-container">
                <h1><Translate label={"invite_header"}/></h1>
                <section>
                    <Formik
                        initialValues={{email: '', role: '', message: ''}}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email(<Translate label={"validation_message_invalid_email"}/>)
                                .required(<Translate label={"validation_message_required"}/>),
                            role: Yup.string()
                                .required(<Translate label={"validation_message_required"}/>)
                        })}
                        onSubmit={(values, actions) => {
                            trackPromise(
                                invite(values, actions)
                            )
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {props => (
                            <Form className="invite-blocks" onSubmit={props.handleSubmit}>
                                <Field name="email">
                                    {
                                        fieldProps => (
                                            <TextInput
                                                {...fieldProps.field}
                                                id="email" label="email_label" placeholder="email_label_placeholder"
                                                onBlur={createOnBlurFunction(props, fieldProps)}
                                                hasError={props.errors.email} errorMessage={props.errors.email}
                                                disabled={props.isSubmitting}
                                                formikProps={props}
                                            />
                                        )
                                    }
                                </Field>
                                <Field name="role">
                                    {
                                        fieldProps => (
                                            <TextSelect
                                                {...fieldProps.field}
                                                id="role" label="assign_role_label" placeholder="assign_role_placeholder"
                                                value={props.values.role}
                                                options = {roleOptions || []}
                                                onBlur={createOnBlurFunction(props, fieldProps)}
                                                hasError={props.errors.role} errorMessage={props.errors.role}
                                                disabled={props.isSubmitting}
                                                formikProps={props}
                                            />
                                        )
                                    }
                                </Field>
                                <TextArea
                                    id="message" label="message_label" placeholder="message_placeholder"
                                    value={props.values.message}
                                    onChange={props.handleChange}
                                    disabled={props.isSubmitting}
                                />
                                <ButtonAccept className={"invite-button"} disabled={props.isSubmitting} label="invite_button"/>
                                <FormikUnsavedChangesPrompt formikProps={props}/>
                            </Form>
                        )}
                    </Formik>
                </section>
            </div>
        </BasicLayout>
    )
}
