import React from "react";
import "./List.css";
import ListPaging from "./list.paging/ListPaging";
import TranslateSingularPlural from "../translate.singular.plural/TranslateSingularPlural";

export default function List(props) {
    const ItemComponent = props.itemComponent;

    function getClassName() {
        let className = "list";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    function getItems() {
        if (props.items) {
            return props.items;
        }
        return [];
    }

    return (
        <div className={getClassName()}>
            <div className="list-amount">
                <TranslateSingularPlural amount={props.totalResultsCount || 0}
                                         singularLabel={props.singularAmountLabel}
                                         pluralLabel={props.pluralAmountLabel}
                                         params={{amount: props.totalResultsCount || 0}}/>
            </div>
            <div className="list-items">
                {
                    getItems().map((item) => {
                        return <ItemComponent {...item} {...props.additionalItemProps} key={item.id}/>
                    })
                }
            </div>
            {
                props.numberOfPages > 1 &&
                <div className="list-paging-container">
                    <ListPaging currentPage={props.currentPage} numberOfPages={props.numberOfPages} pageStep={2} onPageClick={props.onPageClick}/>
                </div>
            }

        </div>
    );
}