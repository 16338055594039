import {API_PATHS, makeBlobGetRequest, makeUrl} from "../ajax/ajax";
import {musicPlatformTypeNames} from "../../components/common/file.upload/file.upload.music/FileAttachMusic.library"

export function isImage(contentType) {
    return contentType.startsWith("image");
}

export function isVideo(contentType) {
    return contentType.startsWith("video");
}

export function isAudio(contentType) {
    return contentType.startsWith("audio") || musicPlatformTypeNames.includes(contentType);
}

export function isMusicPlatform(contentType) {
    return musicPlatformTypeNames.includes(contentType);
}

export function getSketchHostedFileBlob(fileId, dispatch) {
    return makeBlobGetRequest(makeUrl(API_PATHS.FILE_GET, {id: fileId}), dispatch, null, true);
}

export function downloadFile(blob, fileName) {
    let a = document.createElement('a');
    a.href = blob;
    a.download = fileName;
    a.click();
    document.removeChild(a);
}