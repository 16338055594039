import React, { useState, useEffect } from "react";
import "./FileAttachMusic.css";
import { useDispatch } from "react-redux";
import Translate from "../../translate/Translate";
import TextSelect from "../../text.select/TextSelect";
import TextInput from "../../text.input/TextInput";
import ButtonAccept from "../../button.accept/ButtonAccept";
import { loadMusicPlatforms, getMusicToAttach } from "./FileAttachMusic.library";
import { onEnterClickCreator } from "../../../../services/util/util";
import InfoLink from "../../info.link/InfoLink";

export default function FileAttachMusic(props) {
    const dispatcher = useDispatch();
    const [musicPlatformOptions, updateMusicPlatformOptions] = useState([""]);
    const [musicPlatform, updateMusicPlatform] = useState('');
    const [name, updateName] = useState('');
    const [link, updateLink] = useState('');
    const [embedCode, updateEmbedCode] = useState('');

    useEffect(() => {
        loadMusicPlatforms(dispatcher).then((options) => {
            updateMusicPlatformOptions(options);
        })
    }, [dispatcher]);

    function addMusicFile() {
        const musicFile = getMusicToAttach(musicPlatform, name, link, embedCode);
        if (musicFile) {
            props.onAdd(musicFile);
            updateMusicPlatform('');
            updateName('');
            updateLink('');
            updateEmbedCode('');
        }
    }

    const onKeyDown = onEnterClickCreator(addMusicFile);

    return (
        <div className="file-attach-music" onKeyDown={onKeyDown}>
            <div className="file-attach-music-title">
                <Translate label={"music_attach_heading"}/>
                {props.infoLink && <InfoLink to={props.infoLink} style={{ marginLeft: '1rem' }} />}
            </div>
            <div className="file-attach-music-input-container">
                <div>
                    <TextSelect
                        options={musicPlatformOptions || []}
                        value={musicPlatform}
                        disabled={props.isSubmitting}
                        onChange={(event) => {
                            updateMusicPlatform(event.target.value);
                            updateName('');
                            updateLink('');
                            updateEmbedCode('');
                        }}
                        placeholder={"music_dropdown_placeholder"}
                    />
                </div>
                {musicPlatform !== "BANDCAMP" ?
                
                <div className="file-attach-music-input">
                    <TextInput
                        label="music_attach_name_label"
                        placeholder="music_attach_name_label_placeholder"
                        value={name}
                        disabled={props.isSubmitting}
                        onChange={(event) => updateName(event.target.value)}
                    />
                    <TextInput
                        label="music_attach_link_label"
                        placeholder="music_attach_link_label_placeholder"
                        value={link}
                        disabled={props.isSubmitting}
                        onChange={(event) => updateLink(event.target.value)}
                    />
                    <ButtonAccept
                        className={"add-music-button"}
                        label="music_attach_add_button_label"
                        type={"button"}
                        onClick={addMusicFile}
                    />
                </div>
                :
                <>
                    <div className="file-attach-music-input">
                        <TextInput
                            label="music_attach_name_label"
                            placeholder="music_attach_name_label_placeholder"
                            value={name}
                            disabled={props.isSubmitting}
                            onChange={(event) => updateName(event.target.value)}
                        />
                        <TextInput
                            label="music_attach_link_label"
                            placeholder="music_attach_link_label_placeholder"
                            value={link}
                            disabled={props.isSubmitting}
                            onChange={(event) => updateLink(event.target.value)}
                        />
                    </div>
                    <div className="file-attach-music-input">
                        <TextInput
                            className="file-attach-music-embed-code-input"
                            label="music_attach_embed_code_label"
                            placeholder="music_attach_embed_code_label_placeholder"
                            value={embedCode}
                            disabled={props.isSubmitting}
                            onChange={(event) => updateEmbedCode(event.target.value)}
                        />
                        <ButtonAccept
                            className={"add-music-button"}
                            label="music_attach_add_button_label"
                            type={"button"}
                            onClick={addMusicFile}
                        />
                    </div>
                </>
                }
            </div>
        </div>
    )
}