import React, {useState} from "react";
import "./MentorSearch.css";
import TextInput from "../../common/text.input/TextInput";
import TextSelect from "../../common/text.select/TextSelect";
import CheckboxInput from "../../common/checkbox.input/CheckboxInput";
import {hasPrivilege} from "../../../services/auth/auth";
import {PrivilegeConstants} from "../../../App.library";
import ListParamsContainer from "../../common/list/list.params.container/ListParamsContainer";
import SearchButton from "../../common/search.button/SearchButton";
import {onEnterClickCreator} from "../../../services/util/util";

export default function MentorSearch(props) {
    const [areFiltersHidden, updateAreFiltersHidden] = useState(false);

    const onEnter = onEnterClickCreator(props.onSearch);

    return (
        <ListParamsContainer
            isHidden={areFiltersHidden}
            onHiddenClick={() => {updateAreFiltersHidden(!areFiltersHidden)}}
            showLabel={"mentors_view_show_search_filters"}
            hideLabel={"mentors_view_hide_search_filters"}
            className={"mentors-search-parameters-container"}
            onKeyDown={onEnter}
        >
            <div className="mentors-search-parameters">
                <TextInput id="mentor-name"
                           placeholder={"mentors_view_search_param_name_placeholder"}
                           label="mentors_view_search_param_name"
                           onChange={(event) => {
                               props.onNameChange(event.target.value)
                           }}
                           value={props.name}/>
                <TextSelect id="mentor-role"
                            label="mentors_view_role_param_name"
                            options={props.roles}
                            placeholder={"mentor_any_select_placeholder"}
                            onChange={(event) => {
                                props.onRoleChange(event.target.value)
                            }}
                            value={props.role}/>
                {
                    hasPrivilege(PrivilegeConstants.CAN_HAVE_MENTOR) &&
                    <div className="mentor-show-only-mine-checkbox">
                        <CheckboxInput id="mentor-show-only-mine"
                                       label={"mentor_show_my_mentors"}
                                       onChange={(event) => {
                                           props.onShowOnlyMyMentorsChange(event.target.checked)
                                       }}
                                       checked={props.showOnlyMyMentors}/>
                    </div>
                }
            </div>

            <SearchButton label="mentors-search-button" onClick={props.onSearch}/>
        </ListParamsContainer>
    );

}
