import React from "react";
import "./SkillsManagementGlobalItem.css";
import ListItemRounded from "../../common/list/list.item.rounded/ListItemRounded";
import Translate from "../../common/translate/Translate";
import {makeAlert} from "../../common/alert/Alert";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";

export default function SkillsManagementGlobalItem(props) {
    const language = useSelector(getLanguage);

    function onRemove(event) {
        event.stopPropagation();
        makeAlert(
            language,
            'Are You Sure',
            'skills_management_global_remove_alert_body',
            async () => {await props.onRemove(props.id)},
            {skillName: props.name}
        );
    }

    return (
        <ListItemRounded
            className={"skills-management-global-item"}
        >
            <div className={"skills-management-global-item-name"}>
                {props.name}
            </div>
            <button
                onClick={onRemove}
            >
                <div>
                    <Translate label={"skills_management_global_remove_skill"}/>
                </div>
            </button>
        </ListItemRounded>
    )
}