import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Translate from "../../common/translate/Translate";
import { useDispatch } from "react-redux";
import { getDateValue } from "../../../services/date/date";
import { API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl } from "../../../services/ajax/ajax";
import { displaySuccess } from "../../message.panel/ducks/actions";
import { FieldArray, Form, Formik } from "formik";
import { trackPromise } from "react-promise-tracker";
import { AddItemIcon } from "../../common/svg/svg";
import ProgramEventRow from "./ProgramEventRow";
import ButtonAccept from "../../common/button.accept/ButtonAccept";
import "./ProgramEvents.css";

const ProgramEventSchema = Yup.object({
    events: Yup.array().of(
        Yup.object().shape({
            id: Yup.number(),
            title: Yup.string().required(<Translate label={"validation_message_required"}/>),
            startTime: Yup.date().typeError(<Translate label={"validation_message_required"}/>),
            endTime: Yup.date().typeError(<Translate label={"validation_message_required"}/>)
                .test({
                    name: 'min',
                    exclusive: false,
                    params: { },
                    message: <Translate label={"validation_invalid_time_order"}/>,
                    test: function (value) {
                    return value >= this.parent.startTime
                    }
                }),
            location: Yup.string().required(<Translate label={"validation_message_required"}/>),
            description: Yup.string().required(<Translate label={"validation_message_required"}/>)
        })
    )
});

export default function ProgramEvents(props) {
    const dispatch = useDispatch();
    const [programEvents, updateProgramEvents] = useState({});

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PROGRAM_EVENTS_GET, {id: props.programId}), dispatch).then((response) => {
            updateProgramEvents({events: response.body.programEvents});
        });
    }, []);

    async function saveEvents(values) {
        var events = values.events.map(event => ({...event, time: getDateValue(event.time)}));
        var response = await makeJSONPostRequest(
            makeUrl(API_PATHS.PROGRAM_EVENTS_SAVE, {id: props.programId}),
            dispatch,
            {programEvents: events}
        );
        updateProgramEvents({events: response.body.programEvents});
        dispatch(displaySuccess([{label: 'success_message_program_events_save'}]));
    }

    return (
        Object.keys(programEvents).length > 0 &&
        <Formik
            enableReinitialize={true}
            initialValues={programEvents}
            validationSchema={ProgramEventSchema}
            onSubmit={(values, actions) => {
                trackPromise(
                    saveEvents(values).finally(() => {
                        actions.setSubmitting(false);
                    })
                )
            }}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {(formProps) => (
                <Form className={"program-events-container"}>
                    <FieldArray
                        name="events"
                        render={arrayHelpers => {
                            return (
                                <>
                                    <div className="program-events-header">
                                        <div className="program-events-header-columns">
                                            <Translate label="program_events_header_title_label" />
                                            <Translate label="program_events_header_start_time_label" />
                                            <Translate label="program_events_header_end_time_label" />
                                            <Translate label="program_events_header_location_label" />
                                            <Translate label="program_events_header_description_label" />
                                        </div>
                                        <div
                                            className="program-events-add"
                                            onClick={() => arrayHelpers.push({id: -1, title: '', startTime: null, endTime: null, location: '', description: ''})}
                                        >
                                            <AddItemIcon/>
                                        </div>
                                    </div>
                                    {
                                        formProps.values.events.length === 0 &&
                                        <div className="program-events-no-events"><Translate label="program_events_none_label" /></div>
                                    }
                                    {
                                        formProps.values.events.length > 0 &&
                                        formProps.values.events.map((event, index) => (
                                            <ProgramEventRow 
                                                key={index}
                                                index={index}
                                                disabled={formProps.isSubmitting}
                                                deleteEvent={() => arrayHelpers.remove(index)}
                                                formProps={formProps}
                                            />
                                        ))
                                    }
                                </>
                            );
                        }}
                    />
                    <div className={"program-events-save"}>
                        <ButtonAccept
                            className="submit-button"
                            disabled={formProps.isSubmitting}
                            label="program_events_edit_submit_label"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
}