import React, {useEffect, useState} from "react";
import "./UserManagementEdit.css";
import BasicLayout from "../../../components/layouts/basic.layout/BasicLayout";
import ListResult from "../../../components/common/list.result/ListResult";
import {createViewPath, RouteConstants} from "../../../App.library";
import UserManagementEditForm
    from "../../../components/user.management/user.management.edit.form/UserManagementEditForm";
import Translate from "../../../components/common/translate/Translate";
import {useDispatch} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import {createDefaultUserData} from "./UserManagementEdit.library";
import {displaySuccess} from "../../../components/message.panel/ducks/actions";
import {loadNotifications} from "../../../services/notification/notification";

export default function UserManagementEdit(props) {

    const dispatch = useDispatch();
    const [needsApproval, updateNeedsApproval] = useState(false);
    const [userData, updateUserData] = useState(createDefaultUserData({}, needsApproval));


    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.GET_USER_TO_EDIT, {id: props.match.params.id}), dispatch)
            .then((response) => {
                updateStateVariables(response.body);
            });
    }, [props.match.params.id, dispatch]);

    function goToListResult(id) {
        // clear the data right away, just in case there's some lag with the network call (we don't want them to accidentally overwrite data with someone else's)
        updateStateVariables({});
        props.history.push({
            pathname: createViewPath(RouteConstants.USER_MANAGEMENT_EDIT, {id}),
            state: props.location.state
        });
    }

    async function onApprove(values) {
        const response = await makeJSONPostRequest(makeUrl(API_PATHS.APPROVE_USER, {id: props.match.params.id}), dispatch, values);
        updateStateVariables(response.body);
        await loadNotifications(dispatch);
        dispatch(displaySuccess([{
            label: "user_management_approve_success"
        }]));
    }

    async function onSave(values) {
        const response = await makeJSONPostRequest(makeUrl(API_PATHS.SAVE_USER, {id: props.match.params.id}), dispatch, values);
        updateStateVariables(response.body);
        dispatch(displaySuccess([{
            label: "user_management_save_success"
        }]));
    }

    async function onReject(values) {
        const response = await makeJSONPostRequest(makeUrl(API_PATHS.REJECT_USER, {id: props.match.params.id}), dispatch, values);
        updateStateVariables(response.body);
        await loadNotifications(dispatch);
        dispatch(displaySuccess([{
            label: "user_management_reject_success"
        }]));
    }

    function updateStateVariables(body) {
        const {needsApproval, ...rest} = body;
        updateNeedsApproval(needsApproval);
        updateUserData(createDefaultUserData(rest));
    }

    return(
        <BasicLayout
            {...props}
        >
            <div className={"user-management-edit"}>
                {
                    props.location.state &&
                    <ListResult
                        className={"user-management-edit-list-result"}
                        label={"user_management_next_label"}
                        resultIds={props.location.state.userIds}
                        currentId={props.match.params.id}
                        onForwardClick={goToListResult}
                        onBackClick={goToListResult}
                    />
                }
                <div>
                    <h1><Translate label={"user_management_edit_header"}/></h1>
                </div>
                <UserManagementEditForm
                    formData={userData}
                    onApprove={onApprove}
                    onSave={onSave}
                    onReject={onReject}
                    needsApproval={needsApproval}
                />
            </div>
        </BasicLayout>
    )
}
