import React from "react";
import "./FileViewSensitive.css"
import {translate} from "../../translate/Translate.library";
import {FileMarkedSensitiveIcon} from "../../svg/svg";

export default function FileViewSensitive(props) {

    function getClassName() {
        let className = "file-view-sensitive";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()} onClick={props.onClick}>
            <label>{translate('file_view_sensitive_text', props.language)}</label>
            <FileMarkedSensitiveIcon/>
        </div>
    )
}