import React from "react";
import "./UserImage.css";
import SketchFileImage from "../common/sketch.file.image/SketchFileImage";
import {useDispatch} from "react-redux";
import { getHomePage } from "../../App.library";
import { Link } from "react-router-dom";

export default function UserImage(props) {

    const dispatch = useDispatch();

    function getUserImage() {
        if (props.image) {
            return (
                <SketchFileImage file={props.image} alt={props.alt} dispatch={dispatch}/>
            )
        }
        return (
            <div className="no-image">{props.name && props.name.charAt(0).toUpperCase()}</div>
        )
    }
    return (
        <a href={getHomePage()} className="user-image">
            {getUserImage()}
            {props.children}
        </a>
    )
}