import React from "react";
import "./PortfolioCreate.css";
import PortfolioEditForm from "../../../components/profile/portfolio/portfolio.edit.form/PortfolioEditForm";
import {createViewPath, RouteConstants} from "../../../App.library";
import {
    API_PATHS,
    makeMultiPartFormPostRequest
} from "../../../services/ajax/ajax";
import {useDispatch} from "react-redux";
import createDefaultPortfolioFormData
    from "../../../components/profile/portfolio/portfolio.edit.form/PortfolioEditForm.library";
import { 
    createNewExternallyLinkedFileData,
    createFileUploadFormData,
    processMediaFiles
 } from "../../../components/common/file.upload/FileUpload.library";

export default function PortfolioCreate(props) {

    const dispatcher = useDispatch();

    function goToPortfolioList() {
        props.history.push(createViewPath(RouteConstants.PORTFOLIO, {userId: props.match.params.userId}));
    }

    async function onSubmit(values) {
        values.series.forEach(series => {
            series.files.forEach(file => {
                delete file.isSelected;
                delete file.content;
            });
        });

        if (values.thumbnail) {
            // not part of the API schema
            delete values.thumbnail.isSelected;
            delete values.thumbnail.content;
        }
        
        const {files, ...rest} = values
        const {newSketchHostedFiles, newExternallyLinkedFiles} = await processMediaFiles(files)
        const formData = createFileUploadFormData(newSketchHostedFiles)

        const requestData = {
            ...rest,
            externallyLinkedFiles: createNewExternallyLinkedFileData(newExternallyLinkedFiles)
        }

        formData.append('requestData', JSON.stringify(requestData))

        await makeMultiPartFormPostRequest(
            API_PATHS.PORTFOLIO_CREATE,
            dispatcher,
            formData
        )
        goToPortfolioList();
    }

    return (
        <PortfolioEditForm
            formData={createDefaultPortfolioFormData()}
            titleLabel={"portfolio_create_title"}
            backTo={createViewPath(RouteConstants.PORTFOLIO, {userId: props.match.params.userId})}
            onCancel={goToPortfolioList}
            onSubmit={onSubmit}
            userId={props.match.params.userId}
        />
    )
}