import ButtonAccept from "../button.accept/ButtonAccept";
import React from "react";
import "./SearchButton.css";

export default function SearchButton(props) {
    return (
        <div className={"search-page-search-button"}>
            <ButtonAccept label={props.label || "search-button-label"} onClick={props.onClick} disabled={props.disabled} type={props.type}/>
        </div>
    )
}