import React from "react";
import {createViewPath, RouteConstants} from "../../../../App.library";
import CheckboxInput from "../../../common/checkbox.input/CheckboxInput";
import "./AboutHeader.css"
import EditLink from "../../../common/edit.link/EditLink";
import {getUserId} from "../../../../services/auth/auth";

export default function AboutHeader(props) {

    return (
        <div className="about-header-container">
            <div className="edit">
                {
                    props.canEdit &&
                        <EditLink to={createViewPath(RouteConstants.ABOUT_EDIT, {userId: getUserId()})}/>
                }
            </div>
            <div className="openToCollaborate">
                {
                    props.canHaveOpenToCollaborate &&
                    <CheckboxInput
                        id="about-open-to-collaborate"
                        label={"about_open_to_collaborate_label"}
                        onChange={(event) => {props.updateCollaborationStatus(event.target.checked)}}
                        checked={props.isOpenToCollaborate}
                        disabled={props.disabled || !props.canEditOpenToCollaborate}/>
                }

            </div>
        </div>
    )

}