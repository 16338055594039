import React from "react";
import {Link} from "react-router-dom";
import {createViewPath, getActiveClass, RouteConstants} from "../../App.library";
import Translate from "../common/translate/Translate";
import {AboutIcon, ProjectsIcon, JournalIcon, PortfolioIcon} from "../common/svg/svg";
import "./Nav.css"

export default function Nav(props) {
    const userId = parseInt(props.match.params.userId);
    const aboutPath = createViewPath(RouteConstants.ABOUT, {userId: userId});
    const journalPath = createViewPath(RouteConstants.JOURNAL, {userId: userId});
    const portfolioPath = createViewPath(RouteConstants.PORTFOLIO, {userId: userId});
    const projectsPath = createViewPath(RouteConstants.PROJECTS, {userId: userId});

    return (
        <div className="nav-container">
            {
                props.canShowAbout &&
                <Link className={getActiveClass(props.location, aboutPath)}
                      to={{pathname: aboutPath, state: props.location.state}}>
                    <AboutIcon/>
                    <div>
                        <Translate label="about_left_nav_heading"/>
                    </div>
                </Link>
            }
            {
                props.canShowProjects &&
                <Link className={getActiveClass(props.location, projectsPath)}
                      to={{pathname: projectsPath, state: props.location.state}}>
                    <ProjectsIcon/>
                    <div>
                        <Translate label="projects_left_nav_heading"/>
                    </div>
                </Link>
            }
            {
                props.canShowJournals &&
                <Link className={getActiveClass(props.location, journalPath)}
                      to={{pathname: journalPath, state: props.location.state}}>
                    <JournalIcon/>
                    <div>
                        <Translate label="journal_left_nav_heading"/>
                    </div>
                </Link>
            }
            {
                props.canShowPortfolios &&
                <Link className={getActiveClass(props.location, portfolioPath)}
                      to={{pathname: portfolioPath, state: props.location.state}}>
                    <PortfolioIcon/>
                    <div>
                        <Translate label="portfolio_left_nav_heading"/>
                    </div>
                </Link>
            }
        </div>
    );
}