export const setRoleOptions = (options) => {
    return {
        type: 'SET_ROLE_OPTIONS',
        payload: {
            options
        }
    };
};

export const setMentorRoleOptions = (options) => {
    return {
        type: "SET_MENTOR_ROLE_OPTIONS",
        payload: {
            options
        }
    }
};

export const setOpportunityTypeOptions = (options) => {
    return {
        type: "SET_OPPORTUNITY_TYPE_OPTIONS",
        payload: {
            options
        }
    }
};

export const setUserManagementTypeOptions = (options) => {
    return {
        type: "SET_USER_MANAGEMENT_TYPE_OPTIONS",
        payload: {
            options
        }
    }
};

export const setUserRoleOptions = (options) => {
    return {
        type: "SET_USER_ROLE_OPTIONS",
        payload: {
            options
        }
    }
};

export const setJournalSectionOptions = (options) => {
    return {
        type: "SET_JOURNAL_SECTION_OPTIONS",
        payload: {
            options
        }
    }
};

export const setGlobalAndUserSkillOptions = (options) => {
    return {
        type: "SET_GLOBAL_AND_USER_SKILL_OPTIONS",
        payload: {
            options
        }
    }
};

export const setStatisticPeriodOptions = (options) => {
    return {
        type: "SET_STATISTIC_PERIOD_OPTIONS",
        payload: {
            options
        }
    }
};

export const setParticipationStatusOptions = (options) => {
    return {
        type: "SET_PARTICIPATION_STATUS_OPTIONS",
        payload: {
            options
        }
    }
};