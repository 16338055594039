import React from "react";
import Label from "../../../common/label/Label";
import "./PortfolioEditThumbnailInfo.css"
import FileThumbnail from "../../../common/file.thumbnail/FileThumbnail";

export default function PortfolioEditThumbnailInfo(props) {

    function getClassName() {
        let className = "portfolio-edit-thumbnail-info";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <Label label={props.label}/>
            <div className="thumbnail-info">
                {
                    props.thumbnail &&
                    <div style={{
                        marginRight: '1rem'
                    }}>
                        <FileThumbnail file={props.thumbnail}/>
                    </div>
                }
                <Label label={props.thumbnailText}/>
            </div>
        </div>
    );
}