import React, {useLayoutEffect} from 'react';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import TextInput from "../../components/common/text.input/TextInput";
import PhoneNumberInput from "../../components/common/phone.number.input/PhoneNumberInput";
import CheckboxInput from "../../components/common/checkbox.input/CheckboxInput";
import {API_PATHS, makeJSONPostRequest} from "../../services/ajax/ajax";
import Translate from "../../components/common/translate/Translate";
import {Link} from "react-router-dom";
import {getAuthenticatedHomePage, RouteConstants} from "../../App.library";
import "./SignUp.css"
import {useDispatch} from "react-redux";
import RegistrationLoginLayout from "../../components/layouts/registration.login/RegistrationLoginLayout";
import {createOnBlurFunction} from "../../services/formik/formik";
import queryString from 'query-string';
import ButtonAccept from "../../components/common/button.accept/ButtonAccept";
import {createPasswordSchema} from "../../services/yup/yup";
import CheckboxInputError from "../../components/common/checkbox.input.error/CheckboxInputError";
import {displaySuccess} from "../../components/message.panel/ducks/actions";
import {isAuthenticated} from "../../services/auth/auth";

export default function SignUp(props) {
    const dispatcher = useDispatch();
    const queryParams = queryString.parse(props.location.search);

    async function signUpUser(values, actions) {
        const data = {
            name: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            password: values.password,
            shouldSendEmailNotifications: values.shouldSendEmailNotifications,
            inviteId: queryParams.inviteId || null,
            agreeToTermsAndConditions: values.agreeToTermsAndConditions || false
        };
        const response = await makeJSONPostRequest(API_PATHS.SIGN_UP, dispatcher, data).finally(() => {
                actions.setSubmitting(false);
            });
        if (response.body.invited) {
            dispatcher(displaySuccess([{
                label: "sign_up_success"
            }]));
            props.history.push(RouteConstants.LOGIN)
        } else {
            props.history.push(RouteConstants.REGISTRATION_EXPLANATION)
        }
    }

    useLayoutEffect(() => {
        if (isAuthenticated()) {
            props.history.push(getAuthenticatedHomePage());
        }
    }, [props.history]);

    return (
        <RegistrationLoginLayout
            {...props}
        >
            <div className="sign-up-container">
                <section>
                    <Formik
                        initialValues={{
                            name: '',
                            email: queryParams.email || '',
                            phoneNumber: '',
                            password: '',
                            confirmPassword: '',
                            shouldSendEmailNotifications: false,
                            agreeToTermsAndConditions: false
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string()
                                .required(<Translate label={"validation_message_required"}/>),
                            email: Yup.string()
                                .email(<Translate label={"validation_message_invalid_email"}/>)
                                .required(<Translate label={"validation_message_required"}/>),
                            agreeToTermsAndConditions: Yup.bool()
                                .oneOf([true], <Translate label={"validation_accept_terms_conditions"}/>)
                                .required(<Translate label={"validation_accept_terms_conditions"}/>),
                            ...createPasswordSchema(true)
                        })}
                        onSubmit={(values, actions) => {signUpUser(values, actions)}}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {formProps => (
                            <Form className="sign-up-blocks" onSubmit={formProps.handleSubmit}>
                                <h1><Translate label={"sign_up_header"}/></h1>
                                <Field name="name">
                                    {
                                        fieldProps => (
                                            <TextInput
                                                {...fieldProps.field}
                                                id="name" label="name_label" placeholder="name_label_placeholder"
                                                onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                hasError={formProps.errors.name} errorMessage={formProps.errors.name}
                                                disabled={formProps.isSubmitting}
                                                formikProps={formProps}
                                            />
                                        )
                                    }
                                </Field>
                                <Field name="email">
                                    {
                                        fieldProps => (
                                            <TextInput
                                                {...fieldProps.field}
                                                id="email" label="email_label" placeholder="email_label_placeholder"
                                                onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                hasError={formProps.errors.email} errorMessage={formProps.errors.email}
                                                disabled={formProps.isSubmitting}
                                                formikProps={formProps}
                                            />
                                        )
                                    }
                                </Field>
                                <PhoneNumberInput
                                    id="phoneNumber" label="phone_number_label" placeholder="phone_number_placeholder"
                                    value={formProps.values.phoneNumber}
                                    onChange={formProps.handleChange}
                                    disabled={formProps.isSubmitting}
                                />
                                <Field name="password">
                                    {
                                        fieldProps => (
                                            <TextInput
                                                {...fieldProps.field}
                                                id="password" label="password_label" placeholder="password_label_placeholder"
                                                type="password"
                                                onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                hasError={formProps.errors.password} errorMessage={formProps.errors.password}
                                                disabled={formProps.isSubmitting}
                                                formikProps={formProps}
                                            />
                                        )
                                    }
                                </Field>
                                <Field name="confirmPassword">
                                    {
                                        fieldProps => (
                                            <TextInput
                                                {...fieldProps.field}
                                                id="confirmPassword" label="confirm_password_label" placeholder="password_label_placeholder"
                                                type="password"
                                                onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                hasError={formProps.errors.confirmPassword} errorMessage={formProps.errors.confirmPassword}
                                                disabled={formProps.isSubmitting}
                                                formikProps={formProps}
                                            />
                                        )
                                    }
                                </Field>
                                <CheckboxInput
                                    id="shouldSendEmailNotifications" label="email_notification_label"
                                    className={"sign-up-checkbox-input"}
                                    checked={formProps.values.shouldSendEmailNotifications}
                                    onChange={formProps.handleChange}
                                    disabled={formProps.isSubmitting}
                                />
                                <Field name={"agreeToTermsAndConditions"}>
                                    {
                                        fieldProps => (
                                            <CheckboxInputError
                                                {...fieldProps.field}
                                                className={"sign-up-checkbox-input"}
                                                id={"agreeToTermsAndConditions"} label={"sign_up_terms_and_conditions"}
                                                checked={formProps.values.agreeToTermsAndConditions}
                                                hasError={fieldProps.meta.error} errorMessage={fieldProps.meta.error}
                                                disabled={formProps.isSubmitting}
                                                formikProps={formProps}
                                            />
                                        )
                                    }
                                </Field>
                                <ButtonAccept className={"sign-up-button"} disabled={formProps.isSubmitting} label="sign_up_button"/>
                                <div className={"sign-in-info"}>
                                    <Translate label={"sign_in_text"} />
                                    <Link to={RouteConstants.LOGIN}><Translate label={"sign_in_link_text"} /></Link>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </section>
            </div>
        </RegistrationLoginLayout>
    )
}
