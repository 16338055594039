import {getNotificationLabel, getOnClickFromNotification, getUrlFromNotification} from "../notification";

export const notifications = (state = {notifications: []}, action) => {
    switch (action.type) {
        case 'SET_NOTIFICATIONS':
            return {
                ...state,
                notifications: action.payload.notifications.map(notification => {
                    return {
                        label: getNotificationLabel(notification),
                        url: getUrlFromNotification(notification),
                        onClick: getOnClickFromNotification(notification),
                        ...notification
                    }
                })
            };
        default:
            return state;
    }
};

export const getAllNotifications = (state) => state.notifications;