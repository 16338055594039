import React, { useEffect, useState } from "react";
import {useDispatch} from "react-redux";
import {API_PATHS, makeJSONPostRequest} from "../../../services/ajax/ajax";
import {RouteConstants} from "../../../App.library";
import BasicLayout from "../../../components/layouts/basic.layout/BasicLayout";
import Translate from "../../../components/common/translate/Translate";
import ProgramForm from "../../../components/programs/program.form/ProgramForm";
import createDefaultProgramFormData from "../../../components/programs/program.form/ProgramForm.library";
import { loadProgramTags } from "../Programs.library";
import "./ProgramCreate.css";

export default function ProgramCreate(props) {
    const dispatch = useDispatch();
    const [skillsOptions, updateSkillsOptions] = useState([]);

    useEffect(() => {
        loadProgramTags(dispatch).then((options) => {
            updateSkillsOptions(options);
        })
    }, [dispatch]);

    async function onSubmit(values) {
        await makeJSONPostRequest(API_PATHS.PROGRAM_CREATE, dispatch, values);
        props.history.push({
            pathname: RouteConstants.PROGRAMS,
            state: props.location.state
        });
    }

    return (
        <BasicLayout
            {...props}
        >
            <div className={"programs-create"}>
                <div>
                    <h1><Translate label={"program_create_program"}/></h1>
                </div>
                {
                    <ProgramForm
                        submitLabel={"program_create_submit_label"}
                        onSubmit={onSubmit}
                        formData={createDefaultProgramFormData()}
                        skillsOptions={skillsOptions}
                    />
                }
            </div>
        </BasicLayout>
    )
}