import React from "react";
import "./PortfolioEditSeriesItem.css"
import DeleteLink from "../../../common/delete.link/DeleteLink";
import {Field, FieldArray} from "formik";
import TextInput from "../../../common/text.input/TextInput";
import PortfolioEditSeriesThumbnail from "../portfolio.edit.series.thumbnail/PortfolioEditSeriesThumbnail";
import {createOnBlurFunction} from "../../../../services/formik/formik";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragIcon } from "../../../common/svg/svg";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

export default function PortfolioEditSeriesItem(props) {
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
        id: props.id,
        data: {
            type: "series"
        }
    });
    const style = {
        transition,
        transform: CSS.Transform.toString(transform)
    }

    function getSeriesFiles() {
        if (props.files) {
            return props.files;
        }
        return [];
    }

    return (
        <div className={"portfolio-edit-series-item-container"} ref={setNodeRef} style={style}>
            <div className={"portfolio-edit-series-item"}>
                <div className={"portfolio-edit-series-name"}>
                    <Field name={`series.${props.index}.name`}>
                        {
                            fieldProps => (
                                <TextInput
                                    {...fieldProps.field}
                                    id={`series.${props.index}.name`}
                                    name={`series.${props.index}.name`}
                                    label="portfolio_edit_series_title_label"
                                    onBlur={createOnBlurFunction(props.formProps, fieldProps)}
                                    hasError={fieldProps.meta.error}
                                    errorMessage={fieldProps.meta.error}
                                    disabled={props.formProps.isSubmitting}
                                    formikProps={props.formProps}
                                />
                            )
                        }
                    </Field>
                </div>
                <DeleteLink className={"portfolio-series-delete"} onClick={props.deleteSeries}/>
                <div className={"portfolio-series-files"}>
                    <FieldArray
                        name={`series.${props.index}.files`}
                        render={arrayHelpers => (
                            <>
                                <SortableContext items={getSeriesFiles().map(s => s.id)} strategy={rectSortingStrategy}>
                                    {getSeriesFiles().map((item, index) => (
                                        <PortfolioEditSeriesThumbnail
                                            id={item.id}
                                            seriesId={props.id}
                                            key={item.id}
                                            file={item}
                                            seriesIndex={props.index}
                                            seriesCount={props.seriesCount}
                                            fileIndex={index}
                                            filesCount={props.files ? props.files.length : 0}
                                            onSelectFileChange={() => props.updateThumbnail(item)}
                                            onRemoveFile={() => {
                                                arrayHelpers.remove(index);
                                                props.onRemoveFile([item.id]);
                                            }}
                                        />
                                    ))}
                                </SortableContext>
                            </>
                        )}
                    />
                </div>
            </div>
            <div className={"portfolio-edit-series-item-drag-handle"} {...attributes} {...listeners}>
                <DragIcon />
            </div>
        </div>
    )
}