import React from "react";
import "./SkillsStatisticsTag.css";

export default function SkillsStatisticsTag(props) {
    function getClassName() {
        let className = "skills-statistics-tag";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    function getTotalTagsAmount() {
        if (!props.statistics) {
            return 0;
        }
        return props.statistics.reduce((total, currentValue) => {
            if (currentValue.value) {
                return total + currentValue.value
            }
            return total;
        }, 0);
    }

    return (
        <div className={getClassName()}>
            <div className={"skills-statistics-tag-name"}>
                {props.name}
            </div>
            <div>
                {getTotalTagsAmount()}
            </div>
        </div>
    )
}