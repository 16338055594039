export const auth = (state = {}, action) => {
    switch (action.type) {
        case'LOG_OUT':
            return {
                isLoggedOut: true
            };
        default:
            return state;
    }
};

export const isLoggedOut = (state) => state.isLoggedOut;