import React from "react";
import "./ListItemRounded.css";

export default function ListItemRounded(props) {
    const {className, ...rest} = props;

    function getClassName() {
        let newClassName = "list-item-rounded";
        if (props.className) {
            newClassName += ' ' + className;
        }
        return newClassName;
    }
    return (
        <div className={getClassName()} {...rest}>
            {props.children}
        </div>
    )
}