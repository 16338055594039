import React from "react";
import Translate from "../../../components/common/translate/Translate";
import "./NotFoundHeading.css";

export default function NotFoundHeading(props) {
    return (
        <div className={"not-found-heading"}>
            <h1><Translate label={"not_found_heading"}/></h1>
        </div>
    )
}