import React, {useCallback, useState} from "react";
import List from "../../../components/common/list/List";
import SearchItem from "../../../components/search/search.item/SearchItem";
import {useDispatch} from "react-redux";
import {DEFAULT_PAGE, getKeywordsForAPICall, privateBuildSearchItem} from "../Search.library";
import {API_PATHS, makeJSONGetRequest, makeUrl} from "../../../services/ajax/ajax";
import "./SearchPrivate.css";
import SearchPrivateParams from "../../../components/search/search.private.params/SearchPrivateParams";
import useRouteChange from "../../../components/common/list/hooks/useRouteChange";
import {createViewPath, RouteConstants} from "../../../App.library";

export default function SearchPrivate(props) {
    const dispatcher = useDispatch();
    const [formData, updateFormData] = useState(privateBuildSearchItem(props.location.search));
    const [searchResults, updateSearchResults] = useState({});

    const onRouteChange = useCallback((searchParams) => {
        const newFormData = privateBuildSearchItem(searchParams);
        makeJSONGetRequest(makeUrl(API_PATHS.PRIVATE_SEARCH, null, {
            ...newFormData,
            pageNumber: newFormData.pageNumber - 1,
            keywords: getKeywordsForAPICall(newFormData.keywords),
            pageSize: 8
        }), dispatcher).then((response) => {
            updateFormData(newFormData);
            updateSearchResults(response.body);
        });
    }, [dispatcher]);

    useRouteChange(onRouteChange);

    function onSearch() {
        search(DEFAULT_PAGE);
    }

    function onPageClick(newPage) {
        search(newPage);
    }

    function search(page) {
        const searchQueryParams = {
            ...formData,
            pageNumber: page
        };
        props.history.push({
            pathname: props.history.pathname,
            search: makeUrl('', null, searchQueryParams)
        });
    }

    function onFormDataChange(key, value) {
        const newFormData = {
            ...formData,
        };
        newFormData[key] = value;
        updateFormData(newFormData)
    }

    function onItemClick(id) {
        props.history.push({
            pathname: createViewPath(RouteConstants.ABOUT, {userId: id}),
            state: {userIds: searchResults.resultIds || [], path: RouteConstants.ABOUT}
        });
    }

    return (
        <div className={"search-container-private"}>
           <SearchPrivateParams
                onSearch={onSearch}
                onNameChange={(value) => {onFormDataChange('name', value)}}
                onEmailChange={(value) => {onFormDataChange('email', value)}}
                onKeywordsChange={(value) => {onFormDataChange('keywords', value)}}
                onShowOnlyMyPeersChange={(value) => {onFormDataChange('showOnlyMyPeers', value)}}
                onHasImageChanged={(value) => {onFormDataChange('hasProfileImage', value)}}
                onHasPortfolioWorksChanged={(value) => {onFormDataChange('hasPortfolioWorks', value)}}
                onOpenToCollaborationChanged={(value) => {onFormDataChange('openToCollaboration', value)}}
                onKeywordClear={() => {onFormDataChange('keywords', '')}}
                name={formData.name}
                email={formData.email}
                keywords={formData.keywords}
                showOnlyMyPeers={formData.showOnlyMyPeers}
                hasProfileImage={formData.hasProfileImage}
                hasPortfolioWorks={formData.hasPortfolioWorks}
                openToCollaboration={formData.openToCollaboration}
           />
            <List
                {...props}
                onPageClick={onPageClick}
                currentPage={formData.pageNumber}
                numberOfPages={searchResults.numberOfPages}
                singularAmountLabel={"mentors_view_found_number_of_profiles_singular"}
                pluralAmountLabel={"mentors_view_found_number_of_profiles_plural"}
                itemComponent={SearchItem}
                items={searchResults.results}
                totalResultsCount={searchResults.totalResultsCount}
                additionalItemProps={{
                    onItemClick: onItemClick
                }}
            />
        </div>
    )
}