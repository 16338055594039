import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage } from "../../../store/rootReducer";
import { API_PATHS, makeJSONPostRequest, makeUrl } from "../../../services/ajax/ajax";
import { makeAlert } from "../../common/alert/Alert";
import Translate from "../../common/translate/Translate";
import './ProgramParticipationAssociationButton.css';

export default function ProgramParticipationAssociationButton(props) {
    const language = useSelector(getLanguage);
    const dispatch = useDispatch();

    function getClassName() {
        let className = "program-participation-association-button";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    async function updateParticipation(participationAction) {
        const data = {userId: props.userId, participationAction: participationAction};
        var response = await makeJSONPostRequest(
            makeUrl(API_PATHS.PROGRAM_UPDATE_PARTICIPATION, {id: props.programId}),
            dispatch,
            data
        );
        props.updateSearchResultParticipation(response.body.userId, response.body.participationStatus);
    }

    function onRemove() {
        makeAlert(
            language,
            "program_participation_remove_modal_title",
            "program_participation_remove_alert_body",
            async () => {await updateParticipation('REMOVE')}
        )
    }

    return (
        <div className={getClassName()}>
            {
                props.participationStatus === 'NOT_PARTICIPATING' &&
                <button className={"association-button-add"} onClick={() => updateParticipation('ADD')}>
                    <Translate label={"program_edit_participation_add"}/>
                </button>
            }
            {
                props.participationStatus === 'PARTICIPATING' &&
                <button className={"association-button-remove"} onClick={onRemove}>
                    <Translate label={"program_edit_participation_remove"}/>
                </button>
            }
            {
                props.participationStatus === 'PARTICIPATION_PENDING' &&
                <>
                    <button className={"association-button-approve"} onClick={() => updateParticipation('APPROVE')}>
                        <Translate label={"program_edit_participation_approve"}/>
                    </button>
                    <button className={"association-button-reject"} onClick={() => updateParticipation('REJECT')}>
                        <Translate label={"program_edit_participation_reject"}/>
                    </button>
                </>
            }
        </div>
    )
}