import React, { useState, useCallback } from 'react'
import './ProjectsEditForm.css'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import ProfileTextArea from '../../profile.text.area/ProfileTextArea'
import { createOnBlurFunction } from '../../../../services/formik/formik'
import SkillTagging from '../../../common/skill/skill.tagging/SkillTagging'
import cloneDeep from 'lodash/cloneDeep'
import ProfileUploadMedia from '../../profile.upload.media/ProfileUploadMedia'
import ProjectsEditHeader from '../projects.edit.header/ProjectsEditHeader'
import { trackPromise } from 'react-promise-tracker'
import FormikUnsavedChangesPrompt from '../../../common/formik.unsaved.changes.prompt/FormikUnsavedChangesPrompt'
import ButtonSaveCancel from '../../../common/button.save.cancel/ButtonSaveCancel'
import ProfileShareMentorsPeers from '../../profile.share.mentors.peers/ProfileShareMentorsPeers'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import ProfileTextInput from '../../profile.text.input/ProfileTextInput'
import AppLinks from "../../../../resources/data/links.json"
import {isMusicPlatform} from "../../../../services/file/file";

const ProjectsSchema = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  timeline: Yup.string(),
  budget: Yup.number().typeError('Please enter a valid number'),
  developmentAtSketch: Yup.string(),
  developmentOutsideSketch: Yup.string(),
  milestonesTimeline: Yup.string(),
})

export default function ProjectsEditForm(props) {
  const isMobileScreen = useMediaQuery('(min-width: 600px)')

  const [files, setFiles] = useState(props.formData.files)
  const [peerValues, setPeerValues] = useState(props.formData.peers)
  const [mentorValues, setMentorValues] = useState(props.formData.mentors)

  const onSubmit = useCallback(
    (values, actions) => {
      trackPromise(
        props
          .onSubmit({
            ...values,
            peers: peerValues,
            mentors: mentorValues,
            files,
          })
          .then(() => {
            actions.resetForm()
          })
          .finally(() => {
            actions.setSubmitting(false)
          })
      )
    },
    [peerValues, mentorValues, files]
  )

  const styles = {
    container: (isMobileScreen) => ({
      display: 'flex',
      flexDirection: isMobileScreen ? 'row' : 'column',
      justifyContent: 'stretch',
      gap: '1rem',
    }),
    child: (isMobileScreen) => ({ width: isMobileScreen ? '50%' : '100%' }),
  }

  return (
    <div style={styles.container(isMobileScreen)}>
      <div style={styles.child(isMobileScreen)}>
        <Formik
          initialValues={props.formData}
          validationSchema={ProjectsSchema}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formProps) => (
            <Form>
              <ProjectsEditHeader
                titleLabel={props.titleLabel}
                titleLabelParams={props.titleLabelParams}
                backTo={props.backTo}
              />
              <div>
                <Field name={'title'}>
                  {(fieldProps) => (
                    <ProfileTextInput
                      {...fieldProps.field}
                      id={'title'}
                      name={'title'}
                      hasError={fieldProps.meta.error}
                      errorMessage={fieldProps.meta.error}
                      onBlur={createOnBlurFunction(formProps, fieldProps)}
                      disabled={formProps.isSubmitting}
                      label={'title'}
                      formikProps={formProps}
                      infoLink={AppLinks.help_title}
                    />
                  )}
                </Field>
                <Field name={'description'}>
                  {(fieldProps) => (
                    <ProfileTextArea
                      {...fieldProps.field}
                      id={'description'}
                      name={'description'}
                      hasError={fieldProps.meta.error}
                      errorMessage={fieldProps.meta.error}
                      onBlur={createOnBlurFunction(formProps, fieldProps)}
                      disabled={formProps.isSubmitting}
                      label={'project_description'}
                      formikProps={formProps}
                      infoLink={AppLinks.help_project_description}
                    />
                  )}
                </Field>
                <Field name={'timeline'}>
                  {(fieldProps) => (
                    <ProfileTextArea
                      {...fieldProps.field}
                      id={'timeline'}
                      name={'timeline'}
                      hasError={fieldProps.meta.error}
                      errorMessage={fieldProps.meta.error}
                      onBlur={createOnBlurFunction(formProps, fieldProps)}
                      disabled={formProps.isSubmitting}
                      label={'timeline'}
                      formikProps={formProps}
                      infoLink={AppLinks.help_project_timeline}
                    />
                  )}
                </Field>
                <Field name={'budget'}>
                  {(fieldProps) => (
                    <ProfileTextInput
                      {...fieldProps.field}
                      id={'budget'}
                      name={'budget'}
                      hasError={fieldProps.meta.error}
                      errorMessage={fieldProps.meta.error}
                      onBlur={createOnBlurFunction(formProps, fieldProps)}
                      disabled={formProps.isSubmitting}
                      label={'budget'}
                      formikProps={formProps}
                      infoLink={AppLinks.help_project_budget}
                    />
                  )}
                </Field>
                <Field name={'developmentAtSketch'}>
                  {(fieldProps) => (
                    <ProfileTextArea
                      {...fieldProps.field}
                      id={'developmentAtSketch'}
                      name={'developmentAtSketch'}
                      hasError={fieldProps.meta.error}
                      errorMessage={fieldProps.meta.error}
                      onBlur={createOnBlurFunction(formProps, fieldProps)}
                      disabled={formProps.isSubmitting}
                      label={'development_at_sketch'}
                      formikProps={formProps}
                      infoLink={AppLinks.help_artist_development_at_sketch}
                    />
                  )}
                </Field>
                <Field name={'developmentOutsideSketch'}>
                  {(fieldProps) => (
                    <ProfileTextArea
                      {...fieldProps.field}
                      id={'developmentOutsideSketch'}
                      name={'developmentOutsideSketch'}
                      hasError={fieldProps.meta.error}
                      errorMessage={fieldProps.meta.error}
                      onBlur={createOnBlurFunction(formProps, fieldProps)}
                      disabled={formProps.isSubmitting}
                      label={'development_outside_sketch'}
                      formikProps={formProps}
                      infoLink={AppLinks.help_artist_development_outside_sketch}
                    />
                  )}
                </Field>
                <Field name={'milestonesTimeline'}>
                  {(fieldProps) => (
                    <ProfileTextArea
                      {...fieldProps.field}
                      id={'milestonesTimeline'}
                      name={'milestonesTimeline'}
                      hasError={fieldProps.meta.error}
                      errorMessage={fieldProps.meta.error}
                      onBlur={createOnBlurFunction(formProps, fieldProps)}
                      disabled={formProps.isSubmitting}
                      label={'milestones_and_timeline'}
                      formikProps={formProps}
                      infoLink={AppLinks.help_milestones_and_timeline}
                    />
                  )}
                </Field>
              </div>
              <SkillTagging
                skillTags={formProps.values.skillTags}
                skillsOptions={props.skillsOptions}
                onAddSkillTag={(skillTag) => {
                  formProps.setFieldValue(
                    'skillTags',
                    formProps.values.skillTags.concat([skillTag])
                  )
                }}
                onRemoveSkillTag={(_, index) => {
                  const newSkillTags = cloneDeep(formProps.values.skillTags)
                  newSkillTags.splice(index, 1)
                  formProps.setFieldValue('skillTags', newSkillTags)
                }}
                infoLink={AppLinks.help_tags}
              />
              <ButtonSaveCancel
                onCancel={props.onCancel}
                disabled={formProps.isSubmitting}
              />
              <FormikUnsavedChangesPrompt formikProps={formProps} />
            </Form>
          )}
        </Formik>
      </div>
      <div style={styles.child(isMobileScreen)}>
        <ProfileUploadMedia
          multiple
          files={files}
          onChange={(newFiles) => setFiles(
            typeof newFiles !== 'array' && (newFiles.type === 'video' || isMusicPlatform(newFiles.type)) 
              ? [...files, newFiles] 
              : [...files, ...newFiles]
            )}
          onRemoveFile={(index) => {
            const filesCopy = cloneDeep(files)
            filesCopy.splice(index, 1)
            setFiles(filesCopy)
          }}
          infoLinks={[AppLinks.help_files, AppLinks.help_video, AppLinks.help_music_platform]}
        />
        <ProfileShareMentorsPeers
          peerValues={peerValues}
          mentorValues={mentorValues}
          setPeerValues={setPeerValues}
          setMentorValues={setMentorValues}
          projectsPage
        />
      </div>
    </div>
  )
}
