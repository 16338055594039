import React from "react";
import "./JournalListItem.css"
import ListItemViewer from "../list.item.viewer/ListItemViewer";
import ListItemComment from "../list.item.comment/ListItemComment";
import SkillTag from "../../../common/skill/skill.tag/SkillTag";
import {formatLocalDateTime} from "../../../../services/date/date";
import Translate from "../../../common/translate/Translate";
import FileThumbnail from "../../../common/file.thumbnail/FileThumbnail";
import {classFileThumbnailSmaller} from "../../../common/file.thumbnail/FileThumbnail.library";

export default function JournalListItem(props) {
    return (
        <div className={"journals-list-item"} onClick={() => props.onItemClick(props.id)}>
            <div className={"journal-list-item-header"}>
                <div className={"journal-date"}>
                    <span>{props.journalDate && formatLocalDateTime(props.journalDate)}</span>
                </div>
            </div>
            {
                props.files && props.files.length > 0 &&
                <div className={"journal-file-thumbnails"}>
                    {props.files.map((file, index) => <FileThumbnail file={file} size={classFileThumbnailSmaller} key={index}/>)}
                </div>
            }
            <div className={"journal-item-section-content"}>
                <div className={"journal-item-section-name"}>
                    <Translate label={props.sectionName.toLowerCase()}/>
                </div>
                {props.sectionContent}
            </div>
            <div className={"journal-item-skill-viewer-comment"}>
                <div className={"journal-item-skills"}>
                    {
                        props.skills && props.skills.length > 0 &&
                        props.skills.map((skill, index) => <SkillTag skillName={skill}  key={index}/>)
                    }
                </div>
                <div className={"journal-item-counts"}>
                    <ListItemViewer viewerCount={props.viewerCount} viewerName={props.viewerName} viewerUserImage={props.viewerUserImage}/>
                    <ListItemComment commentCount={props.commentCount}/>
                </div>
            </div>
        </div>
    )
}