import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {getNotifications, getUserDetails} from "../../store/rootReducer";
import UserImage from "../user.image/UserImage";
import "./UserNotification.css";
import UserNotificationList from "./user.notification.list/UserNotificationList";
import useOutsideClick from "../../hooks/useOutsideClick";
import {getNotificationCount} from "./UserNotification.library";
import {onEnterClickCreator} from "../../services/util/util";

export default function UserNotification(props) {
    const [showDropdown, updateShowDropdown] = useState(false);
    const notifications = useSelector(getNotifications);
    const notificationsRef = useRef();
    const userDetails = useSelector(getUserDetails);

    useOutsideClick(notificationsRef, () => {
        updateShowDropdown(false);
    });

    const onKeyDown = onEnterClickCreator(toggleShowDropdown);

    function toggleShowDropdown() {
        updateShowDropdown(!showDropdown);
    }

    function hasNotifications() {
        return notifications && notifications.length > 0;
    }

    function getClassName() {
        let className = "user-notification";
        if (hasNotifications()) {
            className += " has-notifications"
        }
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()} ref={notificationsRef}>
            <div className={"user-notification-container"} onClick={() => toggleShowDropdown()} tabIndex={0} onKeyDown={onKeyDown}>
                <UserImage 
                    name={userDetails.name} 
                    image={userDetails.userImage} 
                    alt={`${userDetails.name} header user image`}
                />
                {hasNotifications() && (
                    <div className={"user-notification-amount"}>
                        {getNotificationCount(notifications)}
                    </div>
                )}
            </div>
            {
                hasNotifications() && showDropdown &&
                    <UserNotificationList
                        className={"user-notification-list"}
                        notifications={notifications}
                        onNotificationClick={() => updateShowDropdown(false)}
                    />
            }

        </div>
    )
}