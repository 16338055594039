import {getUserId, isAuthenticated} from "./services/auth/auth";
import queryString from "query-string";

const CONTEXT_PATH = "/";

export const RouteConstants = {
    ROOT_ROUTE: CONTEXT_PATH,
    LOGIN: CONTEXT_PATH + "login",
    TERMS_AND_CONDITIONS: CONTEXT_PATH + "terms-and-conditions",
    FORGOT_YOUR_PASSWORD: CONTEXT_PATH + "forgot-password",
    FORGOT_YOUR_PASSWORD_RESET: CONTEXT_PATH + "forgot-password-reset",
    SEARCH: CONTEXT_PATH + "search",
    MENTORS: CONTEXT_PATH + "mentors",
    OPPORTUNITIES: CONTEXT_PATH + "opportunities",
    OPPORTUNITIES_SUBMIT: CONTEXT_PATH + "opportunities/submit",
    OPPORTUNITIES_CREATE: CONTEXT_PATH + "opportunities/create",
    OPPORTUNITIES_EDIT: CONTEXT_PATH + "opportunities/:id/edit",
    OPPORTUNITIES_VIEW: CONTEXT_PATH + 'opportunities/:id',
    PROGRAMS: CONTEXT_PATH + "programs",
    PROGRAM_CREATE: CONTEXT_PATH + "programs/create",
    PROGRAM_EDIT: CONTEXT_PATH + "programs/:id/edit",
    PROGRAM_VIEW: CONTEXT_PATH + 'programs/:id',
    SIGN_UP: CONTEXT_PATH + "sign-up",
    REGISTRATION_EXPLANATION: CONTEXT_PATH + "registration-explanation",
    INVITE: CONTEXT_PATH + "invite",
    USER_MANAGEMENT: CONTEXT_PATH + "users",
    USER_MANAGEMENT_EDIT: CONTEXT_PATH + "users/:id/edit",
    REQUEST_REFERENCE_LETTER: CONTEXT_PATH + "request-reference-letter",
    PREFERENCES: CONTEXT_PATH + "preferences",
    PROFILE_BASE: CONTEXT_PATH + "profile/:userId",
    ABOUT: CONTEXT_PATH + "profile/:userId/about",
    ABOUT_EDIT: CONTEXT_PATH + "profile/:userId/about/edit",
    JOURNAL: CONTEXT_PATH + "profile/:userId/journal",
    JOURNAL_CREATE: CONTEXT_PATH + "profile/:userId/journal/create",
    JOURNAL_EDIT: CONTEXT_PATH + "profile/:userId/journal/:journalId/edit",
    JOURNAL_VIEW: CONTEXT_PATH + "profile/:userId/journal/:journalId",
    PORTFOLIO: CONTEXT_PATH + "profile/:userId/portfolio",
    PORTFOLIO_VIEW_SERIES: CONTEXT_PATH + "profile/:userId/portfolio/:portfolioId/series/:seriesId",
    PORTFOLIO_CREATE: CONTEXT_PATH + "profile/:userId/portfolio/create",
    PORTFOLIO_EDIT: CONTEXT_PATH + "profile/:userId/portfolio/:portfolioId/edit",
    PORTFOLIO_VIEW: CONTEXT_PATH + "profile/:userId/portfolio/:portfolioId",
    PROJECTS: CONTEXT_PATH + "profile/:userId/projects",
    PROJECTS_CREATE: CONTEXT_PATH + "profile/:userId/projects/create",
    PROJECTS_EDIT: CONTEXT_PATH + "profile/:userId/projects/:projectId/edit",
    PROJECTS_VIEW: CONTEXT_PATH + "profile/:userId/projects/:projectId",
    SKILLS_MANAGEMENT_GLOBAL: CONTEXT_PATH + "skills-management/global",
    REPORTS: CONTEXT_PATH + "reports"
};

export const PrivilegeConstants = {
    CAN_INVITE_USER: "CAN_INVITE_USER",
    CAN_HAVE_MENTOR: "CAN_HAVE_MENTOR",
    CAN_CREATE_OPPORTUNITY: "CAN_CREATE_OPPORTUNITY",
    CAN_CREATE_PROGRAM: "CAN_CREATE_PROGRAM",
    CAN_MANAGE_USERS: "CAN_MANAGE_USERS",
    CAN_EDIT_GLOBAL_SKILLS: "CAN_EDIT_GLOBAL_SKILLS",
    CAN_HAVE_JOURNAL: "CAN_HAVE_JOURNAL",
    CAN_SEE_REPORTS: "CAN_SEE_REPORTS"
};

export function getAuthenticatedHomePage() {
    return createViewPath(RouteConstants.ABOUT, {userId: getUserId()});
}
export const LOGGED_OUT_HOME_PAGE = RouteConstants.LOGIN;

export function getHomePage() {
    if (isAuthenticated()) {
        return getAuthenticatedHomePage();
    }
    return LOGGED_OUT_HOME_PAGE;
}

export function isActiveRoute(location, route) {
    if (location.pathname.startsWith(route)) {
        return true;
    }
}

export function getActiveClass(location, route) {
    if (isActiveRoute(location, route)) {
        return 'active';
    }
}

export function createViewPath(route, params, queryStringParams) {
    let newRoute = route;

    if (params) {
        Object.keys(params).forEach((key) => {
            newRoute = newRoute.replace(':' + key, params[key]);
        });
    }

    if (queryStringParams) {
        const string = queryString.stringify(queryStringParams)
        if (string) {
            newRoute += '?' + string;
        }
    }

    return newRoute;
}

export const onFormDataChange = (formData, updateFormData) => (key, value) => {
    const newFormData = {
        ...formData,
    };
    newFormData[key] = value;
    updateFormData(newFormData)
}