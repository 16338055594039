import {getDateValue} from "../../../../../services/date/date";

const PeriodFormatter = {
    MONTHLY_STATISTIC: {
        format: (period) => {
            const dateOptions = {month: 'short', year: 'numeric'}
            return getDateValue(period.startDate).toLocaleString("en-US", dateOptions);
        }
    },
    WEEKLY_STATISTIC: {
        format: (period) => {
            const dateOptions = {month: 'short', day: 'numeric'};
            return getDateValue(period.startDate).toLocaleString("en-US", dateOptions);
        }
    },
    DAILY_STATISTIC: {
        format: (period) => {
            const dateOptions = {month: 'short', day: 'numeric'};
            return getDateValue(period.startDate).toLocaleString("en-US", dateOptions);
        }
    }
};

export function formatPeriod(currentPeriod, periodValue) {
    if (!currentPeriod) {
        return "";
    }
    return PeriodFormatter[currentPeriod].format(periodValue);
}