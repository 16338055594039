export const message = (state = {}, action) => {
    switch (action.type) {
        case 'DISPLAY_SUCCESS':
            return {
                isSuccess: true,
                messages: action.payload.messages
            };
        case 'DISPLAY_ERROR':
            return {
                isSuccess: false,
                messages: action.payload.messages
            };
        case 'CLEAR_MESSAGE':
            return {};
        default:
            return state;
    }
};

export const isSuccess = (state) => state.isSuccess;
export const getMessages = (state) => state.messages;
