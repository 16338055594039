import React from "react";
import "./MentorItemNameRole.css";
import {translate} from "../../common/translate/Translate.library";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";

export default function MentorItemNameRole(props) {
    const language = useSelector(getLanguage);

    function getClassName() {
        let className = "mentor-item-name-role";
        if (props.isMentor) {
            className += " is-a-mentor";
        }
        return className;
    }
    return (
        <div className={getClassName()}>
            <div className="mentor-name">
                {props.name}
            </div>
            <div className="mentor-role">
                {props.roles.map((value, index) => {
                    return translate(value, language)
                }).join(', ')}
            </div>
        </div>
    )
}