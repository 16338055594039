import React from "react";
import Translate from "../../common/translate/Translate";
import "./OpportunitiesItemType.css";
import {getOpportunityTypeClassName, getOpportunityTypeLabel} from "./OpportunityItemType.library";

export default function OpportunitiesItemType(props) {

    function getClassName() {
        let className = "opportunities-list-item-type " + getOpportunityTypeClassName(props.type);
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <Translate label={getOpportunityTypeLabel(props.type)}/>
        </div>
    )
}