export const selectOptions = (state = {}, action) => {
    switch (action.type) {
        case 'SET_ROLE_OPTIONS':
            return {
                ...state,
                roles: action.payload.options
            };
        case 'SET_MENTOR_ROLE_OPTIONS':
            return {
                ...state,
                mentorRoles: action.payload.options
            };
        case 'SET_OPPORTUNITY_TYPE_OPTIONS':
            return {
                ...state,
                opportunityTypes: action.payload.options
            };
        case 'SET_USER_MANAGEMENT_TYPE_OPTIONS':
            return {
                ...state,
                userManagementTypes: action.payload.options
            };
        case 'SET_USER_ROLE_OPTIONS':
            return {
                ...state,
                userRoles: action.payload.options
            };
        case 'SET_JOURNAL_SECTION_OPTIONS':
            return {
                ...state,
                journalSections: action.payload.options
            };
        case 'SET_GLOBAL_AND_USER_SKILL_OPTIONS':
            return {
                ...state,
                globalAndUserSkills: action.payload.options
            };
        case 'SET_STATISTIC_PERIOD_OPTIONS':
            return {
                ...state,
                statisticPeriodOptions: action.payload.options
            };
        case 'SET_PARTICIPATION_STATUS_OPTIONS':
            return {
                ...state,
                participationStatusOptions: action.payload.options
            };
        default:
            return state;
    }
};

export const getRoles = (state) => state.roles;
export const getMentorRoles = (state) => state.mentorRoles;
export const getOpportunityTypes = (state) => state.opportunityTypes;
export const getUserManagementTypes = (state) => state.userManagementTypes;
export const getUserRoles = (state) => state.userRoles;
export const getJournalSection = (state) => state.journalSections;
export const getGlobalAndUserSkills = (state) => state.globalAndUserSkills;
export const getStatisticPeriods = (state) => state.statisticPeriodOptions;
export const getParticipationStatuses = (state) => state.participationStatusOptions;