import React from "react";
import "./ProfileUploadMedia.css";
import FileThumbnail from "../../common/file.thumbnail/FileThumbnail";
import FileUpload from "../../common/file.upload/FileUpload";

export default function ProfileUploadMedia(props) {
    return (
        <FileUpload
            {...props}
            fileUploadItem={FileThumbnail}
            className={"profile-upload-media"}
            buttonClassName={"profile-edit-add-media"}
            buttonWrapperClassName={"profile-edit-button-wrapper"}
            fileUploadItemContainerClassName={"profile-file-upload-item-container"}
            showAddVideo={true}
            showAddMusicPlatform={true}
        />
    )
}