import React from "react";
import "./ProfileViewTextArea.css";
import Translate from "../../common/translate/Translate";

export default function ProfileViewTextArea(props) {
    return (
        <div className={"profile-view-text-area"}>
            <div className={"profile-view-text-area-label"}>
                <Translate label={props.label}/>
            </div>
            <p>{props.text}</p>
        </div>
    )
}