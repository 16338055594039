import { combineReducers } from "redux";
import { language, getLanguageFromLanguageState} from "../components/common/translate/ducks/reducers";
import {message, isSuccess, getMessages as getMessagesFromMessageState} from "../components/message.panel/ducks/reducers";
import {auth} from "../services/auth/ducks/reducers";
import {isLoggedOut as getIsLoggedOut} from "../services/auth/ducks/reducers";
import {
    selectOptions,
    getRoles as getRoleOptionsFromSelectOptionsState,
    getMentorRoles,
    getOpportunityTypes,
    getUserManagementTypes,
    getUserRoles,
    getJournalSection,
    getGlobalAndUserSkills,
    getStatisticPeriods,
    getParticipationStatuses
} from "../services/select.options/ducks/reducers";
import {notifications, getAllNotifications} from "../services/notification/ducks/reducers";
import {getUserDetailsState, userDetails} from "../services/user.details/ducks/reducers";

const appReducer = combineReducers({
    language,
    message,
    auth,
    selectOptions,
    notifications,
    userDetails
});

const rootReducer = (state, action) => {
    if (action.type === 'DESTROY_SESSION') {
        state = undefined;
    }
    return appReducer(state, action);
};

export const getLanguage = (state) => getLanguageFromLanguageState(state.language);
export const isMessageSuccess = (state) => isSuccess(state.message);
export const getMessages = (state) => getMessagesFromMessageState(state.message);
export const isLoggedOut = (state) => getIsLoggedOut(state.auth);
export const getRoleOptions = (state) => getRoleOptionsFromSelectOptionsState(state.selectOptions);
export const getMentorRoleOptions = (state) => getMentorRoles(state.selectOptions);
export const getOpportunityTypeOptions = (state) => getOpportunityTypes(state.selectOptions);
export const getUserManagementTypeOptions = (state) => getUserManagementTypes(state.selectOptions);
export const getParticipationStatusOptions = (state) => getParticipationStatuses(state.selectOptions);
export const getUserRoleOptions = (state) => getUserRoles(state.selectOptions);
export const getJournalSectionOptions = (state) => getJournalSection(state.selectOptions);
export const getGlobalAndUserSkillsOptions = (state) => getGlobalAndUserSkills(state.selectOptions);
export const getStatisticPeriodOptions = (state) => getStatisticPeriods(state.selectOptions);
export const getNotifications = (state) => getAllNotifications(state.notifications);
export const getUserDetails = (state) => getUserDetailsState(state.userDetails);

export default rootReducer;
