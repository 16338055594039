import React, {useEffect, useState} from "react";
import List from "../../components/common/list/List";
import {API_PATHS, makeJSONGetRequest, makeUrl} from "../../services/ajax/ajax";
import {useDispatch} from "react-redux";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import Translate from "../../components/common/translate/Translate";
import OpportunitiesListItem from "../../components/opportunities/opportunities.list.item/OpportunitiesListItem";
import {buildListOpportunitiesRequest} from "./Opportunities.library";
import "./Opportunities.css";
import OpportunitiesSubmitLink from "../../components/opportunities/opportunities.submit.link/OpportunitiesSubmitLink";
import {createViewPath, RouteConstants} from "../../App.library";
import InfoLink from "../../components/common/info.link/InfoLink";
import AppLinks from "../../resources/data/links";

export default function Opportunities(props) {

    const dispatcher = useDispatch();
    const [formData, updateFormData] = useState(buildListOpportunitiesRequest(props.location.search));
    const [opportunityResults, updateOpportunityResults] = useState({});

    useEffect(() => {
        const newFormData = buildListOpportunitiesRequest(props.location.search);
        makeJSONGetRequest(makeUrl(API_PATHS.OPPORTUNITIES_LIST, null, {
            ...newFormData,
            pageNumber: newFormData.pageNumber - 1,
            pageSize: 20
        }), dispatcher).then((response) => {
            updateFormData(newFormData);
            updateOpportunityResults(response.body);
        });
    }, [props.location.search, dispatcher]);

    function onPageClick(currentPage) {
        listOpportunities(currentPage);
    }

    function listOpportunities(page) {
        const searchQueryParams = {
            ...formData,
            pageNumber: page
        };
        props.history.push({
            pathname: props.history.pathname,
            search: makeUrl('', null, searchQueryParams)
        });
    }

    function onItemClick(id) {
        props.history.push({
            pathname: createViewPath(RouteConstants.OPPORTUNITIES_VIEW, {id: id}), state: {opportunityIds: opportunityResults.opportunityIds}
        });
    }

    return (
        <BasicLayout
            {...props}
        >
            <div className={"opportunities-container"}>
                <div className={"opportunities-container-heading"}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <h1>
                            <Translate label={"opportunities_view_heading"}/>
                        </h1>
                        <InfoLink to={AppLinks.help_opportunities_view} />
                    </div>
                    <div className={"opportunities-submit-create-link"}>
                        <OpportunitiesSubmitLink/>
                    </div>
                </div>
                <List
                    singularAmountLabel={"opportunities_amount_label_singular"}
                    pluralAmountLabel={"opportunities_amount_label_plural"}
                    onPageClick={onPageClick}
                    currentPage={formData.pageNumber}
                    numberOfPages={opportunityResults.numberOfPages}
                    items={opportunityResults.opportunities}
                    itemComponent={OpportunitiesListItem}
                    totalResultsCount={opportunityResults.totalResultsCount}
                    additionalItemProps={{
                        onItemClick
                    }}
                />
            </div>
        </BasicLayout>
    )
}
