import React from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import "./BasicLayout.css";
import MessagePanel from "../../message.panel/MessagePanel";

export default function BasicLayout(props) {
    return (
        <div className="basic-layout">
            <Header {...props} authRoute={props.authRoute} authLabel={props.authLabel} authOnClick={props.authOnClick} />
            <section>
                <MessagePanel />
                {props.children}
            </section>
            <Footer />
        </div>
    )
}
