import React from 'react'
import "./PhoneNumberInput.css"
import Input from "../input/Input";
import Label from "../label/Label";
import FieldErrorFeedback from "../field.error.feedback/FieldErrorFeedback";

export default function PhoneNumberInput(props) {

    return (
        <div className="tel-input">
            <Label htmlFor={props.id} label={props.label} params={props.labelParams} />
            <Input
                hasError={props.hasError}
                disabled={props.disabled}
                id={props.id}
                value={props.value}
                placeholder={props.placeholder}
                type="tel"
                pattern="[0-9]{3}-[0-9]{2}-[0-9]{4}"
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
            <FieldErrorFeedback errorMessage={props.hasError ? props.errorMessage : null}/>
        </div>
    );
}