import React, {useCallback, useState} from "react";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import Translate from "../../components/common/translate/Translate";
import "./Mentors.css";
import List from "../../components/common/list/List";
import MentorItem from "../../components/mentor/mentor.item/MentorItem";
import MentorSearch from "../../components/mentor/mentor.search/MentorSearch";
import {API_PATHS, makeJSONGetRequest, makeUrl} from "../../services/ajax/ajax";
import {useDispatch} from "react-redux";
import useFetchOrStore from "../../hooks/useFetchOrStore";
import {getMentorRoleOptions} from "../../store/rootReducer";
import {setMentorRoleOptions} from "../../services/select.options/ducks/actions";
import {buildMentorsSearchObject, DEFAULT_PAGE} from "./Mentors.library";
import useRouteChange from "../../components/common/list/hooks/useRouteChange";
import {createViewPath, RouteConstants} from "../../App.library";

export default function Mentors(props) {
    const dispatcher = useDispatch();

    const [formData, updateFormData] = useState(buildMentorsSearchObject(props.location.search));
    const [roleOptions] = useFetchOrStore(getMentorRoleOptions, fetchMentorRoles);

    const [searchResults, updateSearchResults] = useState({});

    async function fetchMentorRoles() {
        const response = await makeJSONGetRequest(API_PATHS.GET_MENTOR_ROLE_OPTIONS, dispatcher);
        dispatcher(setMentorRoleOptions(response.body.options));
    }

    const onRouteChange = useCallback((searchParams) => {
        const newFormData = buildMentorsSearchObject(searchParams);
        makeJSONGetRequest(makeUrl(API_PATHS.MENTOR_SEARCH, null, {
            ...newFormData,
            pageNumber: newFormData.pageNumber - 1, // subtract 1 since the paging in the backend is 0 indexed
            pageSize: 8
        }), dispatcher).then((response) => {
            updateFormData(newFormData);
            updateSearchResults(response.body);
        });
    }, [dispatcher])

    useRouteChange(onRouteChange);

    function onPageClick(newPage) {
        search(newPage);
    }

    function onSearchClick() {
        search(DEFAULT_PAGE);
    }

    function search(page) {
        const searchQueryParams = {
            ...formData,
            pageNumber: page
        };
        props.history.push({
            pathname: props.history.pathname,
            search: makeUrl('', null, searchQueryParams)
        });
    }

    function onNameChange(value) {
       updateFormData({...formData, name: value});
    }

    function onRoleChange(value) {
        updateFormData({...formData, roleId: value});
    }

    function onShowOnlyMyMentorsChange(value) {
        updateFormData({...formData, showOnlyMyMentors: value})
    }

    function onItemClick(id) {
        props.history.push({
            pathname: createViewPath(RouteConstants.ABOUT, {userId: id}),
            state: {userIds: searchResults.resultIds || [], path: RouteConstants.ABOUT}
        });
    }

    function onRequestLetterOfReference(id) {
        props.history.push({
            pathname: RouteConstants.REQUEST_REFERENCE_LETTER,
            state: {mentorId: id}
        })
    }

    return (
        <BasicLayout {...props}>
            <section className="mentors-container">
                <div>
                    <h1><Translate label="mentors_view_heading"/></h1>
                </div>
                <MentorSearch
                    roles={roleOptions || []}
                    name={formData.name}
                    role={formData.roleId}
                    onSearch={onSearchClick}
                    onNameChange={onNameChange}
                    onRoleChange={onRoleChange}
                    onShowOnlyMyMentorsChange={onShowOnlyMyMentorsChange}
                    showOnlyMyMentors={formData.showOnlyMyMentors}
                />
                <List
                    onPageClick={onPageClick}
                    currentPage={formData.pageNumber}
                    numberOfPages={searchResults.numberOfPages}
                    singularAmountLabel={"mentors_view_found_number_of_profiles_singular"}
                    pluralAmountLabel={"mentors_view_found_number_of_profiles_plural"}
                    itemComponent={MentorItem}
                    items={searchResults.mentors}
                    totalResultsCount={searchResults.totalResultsCount}
                    additionalItemProps={{
                        onItemClick: onItemClick,
                        onRequestLetterOfReference
                    }}
                />
            </section>
        </BasicLayout>
    );
}
