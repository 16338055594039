import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import useFetchOrStore from "../../../hooks/useFetchOrStore";
import { getParticipationStatusOptions } from "../../../store/rootReducer";
import { setParticipationStatusOptions } from "../../../services/select.options/ducks/actions";
import { DEFAULT_PAGE } from "../../../views/search/Search.library";
import { API_PATHS, makeJSONGetRequest, makeUrl } from "../../../services/ajax/ajax";
import TextInput from "../../common/text.input/TextInput";
import TextSelect from "../../common/text.select/TextSelect";
import ButtonAccept from "../../common/button.accept/ButtonAccept";
import List from "../../common/list/List";
import ProgramParticipationListItem from "../program.participation.list.item/ProgramParticipationListItem";
import "./ProgramParticipation.css";

export default function ProgramParticipation(props) {
    const dispatch = useDispatch();
    const [participationStatuses] = useFetchOrStore(getParticipationStatusOptions, fetchParticipationStatuses);
    const [formData, updateFormData] = useState({name: '', email: '', status: '', pageNumber: 1});
    const [searchResults, updateSearchResults] = useState({});

    useEffect(() => {
        onSearch();
    }, []);

    async function fetchParticipationStatuses() {
        const response = await makeJSONGetRequest(API_PATHS.GET_PROGRAM_PARTICIPATION_STATUSES, dispatch);
        dispatch(setParticipationStatusOptions(response.body.options));
    }

    function onSearch() {
        search(DEFAULT_PAGE);
    }

    function onPageClick(newPage) {
        search(newPage);
    }

    function search(page) {
        makeJSONGetRequest(makeUrl(API_PATHS.PROGRAM_PARTICIPATION_SEARCH, {id: props.programId},
            {
                ...formData,
                pageNumber: page - 1,
                pageSize: 8
            }), dispatch).then((response) => {
            updateSearchResults(response.body);
            updateFormData({...formData, pageNumber: page});
        });
    }

    function onFormDataChange(key, value) {
        const newFormData = {
            ...formData,
        };
        newFormData[key] = value;
        updateFormData(newFormData)
    }

    function updateSearchResultParticipation(userId, participationStatus) {
        const newSearchResults = {...searchResults};
        const updatedResult = newSearchResults.results.find((user) => user.id === userId);
        updatedResult.participationStatus = participationStatus;
        updateSearchResults(newSearchResults);
    }

    return (
        <div className="program-participation">
            <div className="participation-search">
                <div className="participation-search-params">
                    <TextInput
                        id={"search-name"}
                        label={"search_parameter_name_label"}
                        placeholder={"search_parameter_name_label_placeholder"}
                        onChange={(event) => onFormDataChange('name', event.target.value)}
                        value={formData.name}
                    />
                    <TextInput
                        id={"search-email"}
                        label={"search_parameter_email_label"}
                        placeholder={"search_parameter_email_label_placeholder"}
                        onChange={(event) => onFormDataChange('email', event.target.value)}
                        value={formData.email}
                    />
                    <TextSelect
                        id={"search-status"}
                        label={"search_parameter_participation_status_label"}
                        options={participationStatuses || []}
                        onChange={(event) => onFormDataChange('status', event.target.value)}
                        value={formData.status}
                    />
                </div>
                <ButtonAccept className="participation-search-button" label="search-button-label" onClick={onSearch} />
            </div>
            <List
                {...props}
                className={"participation-user-results"}
                onPageClick={onPageClick}
                currentPage={formData.pageNumber}
                numberOfPages={searchResults.numberOfPages}
                singularAmountLabel={"program_edit_found_number_of_participant_singular"}
                pluralAmountLabel={"program_edit_found_number_of_participant_plural"}
                itemComponent={ProgramParticipationListItem}
                items={searchResults.results}
                totalResultsCount={searchResults.totalResultsCount}
                additionalItemProps={{
                    programId: props.programId,
                    updateSearchResultParticipation
                }}
            />
        </div>
    )
}