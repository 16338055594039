import React from "react";
import {translate} from "../translate/Translate.library";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";
import "./Select.css";

export default function Select(props) {
    const language = useSelector(getLanguage);

    function getInputClassName() {
        let className = 'basic-select';
        if (props.hasError) {
            className = className + ' select-error';
        }
        return className;
    }

    return (
        <div className={getInputClassName()}>
            <select
                disabled={props.disabled}
                value={props.value}
                id={props.id}
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
            >
                {props.placeholder && <option key="" value="">{translate(props.placeholder, language)}</option>}
                {
                    props.options.map((option) => {
                        return <option key={option.value} value={option.value}>{translate(option.label, language)}</option>
                    })
                }
            </select>
        </div>
    )
}
