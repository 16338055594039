import React from "react";
import "./SearchItemNameStatement.css";

export default function SearchItemNameStatement(props) {

    function getNameClass() {
        let className = "search-item-name";
        if (props.isPeer) {
            className += ' is-a-peer';
        }
        return className;
    }

    return (
        <div className={"search-item-name-statement"}>
            <div className={getNameClass()}>
                {props.name}
            </div>
            {
                props.statement &&
                    <div className={"search-item-statement"}>
                        <span>{'“'}</span>
                        <div className={"search-item-statement-text"}>
                            { props.statement}
                        </div>
                        <span>{'”'}</span>
                    </div>
            }
        </div>
    )
}