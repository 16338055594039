import React, {useState, useEffect} from 'react';
import "./BandcampEmbed.css";

const BandcampEmbed = ({ location }) => {
    const [albumId, updateAlbumId] = useState('');
    const [trackId, updateTrackId] = useState('');

    useEffect(() => {
        function getIds(location) {
            if (!location) return;
            
            const regex = /link=([^,]+),albumId=(\d+),trackId=(\d*|\b)/;
            const match = location.match(regex);

            if (!match) return location;

            updateAlbumId(match[2]);
            updateTrackId(match[3]);
        }

        getIds(location);
    }, [location]);

    function getSrc() {
        if (!albumId) {
            return;
        }

        if (!trackId) {
            return `https://bandcamp.com/EmbeddedPlayer/album=${albumId}/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/`;
        }

        return `https://bandcamp.com/EmbeddedPlayer/album=${albumId}/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/track=${trackId}/transparent=true/`;
    }

    return (
        <div className="bandcamp-embed-container">
            <iframe
                title="bandcamp-embed"
                className="bandcamp-embed"
                src={getSrc()}
                seamless
            />
        </div>
    );
};

export default BandcampEmbed;