import React from 'react'
import './NewWindowWarning.css'

const NewWindowWarning = () => (
  <span class="screen-reader-only">
      (opens in a new window)
  </span>
)

export default NewWindowWarning
