import React from "react";
import {formatLocalDate} from "../../../services/date/date";
import Translate from "../../common/translate/Translate";

export default function OpportunitiesDateLabel(props) {
    return (
        <>
            {
                props.date
                    ? <span>{formatLocalDate(props.date)}</span>
                    : <Translate label={props.fallbackLabel}/>
            }
        </>
    )
}