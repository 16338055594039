import React from 'react'
import { translate } from "./Translate.library";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";

export default function Translate(props) {
    const language = useSelector(getLanguage);
    return (
        <span dangerouslySetInnerHTML={{__html: translate(props.label, language, props.params)}} />
    )
}