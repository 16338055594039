import React from "react";
import "./ButtonReply.css";
import {ReplyIcon} from "../svg/svg";
import Translate from "../translate/Translate";

export default function ButtonReply(props) {

    function getClassName() {
        let className = "button-reply";
        if (props.className) {
            className += ' ' + className;
        }
        return className;
    }

    return (
        <div className={getClassName()} onClick={props.onClick} tabIndex={0}>
            <ReplyIcon/>
            <div>
                <Translate label={"button_reply_label"}/>
            </div>
        </div>
    )
}