import React, {useState} from "react";
import "./SkillsManagementGlobalParams.css";
import ListParamsContainer from "../../common/list/list.params.container/ListParamsContainer";
import TextInput from "../../common/text.input/TextInput";
import SearchButton from "../../common/search.button/SearchButton";
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import Translate from "../../common/translate/Translate";
import {createOnBlurFunction} from "../../../services/formik/formik";
import {trackPromise} from "react-promise-tracker";


const SkillsManagementSchema = Yup.object().shape({
    name: Yup.string().required(<Translate label={"validation_message_required"}/>)
});

export default function SkillsManagementGlobalParams(props) {
    const [areFiltersHidden, updateAreFiltersHidden] = useState(false);

    return (
        <ListParamsContainer
            isHidden={areFiltersHidden}
            onHiddenClick={() => {
                updateAreFiltersHidden(!areFiltersHidden)
            }}
            showLabel={"skills_management_global_show_params"}
            hideLabel={"skills_management_global_hide_params"}
            className={"skills-management-global-params"}
        >
            <Formik
                enableReinitialize={true}
                initialValues={{name: ''}}
                validationSchema={SkillsManagementSchema}
                onSubmit={(values, actions) => {
                    trackPromise(
                        props.onAdd(values)
                            .then(() => {
                                actions.resetForm();
                            }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    );
                }}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {
                    formProps => (
                        <Form
                            className={"skills-management-global-params-container"}
                        >
                            <Field name={"name"}>
                                {
                                    fieldProps => (
                                        <TextInput
                                            {...fieldProps.field}
                                            id={"name"}
                                            name={"name"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"skills_management_global_add_text_box"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                            <SearchButton type={"submit"} label={"skills_management_global_add_search_button"}/>
                        </Form>
                    )
                }
            </Formik>
        </ListParamsContainer>
    )
}