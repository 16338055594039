import React, {useCallback, useEffect, useState} from "react";
import "./PortfolioViewSeries.css";
import {useDispatch, useSelector} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeUrl} from "../../../services/ajax/ajax";
import PortfolioViewSeriesHeader
    from "../../../components/profile/portfolio/portfolio.view.series.header/PortfolioViewSeriesHeader";
import {createViewPath, RouteConstants} from "../../../App.library";
import FileThumbnail from "../../../components/common/file.thumbnail/FileThumbnail";
import {makeFileViewer} from "../../../components/common/file.viewer/FileViewer";
import {getLanguage} from "../../../store/rootReducer";

export default function PortfolioViewSeries(props) {
    const language = useSelector(getLanguage);
    const dispatcher = useDispatch();
    const [seriesData, updateSeriesData] = useState({});

    const refreshSeriesData = useCallback(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PORTFOLIO_SERIES_GET, {portfolioId: props.match.params.portfolioId, seriesId: props.match.params.seriesId}), dispatcher)
            .then((response) => {
                updateSeriesData(response.body);
            })
    }, [props.match.params.seriesId, props.match.params.portfolioId, dispatcher]);

    const onThumbnailClick = (file) => () => {
        makeFileViewer(language, dispatcher, file, seriesData.files, seriesData.canEditFiles, seriesData.canMarkFilesSensitive, refreshSeriesData);
    }

    useEffect(() => {
        refreshSeriesData()
    }, [refreshSeriesData]);

    return (
        <div className={"portfolio-view-series"}>
            {
                seriesData && Object.keys(seriesData).length > 0 &&
                <>
                    <PortfolioViewSeriesHeader
                        backTo={createViewPath(RouteConstants.PORTFOLIO_VIEW, {portfolioId: props.match.params.portfolioId, userId: props.match.params.userId})}
                        titleLabel={"portfolio_series_view_title"}
                        titleLabelParams={{portfolioName: seriesData.portfolioName, seriesName: seriesData.seriesName}}
                    />
                    {
                        seriesData.files && Object.keys(seriesData.files).length > 0
                        &&
                        <div className={"portfolio-series-view-gallery"}>
                            {
                                seriesData.files.map(file => {
                                    return <FileThumbnail
                                        file={file}
                                        onThumbnailClick={onThumbnailClick(file)}
                                        key={file.id}
                                        className={"portfolio-series-view-thumbnail"}
                                        fullSize
                                    />
                                })
                            }
                        </div>
                    }
                </>
            }
        </div>
    )
}