import React from "react";
import "./CheckboxInput.css"
import Label from "../label/Label";
import Checkbox from "../checkbox/Checkbox";

export default function CheckboxInput(props) {

    function getClassName() {
        let className = "checkbox-input";
        if (props.className) {
            className += " " + props.className;
        }
        if (props.disabled) {
            className += " disabled-checkbox-input";
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <Checkbox
                id={props.id}
                disabled={props.disabled}
                checked={props.checked}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
            <Label htmlFor={props.id} label={props.label} params={props.labelParams} />
        </div>
    );
}