import React from 'react';
import "./AppleMusicEmbed.css"

const AppleMusicEmbed = ({ uri }) => {

    function getClassName() {
        var className = "apple-music-embed";
        
        // if uri is a track
        if (uri.includes("?i=")) {
            className += " apple-music-embed-track"
        }

        return className;
    }

    function getKey(uri) {
        const regex = /album.*/;
        const match = uri.match(regex);

        if (!match) return uri;
        
        return match[0];
    }

    return (
        <div className='apple-music-embed-container'>
            <iframe
                title="apple-music-embed"
                className={getClassName()}
                allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                src={`https://embed.music.apple.com/us/${getKey(uri)}`}
            />
        </div>
    );
};

export default AppleMusicEmbed;