import React, {useCallback, useState} from "react";
import "./SkillsManagementGlobal.css";
import BasicLayout from "../../../components/layouts/basic.layout/BasicLayout";
import {useDispatch} from "react-redux";
import {buildGlobalSkillsSearchObject, DEFAULT_PAGE} from "./SkillsManagementGlobal.library";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import Translate from "../../../components/common/translate/Translate";
import List from "../../../components/common/list/List";
import SkillsManagementGlobalItem
    from "../../../components/skills.management/skills.management.global.item/SkillsManagementGlobalItem";
import useRouteChange from "../../../components/common/list/hooks/useRouteChange";
import SkillsManagementGlobalParams
    from "../../../components/skills.management/skills.management.global.params/SkillsManagementGlobalParams";
import {displaySuccess} from "../../../components/message.panel/ducks/actions";

export default function SkillsManagementGlobal(props) {
    const dispatcher = useDispatch();
    const [formData, updateFormData] = useState(buildGlobalSkillsSearchObject(props.location.search));
    const [searchResults, updateSearchResults] = useState({});

    const onRouteChange = useCallback((searchParams) => {
        const newFormData = buildGlobalSkillsSearchObject(searchParams);
        makeJSONGetRequest(makeUrl(API_PATHS.GET_GLOBAL_SKILLS_FOR_EDITING, null, {
            ...newFormData,
            pageNumber: newFormData.pageNumber - 1,
            pageSize: 20
        }), dispatcher).then((response) => {
            updateFormData(newFormData);
            updateSearchResults(response.body);
        });
    }, [dispatcher]);

    useRouteChange(onRouteChange);

    function onPageClick(newPage) {
        loadResults(newPage);
    }

    async function onAdd(values) {
        await makeJSONPostRequest(API_PATHS.ADD_GLOBAL_SKILL, dispatcher, values);
        loadResults(DEFAULT_PAGE);
        displaySuccess([
            {
                label: "skills_management_global_successfully_added_skill"
            }
        ]);
    }

    async function onRemove(id) {
        await makeJSONPostRequest(makeUrl(API_PATHS.REMOVE_GLOBAL_SKILL, {
            skillId: id
        }), dispatcher);
        loadResults(DEFAULT_PAGE);
        displaySuccess([
            {
                label: "skills_management_global_successfully_removed_skill"
            }
        ])
    }

    function loadResults(page) {
        const searchQueryParams = {
            ...formData,
            pageNumber: page
        };
        props.history.push({
            pathname: props.history.pathname,
            search: makeUrl('', null, searchQueryParams)
        });
    }

    return (
        <BasicLayout {...props}>
            <section className={"skills-management-global-container"}>
                <div>
                    <h1><Translate label={"skills_management_global_heading"}/></h1>
                </div>
                <SkillsManagementGlobalParams
                    onAdd={onAdd}
                />
                <List
                    onPageClick={onPageClick}
                    currentPage={formData.pageNumber}
                    numberOfPages={searchResults.numberOfPages}
                    singularAmountLabel={"skills_management_global_number_of_skills_singular"}
                    pluralAmountLabel={"skills_management_global_number_of_skills_plural"}
                    items={searchResults.skillTags}
                    totalResultsCount={searchResults.totalResultsCount}
                    itemComponent={SkillsManagementGlobalItem}
                    additionalItemProps={{
                        onRemove
                    }}
                />
            </section>
        </BasicLayout>
    )
}