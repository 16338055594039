import React from "react";
import ChevronShowHide from "../../chevron.show.hide/ChevronShowHide";
import "./ListParamsContainer.css";

export default function ListParamsContainer(props) {

    function getParametersClassName() {
        let className = 'list-parameters';
        if (props.className) {
            className += ' ' + props.className;
        }
        if (props.isHidden) {
            className += ' is-hidden';
        }

        return className;
    }

    return (
        <div className={'list-parameters-container'} onKeyDown={props.onKeyDown}>
            <div className={"hide-show-parameters"}>
                <ChevronShowHide
                    isHidden={props.isHidden}
                    onHiddenClick={() => {
                        props.onHiddenClick();
                    }}
                    showLabel={props.showLabel}
                    hideLabel={props.hideLabel}
                />
            </div>
            <div className={getParametersClassName()}>
                {props.children}
            </div>
        </div>
    );
}