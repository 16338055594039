import queryString from "query-string";
import {convertStringToBoolean} from "../../services/util/util";

export const DEFAULT_PAGE = 1;

export function privateBuildSearchItem(searchString) {
    const queryParameters = queryString.parse(searchString);
    return {
        name: queryParameters.name || '',
        email: queryParameters.email || '',
        keywords: queryParameters.keywords || '',
        showOnlyMyPeers: convertStringToBoolean(queryParameters.showOnlyMyPeers) || false,
        hasProfileImage: convertStringToBoolean(queryParameters.hasProfileImage) || false,
        hasPortfolioWorks: convertStringToBoolean(queryParameters.hasPortfolioWorks) || false,
        openToCollaboration: convertStringToBoolean(queryParameters.openToCollaboration) || false,
        pageNumber: parseInt(queryParameters.pageNumber || DEFAULT_PAGE)
    };
}

export function publicBuildSearchItem(searchString) {
    const queryParameters = queryString.parse(searchString);
    return {
        keywords: queryParameters.keywords || '',
        pageNumber: parseInt(queryParameters.pageNumber || DEFAULT_PAGE)
    }
}

export function getKeywordsForAPICall(keywords) {
    if (!keywords) {
        return []
    }
    const sanitizedKeywords = keywords.replace(/\s\s+/g, ' ');
    return sanitizedKeywords.split(' ');
}