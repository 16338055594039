import queryString from "query-string";
import {API_PATHS, makeJSONGetRequest, makeUrl} from "../../services/ajax/ajax";

const DEFAULT_KEYWORDS_VALUE = '';
const DEFAULT_SECTION_VALUE = '';
const DEFAULT_SKILL_TAG_VALUE = '';
const DEFAULT_USER_SKILL_TAG_VALUE = '';
export const DEFAULT_PAGE = 1;

export function buildListJournalsRequest(searchString) {
    const queryParameters = queryString.parse(searchString);
    return {
        keywords: queryParameters.keywords || DEFAULT_KEYWORDS_VALUE,
        section: queryParameters.section || DEFAULT_SECTION_VALUE,
        skillTag: queryParameters.skillTag || DEFAULT_SKILL_TAG_VALUE,
        userSkillTag: queryParameters.userSkillTag || DEFAULT_USER_SKILL_TAG_VALUE,
        pageNumber: parseInt(queryParameters.pageNumber || DEFAULT_PAGE)
    }
}

export function getKeywordsForAPICall(keywords) {
    const trimmedKeywords = keywords.trim();
    if (!trimmedKeywords) {
        return []
    }
    const sanitizedKeywords = trimmedKeywords.replace(/\s\s+/g, ' ');
    return sanitizedKeywords.split(' ');
}

export async function loadUserTags(userId, dispatcher) {
    const response = await makeJSONGetRequest(makeUrl(API_PATHS.GET_GLOBAL_AND_USER_SKILL_OPTIONS, {userId: userId}), dispatcher)
    return response.body.options;
}