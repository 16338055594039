import React, { useEffect, useState } from "react";
import "./Portfolio.css"
import {API_PATHS, makeJSONGetRequest, makeUrl, makeJSONPostRequest} from "../../services/ajax/ajax";
import {useDispatch} from "react-redux";
import PortfolioListItem from "../../components/profile/portfolio/portfolio.list.item/PortfolioListItem";
import {createViewPath, RouteConstants} from "../../App.library";
import ProfileAddEntry from "../../components/profile/profile.add.entry/ProfileAddEntry";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, arrayMove, rectSortingStrategy } from "@dnd-kit/sortable";

export default function Portfolio(props) {
    const userId = parseInt(props.match.params.userId);
    const dispatcher = useDispatch();
    const [portfoliosData, updatePortfoliosData] = useState({});

    function getPortfolios() {
        if (portfoliosData.portfolios) {
            return portfoliosData.portfolios;
        }
        return [];
    }

    function goToPortfolio(id) {
        props.history.push(createViewPath(RouteConstants.PORTFOLIO_VIEW, {userId: userId, portfolioId: id}));
    }

    function editPortfolioTo(id) {
        props.history.push(createViewPath(RouteConstants.PORTFOLIO_EDIT, {userId: userId, portfolioId: id}));
    }

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PORTFOLIO_LIST, {userId: userId}), dispatcher)
            .then((response) => {
                updatePortfoliosData(response.body);
            });
    }, [userId, dispatcher]);

    function handleDragEnd(event) {
        if (!portfoliosData.canPerformPortfolioOperations) return;
        const {active, over} = event;
        if (active.id === over.id) {
            return;
        }

        handleSort(active, over);
    }

    function handleSort(active, over) {
        const oldIndex = getPortfolios().findIndex(p => p.id === active.id);
        const newIndex = getPortfolios().findIndex(p => p.id === over.id);
        const updatedPortfolios = arrayMove(getPortfolios(), oldIndex, newIndex);
        updatePortfoliosData((prevPortfolioData) => ({
            ...prevPortfolioData,
            portfolios: updatedPortfolios
        }));

        makeJSONPostRequest(API_PATHS.PORTFOLIO_SAVE_ORDER, dispatcher, updatedPortfolios);
    }

    return (
        <div className="portfolio-container">
            <ProfileAddEntry
                canAddEntry={portfoliosData.canPerformPortfolioOperations}
                to={createViewPath(RouteConstants.PORTFOLIO_CREATE, {userId: props.match.params.userId})}
                label={"portfolio_add_entry"}
                className={"add-portfolio-button"}
            />
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={getPortfolios()} strategy={rectSortingStrategy}>
                    {
                        getPortfolios().map((item) => {
                            return <PortfolioListItem
                                id={item.id}
                                image={item.thumbnail}
                                alt={"Portfolio image for " + item.name}
                                name={item.name}
                                onClick={goToPortfolio}
                                canEdit={portfoliosData.canPerformPortfolioOperations}
                                editPortfolioTo={editPortfolioTo}
                                key={item.id}
                                isDraft={item.draft}
                                />
                        })
                    }
                </SortableContext>
            </DndContext>
        </div>
    )
}