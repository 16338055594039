import React from "react";
import {useDispatch} from "react-redux";
import {API_PATHS, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import {getParticipationButtonDisabled, getParticipationButtonLabel} from "./ProgramRequestParticipationButton.library";
import Translate from "../../common/translate/Translate";
import "./ProgramRequestParticipationButton.css";

export default function ProgramRequestParticipationButton(props) {
    const dispatch = useDispatch();

    function getClassName() {
        let className = "program-request-participation-button";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    async function onclick(event) {
        event.stopPropagation();
        var response = await makeJSONPostRequest(
            makeUrl(API_PATHS.PROGRAM_REQUEST_PARTICIPATION, {id: props.programId}),
            dispatch
        );
        props.updateParticipationStatus(response.body.id, response.body.participationStatus);
    }
    
    return (
        <div className={getClassName()}>
            <button
                type="button"
                disabled={getParticipationButtonDisabled(props.status)}
                onClick={(event) => onclick(event)}
            >
                <Translate label={getParticipationButtonLabel(props.status)}/>
            </button>
        </div>
    );
}