import {isAuthenticated} from "../../services/auth/auth";
import {RouteConstants} from "../../App.library";
import {logOutAction} from "../../services/auth/ducks/actions";

function getDefaults(dispatcher) {
    if (isAuthenticated()) {
        return {
            authRoute: RouteConstants.LOGIN,
            authOnClick: () => dispatcher(logOutAction()),
            authLabel: 'header_log_out'
        }
    }
    return {
        authRoute: RouteConstants.LOGIN,
        authOnClick: () => {},
        authLabel: 'header_sign_in'
    }
}

export function getAuthLinkProperties(authRoute, authOnClick, authLabel, dispatcher) {
    const defaults = getDefaults(dispatcher);
    return {
        authRoute: authRoute || defaults.authRoute,
        authOnClick: authOnClick || defaults.authOnClick,
        authLabel: authLabel || defaults.authLabel
    }
}
