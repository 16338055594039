import {useCallback, useEffect} from "react";
import {getIn} from "formik";
import scrollToElement from 'scroll-to-element';

export default function FormikScrollToFirstError(props) {
    const { errors, isSubmitting, isValid } = props.formikProps;

    const getFirstErrorFieldName = useCallback((firstErrorField) => {
        if (firstErrorField) {
            const errorValue = getIn(errors, firstErrorField);
            if (Array.isArray(errorValue)) {
                for (const i in errorValue) {
                    if (errorValue[i]) {
                        return getFirstErrorFieldName(firstErrorField.concat('.').concat(i).concat('.')
                            .concat(Object.keys(errorValue[i])[0])); // Assuming that the formik field array field ids use the . notation to access the nested values.
                    }
                }
            } else {
                return firstErrorField;
            }
        }
    }, [errors]);

    useEffect(() => {
        if (isSubmitting && !isValid) {
            const firstError = getFirstErrorFieldName(Object.keys(errors)[0]);
            if (firstError && firstError === props.fieldId) {
                scrollToElement(props.fieldRef.current, {align: "middle", duration: 500});
            }
        }
    }, [isSubmitting, isValid, getFirstErrorFieldName, errors, props.fieldId, props.fieldRef]);

    return null;
}