import React from "react";
import {Link} from "react-router-dom";
import Translate from "../../common/translate/Translate";
import "./UserNotificationList.css";
import {useDispatch} from "react-redux";

export default function UserNotificationList(props) {
    const dispatch = useDispatch();

    function getClassName() {
        let className = "user-notification-list-container";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <ul className={getClassName()}>
            <div className={"user-notification-list-pointer"}>
            </div>

            <div className={"user-notifications-items-wrapper"}>
                {
                    props.notifications.map((notification) => {
                        return (
                            <li onClick={() => {props.onNotificationClick(); notification.onClick(dispatch)}}>
                                <Link to={notification.url}>
                                    <Translate label={notification.label} params={notification.parameters}/>
                                </Link>
                            </li>
                        )
                    })
                }
            </div>

        </ul>
    )
}