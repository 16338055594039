export default function createDefaultPortfolioFormData(values = {}) {
    return {
        name: values.name || '',
        description: values.description || '',
        artisticStatement: values.artisticStatement || '',
        thumbnail: values.thumbnail || undefined,
        series: values.series || [{
            name: 'Miscellaneous',
            files: []
        }],
        files: values.files || [],
        draft: values.draft || false,
    }
}