import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../store/rootReducer";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import { loadProgramTags } from "../Programs.library";
import {createViewPath, RouteConstants} from "../../../App.library";
import BasicLayout from "../../../components/layouts/basic.layout/BasicLayout";
import Translate from "../../../components/common/translate/Translate";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { translate } from "../../../components/common/translate/Translate.library";
import ProgramForm from "../../../components/programs/program.form/ProgramForm";
import createDefaultProgramFormData from "../../../components/programs/program.form/ProgramForm.library";
import ProgramParticipation from "../../../components/programs/program.participation/ProgramParticipation";
import ProgramEvents from "../../../components/programs/program.events/ProgramEvents";
import "./ProgramEdit.css";

export default function ProgramEdit(props) {
    const programId = props.match.params.id;
    const dispatch = useDispatch();
    const language = useSelector(getLanguage);
    const [formData, updateFormData] = useState({});
    const [skillsOptions, updateSkillsOptions] = useState([]);
    const [tabIndex, updateTabIndex] = useState(0);

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PROGRAM_EDIT_GET, {id: programId}), dispatch)
            .then(response => updateFormData(response.body));
    }, [programId, dispatch]);

    useEffect(() => {
        loadProgramTags(dispatch).then((options) => {
            updateSkillsOptions(options);
        })
    }, [dispatch]);

    async function onSubmit(values) {
        await makeJSONPostRequest(makeUrl(API_PATHS.PROGRAM_EDIT_SAVE, {id: programId}), dispatch, values);
        props.history.push({
            pathname: createViewPath(RouteConstants.PROGRAM_VIEW, {id: programId}),
            state: props.location.state
        });
    }

    return (
        <BasicLayout
            {...props}
        >
            <div className={"programs-edit"}>
                <div>
                    <h1><Translate label={"program_edit_program"}/></h1>
                </div>
                {
                    Object.keys(formData).length > 0 &&
                    <>
                        <AppBar className="programs-edit-tabs" position="static">
                            <Tabs value={tabIndex} onChange={(event, value) => updateTabIndex(value) }>
                                <Tab value={0} label={translate('program_edit_properties_tab', language)} />
                                <Tab value={1} label={translate('program_edit_participation_tab', language)} />
                                <Tab value={2} label={translate('program_edit_events_tab', language)} />
                            </Tabs>
                        </AppBar>
                        {   
                            tabIndex === 0 &&
                            <ProgramForm
                                submitLabel={"program_edit_submit_label"}
                                onSubmit={onSubmit}
                                formData={createDefaultProgramFormData(formData)}
                                skillsOptions={skillsOptions}
                            />
                        }
                        {   
                            tabIndex === 1 &&
                            <ProgramParticipation
                                programId={programId}
                            />
                        }
                        {   
                            tabIndex === 2 &&
                            <ProgramEvents
                                programId={programId}
                            />
                        }
                    </>
                }
            </div>
        </BasicLayout>
    )
}