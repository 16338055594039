import React from "react";
import OpportunitiesListItemTitle from "../opportunities.list.item.title/OpportunitiesListItemTitle";
import "./OpportunitiesListItem.css";
import Translate from "../../common/translate/Translate";
import ListItemRectangular from "../../common/list/list.item.rectangular/ListItemRectangular";
import OpportunitiesDateLabel from "../opportunities.date.label/OpportunitiesDateLabel";
import ListItemViewLink from "../../common/list/list.item.view.link/ListItemViewLink";
import LineSplit from "../../common/linesplit/LineSplit";

export default function OpportunitiesListItem(props) {
    return (
        <ListItemRectangular className={"opportunities-list-item"}
            onClick={() => {props.onItemClick(props.id)}}
        >
            <OpportunitiesListItemTitle
                title={props.title}
                type={props.type}
                canEdit={props.canEdit}
            />
            <LineSplit string={props.description} separator={'\n'} />
            <div className={"opportunities-list-item-footer"}>
                <Translate label={"opportunities-deadline-label"}/>
                <OpportunitiesDateLabel
                    date={props.deadline}
                    fallbackLabel={'opportunities_no_deadline'}
                />
                <ListItemViewLink
                    className={"opportunities-list-item-view-link"}
                    label={"opportunities_item_view"}
                />
            </div>
        </ListItemRectangular>
    )
}