window.dataLayer = window.dataLayer || [];
const gtag = function () {
    window.dataLayer.push(arguments);
};
gtag('js', new Date());
gtag('config', 'UA-167988629-1');

export function setGoogleAnalyticsPage(route) {
    gtag('config', 'UA-167988629-1', {
        'page_path': route
    });
}
