import React, {useEffect, useState} from "react";
import "./ProjectsCreate.css";
import {useDispatch} from "react-redux";
import {loadUserTags} from "../../journal/Journal.library";
import ProjectsEditForm from "../../../components/profile/projects/projects.edit.form/ProjectsEditForm";
import createDefaultProjectsData from "../../../components/profile/projects/projects.edit.form/ProjectsEditForm.library";
import {createFileUploadFormData, createNewExternallyLinkedFileData, processMediaFiles} from "../../../components/common/file.upload/FileUpload.library";
import {API_PATHS, makeMultiPartFormPostRequest} from "../../../services/ajax/ajax";
import {createViewPath, RouteConstants} from "../../../App.library";

export default function ProjectsCreate(props) {
    const dispatcher = useDispatch();
    const [skillsOptions, updateSkillsOptions] = useState([]);

    useEffect(() => {
        loadUserTags(props.match.params.userId, dispatcher).then((options) => {
            updateSkillsOptions(options);
        })
    }, [dispatcher, props.match.params.userId]);

    async function onSubmit(values) {
        const {files, ...rest} = values;
        const {newSketchHostedFiles, newExternallyLinkedFiles} = await processMediaFiles(files);
        const formData = createFileUploadFormData(newSketchHostedFiles);

        const requestData = {
            ...rest,
            externallyLinkedFiles: createNewExternallyLinkedFileData(newExternallyLinkedFiles)
        };

        formData.append('requestData', JSON.stringify(requestData));
        await makeMultiPartFormPostRequest(
            API_PATHS.PROJECT_CREATE,
            dispatcher,
            formData
        );
        props.history.push(createViewPath(RouteConstants.PROJECTS, {userId: props.match.params.userId}));
    }

    return (
        <div>
            <ProjectsEditForm
                formData={createDefaultProjectsData()}
                titleLabel={"projects_create_title"}
                backTo={createViewPath(RouteConstants.PROJECTS, {userId: props.match.params.userId})}
                onCancel={() => {
                    props.history.push(createViewPath(RouteConstants.PROJECTS, {userId: props.match.params.userId}))
                }}
                skillsOptions={skillsOptions}
                onSubmit={onSubmit}
            />
        </div>
    )
}