export function createDefaultUserData(userData) {
    const commonData = {
        email : userData.email || "",
        name: userData.name || "",
        phoneNumber: userData.phoneNumber || "",
        password: userData.password || "",
        confirmPassword: userData.confirmPassword || "",
        shouldSendEmailNotifications: userData.shouldSendEmailNotifications || false,
        inActive: userData.inActive || false,
        roles: userData.roles || []
    };
    return commonData;
}