import React from "react";
import {useSelector} from "react-redux";
import {getMessages, isMessageSuccess} from "../../store/rootReducer";
import Translate from "../common/translate/Translate";
import "./MessagePanel.css";

export default function MessagePanel(props) {

    const isSuccess = useSelector(isMessageSuccess);
    const messages = useSelector(getMessages);

    function getClassName() {
        let className = 'message-panel';
        if (props.className) {
            className = className + ' ' + props.className;
        }
        if (isSuccess) {
            className = className + ' message-panel-success';
        }
        if (!isSuccess) {
            className = className + ' message-panel-error';
        }
        return className;
    }

    return (
        <>
            {
                messages &&
                <ul className={getClassName()}>
                    {
                        messages.map((message, index) => {
                            return (
                                <li key={index}>
                                    <Translate label={message.label} params={message.params} />
                                </li>
                            )
                        })
                    }
                </ul>
            }

        </>
    );

}
