export default function createDefaultOpportunitiesFormData() {
    return {
        contactName: '',
        email: '',
        organization: '',
        title: '',
        opportunityDate: null,
        deadline: null,
        budget: '',
        description: '',
        eligibility: '',
        submissionRequirements: '',
        files: []
    }
}