import React, {useEffect, useRef, useState} from "react";
import Translate from "../common/translate/Translate";
import CommentItem from "./comment.item/CommentItem";
import "./Comments.css";
import TextArea from "../common/text.area/TextArea";
import ButtonReply from "../common/button.reply/ButtonReply";

export default function Comments(props) {

    const commentsEndRef = useRef();
    const [newCommentData, updateCommentData] = useState('');
    const [disableSubmitComment, updateDisableSubmitComment] = useState(false);

    useEffect(() => {
        scrollToBottomOfComments();
    }, [commentsEndRef]);

    function getCommentsClassName() {
        let className = "comments-container";
        if (props.commentsClassName) {
            className += " " + props.commentsClassName;
        }
        return className;
    }

    function submitComment() {
        updateDisableSubmitComment(true);
        props.onAddComment(newCommentData).then(() => {
            scrollToBottomOfComments();
        }).finally(() => {
            updateDisableSubmitComment(false);
        });
        updateCommentData('');
    }

    async function onDeleteComment(id) {
        await props.onDeleteComment(id);
    }

    function scrollToBottomOfComments() {
        commentsEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }

    return (
        <div className={"comments"} >
            <div className={"comments-title"}>
                <Translate label={"comments_title"} params={{amount: props.comments.length}}/>
            </div>
            <div className={getCommentsClassName()}>
                {
                    props.comments && props.comments.length > 0 &&
                        props.comments.map((comment, index) => {
                            return (
                                <CommentItem
                                    key={index}
                                    className={"comments-container-comment-item"}
                                    comment={comment}
                                    onDeleteComment={onDeleteComment}
                                />
                            );
                        })
                }
                <div ref={commentsEndRef} className={"comments-scroll-to"}/>
            </div>
            <div className={"comments-area"}>
                <TextArea
                    className={"comments-input"}
                    value={newCommentData}
                    onChange={(event) => updateCommentData(event.target.value)}
                    placeholder={"comments_add_placeholder"}
                    disabled={disableSubmitComment}
                />
                <div className={"comments-area-reply-button"}>
                    <ButtonReply
                        onClick={submitComment}
                    />
                </div>
            </div>
        </div>
    )
}