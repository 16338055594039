import React from "react";
import Translate from "../translate/Translate";
import "./ButtonAccept.css"

export default function ButtonAccept(props) {

    function getClassName() {
        let className = "button-accept";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
      <div className={getClassName()}>
        <button
          type={props.type || 'submit'}
          disabled={props.disabled}
          onClick={props.onClick}
          style={props.buttonStyle}
        >
          <Translate label={props.label} />
        </button>
      </div>
    )
}