import React, {useRef} from "react";
import "./TextDatePicker.css";
import Label from "../label/Label";
import FieldErrorFeedback from "../field.error.feedback/FieldErrorFeedback";
import DatePicker from "../date.picker/DatePicker";
import FormikScrollToFirstError from "../formik.on.submit.scroll/FormikScrollToFirstError";

export default function TextDatePicker(props) {
    const ref = useRef();
    const {label, labelParams, hasError, errorMessage, ...rest} = props;

    function getClassName() {
        let className = "text-date-picker";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    return (
        <div ref={ref} className={getClassName()}>
            <Label htmlFor={props.id} label={label} params={labelParams} />
            <DatePicker
                {...rest}
                hasError={hasError}
            />
            <FieldErrorFeedback errorMessage={hasError ? errorMessage : null} />
            {
                props.formikProps &&
                <FormikScrollToFirstError formikProps={props.formikProps} fieldId={props.id} fieldRef={ref}/>
            }
        </div>
    );
}