import queryString from "query-string";

export const DEFAULT_PAGE = 1;

export function buildGlobalSkillsSearchObject(searchString) {
    const queryParameters = queryString.parse(searchString);

    return {
        pageNumber: parseInt(queryParameters.pageNumber || DEFAULT_PAGE)
    };
}