import React from "react";
import {Link} from "react-router-dom";
import Translate from "../../../common/translate/Translate";
import {RightArrow} from "../../../common/svg/svg";
import "./PortfolioViewSeriesItemTitle.css"

export default function PortfolioViewSeriesItemTitle(props) {

    return (
        <div className={"portfolio-view-series-title"}>
            <div className={"portfolio-view-series-name"}>{props.name}</div>
            <div className={"portfolio-view-series-link"}>
                <Link to={props.seriesLink}>
                    <div><Translate label={"portfolio_view_series"}/></div>
                    <RightArrow />
                </Link>
            </div>
        </div>
    )
}