import React from "react";
import "./ListPaging.css";
import {LeftArrow, RightArrow} from "./../../svg/svg";
import {createPages, ELLIPSIS_SYMBOL} from "./ListPaging.library";

export default function ListPaging(props) {

    function getCurrentPage() {
        return props.currentPage || 1;
    }

    function getNumberOfPages() {
        return props.numberOfPages || 1;
    }

    function getListPagingArrowClassName(isEnabled) {
        let className = 'list-paging-arrow';
        if (isEnabled) {
            className += ' enabled';
        }
        return className;
    }

    function getLeftArrow() {
        const currentPage = getCurrentPage();
        const isEnabled = currentPage > 1;
        const onClick = () => {
            if (isEnabled) {
                props.onPageClick(currentPage - 1);
            }
        };
        return (
            <div id="paging-left-arrow" className={getListPagingArrowClassName(isEnabled)} onClick={onClick} tabIndex={0}>
                <LeftArrow/>
            </div>
        );
    }

    function getRightArrow() {
        const currentPage = getCurrentPage();
        const isEnabled = currentPage < getNumberOfPages();
        const onClick = () => {
            if (isEnabled) {
                props.onPageClick(currentPage + 1);
            }
        };
        return (
            <div id="paging-right-arrow" className={getListPagingArrowClassName(isEnabled)} onClick={onClick} tabIndex={0}>
                <RightArrow />
            </div>
        );
    }

    function getPageClassName(currentPage) {
        let className = 'list-paging-page';
        if (currentPage === getCurrentPage()) {
            className += ' active';
        }
        if (currentPage === ELLIPSIS_SYMBOL) {
            className += ' ellipsis-page'
        }
        return className;
    }

    return (
        <div className="list-paging">
            {
                getLeftArrow()
            }
            {
                createPages(getNumberOfPages(), getCurrentPage(), props.pageStep).map((currentPage, index) => {
                    if (currentPage === ELLIPSIS_SYMBOL) {
                        return (<div id={'page-ellipsis-' + index} key={index} className={getPageClassName(currentPage)}>{currentPage}</div>)
                    }
                    return (<div id={"page-" + currentPage + '-' + index} key={index} tabIndex={0} className={getPageClassName(currentPage)} onClick={() => {props.onPageClick(currentPage)}}>{currentPage}</div>)
                })
            }
            {
                getRightArrow()
            }
        </div>
    )
}