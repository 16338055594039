import queryString from "query-string";

export const DEFAULT_PAGE = 1;

export function buildUserManagementSearchObject(searchString) {
    const queryParameters = queryString.parse(searchString);
    return {
        name: queryParameters.name || '',
        email: queryParameters.email || '',
        userType: queryParameters.userType || '',
        pageNumber: parseInt(queryParameters.pageNumber || DEFAULT_PAGE)
    }
}