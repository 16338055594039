import React, {useEffect, useState} from "react";
import "./ProjectsView.css";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage, getStatisticPeriodOptions} from "../../../store/rootReducer";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import ProfileViewTextArea from "../../../components/profile/profile.view.text.area/ProfileViewTextArea";
import SkillTaggingList from "../../../components/common/skill/skill.tagging.list/SkillTaggingList";
import FileUploadList from "../../../components/common/file.upload/file.upload.list/FileUploadList";
import FileThumbnail from "../../../components/common/file.thumbnail/FileThumbnail";
import ProjectsViewHeader from "../../../components/profile/projects/projects.view.header/ProjectsViewHeader";
import {createViewPath, RouteConstants} from "../../../App.library";
import {makeAlert} from "../../../components/common/alert/Alert";
import {formatLocalDateTime} from "../../../services/date/date";
import SkillsStatistics from "../../../components/common/skill/skills.statistics/SkillsStatistics";
import useFetchOrStore from "../../../hooks/useFetchOrStore";
import {setStatisticPeriodOptions} from "../../../services/select.options/ducks/actions";
import {makeFileViewer} from "../../../components/common/file.viewer/FileViewer";
import Comments from "../../../components/comments/Comments";
import useProjectsComments from "./useProjectsComments";

export default function ProjectsView(props) {
    const dispatcher = useDispatch();
    const language = useSelector(getLanguage);
    const [viewData, updateViewData] = useState({});
    const [statistics, updateStatistics] = useState({});
    const [statisticPeriodOptions] = useFetchOrStore(getStatisticPeriodOptions, fetchStatisticPeriodOptions);
    const [statisticPeriodValue, updateStatisticPeriodValue] = useState('MONTHLY_STATISTIC');
    const [periodEndDate, updatePeriodEndDate] = useState();

    async function fetchStatisticPeriodOptions() {
        const response = await makeJSONGetRequest(API_PATHS.GET_STATISTIC_PERIODS, dispatcher);
        dispatcher(setStatisticPeriodOptions(response.body.options));
    }

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PROJECT_VIEW, {projectId: props.match.params.projectId}), dispatcher)
            .then((response) => {
                updateViewData(response.body);
                updatePeriodEndDate(response.body.endDate);
            })
    }, [dispatcher, props.match.params.projectId]);

    useEffect(() => {
        makeJSONPostRequest(makeUrl(API_PATHS.CALCULATE_PROJECT_STATISTICS, {projectId: props.match.params.projectId}), dispatcher, {
            period: statisticPeriodValue, periodEndDate: periodEndDate
        }).then((response) => {
            updateStatistics(response.body);
        })
    }, [dispatcher, props.match.params.projectId, statisticPeriodValue, periodEndDate]);

    async function deleteProject() {
        await makeJSONPostRequest(makeUrl(API_PATHS.PROJECT_DELETE, {projectId: props.match.params.projectId}), dispatcher);
        props.history.push(createViewPath(RouteConstants.PROJECTS, {userId: props.match.params.userId}));
    }

    function onDeleteProject() {
        makeAlert(
            language,
            "projects_delete_modal_title",
            "projects_delete_alert_body",
            deleteProject
        )
    }

    const onThumbnailClick = (file) => () => {
        makeFileViewer(language, dispatcher, file, viewData.files, false, false, () => {});
    }

    const { 
        comments, 
        onAddComment, 
        onDeleteComment 
    } = useProjectsComments()

    return (
        <div className={"projects-view"}>
            <ProjectsViewHeader
                titleLabel={"projects_view_title"}
                backTo={createViewPath(RouteConstants.PROJECTS, {userId: props.match.params.userId})}
                editTo={createViewPath(RouteConstants.PROJECTS_EDIT, {userId: props.match.params.userId, projectId: props.match.params.projectId})}
                canEdit={viewData.canEdit}
                onDelete={onDeleteProject}
            />
            <div className={"projects-view-content"}>
                {
                    Object.keys(viewData).length > 0 &&
                    <div>
                        <div className={"projects-view-text"}>
                            <div className={"projects-view-text-date"}>{formatLocalDateTime(viewData.date)}</div>
                            <ProfileViewTextArea
                                label={"title"}
                                text={viewData.title}
                            />
                            <ProfileViewTextArea
                                label={"project_description"}
                                text={viewData.description}
                            />
                            <ProfileViewTextArea
                                label={"timeline"}
                                text={viewData.timeline}
                            />
                            <ProfileViewTextArea
                                label={"budget"}
                                text={viewData.budget}
                            />
                            <ProfileViewTextArea
                                label={"development_at_sketch"}
                                text={viewData.developmentAtSketch}
                            />
                            <ProfileViewTextArea
                                label={"development_outside_sketch"}
                                text={viewData.developmentOutsideSketch}
                            />
                            <ProfileViewTextArea
                                label={"milestones_and_timeline"}
                                text={viewData.milestonesTimeline}
                            />
                        </div>
                        <SkillTaggingList
                            skillTags={viewData.skillTags}
                        />
                    </div>
                }
                <div className={"projects-view-thumbnails"}>
                    <FileUploadList
                        fileUploadItem={FileThumbnail}
                        files={viewData.files}
                        onThumbnailClick={onThumbnailClick}
                    />
                    <Comments
                        comments={comments}
                        onAddComment={onAddComment}
                        onDeleteComment={onDeleteComment}
                    />
                </div>
            </div>
            {
                Object.keys(viewData).length > 0 && Object.keys(statistics).length > 0 && Object.keys(statisticPeriodOptions).length > 0 &&
                <SkillsStatistics
                    {...statistics}
                    minPeriodDate={viewData.date}
                    maxPeriodDate={viewData.endDate}
                    statisticPeriodOptions={statisticPeriodOptions}
                    statisticPeriodValue={statisticPeriodValue}
                    periodEndDate={periodEndDate}
                    onStatisticOptionChanged={(event) => {
                        updateStatisticPeriodValue(event.target.value)
                    }}
                    onPeriodEndDateChanged={(value) => {
                        updatePeriodEndDate(value);
                    }}
                />
            }

        </div>
    )
}