import React from "react";
import "./SkillsStatisticsPeriodHeader.css";
import Translate from "../../../translate/Translate";
import {formatPeriod} from "./SkillsStatisticsPeriodHeader.library";

export default function SkillsStatisticsPeriodHeader(props) {

    function getPeriods() {
        if (props.periods) {
            return props.periods;
        }
        return ["", "", "", "", "", "", "", "", "", "", "", ""];
    }

    return (
        <div className={"skills-statistics-header"}>
            <div className={"period-tag-header-item"}>
                <Translate label={"skills_statistics"} params={{amount: props.tagsLength}}/>
            </div>
            {
                getPeriods().map((periodTime, index) => {
                    return (
                        <div className={"period-header-item"} key={index}>
                            <div>
                                {formatPeriod(props.currentPeriod, periodTime)}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}