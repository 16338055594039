export const userDetails = (state = {}, action) => {
    switch (action.type) {
        case "SET_USER_DETAILS":
            return {
                ...action.payload.userDetails
            };
        default:
            return state;
    }
};

export const getUserDetailsState = (state) => state;