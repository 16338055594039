import {API_PATHS, makeBlobGetRequest, makeJSONGetRequest, makeUrl} from "../../../services/ajax/ajax";
import {isAudio, isImage, isVideo, isMusicPlatform} from "../../../services/file/file";
import ThumbnailPhoto from "../../../resources/images/thumbnail-picture.png";
import ThumbnailAudio from "../../../resources/images/thumbnail-audio.png";
import ThumbnailVideo from "../../../resources/images/thumbnail-video.png";
import ThumbnailDocument from "../../../resources/images/thumbnail-document.png";
import ThumbnailError from "../../../resources/images/thumbnail-error.png";
import ThumbnailSensitive from "../../../resources/images/thumbnail-sensitive.png";

export const classFileThumbnailSmaller = "file-thumbnail-smaller";
export const classFileThumbnailSmall = "file-thumbnail-small";
export const classFileThumbnailLarge = "file-thumbnail-large";

export function getClassName(props) {
    let className = "file-thumbnail";
    className += " " + (props.size ? props.size : classFileThumbnailSmall);
    if (props.className) {
        className += " " + props.className;
    }
    return className;
}

export function getImageThumbnail(file) {
    return new Promise((resolve, reject) => {
        if (file.id && file.id > 0) {
            return resolve(ThumbnailPhoto);
        }
        const reader = new FileReader();
        reader.onloadend = function () {
            return resolve(reader.result);
        }
        reader.onerror = function (event) {
            reader.abort();
            reject(event);
        }
        if (file.content) {
            reader.readAsDataURL(file.content);
        } else {
            reject("No file content");
        }
    });
}

export function getDefaultThumbnail(file) {
    if (!file || !file.type) {
        return ThumbnailError;
    }
    const contentType = file.type;
    if (isImage(contentType)) {
        return ThumbnailPhoto;
    } else if (isAudio(contentType)) {
        return ThumbnailAudio;
    } else if (isVideo(contentType)) {
        return ThumbnailVideo;
    } else {
        return ThumbnailDocument;
    }
}

export async function getExistingThumbnail(file, dispatch, fullSize) {
    const imageApiPath = fullSize ? API_PATHS.FILE_GET : API_PATHS.THUMBNAIL_GET

    if (file && file.id && file.id > 0 && file.type) {
        if (file.markedAsSensitive) {
            return ThumbnailSensitive;
        }
        const fileId = file.id;
        const contentType = file.type;
        if (isVideo(contentType)) {
            const response = await makeJSONGetRequest(makeUrl(API_PATHS.VIDEO_THUMBNAIL_GET, {id: fileId}), dispatch);
            return response.body.videoThumbnailLocation;
        } else if (!isMusicPlatform(contentType)) {
            const response = await makeBlobGetRequest(makeUrl(imageApiPath, {id: fileId}), dispatch);
            return response.body;
        }
    }
    
    if (file && isVideo(file.type) && file.content) {
        const response = await makeJSONGetRequest(makeUrl(API_PATHS.YOUTUBE_VIDEO_THUMBNAIL_GET, {id: file.content}), dispatch);
        return response.body.videoThumbnailLocation;
    }
}