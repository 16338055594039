import React from "react";
import "./DeleteLink.css";
import {DeleteIcon} from "../svg/svg";
import Translate from "../translate/Translate";
import {onEnterClickCreator} from "../../../services/util/util";

export default function DeleteLink(props) {

    function getClassName() {
        let className = "delete-link";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    const onKeyPress = onEnterClickCreator(props.onClick);

    return (
        <div className={getClassName()} onClick={props.onClick} tabIndex={0} onKeyPress={onKeyPress}>
            <DeleteIcon/>
            <div>
                <Translate label={"delete_label"}/>
            </div>
        </div>
    )
}