import React from 'react';
import "./TidalEmbed.css"

const TidalEmbed = ({ uri }) => {

    function getKey(uri) {
        const trackRegex = /track\/(\d+)/;
        const albumRegex = /album\/(\d+)/;

        const trackMatch = uri.match(trackRegex);
        if (trackMatch) {
            return "tracks/" + trackMatch[1];
        } else {
            const albumMatch = uri.match(albumRegex);
            if (albumMatch) {
                return "albums/" + albumMatch[1];
            }
        }

        return uri;
    }

    return (
        <div class="tidal-embed-container">
            <iframe
                title="tidal-embed"
                className="tidal-embed"
                src={`https://embed.tidal.com/${getKey(uri)}?layout=gridify`}
                allowfullscreen="allowfullscreen"
            />
        </div>
    );
};

export default TidalEmbed;