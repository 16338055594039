import React from "react";
import "./ForgotPasswordReset.css"
import {useDispatch} from "react-redux";
import RegistrationLoginLayout from "../../../components/layouts/registration.login/RegistrationLoginLayout";
import Translate from "../../../components/common/translate/Translate";
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {createPasswordSchema} from "../../../services/yup/yup";
import TextInput from "../../../components/common/text.input/TextInput";
import {createOnBlurFunction} from "../../../services/formik/formik";
import ButtonAccept from "../../../components/common/button.accept/ButtonAccept";
import {API_PATHS, makeJSONPostRequest} from "../../../services/ajax/ajax";
import queryString from "query-string";
import {RouteConstants} from "../../../App.library";

export default function ForgotPasswordReset(props) {
    const dispatch = useDispatch();

    async function sendPasswordResetRequest(values) {
        const queryParameters = queryString.parse(props.location.search);
        await makeJSONPostRequest(API_PATHS.FORGOT_PASSWORD_RESET_PASSWORD, dispatch, {
            password: values.password,
            token: queryParameters.token
        });
        props.history.push(RouteConstants.LOGIN);
    }

    return (
        <RegistrationLoginLayout
            {...props}
        >
            <div className={"forgot-password-reset-container"}>
                <section>
                    <div>
                        <h1><Translate label={"forgot_password_reset_heading"}/></h1>
                    </div>
                   <Formik
                       initialValues={{password: '', confirmPassword: ''}}
                       validationSchema={Yup.object().shape({
                           ...createPasswordSchema(true)
                       })}
                       onSubmit={(values, actions) => {
                           sendPasswordResetRequest(values).then(() => {
                               actions.resetForm();
                           }).finally(() => {
                               actions.setSubmitting(false);
                           })
                       }}
                       validateOnChange={false}
                       validateOnBlur={false}
                   >
                       {
                           formProps => (
                               <Form
                                className={"forgot-password-reset-form"}
                               >
                                   <Field
                                       name={"password"}
                                   >
                                       {
                                           fieldProps => (
                                               <TextInput
                                                   {...fieldProps.field}
                                                   id={"password"}
                                                   label={"password_label"}
                                                   type={"password"}
                                                   onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                   hasError={fieldProps.meta.error}
                                                   errorMessage={fieldProps.meta.error}
                                                   disabled={formProps.isSubmitting}
                                                   formikProps={formProps}
                                               />
                                           )
                                       }
                                   </Field>
                                   <Field
                                        name={"confirmPassword"}
                                   >
                                       {
                                           fieldProps => (
                                               <TextInput
                                                   {...fieldProps.field}
                                                   id={"confirmPassword"}
                                                   label={"confirm_password_label"}
                                                   type={"password"}
                                                   onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                   hasError={fieldProps.meta.error}
                                                   errorMessage={fieldProps.meta.error}
                                                   disabled={formProps.isSubmitting}
                                                   formikProps={formProps}
                                               />
                                           )
                                       }
                                   </Field>
                                   <ButtonAccept className={"forgot-password-reset-submit-button"} disabled={formProps.isSubmitting} label={"forgot_password_reset_submit_button"}/>
                               </Form>
                           )
                       }
                   </Formik>
                </section>
            </div>
        </RegistrationLoginLayout>
    )
}