const StatusLabelMapping = {
    NOT_PARTICIPATING: {
        label: 'program_request_join',
        buttonDisabled: false
    },
    PARTICIPATION_PENDING: {
        label: 'program_participation_requested',
        buttonDisabled: true
    },
    PARTICIPATING: {
        label: 'program_currently_participating',
        buttonDisabled: true
    }
};

export function getParticipationButtonLabel(status) {
    return status ? StatusLabelMapping[status].label : '';
}

export function getParticipationButtonDisabled(status) {
    return status ? StatusLabelMapping[status].buttonDisabled : false;
}