import React from "react";
import SketchLogo from "../../resources/images/sketch-logo.svg";
import Translate from "../common/translate/Translate";
import AppLinks from "../../resources/data/links";
import {FacebookIcon, InstagramIcon, TwitterIcon, YouTubeIcon} from "../common/svg/svg";
import "./Footer.css";
import NewWindowWarning from "../common/newwindowwarning/NewWindowWarning";

export default function Footer() {
  return (
    <footer>
      <a
        href={AppLinks.sketch}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="link to Sketch website"
      >
        <img src={SketchLogo} alt="sketch logo" />
        <NewWindowWarning />
      </a>
      <div>
        <Translate label={'footer_text'} />
      </div>
      <div>
        <a
          href={AppLinks.facebook}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="link to Sketch Facebook account"
        >
          <FacebookIcon />
          <NewWindowWarning />
        </a>
        <a
          href={AppLinks.instagram}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="link to Sketch Instagram account"
        >
          <InstagramIcon />
          <NewWindowWarning />
        </a>
        <a
          href={AppLinks.twitter}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="link to Sketch Twitter account"
        >
          <TwitterIcon />
          <NewWindowWarning />
        </a>
        <a
          href={AppLinks.youtube}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="link to Sketch YouTube account"
        >
          <YouTubeIcon />
          <NewWindowWarning />
        </a>
      </div>
    </footer>
  )
}
