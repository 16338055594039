import React from "react";
import {CVIcon, ParticipantInstagramIcon, ParticipantLinkedInIcon, ParticipantWebsiteIcon} from "../../common/svg/svg";
import "./ProfileSocial.css"
import PeerButton from "../../common/peer.button/PeerButton";
import SketchFileLink from "../../common/sketch.file.link/SketchFileLink";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";
import {translate} from "../../common/translate/Translate.library";
import {getExternalURL} from "./ProfileSocial.library";

export default function ProfileSocial(props) {

    const language = useSelector(getLanguage);
    const dispatch = useDispatch();

    return (
        <div className="profile-social-container">
            <div className="profile-social-name">{props.name}</div>
            <div className="profile-social-links">
                {
                    props.cv &&
                    <SketchFileLink file={props.cv} dispatch={dispatch} title={translate("about_cv_mouse_over", language)}>
                        <CVIcon/>
                    </SketchFileLink>
                }
                {
                    props.website &&
                    <a href={getExternalURL(props.website)} target="_blank" rel="noopener noreferrer" title={translate("about_website_mouse_over", language)}>
                        <ParticipantWebsiteIcon/>
                    </a>
                }
                {
                    props.instagram &&
                    <a href={getExternalURL(props.instagram)} target="_blank" rel="noopener noreferrer" title={translate("about_instagram_mouse_over", language)}>
                        <ParticipantInstagramIcon/>
                    </a>
                }
                {
                    props.linkedin &&
                    <a href={getExternalURL(props.linkedin)} target="_blank" rel="noopener noreferrer" title={translate("about_linked_in_mouse_over", language)}>
                        <ParticipantLinkedInIcon/>
                    </a>
                }
            </div>
            {
                props.canBePeer &&
                    <PeerButton
                        className={"profile-add-peer"}
                        isPeer={props.isPeer}
                        disableAddPeer={props.disableAddPeer}
                        disableRemovePeer={props.disableRemovePeer}
                        onPeerAdd={props.onPeerAdd}
                        onPeerRemove={props.onPeerRemove}
                    />
            }
        </div>
    );
}