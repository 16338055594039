import React, {useCallback, useEffect, useState} from "react";
import "./JournalView.css";
import {useDispatch, useSelector} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import Comments from "../../../components/comments/Comments";
import SkillTaggingList from "../../../components/common/skill/skill.tagging.list/SkillTaggingList";
import ProfileViewTextArea from "../../../components/profile/profile.view.text.area/ProfileViewTextArea";
import {formatLocalDateTime} from "../../../services/date/date";
import JournalViewHeader from "../../../components/profile/journal/journal.view.header/JournalViewHeader";
import {createViewPath, RouteConstants} from "../../../App.library";
import {makeAlert} from "../../../components/common/alert/Alert";
import {getLanguage} from "../../../store/rootReducer";
import FileUploadList from "../../../components/common/file.upload/file.upload.list/FileUploadList";
import FileThumbnail from "../../../components/common/file.thumbnail/FileThumbnail";
import cloneDeep from "lodash/cloneDeep";
import TextSelect from "../../../components/common/text.select/TextSelect";
import ButtonAccept from "../../../components/common/button.accept/ButtonAccept";
import {displaySuccess} from "../../../components/message.panel/ducks/actions";
import {makeFileViewer} from "../../../components/common/file.viewer/FileViewer";
import {trackPromise} from "react-promise-tracker";

export default function JournalView(props) {
    const dispatcher = useDispatch();
    const language = useSelector(getLanguage);
    const [comments, updateComments] = useState({});
    const [viewData, updateViewData] = useState({});
    const [userPortfolioOptions, updateUserPortfolioOptions] = useState([]);
    const [portfolio, updatePortfolio] = useState('');

    const refreshViewData = useCallback(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.JOURNAL_VIEW, {journalId: props.match.params.journalId}), dispatcher)
            .then((response) => {
                sanitizeViewData(response.body);
            });
    }, [props.match.params.journalId, dispatcher]);

    useEffect(() => {
        refreshViewData();
    }, [refreshViewData]);

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.JOURNAL_COMMENTS, {journalId: props.match.params.journalId}), dispatcher)
            .then((response) => {
                updateComments(response.body);
            })
    }, [dispatcher, props.match.params.journalId]);

    useEffect(() => {
        makeJSONGetRequest(API_PATHS.GET_USER_PORTFOLIO_OPTIONS, dispatcher)
            .then((response) => updateUserPortfolioOptions(response.body.options));
    }, [dispatcher]);

    async function onDeleteComment(id) {
        console.log(makeUrl(API_PATHS.JOURNAL_COMMENTS_DELETE, {journalId: props.match.params.journalId, commentId: id}))
        const response =
            await makeJSONPostRequest(makeUrl(API_PATHS.JOURNAL_COMMENTS_DELETE, {journalId: props.match.params.journalId, commentId: id}),
            dispatcher);
        updateComments(response.body)
    }

    async function onAddComment(newCommentData) {
        if (!newCommentData) {
            return;
        }
        const response =
            await makeJSONPostRequest(makeUrl(API_PATHS.JOURNAL_COMMENTS_ADD, {journalId: props.match.params.journalId}),
                dispatcher, {comment: newCommentData});
        updateComments(response.body);
    }

    async function deleteJournal() {
        await makeJSONPostRequest(makeUrl(API_PATHS.JOURNAL_DELETE, {journalId: props.match.params.journalId}), dispatcher);
        props.history.push(createViewPath(RouteConstants.JOURNAL, {userId: props.match.params.userId}));
    }

    function onDeleteJournal() {
        makeAlert(
            language,
            'journal_delete_modal_title',
            'journal_delete_alert_body',
            deleteJournal
        );
    }

    function onSelectFileChange(index, isFileSelected) {
        const files = cloneDeep(viewData.files);
        files[index].isSelected = isFileSelected;
        updateViewData({...viewData, files: files});
    }

    function sanitizeViewData(viewData) {
        const files = cloneDeep(viewData.files);
        files.forEach(file => file.isSelected = false);
        updateViewData({...viewData, files: files});
    }

    function isAddPortfolioButtonDisabled() {
        const selectedFileCount = viewData.files.filter(file => file.isSelected).length;
        return selectedFileCount === 0 || !portfolio;
    }

    async function addMediaToPortfolio() {
        const files = cloneDeep(viewData.files);
        const selectedFiles = files.filter(file => file.isSelected).map(file => {
            const {isSelected, ...rest} = file;
            return rest;
        });

        const portfolioMediaData = {portfolio: portfolio, selectedFiles: selectedFiles};
        await makeJSONPostRequest(makeUrl(API_PATHS.JOURNAL_PORTFOLIO_ADD_MEDIA, {journalId: props.match.params.journalId}),
            dispatcher, portfolioMediaData);

        sanitizeViewData(viewData);
        updatePortfolio('');
        dispatcher(displaySuccess([{label: 'success_message_media_added_to_portfolio'}]));
    }

    const onThumbnailClick = (file) => () => {
        makeFileViewer(language, dispatcher, file, viewData.files, viewData.canEdit, viewData.canMarkFilesSensitive, refreshViewData);
    }

    return (
        <div className={"journal-view"}>
            <JournalViewHeader
                backTo={createViewPath(RouteConstants.JOURNAL, {userId: props.match.params.userId})}
                editTo={createViewPath(RouteConstants.JOURNAL_EDIT, {userId: props.match.params.userId, journalId: props.match.params.journalId})}
                canEdit={viewData.canEdit}
                onDelete={onDeleteJournal}
                titleLabel={"journal_view_header"}
            />
            <div className={"journal-view-content"}>
                {
                    Object.keys(viewData).length > 0
                    &&
                    <div>
                        <div className={"journal-view-text"}>
                            <div className={"journal-view-text-date"}>{formatLocalDateTime(viewData.journalDate)}</div>
                            <ProfileViewTextArea
                                label={"journal_edit_work_label"}
                                text={viewData.work}
                            />
                            <ProfileViewTextArea
                                label={"journal_edit_progress_label"}
                                text={viewData.progress}
                            />
                            <ProfileViewTextArea
                                label={"journal_edit_challenges_label"}
                                text={viewData.challenges}
                            />
                            <ProfileViewTextArea
                                label={"journal_edit_goal_label"}
                                text={viewData.goal}
                            />
                            <ProfileViewTextArea
                                label={"journal_edit_connections_label"}
                                text={viewData.connections}
                            />
                            <ProfileViewTextArea
                                label={"journal_edit_reflection_label"}
                                text={viewData.reflection}
                            />
                            <ProfileViewTextArea
                                label={"journal_edit_feelings_label"}
                                text={viewData.feelings}
                            />
                        </div>
                        <SkillTaggingList
                            skillTags={viewData.skillTags}
                        />
                    </div>
                }
                <div className={"journal-view-comments-thumbnails"}>
                    {
                        viewData.files && viewData.files.length > 0 &&
                        <>
                            <FileUploadList
                                fileUploadItem={FileThumbnail}
                                files={viewData.files}
                                onSelectFileChange={viewData.canEdit && userPortfolioOptions.length > 0 && onSelectFileChange}
                                onThumbnailClick={onThumbnailClick}
                            />
                            {
                                viewData.canEdit && userPortfolioOptions.length > 0 &&
                                <div className={"journal-view-add-to-portfolio"}>
                                    <TextSelect
                                        id="journal-view-portfolio"
                                        label="journal_view_add_to_portfolio_label"
                                        options={userPortfolioOptions || []}
                                        onChange={(event) => updatePortfolio(event.target.value)}
                                        value={portfolio}
                                        placeholder={"journal_view_add_to_portfolio_placeholder"}
                                    />
                                    <div className={"journal-add-portfolio-files"}>
                                        <ButtonAccept
                                            type={"button"}
                                            label={"journal_view_add_portfolio_button_label"}
                                            onClick={() => {
                                                trackPromise(
                                                    addMediaToPortfolio()
                                                );
                                            }}
                                            disabled={isAddPortfolioButtonDisabled()}
                                        />
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {
                        Object.keys(comments).length > 0 &&
                        <Comments
                            commentsClassName={"journal-comments"}
                            comments={comments.comments}
                            onAddComment={onAddComment}
                            onDeleteComment={onDeleteComment}
                        />
                    }
                </div>
            </div>
        </div>
    )
}