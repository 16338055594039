import React, {useRef} from "react";
import "./CheckboxInputError.css";
import CheckboxInput from "../checkbox.input/CheckboxInput";
import FieldErrorFeedback from "../field.error.feedback/FieldErrorFeedback";
import FormikScrollToFirstError from "../formik.on.submit.scroll/FormikScrollToFirstError";

export default function CheckboxInputError(props) {
    const ref = useRef();
    const {hasError, errorMessage, ...rest} = props;

    function getClassName() {
        let className = "checkbox-input-error";
        if (props.className) {
            className += ' ' + props.className
        }

        if (hasError) {
            className += ' checkbox-error'
        }
        return className;
    }

    return (
        <div ref={ref} className={getClassName()}>
            <CheckboxInput {...rest}/>
            <FieldErrorFeedback errorMessage={hasError ? errorMessage : null}/>
            {
                props.formikProps &&
                <FormikScrollToFirstError formikProps={props.formikProps} fieldId={props.id} fieldRef={ref}/>
            }
        </div>
    )
}