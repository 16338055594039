import React, {useRef} from "react";
import "./FileUpload.css";
import ButtonAccept from "../button.accept/ButtonAccept";
import FileUploadList from "./file.upload.list/FileUploadList";
import FileAttachVideo from "./file.upload.video/FileAttachVideo";
import Translate from "../translate/Translate";
import {isVideo} from "../../../services/file/file";
import {useDispatch} from "react-redux";
import {displayError} from "../../message.panel/ducks/actions";
import InfoLink from "../info.link/InfoLink";
import FileAttachMusic from "./file.upload.music/FileAttachMusic"

export default function FileUpload(props) {
    const dispatch = useDispatch();
    const input = useRef();
    const showFileUploadList = props.showFileUploadList ?? true;

    function attachFile() {
        input.current.click();
    }

    function getClassName() {
        let className = "file-upload";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <input
                ref={input}
                id={props.id}
                name={props.name}
                type={"file"}
                onChange={(event) => {
                    let targetFiles = [...event.target.files];
                    targetFiles = targetFiles.map(file => {
                        return {
                            name: file.name,
                            type: file.type,
                            content: file
                        }
                    });
                    // Videos should be attached via the FileAttachVideo component
                    const addedFiles = targetFiles.filter((file) => !isVideo(file.type));
                    if (addedFiles.length < targetFiles.length) {
                        dispatch(displayError([{label: 'error_trying_to_add_invalid_video_file'}]));
                    }
                    props.onChange(addedFiles);
                }}
                multiple={props.multiple}
                accept={props.accept}
            />
            <div className={props.buttonWrapperClassName}>
                <div className={"file-upload-button"}>
                    {
                        (props.showAddVideo || props.showAddMusicPlatform) &&
                        <div className={"file-upload-button-title"}>
                            <Translate label={"upload_files_heading"}/>
                            {props.infoLinks && <InfoLink to={props.infoLinks[0]} style={{ marginLeft: '1rem' }} />}
                        </div>
                    }
                    <ButtonAccept
                        type={"button"}
                        label={props.buttonLabel || "default_file_upload_button"}
                        className={props.buttonClassName}
                        onClick={attachFile}
                    />
                </div>
                {
                    props.uploadHelpText && (
                        <div style={{
                            marginTop: '1rem'
                        }}>
                            <Translate label={props.uploadHelpText} />
                        </div>
                    )
                }
                {
                    props.showAddVideo &&
                    <FileAttachVideo
                        onAdd={(videoFile) => props.onChange(videoFile)}
                        {...(props.infoLinks && { infoLink: props.infoLinks[1]})}
                    />
                }
                {
                    props.showAddMusicPlatform &&
                    <FileAttachMusic
                        onAdd={(musicFile) => props.onChange(musicFile)}
                        {...(props.infoLinks && { infoLink: props.infoLinks[2]})}
                    />
                }
            </div>
            {showFileUploadList && <FileUploadList
                fileUploadItem={props.fileUploadItem}
                files={props.files}
                className={props.fileUploadItemContainerClassName}
                onRemoveFile={props.onRemoveFile}
            />}
        </div>
    )
}