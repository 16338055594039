import React from 'react';
import "./SpotifyEmbed.css"

const SpotifyEmbed = ({ uri }) => {

    function getKey(uri) {
        const trackRegex = /track.*/;
        const albumRegex = /album.*/;

        const trackMatch = uri.match(trackRegex);
        if (trackMatch) {
            return trackMatch[0];
        } else {
            const albumMatch = uri.match(albumRegex);
            if (albumMatch) {
                return albumMatch[0];
            }
        }

        return uri;
    }

    return (
        <div className="spotify-embed-container">
            <iframe
                title="spotify-embed"
                className="spotify-embed"
                src={`https://open.spotify.com/embed/${getKey(uri)}`}
                allowtransparency="true"
                allow="encrypted-media"
            />
        </div>
    );
};

export default SpotifyEmbed;