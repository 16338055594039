import React from "react";
import ListItemRectangular from "../../common/list/list.item.rectangular/ListItemRectangular";
import {formatLocalDate} from "../../../services/date/date";
import LineSplit from "../../common/linesplit/LineSplit";
import ProgramRequestParticipationButton from "../program.request.participation.button/ProgramRequestParticipationButton";
import "./ProgramListItem.css";

export default function ProgramListItem(props) {
    return (
        <ListItemRectangular className={"programs-list-item"}
            onClick={() => {props.onItemClick(props.id)}}
        >
            <div className={"programs-list-item-header"}>
                <div className={"programs-list-item-header-name"}>
                    {props.name}
                </div>
                <div className={"programs-list-item-header-dates"}>
                    {formatLocalDate(props.startDate)} - {formatLocalDate(props.endDate)}
                </div>
            </div>
            <LineSplit string={props.description} separator={'\n'} />
            {
                props.participationStatus &&
                <ProgramRequestParticipationButton 
                    className={"program-list-item-participation-button"}
                    programId={props.id}
                    status={props.participationStatus} 
                    updateParticipationStatus={props.updateParticipationStatus}

                />
            }
        </ListItemRectangular>
    )
}