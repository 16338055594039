import React from "react";
import "./Checkbox.css";

export default function Checkbox(props) {
    return (
        <div className={"checkbox"}>
            <input id={props.id}
                   type="checkbox"
                   disabled={props.disabled}
                   checked={props.checked}
                   onChange={props.onChange}
                   onBlur={props.onBlur}
            />
        </div>
    )
}