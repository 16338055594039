import React, {useState} from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import Translate from "../translate/Translate";
import "./FormikUnsavedChangesPrompt.css";
import ButtonAccept from "../button.accept/ButtonAccept";
import ButtonSecondary from "../button.secondary/ButtonSecondary";

export default function FormikUnsavedChangesPrompt(props) {
    const [confirming, updateConfirming] = useState(false);
    const {dirty, isSubmitting} = props.formikProps;

    return (
        <NavigationPrompt when={dirty && !isSubmitting}>
            {({onConfirm, onCancel}) => (
                <div className="unsaved-changes-alert-overlay">
                    <div className="unsaved-changes-alert">
                        <h1><Translate label={"unsaved_changes_alert_title"}/></h1>
                        <p><Translate label={"unsaved_changes_alert_message"}/></p>
                        <div className="buttons">
                            <ButtonAccept type={"button"} disabled={confirming} label={"confirm_button"} onClick={() => {
                                try {
                                    updateConfirming(true)
                                    onConfirm();
                                } catch (error) {
                                } finally {
                                    updateConfirming(false);
                                }
                            }}/>
                            <ButtonSecondary className="cancel-button" disabled={confirming} label={"cancel_button"} onClick={onCancel}/>
                        </div>
                    </div>
                </div>
            )}
        </NavigationPrompt>
    )
}