export function createDefaultAboutEditData(formData = {}) {
    return {
        name: formData.name || '',
        userImageFiles: formData.userImage ? [formData.userImage] : [],
        cv: formData.cv ? [formData.cv] : [],
        website: formData.website || '',
        instagram: formData.instagram || '',
        linkedin: formData.linkedin || '',
        email: formData.email || '',
        phoneNumber: formData.phoneNumber || '',
        personalInfo: formData.personalInfo || '',
        artistStatement: formData.artistStatement || '',
        openToCollaborate: formData.openToCollaborate || false,
        availableProgramSkills: formData.availableProgramSkills || [],
        selectedProgramSkills: formData.selectedProgramSkills || []
    };
}