import React from "react";
import "./SkillTag.css"
import {InputClearRemove} from "../../svg/svg";

export default function SkillTag(props) {

    function getClassName() {
        let className = "skill-tag";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            {props.skillName}
            {
                props.onRemove &&
                    <div onClick={props.onRemove}>
                        <InputClearRemove/>
                    </div>
            }
        </div>
    )
}