import React, { useState } from "react";
import PortfolioEditSeriesItem from "../portfolio.edit.series.item/PortfolioEditSeriesItem";
import "./PortfolioEditSeries.css"
import ButtonAccept from "../../../common/button.accept/ButtonAccept";
import {FieldArray} from "formik";
import createDefaultSeries from "./PortfolioEditSeries.library";
import Translate from "../../../common/translate/Translate";
import { DndContext, rectIntersection } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { throttle }  from "../../../../services/throttle/throttle";

export default function PortfolioEditSeries(props) {
    const [addNewSeriesId, setAddNewSeriesId] = useState(-100);

    function getClassName() {
        let className = "portfolio-edit-series";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    function getPortfolioSeries() {
        if (props.series) {
            return props.series;
        }
        return [];
    }

    function isAThumbnail(draggableItem) {
        return draggableItem.data.current?.type === "thumbnail";
    }

    function isASeries(draggableItem) {
        return draggableItem.data.current?.type === "series";
    }

    function handleDragEnd(event, arrayHelpers) {
        const {active, over} = event;
        if (!active || !over) {
            return;
        }

        const isOverAThumbnail = isAThumbnail(over);
        const isActiveASeries = isASeries(active);
        const isOverASeries = isASeries(over);

        // If moving a series over a series
        if (isActiveASeries && isOverASeries) {
            const activeSeriesId = active.id;
            const overSeriesId = over.id

            if (activeSeriesId === overSeriesId) return;

            adjustSeries(activeSeriesId, overSeriesId, arrayHelpers);
        }

        // If moving a series over a thumbnail
        if (isActiveASeries && isOverAThumbnail) {
            const activeSeriesId = active.id;
            const overSeriesId = over.data.current?.seriesId

            if (activeSeriesId === overSeriesId) return;

            adjustSeries(activeSeriesId, overSeriesId, arrayHelpers);
        }
    }

    function adjustSeries(activeSeriesId, overSeriesId, arrayHelpers) {
        const oldIndex = getPortfolioSeries().findIndex(p => p?.id === activeSeriesId);
        const newIndex = getPortfolioSeries().findIndex(p => p?.id === overSeriesId);
        arrayHelpers.swap(oldIndex, newIndex);
    }

    const throttleHandleDragOver = throttle(handleDragOver, 100);

    function handleDragOver(event) {
        const {active, over} = event;
        if (!active || !over) {
            return;
        }

        const isActiveAThumbnail = isAThumbnail(active);
        const isOverAThumbnail = isAThumbnail(over);
        const isOverASeries = isASeries(over);

        // If moving a thumbnail over a series
        if (isActiveAThumbnail && isOverASeries) {
            const activeSeriesId = active.data.current?.seriesId;
            const overSeriesId = over.id;

            const activeSeries = getPortfolioSeries().find(s => s?.id === activeSeriesId);
            const overSeries = getPortfolioSeries().find(s => s?.id === overSeriesId);

            const activeThumbnail = activeSeries.files.find(p => p?.id === active.id);
            const activeThumbnailIndex = activeSeries.files.findIndex(p => p?.id === active.id);

            activeSeries.files.splice(activeThumbnailIndex, 1);

            overSeries.files.unshift(activeThumbnail);
        }

        // If moving a thumbnail over another thumbnail
        if (isActiveAThumbnail && isOverAThumbnail) {
            if (active.id === over.id) return;
            const activeSeriesId = active.data.current?.seriesId;
            const overSeriesId = over.data.current?.seriesId;

            // If moving a thumbnail over another thumbnail in the same series
            if (activeSeriesId === overSeriesId) {
                const activeSeries = getPortfolioSeries().find(s => s?.id === activeSeriesId);

                const oldThumbnailIndex = activeSeries.files.findIndex(p => p?.id === active.id);
                const newThumbnailIndex = activeSeries.files.findIndex(p => p?.id === over.id);

                [activeSeries.files[oldThumbnailIndex], activeSeries.files[newThumbnailIndex]] = [activeSeries.files[newThumbnailIndex], activeSeries.files[oldThumbnailIndex]];
            }
            else {
                // If moving a thumbnail over a thumbnail in another series
                const activeSeries = getPortfolioSeries().find(s => s?.id === activeSeriesId);
                const overSeries = getPortfolioSeries().find(s => s?.id === overSeriesId);

                const activeThumbnail = activeSeries.files.find(p => p?.id === active.id);
                const activeThumbnailIndex = activeSeries.files.findIndex(p => p?.id === active.id);

                activeSeries.files.splice(activeThumbnailIndex, 1);

                const overThumbnailIndex = overSeries.files.findIndex(p => p?.id === over.id);
                overSeries.files.splice(overThumbnailIndex, 0, activeThumbnail);
            }
        }
    }

    function getNewSeriesId() {
        const newId = addNewSeriesId;
        setAddNewSeriesId(newId - 1);
        return newId;
    }

    return (
        <div className={getClassName()}>
            <div>
                <Translate label={props.label}/>
            </div>
            <div className={"portfolio-series-explanation"}>
                <Translate label={"portfolio_series_explanation"}/>
            </div>
            <div className={"portfolio-series-edit-fields"}>
                <FieldArray
                    name="series"
                    render={arrayHelpers => (
                        <>
                            <DndContext collisionDetection={rectIntersection} onDragEnd={(e) => handleDragEnd(e, arrayHelpers)} onDragOver={throttleHandleDragOver}>
                                <SortableContext items={getPortfolioSeries().map(s => s.id)} strategy={rectSortingStrategy}>
                                    <div className={"portfolio-edit-series-collection"}>
                                        {getPortfolioSeries().map((series, index) => (
                                            <PortfolioEditSeriesItem
                                                id={series?.id}
                                                key={index}
                                                name={series?.name}
                                                files={series?.files}
                                                seriesCount={props.series ? props.series.length : 0}
                                                deleteSeries={() => {
                                                    arrayHelpers.remove(index);
                                                    const fileIds = (series?.files || []).map(file => file.id);
                                                    props.onRemoveFile(fileIds);
                                                }}
                                                updateThumbnail={props.updateThumbnail}
                                                onRemoveFile={props.onRemoveFile}
                                                formProps={props.formProps}
                                                index={index}
                                            />
                                        ))}
                                    </div>
                                </SortableContext>
                            </DndContext>
                            <ButtonAccept
                                type={"button"}
                                disabled={props.disabled}
                                label={"portfolio_edit_add_new_series"}
                                onClick={() => getPortfolioSeries().push(createDefaultSeries(getNewSeriesId()))}
                            />
                        </>
                    )}
                />
            </div>
        </div>
    );
}