import React from "react";
import ButtonAccept from "../button.accept/ButtonAccept";
import ButtonSecondary from "../button.secondary/ButtonSecondary";
import "./ButtonSaveCancel.css"

export default function ButtonSaveCancel(props) {

    function getClassName() {
        let className = "buttons";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <ButtonAccept disabled={props.disabled} label="save_button" onClick={props.onSave}/>
            <ButtonSecondary disabled={props.disabled} label="cancel_button" onClick={props.onCancel}/>
        </div>
    )
}