import React, {useEffect, useState} from "react";
import "./RequestReferenceLetter.css";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import Translate from "../../components/common/translate/Translate";
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import TextSelect from "../../components/common/text.select/TextSelect";
import TextDatePicker from "../../components/common/text.date.picker/TextDatePicker";
import {createOnBlurFunction} from "../../services/formik/formik";
import TextArea from "../../components/common/text.area/TextArea";
import {useDispatch} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../../services/ajax/ajax";
import ButtonAccept from "../../components/common/button.accept/ButtonAccept";
import {displaySuccess} from "../../components/message.panel/ducks/actions";
import {buildRequestReferenceRequestFromValues} from "./RequestReferenceLetter.library";
import FormikUnsavedChangesPrompt from "../../components/common/formik.unsaved.changes.prompt/FormikUnsavedChangesPrompt";
import {getDateValue} from "../../services/date/date";
import CheckboxInput from "../../components/common/checkbox.input/CheckboxInput";
import {getUserId} from "../../services/auth/auth";

const schema = Yup.object().shape({
    mentor: Yup.string().required(<Translate label={"validation_message_required"}/>),
    opportunity: Yup.string(),
    portfolio: Yup.string(),
    dateNeeded: Yup.date().nullable(),
    contact: Yup.string(),
    message: Yup.string(),
    includeCV: Yup.bool()
});

export default function RequestReferenceLetter(props) {

    const dispatcher = useDispatch();

    const [openOpportunitiesOptions, updateOpenOpportunitiesOptions] = useState();
    const [userPortfolioOptions, updateUserPortfolioOptions] = useState();
    const [userMentorOptions, updateUserMentorOptions] = useState();
    const [profileSocialData, updateProfileSocialData] = useState();

    useEffect(() => {
        makeJSONGetRequest(API_PATHS.GET_USER_MENTOR_OPTIONS, dispatcher)
            .then((response) => updateUserMentorOptions(response.body.options));
        makeJSONGetRequest(API_PATHS.GET_USER_PORTFOLIO_OPTIONS, dispatcher)
            .then((response) => updateUserPortfolioOptions(response.body.options));
        makeJSONGetRequest(API_PATHS.GET_OPEN_OPPORTUNITY_OPTIONS, dispatcher)
            .then((response) => updateOpenOpportunitiesOptions(response.body.options));
        makeJSONGetRequest(makeUrl(API_PATHS.PROFILE_SOCIAL, {userId: getUserId()}), dispatcher)
            .then((response) => updateProfileSocialData(response.body));
    }, [dispatcher]);

    function hasOptions() {
        return openOpportunitiesOptions && userPortfolioOptions && userMentorOptions;
    }

    async function onSubmit(values) {
        const body = buildRequestReferenceRequestFromValues(values);
        makeJSONPostRequest(API_PATHS.REQUEST_LETTER_OF_REFERENCE, dispatcher, body)
            .then(() => {
                dispatcher(displaySuccess([
                    {
                        label: "request_reference_success"
                    }
                ]));
            });
    }

    return (
        <BasicLayout
            {...props}
        >
            <div className={"request-reference-letter-view"}>
                <div>
                    <h1><Translate label={"request_reference_letter_heading"}/></h1>
                </div>
                {
                    hasOptions() &&
                    <Formik
                        validationSchema={schema}
                        initialValues={{
                            mentor: (props.location.state && props.location.state.mentorId) || '',
                            opportunity: '',
                            portfolio: '',
                            contact: '',
                            message: '',
                            includeCV: false
                        }}
                        onSubmit={(values, actions) => {
                            onSubmit(values).then(() => {
                                actions.resetForm();
                            }).finally(() => {
                                actions.setSubmitting(false);
                            })
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {
                            formProps => (
                                <Form className={"request-reference-letter-form"}>
                                    <Field name={"mentor"}>
                                        {
                                            fieldProps => (
                                                <TextSelect
                                                    {...fieldProps.field}
                                                    options={userMentorOptions}
                                                    disabled={formProps.isSubmitting}
                                                    hasError={fieldProps.meta.error}
                                                    errorMessage={fieldProps.meta.error}
                                                    id={"mentor"}
                                                    name={"mentor"}
                                                    label={"request_reference_mentor_field_label"}
                                                    formikProps={formProps}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={"opportunity"}>
                                        {
                                            fieldProps => (
                                                <TextSelect
                                                    {...fieldProps.field}
                                                    options={openOpportunitiesOptions}
                                                    disabled={formProps.isSubmitting}
                                                    hasError={fieldProps.meta.error}
                                                    errorMessage={fieldProps.meta.error}
                                                    id={"opportunity"}
                                                    name={"opportunity"}
                                                    label={"request_reference_opportunity_field_label"}
                                                    placeholder={"request_reference_opportunity_placeholder"}
                                                    formikProps={formProps}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={"portfolio"}>
                                        {
                                            fieldProps => (
                                                <TextSelect
                                                    {...fieldProps.field}
                                                    options={userPortfolioOptions}
                                                    disabled={formProps.disabled}
                                                    hasError={fieldProps.meta.error}
                                                    errorMessage={fieldProps.meta.error}
                                                    id={"portfolio"}
                                                    name={"portfolio"}
                                                    label={"request_reference_portfolio_field_label"}
                                                    placeholder={"request_reference_portfolio_placeholder"}
                                                    formikProps={formProps}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={"dateNeeded"}>
                                        {
                                            fieldProps => (
                                                <TextDatePicker
                                                    selected={fieldProps.field.value && getDateValue(fieldProps.field.value)}
                                                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                    id={"dateNeeded"}
                                                    name={"dateNeeded"}
                                                    hasError={fieldProps.meta.error}
                                                    errorMessage={fieldProps.meta.error}
                                                    disabled={formProps.isSubmitting}
                                                    minDate={getDateValue()}
                                                    onChange={(value) => {
                                                        formProps.setFieldValue(fieldProps.field.name, value);
                                                    }}
                                                    label={"request_reference_date_needed_label"}
                                                    formikProps={formProps}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={"contact"}>
                                        {
                                            fieldProps => (
                                                <TextArea
                                                    {...fieldProps.field}
                                                    id={"contact"}
                                                    hasError={fieldProps.meta.error}
                                                    errorMessage={fieldProps.meta.error}
                                                    name={"contact"}
                                                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                    disabled={formProps.isSubmitting}
                                                    label={"request_reference_contact_label"}
                                                    formikProps={formProps}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={"message"}>
                                        {
                                            fieldProps => (
                                                <TextArea
                                                    {...fieldProps.field}
                                                    id={"message"}
                                                    hasError={fieldProps.meta.error}
                                                    errorMessage={fieldProps.meta.error}
                                                    name={"message"}
                                                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                    disabled={formProps.isSubmitting}
                                                    label={"request_reference_message"}
                                                    formikProps={formProps}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name={"includeCV"}>
                                    {
                                            fieldProps => (
                                                <CheckboxInput
                                                    {...fieldProps.field}
                                                    id="includeCV"
                                                    name="includeCV"
                                                    label={"request_reference_include_cv"}
                                                    disabled={profileSocialData?.cv == null || formProps.isSubmitting}
                                                    formikProps={formProps} />
                                            )
                                        }
                                    </Field>
                                    <ButtonAccept
                                        className={"request-letter-reference-accept-button"}
                                        label={"request_reference_form_button"}
                                    />
                                    <FormikUnsavedChangesPrompt formikProps={formProps}/>
                                </Form>
                            )
                        }
                    </Formik>
                }
            </div>
        </BasicLayout>

    )
}