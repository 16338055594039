import React from "react";
import {SearchAddPeer, SearchRemovePeer} from "../svg/svg";
import "./PeerButton.css";
import {makeAlert} from "../alert/Alert";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";

export default function PeerButton(props) {

    const language = useSelector(getLanguage);

    function getClassName() {
        let className = "peer-button";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    function removePeer(event) {
        event.stopPropagation();
        makeAlert(language,
            "peer_remove_alert_title",
            "relationship_remove_alert_message",
            async () => {await props.onPeerRemove()}
        );
    }

    function getPeerButton() {
        if (props.isPeer) {
            return (
                <>
                    <button className={"search-item-remove-peer"} onClick={(event) => {removePeer(event)}}
                            disabled={props.disableRemovePeer}>
                        <SearchRemovePeer/>
                    </button>
                </>
            )
        }
        return (
            <>
                <button className={"search-item-add-peer"} onClick={(event) => {
                    props.onPeerAdd();
                    event.stopPropagation();
                }} disabled={props.disableAddPeer}>
                    <SearchAddPeer/>
                </button>
            </>
        )
    }

    return (
        <div className={getClassName()}>
            {getPeerButton()}
        </div>
    )
}