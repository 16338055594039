import React from "react";
import UserImage from "../../../user.image/UserImage";
import Translate from "../../../common/translate/Translate";
import {PrivateJournalIcon} from "../../../common/svg/svg";
import "./ListItemViewer.css";
import TranslateSingularPlural from "../../../common/translate.singular.plural/TranslateSingularPlural";

export default function ListItemViewer(props) {
    return (
        <div className={"journal-item-viewer"}>
            {props.viewerCount && props.viewerCount > 0
                ? <>
                    <UserImage name={props.viewerName} image={props.viewerUserImage} alt={`${props.viewerName} header user image`}/>
                    <div className={"viewer-count-text"}>
                        <TranslateSingularPlural amount={props.viewerCount}
                                                 singularLabel={"journal_list_viewer_label_singular"}
                                                 pluralLabel={"journal_list_viewer_label_plural"}
                                                 params={{amount: props.viewerCount}}/>
                    </div>
                </>
                : <>
                    <PrivateJournalIcon/>
                    <div>
                        <Translate label={"journal_list_private_journal_label"}/>
                    </div>
                </>
            }
        </div>
    )
}