import React from "react";
import "./OpportunitiesForm.css";
import {Field, Form, Formik} from "formik";
import TextInput from "../../common/text.input/TextInput";
import {createOnBlurFunction} from "../../../services/formik/formik";
import * as Yup from 'yup';
import TextDatePicker from "../../common/text.date.picker/TextDatePicker";
import TextArea from "../../common/text.area/TextArea";
import Translate from "../../common/translate/Translate";
import FileUpload from "../../common/file.upload/FileUpload";
import cloneDeep from "lodash/cloneDeep";
import TextSelect from "../../common/text.select/TextSelect";
import ButtonAccept from "../../common/button.accept/ButtonAccept";
import {trackPromise} from "react-promise-tracker";
import {getDateValue} from "../../../services/date/date";
import FileThumbnail from "../../common/file.thumbnail/FileThumbnail";

export default function OpportunitiesForm(props) {
    const OpportunitiesSchema = Yup.object().shape(createSchema());

    function createSchema() {
        const defaultSchema = {
            contactName: Yup.string()
                .required(<Translate label={"validation_message_required"}/>),
            email: Yup.string()
                .required(<Translate label={"validation_message_required"}/>)
                .email(<Translate label={"validation_message_invalid_email"}/>),
            organization: Yup.string(),
            title: Yup.string()
                .required(<Translate label={"validation_message_required"}/>),
            opportunityDate: Yup.date().nullable(),
            deadline: Yup.date().nullable(),
            budget: Yup.string()
                .required(<Translate label={"validation_message_required"}/>),
            description: Yup.string()
                .required(<Translate label={"validation_message_required"}/>),
            eligibility: Yup.string()
                .required(<Translate label={"validation_message_required"}/>),
            submissionRequirements: Yup.string()
                .required(<Translate label={"validation_message_required"}/>),
            detailsUrl: Yup.string().url()
        };

        if (props.showFileUpload) {
            defaultSchema['files'] = Yup.array();
        }

        if (props.opportunityTypes) {
            defaultSchema['type'] = Yup.string().required(<Translate label={"validation_message_required"}/>)
        }
        return defaultSchema;
    }

    return (
        <Formik
            initialValues={props.formData}
            validationSchema={OpportunitiesSchema}
            onSubmit={(values, actions) => {
                trackPromise(
                    props.onSubmit(values)
                        .then(() => {
                            actions.resetForm();
                        }).finally(() => {
                        actions.setSubmitting(false);
                    })
                )
            }}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {
                formProps => (
                    <Form className={"opportunities-form"}>
                        <div className={"opportunities-form-section"}>
                            <Field name="contactName">
                                {
                                    fieldProps => (
                                        <TextInput
                                            {...fieldProps.field}
                                            id={"contactName"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name={"contactName"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"opportunity_contact_name_label"}
                                            placeholder={"opportunity_contact_name_placeholder"}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                            <Field name="email">
                                {
                                    fieldProps => (
                                        <TextInput
                                            {...fieldProps.field}
                                            id={"email"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name={"email"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"opportunity_email_address_label"}
                                            placeholder={"opportunity_email_address_placeholder"}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                            <Field name="organization">
                                {
                                    fieldProps => (
                                        <TextInput
                                            {...fieldProps.field}
                                            id={"organization"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name={"organization"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"opportunity_organization_label"}
                                            placeholder={"opportunity_organization_placeholder"}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                        </div>

                        <div className={"opportunities-form-section"}>
                            <Field name="title">
                                {
                                    fieldProps => (
                                        <TextInput
                                            {...fieldProps.field}
                                            id={"title"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name={"title"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"opportunity_title_label"}
                                            placeholder={"opportunity_title_placeholder"}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                            <Field name={"opportunityDate"}>
                                {
                                    fieldProps => (
                                        <TextDatePicker
                                            selected={fieldProps.field.value && getDateValue(fieldProps.field.value)}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            label={"opportunity_date_label"}
                                            id={"opportunityDate"}
                                            name={"opportunityDate"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            disabled={formProps.isSubmitting}
                                            placeholderText={"opportunities_date_placeholder_text"}
                                            minDate={getDateValue()}
                                            onChange={(value) => {
                                                formProps.setFieldValue(fieldProps.field.name, value);
                                            }}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                            <Field name={"deadline"}>
                                {
                                    fieldProps => (
                                        <TextDatePicker
                                            selected={fieldProps.field.value && getDateValue(fieldProps.field.value)}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            label={"opportunity_deadline_label"}
                                            id={"deadline"}
                                            name={"deadline"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            disabled={formProps.isSubmitting}
                                            placeholderText={"opportunities_date_placeholder_text"}
                                            minDate={getDateValue()}
                                            onChange={(value) => {
                                                formProps.setFieldValue(fieldProps.field.name, value);
                                            }}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                            <Field name="budget">
                                {
                                    fieldProps => (
                                        <TextInput
                                            {...fieldProps.field}
                                            id={"budget"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name={"budget"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"opportunity_budget_label"}
                                            placeholder={"opportunity_budget_placeholder"}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                            {
                                props.opportunityTypes &&
                                    <Field name={"type"}>
                                        {
                                            fieldProps => (
                                                <TextSelect
                                                    {...fieldProps.field}
                                                    options={props.opportunityTypes}
                                                    disabled={formProps.isSubmitting}
                                                    hasError={fieldProps.meta.error}
                                                    errorMessage={fieldProps.meta.error}
                                                    id={"type"}
                                                    name={"type"}
                                                    label={"opportunity_type_label"}
                                                    placeholder={"select_type"}
                                                    formikProps={formProps}
                                                />
                                            )
                                        }
                                    </Field>
                            }
                            <Field name="detailsUrl">
                                {
                                    fieldProps => (
                                        <TextInput
                                            {...fieldProps.field}
                                            id="detailsUrl"
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name="detailsUrl"
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"opportunity_url_label"}
                                            placeholder={"opportunity_url_placeholder"}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                        </div>

                        <div className={"opportunities-form-section"}>
                            <Field name="description">
                                {
                                    fieldProps => (
                                        <TextArea
                                            {...fieldProps.field}
                                            className={"opportunities-form-description"}
                                            id={"description"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name={"description"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"opportunity_description_label"}
                                            placeholder={"opportunity_description_label_placeholder"}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                            <Field name="eligibility">
                                {
                                    fieldProps => (
                                        <TextArea
                                            {...fieldProps.field}
                                            className={"opportunities-form-eligibility"}
                                            id={"eligibility"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name={"eligibility"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"opportunity_eligibility_label"}
                                            placeholder={"opportunity_eligibility_label_placeholder"}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                            <Field name="submissionRequirements">
                                {
                                    fieldProps => (
                                        <TextArea
                                            {...fieldProps.field}
                                            className={"opportunities-form-submission-requirements"}
                                            id={"submissionRequirements"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name={"submissionRequirements"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"opportunity_submission_requirements_label"}
                                            placeholder={"opportunity_submission_requirements_label_placeholder"}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                        </div>
                        {
                            props.showFileUpload &&
                            <div className={"opportunities-form-section"}>
                                <FileUpload
                                    fileUploadItem={FileThumbnail}
                                    buttonClassName={"opportunities-file-upload-button"}
                                    buttonWrapperClassName={"opportunities-file-upload-button-wrapper"}
                                    className={"opportunities-file-upload"}
                                    id={"files"}
                                    name={"files"}
                                    multiple={true}
                                    files={formProps.values.files}
                                    onChange={(files) => {
                                        formProps.setFieldValue("files", formProps.values.files.concat(files));
                                    }}
                                    onRemoveFile={(index) => {
                                        const newFiles = cloneDeep(formProps.values.files);
                                        newFiles.splice(index, 1);
                                        formProps.setFieldValue("files", newFiles);
                                    }}
                                    showAddVideo={true}
                                    showAddMusicPlatform={true}
                                />
                            </div>
                        }

                        <ButtonAccept
                            className={"submit-button"}
                            disabled={formProps.isSubmitting}
                            label={props.submitLabel}
                        />
                    </Form>
                )
            }
        </Formik>
    )
}
