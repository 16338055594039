import React, {useCallback, useEffect, useState} from "react";
import BasicLayout from "../basic.layout/BasicLayout";
import Nav from "../../nav/Nav";
import UserImage from "../../user.image/UserImage";
import ProfileSocial from "../../profile/profile.social/ProfileSocial";
import "./ProfileLayout.css"
import {useDispatch} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeUrl} from "../../../services/ajax/ajax";
import ListResult from "../../common/list.result/ListResult";
import {createViewPath, RouteConstants} from "../../../App.library";
import {useUpdatePeer} from "../../common/peer.button/hooks/useUpdatePeer";
import {Route, Switch} from "react-router-dom";
import PrivateRoute from "../../common/private.route/PrivateRoute";
import AboutEdit from "../../../views/about/about.edit/AboutEdit";
import About from "../../../views/about/About";
import Journal from "../../../views/journal/Journal";
import Projects from "../../../views/projects/Projects";
import Portfolio from "../../../views/portfolio/Portfolio";
import JournalCreate from "../../../views/journal/journal.create/JournalCreate";
import JournalEdit from "../../../views/journal/journal.edit/JournalEdit";
import JournalView from "../../../views/journal/journal.view/JournalView";
import PortfolioCreate from "../../../views/portfolio/portfolio.create/PortfolioCreate";
import PortfolioEdit from "../../../views/portfolio/portfolio.edit/PortfolioEdit";
import PortfolioView from "../../../views/portfolio/portfolio.view/PortfolioView";
import ProjectsCreate from "../../../views/projects/projects.create/ProjectsCreate";
import ProjectsEdit from "../../../views/projects/projects.edit/ProjectsEdit";
import ProjectsView from "../../../views/projects/projects.view/ProjectsView";
import PortfolioViewSeries from "../../../views/portfolio/portfolio.view.series/PortfolioViewSeries";
import NotFoundHeading from "../../../views/not.found/not.found.heading/NotFoundHeading";

export default function ProfileLayout(props) {

    const userId = props.match.params.userId;
    const dispatcher = useDispatch();
    const [profileSocialData, updateProfileSocialData] = useState({});

    const [disableAddPeer, disableRemovePeer, onPeerAdd, onPeerRemove] = useUpdatePeer(updateIsPeer, userId);

    function updateIsPeer(value) {
        const newValue = {...profileSocialData, peer: value};
        updateProfileSocialData(newValue);
    }

    function goToListResult(id) {
        props.history.push({
            pathname: createViewPath(props.location.state ? props.location.state.path : RouteConstants.ABOUT,
                {userId: id}),
            state: props.location.state
        });
    }

    const refreshSocialData = useCallback(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PROFILE_SOCIAL, {userId: userId}), dispatcher)
            .then((response) => {
                updateProfileSocialData(response.body);
            });
    }, [userId, dispatcher]);

    useEffect(() => {
        refreshSocialData();
    }, [refreshSocialData, userId]);

    return (
        <BasicLayout {...props}>
            {
                Object.keys(profileSocialData).length > 0 &&
                    <div className="profile-view">
                        {
                            props.location.state &&
                            <ListResult
                                className={'profile-search-result'}
                                label={"profile_next_label"}
                                resultIds={props.location.state.userIds}
                                currentId={userId}
                                onForwardClick={goToListResult}
                                onBackClick={goToListResult}
                            />
                        }
                        <section className="profile-container">
                            <div className="profile-info">
                                <UserImage
                                    image={profileSocialData.userImage}
                                    alt={"Profile user image for " + profileSocialData.name}
                                    name={profileSocialData.name}/>
                                <ProfileSocial
                                    {...props}
                                    name={profileSocialData.name}
                                    cv={profileSocialData.cv}
                                    website={profileSocialData.website}
                                    instagram={profileSocialData.instagram}
                                    linkedin={profileSocialData.linkedin}
                                    canBePeer={profileSocialData.canBePeer}
                                    isPeer={profileSocialData.peer}
                                    disableAddPeer={disableAddPeer}
                                    disableRemovePeer={disableRemovePeer}
                                    onPeerAdd={onPeerAdd}
                                    onPeerRemove={onPeerRemove}
                                    userId={userId}
                                />
                            </div>
                            <Nav
                                {...props}
                                canShowProjects={profileSocialData.canShowProjects}
                                canShowJournals={profileSocialData.canShowJournals}
                                canShowPortfolios={profileSocialData.canShowPortfolios}
                                canShowAbout={profileSocialData.canShowAbout}
                            />
                            <section className="profile-view">
                                <Switch>
                                    <PrivateRoute exact path={RouteConstants.ABOUT_EDIT} component={AboutEdit} renderProps={{refreshSocialData: refreshSocialData}}/>
                                    <PrivateRoute exact path={RouteConstants.ABOUT} component={About}/>
                                    <PrivateRoute path={RouteConstants.JOURNAL_CREATE} component={JournalCreate}/>
                                    <PrivateRoute path={RouteConstants.JOURNAL_EDIT} component={JournalEdit}/>
                                    <PrivateRoute path={RouteConstants.JOURNAL_VIEW} component={JournalView}/>
                                    <PrivateRoute path={RouteConstants.JOURNAL} component={Journal}/>
                                    <PrivateRoute path={RouteConstants.PROJECTS_CREATE} component={ProjectsCreate}/>
                                    <PrivateRoute path={RouteConstants.PROJECTS_EDIT} component={ProjectsEdit}/>
                                    <PrivateRoute path={RouteConstants.PROJECTS_VIEW} component={ProjectsView}/>
                                    <PrivateRoute path={RouteConstants.PROJECTS} component={Projects}/>
                                    <PrivateRoute path={RouteConstants.PORTFOLIO_CREATE} component={PortfolioCreate}/>
                                    <PrivateRoute path={RouteConstants.PORTFOLIO_EDIT} component={PortfolioEdit}/>
                                    <Route path={RouteConstants.PORTFOLIO_VIEW_SERIES} render={props => <PortfolioViewSeries {...props}/>}/>
                                    <Route path={RouteConstants.PORTFOLIO_VIEW} render={props => <PortfolioView {...props} />}/>
                                    <Route path={RouteConstants.PORTFOLIO} render={props => <Portfolio {...props} />}/>
                                    <Route render={props => <NotFoundHeading {...props} />}/>
                                </Switch>
                            </section>
                        </section>
                    </div>
            }
        </BasicLayout>
    )
}