import React from "react";
import Translate from "../translate/Translate";
import {ChevronDown, ChevronUp} from "../svg/svg";
import "./ChevronShowHide.css"

export default function ChevronShowHide(props) {

    function getClassName() {
        let className = "chevron-show-hide";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    function getHideShowFilterButton() {
        if (props.isHidden) {
            return (
                <>
                    <div>
                        <Translate label={props.showLabel}/>
                    </div>
                    <ChevronDown/>
                </>
            )
        }
        return (
            <>
                <div>
                    <Translate label={props.hideLabel}/>
                </div>
                <ChevronUp/>
            </>
        )
    }
    return (
        <div className={getClassName()} onClick={props.onHiddenClick}>
            {getHideShowFilterButton()}
        </div>
    )
}