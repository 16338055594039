import React from "react";
import "./SkillTaggingList.css";
import Translate from "../../translate/Translate";
import SkillTag from "../skill.tag/SkillTag";

export default function SkillTaggingList(props) {

    function getRemoveSkillTag(tag, index) {
        if (!props.onRemoveSkillTag) {
            return;
        }
        return () => {
            props.onRemoveSkillTag(tag, index)
        }
    }

    return (
        <div className={"skill-tagging-list"}>
            {
                props.skillTags && props.skillTags.length > 0 &&
                <>
                    {
                        props.showTitle &&
                            <div className={"skill-tag-title"}>
                                <Translate label={"edit_skill_tags"}/>
                            </div>
                    }

                    <div className={"skill-tagging-list"}>
                        {
                            props.skillTags.map((tag, index) => (
                                <SkillTag
                                    key={index}
                                    skillName={tag.name}
                                    onRemove={getRemoveSkillTag(tag, index)}
                                />
                            ))
                        }
                    </div>
                </>
            }
        </div>
    )
}