import React from "react";
import "./OpportunitiesListItemTitle.css";
import OpportunitiesItemType from "../opportunities.item.type/OpportunitiesItemType";

export default function OpportunitiesListItemTitle(props) {
    return (
        <div className={"opportunities-list-item-title-type"}>
            <div className={"opportunities-list-item-title"}>
                {props.title}
            </div>
            <OpportunitiesItemType
                type={props.type}
                className={'opportunities-list-item-type'}
            />
        </div>
    )
}