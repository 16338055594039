import React, {useEffect, useState} from "react";
import YouTube from "react-youtube";
import {isAudio, isImage, isVideo, isMusicPlatform} from "../../../services/file/file";
import SketchFileLink from "../sketch.file.link/SketchFileLink";
import SketchFileImage from "../sketch.file.image/SketchFileImage";
import SketchFileAudio from "../sketch.file.audio/SketchFileAudio";
import FileViewDocument from "./file.view.document/FileViewDocument";
import FileViewError from "./file.view.error/FileViewError";
import FileViewSensitive from "./file.view.sensitive/FileViewSensitive";
import SketchFileMusicPlatform from "../sketch.file.music.platform/SketchFileMusicPlatform";

export function MediaView({file, onLoadComponent, dispatch, language}) {
    const [showFile, updateShowFile] = useState(!file.markedAsSensitive);

    useEffect(() => {
        updateShowFile(!file.markedAsSensitive);
    }, [file.markedAsSensitive, file.id]);

    if (!file || !file.id || !file.type) {
        return <FileViewError language={language}/>;
    }

    if (!showFile && file.markedAsSensitive) {
        return <FileViewSensitive language={language} onClick={() => updateShowFile(true)}/>;
    }

    const contentType = file.type;
    if (isVideo(contentType)) {
        return (
            <div className={"sketch-youtube-video"}>
                <YouTube videoId={file.location}/>
            </div>
        );
    } else if (isImage(contentType)) {
        return <SketchFileImage className={"sketch-file-content"} dispatch={dispatch} file={file} alt={file.name} onLoadComponent={onLoadComponent}/>;
    } else if (isMusicPlatform(contentType)) {
        return <SketchFileMusicPlatform file={file} />
    } else if (isAudio(contentType)) {
        return <SketchFileAudio className={"sketch-file-content"} dispatch={dispatch} file={file} onLoadComponent={onLoadComponent}/>;
    } else {
        return (
            <SketchFileLink className={"sketch-file-content"} dispatch={dispatch} file={file} onLoadComponent={onLoadComponent}>
                <FileViewDocument language={language}/>
            </SketchFileLink>
        );
    }
}