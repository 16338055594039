import {isVideo, isMusicPlatform} from "../../../services/file/file";

export function getOldAndNewFiles(files) {
    if (!files) {
        return {oldFiles: [], newFiles: []};
    }
    return files.reduce((accumulator, currentFile) => {
        if (!!currentFile.id && currentFile.id > 0) {
            accumulator.oldFiles.push(currentFile);
        } else {
            accumulator.newFiles.push((isVideo(currentFile.type) || isMusicPlatform(currentFile.type)) ? currentFile : currentFile.content);
        }
        return accumulator;
    }, {oldFiles: [], newFiles: []});
}

export function createFileUploadFormData(newFiles) {
    const formData = new FormData();
    for (const file of newFiles) {
        formData.append('files', file, file.name);
    }

    return formData;
}

export function createNewExternallyLinkedFileData(newExternallyLinkedFiles) {
    return newExternallyLinkedFiles.map((file) => {
        return {fileName: file.name, location: file.content, type: file.type};
    });
}

export async function processMediaFiles(files) {
    const {oldFiles, newFiles} = getOldAndNewFiles(files);
    const {externallyLinkedFiles, sketchHostedFiles} = getExternallyLinkedAndSketchHostedFiles(newFiles);

    const returnValue = {};
    returnValue.existingFiles = oldFiles;
    returnValue.newSketchHostedFiles = sketchHostedFiles;
    returnValue.newExternallyLinkedFiles = externallyLinkedFiles;
    return returnValue;
}

function getExternallyLinkedAndSketchHostedFiles(files) {
    if (!files) {
        return {externallyLinkedFiles: [], sketchHostedFiles: []};
    }

    return files.reduce((accumulator, currentFile) => {
        if (isVideo(currentFile.type) || isMusicPlatform(currentFile.type)) {
            accumulator.externallyLinkedFiles.push(currentFile);
        } else {
            accumulator.sketchHostedFiles.push(currentFile);
        }
        return accumulator;
    }, {externallyLinkedFiles: [], sketchHostedFiles: []});
}
