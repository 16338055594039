import React from "react";
import Translate from "../translate/Translate";
import "./ButtonSecondary.css"

export default function ButtonSecondary(props) {
    function getClassName() {
        let className = "button-secondary";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <button type="button" disabled={props.disabled} onClick={props.onClick}><Translate label={props.label}/></button>
        </div>
    )
}