import { API_PATHS, makeJSONGetRequest } from "../../../../services/ajax/ajax";

export const musicPlatformTypeNames = [
    "APPLE_MUSIC",
    "BANDCAMP",
    "SOUNDCLOUD",
    "SPOTIFY",
    "TIDAL"
];

export async function loadMusicPlatforms(dispatcher) {
    const response = await makeJSONGetRequest(API_PATHS.GET_MUSIC_PLATFORM_OPTIONS, dispatcher)
    return response.body.options;
}

export function getMusicToAttach(musicPlatform, name, link, embedCode) {
    if (!musicPlatform || !name || !name.trim() || !link || !link.trim()) {
        return;
    }

    if (musicPlatform === "BANDCAMP") {
        if (!embedCode) {
            return;
        }
        const albumRegex = embedCode.match(/album=(\d+)/);
        const trackRegex = embedCode.match(/track=(\d+)/);
        const albumId = !!albumRegex && albumRegex.length === 2 ? albumRegex[1] : '';
        const trackId = !!trackRegex && trackRegex.length === 2 ? trackRegex[1] : '';
        if (!albumId) {
            return;
        }
        link = `link=${link},albumId=${albumId},trackId=${trackId}`;
    }

    return { name: name.trim(), type: musicPlatform, content: link }
}

export function constructMusicPlatformLink(file) {
    if (!file.location) return;

    if (file.type === "BANDCAMP") {
        const regex = new RegExp(/link=([^,]+),albumId=(\d+),trackId=(\d*|\b)/);
        const match = regex.exec(file.location);
        if (!match) return;
        return match[1];
    }
    return file.location;
}