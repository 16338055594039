import React, {useState} from "react";
import { createDefaultSelectedProgramSkills } from "./AboutEditForm.library";
import * as Yup from "yup";
import Translate from "../../../common/translate/Translate";
import {Field, Form, Formik} from "formik";
import AboutEditHeader from "../about.edit.header/AboutEditHeader";
import TextInput from "../../../common/text.input/TextInput";
import {createOnBlurFunction} from "../../../../services/formik/formik";
import {ParticipantInstagramIcon, ParticipantLinkedInIcon, ParticipantWebsiteIcon} from "../../../common/svg/svg";
import "./AboutEditForm.css"
import ProfileTextArea from "../../profile.text.area/ProfileTextArea";
import FileUpload from "../../../common/file.upload/FileUpload";
import AppLinks from "../../../../resources/data/links";
import FileThumbnail from "../../../common/file.thumbnail/FileThumbnail";
import {trackPromise} from "react-promise-tracker";
import FormikUnsavedChangesPrompt from "../../../common/formik.unsaved.changes.prompt/FormikUnsavedChangesPrompt";
import ButtonSaveCancel from "../../../common/button.save.cancel/ButtonSaveCancel";
import CheckboxGroup from "../../../common/checkbox.group/CheckboxGroup";
import { cloneDeep } from "lodash";

export default function AboutEditForm(props) {
    const [selectedProgramSkills, updateSelectedProgramSkills] = useState(createDefaultSelectedProgramSkills(props.formData.availableProgramSkills, props.formData.selectedProgramSkills));

    function getSelectedValues(programId) {
        const targetProgram = selectedProgramSkills.find(programSkills => programSkills.programId === programId);
        return targetProgram?.skills || [];
    }

    function addProgramSkill(programId, skillId) {
        const updatedProgramSkills = cloneDeep(selectedProgramSkills);
        const targetProgram = updatedProgramSkills.find(programSkills => programSkills.programId === programId);
        targetProgram.skills.push(skillId);
        updateSelectedProgramSkills(updatedProgramSkills);
    }

    function removeProgramSkill(programId, skillId) {
        const updatedProgramSkills = cloneDeep(selectedProgramSkills);
        const targetProgram = updatedProgramSkills.find(programSkills => programSkills.programId === programId);
        const index = targetProgram.skills.indexOf(skillId);
        targetProgram.skills.splice(index, 1);
        updateSelectedProgramSkills(updatedProgramSkills);
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={props.formData}
            validationSchema={Yup.object().shape({
                name: Yup.string()
                    .required(<Translate label={"validation_message_required"}/>),
                email: Yup.string()
                    .required(<Translate label={"validation_message_required"}/>)
                    .email(<Translate label={"validation_message_invalid_email"}/>)
            })}
            onSubmit={(values, actions) => {
                trackPromise(
                    props.onSubmit(values, selectedProgramSkills).finally(() => {
                        actions.setSubmitting(false);
                    })
                )
            }}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {
                formProps => (
                    <Form className="about-edit-form">
                        <AboutEditHeader
                            isOpenToCollaborate={formProps.values.openToCollaborate}
                            onIsOpenToCollaborateChange={(value) => {formProps.setFieldValue('openToCollaborate', value)}}
                            disabled={formProps.isSubmitting}
                            canEditOpenToCollaborate={props.canEditOpenToCollaborate}
                            backTo={props.backTo}
                        />
                        <div className="about-edit-fields">
                            <div className="about-edit-basic-info">
                                <div className="about-edit-basic-info-heading"><Translate label="about_basic_info_heading_label"/></div>
                                <div>
                                    <Field name="name">
                                        {
                                            fieldProps => (
                                                <TextInput
                                                    {...fieldProps.field}
                                                    id="about-edit-name"
                                                    label="name_label"
                                                    placeholder="name_label_placeholder"
                                                    value={formProps.values.name}
                                                    hasError={formProps.errors.name}
                                                    errorMessage={formProps.errors.name}
                                                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                    disabled={formProps.isSubmitting}
                                                    formikProps={formProps}
                                                />
                                            )
                                        }
                                    </Field>
                                    <Field name="email">
                                        {
                                            fieldProps => (
                                                <TextInput
                                                    {...fieldProps.field}
                                                    id="about-edit-email"
                                                    label="email_label"
                                                    placeholder="email_label_placeholder"
                                                    value={formProps.values.email}
                                                    hasError={formProps.errors.email}
                                                    errorMessage={formProps.errors.email}
                                                    onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                    disabled={formProps.isSubmitting}
                                                    formikProps={formProps}
                                                />
                                            )
                                        }
                                    </Field>
                                    <TextInput
                                        id="about-edit-phone-number"
                                        name="phoneNumber"
                                        label="phone_number_label"
                                        placeholder="phone_number_placeholder"
                                        value={formProps.values.phoneNumber}
                                        onChange={formProps.handleChange}
                                        disabled={formProps.isSubmitting}
                                        formikProps={formProps}
                                    />
                                </div>
                            </div>
                            <div className="about-edit-links">
                                <div className="about-edit-links-heading"><Translate label="about_personal_links_heading_label"/></div>
                                <div>
                                    <ParticipantWebsiteIcon/>
                                    <TextInput
                                        id="about-edit-link-website"
                                        name="website"
                                        label="about_link_website_label"
                                        placeholder="link_placeholder"
                                        value={formProps.values.website}
                                        onChange={formProps.handleChange}
                                        disabled={formProps.isSubmitting}
                                        formikProps={formProps}
                                    />
                                    <ParticipantInstagramIcon/>
                                    <TextInput
                                        id="about-edit-link-instagram"
                                        name="instagram"
                                        label="about_link_instagram_label"
                                        placeholder="link_placeholder"
                                        value={formProps.values.instagram}
                                        onChange={formProps.handleChange}
                                        disabled={formProps.isSubmitting}
                                        formikProps={formProps}
                                    />
                                    <ParticipantLinkedInIcon/>
                                    <TextInput
                                        id="about-edit-link-linkedin"
                                        name="linkedin"
                                        label="about_link_linkedin_label"
                                        placeholder="link_placeholder"
                                        value={formProps.values.linkedin}
                                        onChange={formProps.handleChange}
                                        disabled={formProps.isSubmitting}
                                        formikProps={formProps}
                                    />
                                </div>
                            </div>
                            <ProfileTextArea
                                id="about_personal_info"
                                name="personalInfo"
                                label="about_personal_info_label"
                                placeholder="personal_info_placeholder"
                                value={formProps.values.personalInfo}
                                onChange={formProps.handleChange}
                                disabled={formProps.isSubmitting}
                                className={"about-edit-personal-info"}
                                infoLink={AppLinks.help_personal_info}
                                formikProps={formProps}
                            />
                            <ProfileTextArea
                                id="about_artist_statement"
                                name="artistStatement"
                                label="about_artist_statement_label"
                                placeholder="artist_statement_placeholder"
                                value={formProps.values.artistStatement}
                                onChange={formProps.handleChange}
                                disabled={formProps.isSubmitting}
                                className={"about-edit-artist-statement"}
                                infoLink={AppLinks.help_artist_statement}
                                formikProps={formProps}
                            />
                            <div className={"about-edit-user-image"}>
                                <div className="about-edit-user-image-heading"><Translate label="about_user_image_heading_label"/></div>
                                <FileUpload
                                    fileUploadItem={FileThumbnail}
                                    buttonClassName="about-edit-file-upload-button"
                                    id={"about_user_image"}
                                    name={"userImage"}
                                    accept={"image/*"}
                                    buttonLabel={"about_upload_profile_image"}
                                    files={formProps.values.userImageFiles}
                                    onChange={(file) => {formProps.setFieldValue("userImageFiles", file)}}
                                    onRemoveFile={() => {formProps.setFieldValue("userImageFiles", [])}}
                                />
                            </div>
                            <div className={"about-edit-cv"}>
                                <div><Translate label={"about_cv_heading_label"}/></div>
                                <FileUpload
                                    fileUploadItem={FileThumbnail}
                                    buttonClassName={"about-edit-file-upload-button"}
                                    buttonLabel={"about_upload_cv"}
                                    id={"about_cv"}
                                    name={"cv"}
                                    accept={"application/pdf"}
                                    files={formProps.values.cv}
                                    onChange={(file) => {formProps.setFieldValue("cv", file)}}
                                    onRemoveFile={() => {formProps.setFieldValue("cv", [])}}
                                />
                            </div>
                            {
                                formProps.values.availableProgramSkills.length > 0 &&
                                <div className={"about-edit-program-skills"}>
                                    <div><Translate label={"about_program_skills_heading_label"}/></div>
                                    {
                                        formProps.values.availableProgramSkills.map(programSkill => (
                                            <CheckboxGroup
                                                label={programSkill.programName}
                                                values={getSelectedValues(programSkill.programId)}
                                                options={programSkill.skills.options || []}
                                                onChange={(value, event) => {
                                                    if (event.target.checked) {
                                                        addProgramSkill(programSkill.programId, value);
                                                    } else {
                                                        removeProgramSkill(programSkill.programId, value);
                                                    }
                                                }}
                                            />
                                        ))
                                    }
                                </div>
                            }
                        </div>
                        <ButtonSaveCancel className={"about-edit-buttons"} onCancel={props.onCancel} disabled={formProps.isSubmitting}/>
                        <FormikUnsavedChangesPrompt formikProps={formProps}/>
                    </Form>
                )
            }
        </Formik>
    )
}
