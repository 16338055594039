export const ELLIPSIS_SYMBOL = '...';

function getStartAndEndSliceIndex(numberOfPages, currentPage, pageStep, totalAmountOfPagesInWindow) {
    const currentIndexOfPage = currentPage - 1;

    let startSlicePage = currentIndexOfPage - pageStep;
    // we use the current page, because slice is exclusive and doesn't include the last index so we need the index + 1 (currentPage)
    let endSlicePage = currentPage + pageStep;
    if (startSlicePage < 0) {
        startSlicePage = 0;
        endSlicePage = totalAmountOfPagesInWindow;
    } else if (endSlicePage > numberOfPages) {
        endSlicePage = numberOfPages;
        startSlicePage = numberOfPages - totalAmountOfPagesInWindow;
    }
    return [startSlicePage, endSlicePage];
}

function addEllipsis(pages, startSlicePage, endSlicePage) {
    const newPages = pages.slice(startSlicePage, endSlicePage);
    if (startSlicePage !== 0) {
        newPages.unshift(ELLIPSIS_SYMBOL);
        newPages.unshift(1);
    }

    if (endSlicePage !== pages.length) {
        newPages.push(ELLIPSIS_SYMBOL);
        newPages.push(pages[pages.length - 1]);
    }

    return newPages;
}

export function createPages(numberOfPages, currentPage, pageStep) {
    const pages = [];
    for (let i = 0; i < numberOfPages; i++) {
        pages[i] = i + 1;
    }

    const totalAmountOfPagesInWindow = (pageStep * 2) + 1;

    if (pages.length <= totalAmountOfPagesInWindow) {
        return pages;
    }

    const [startSlicePage, endSlicePage] = getStartAndEndSliceIndex(numberOfPages, currentPage, pageStep, totalAmountOfPagesInWindow);
    return addEllipsis(pages, startSlicePage, endSlicePage);
}