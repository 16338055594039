import moment from "moment";

export function formatLocalDate(dateString) {
    const date = getDateValue(dateString);
    const options = {month: 'long', day: 'numeric', year: 'numeric'}
    return date.toLocaleDateString("en-US", options);
}

export function formatLocalDateTime(dateTimeString) {
    const datetime = getDateValue(dateTimeString);
    const dateOptions = {month: 'long', day: 'numeric', year: 'numeric'};
    const timeOptions = {hour: 'numeric', minute:'numeric'};
    return datetime.toLocaleDateString("en-US", dateOptions) + " " + datetime.toLocaleTimeString('en', timeOptions);
}

export function setEndOfDay(date) {
    date.setHours(23, 59, 59, 999);
}

export function getDateValue(value) {
    if (!value) {
        return moment().toDate();
    }
    return moment(value).toDate();
}