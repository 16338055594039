import React from "react";
import "./ProfileAddEntry.css";
import {Link} from "react-router-dom";
import {AddItemIcon} from "../../common/svg/svg";
import Translate from "../../common/translate/Translate";

export default function ProfileAddEntry(props) {

    function getClassName() {
        let className = "profile-add-entry";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    return (
        <>
            {
                props.canAddEntry &&
                <div className={getClassName()}>
                    <Link to={props.to}>
                        <AddItemIcon/>
                        <div>
                            <Translate label={props.label}/>
                        </div>
                    </Link>
                </div>
            }
        </>
    )
}