import React from "react";
import {CommentsIcon, NoCommentsIcon} from "../../../common/svg/svg";
import "./ListItemComment.css";
import TranslateSingularPlural from "../../../common/translate.singular.plural/TranslateSingularPlural";

export default function ListItemComment(props) {
    return (
        <div className={"journal-item-comment"}>
            {props.commentCount && props.commentCount > 0
                ? <>
                    <div className={"comment-count-text"}>
                        <TranslateSingularPlural amount={props.commentCount}
                                                 singularLabel={"journal_list_comment_label_singular"}
                                                 pluralLabel={"journal_list_comment_label_plural"}
                                                 params={{amount: props.commentCount}}/>
                    </div>
                    <CommentsIcon/>
                </>
                : <NoCommentsIcon/>
            }
        </div>
    )
}