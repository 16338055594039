import React from "react";
import "./BackLink.css";
import {LeftArrow} from "../svg/svg";
import Translate from "../translate/Translate";
import {Link} from "react-router-dom";

export default function BackLink(props) {
    return (
        <div className={"back-link"}>
            <Link to={props.to}>
                <LeftArrow />
                <div>
                    <Translate label={"default_back_link_text"}/>
                </div>
            </Link>
        </div>
    )
}