import React from "react";
import "./UserManagementtem.css";
import ListItemRectangular from "../../common/list/list.item.rectangular/ListItemRectangular";

export default function UserManagementItem(props) {
    return (
        <ListItemRectangular
            className={"user-management-item"}
            onClick={() => {props.onItemClick(props.id)}}
        >
            <div className={"user-management-item-name"}>
                {props.name}
            </div>
            <div className={"user-management-item-body"}>
                <div className={"user-management-item-email"}>
                    {props.email}
                </div>
            </div>
        </ListItemRectangular>
    )
}