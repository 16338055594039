import React from "react";
import "./ProjectsViewHeader.css";
import BackLink from "../../../common/back.link/BackLink";
import ProfileHeaderText from "../../profile.header.text/ProfileHeaderText";
import EditDeleteLink from "../../../common/edit.delete.link/EditDeleteLink";

export default function ProjectsViewHeader(props) {
    return (
        <div className={"projects-view-header"}>
            <BackLink to={props.backTo}/>
            <ProfileHeaderText className={"projects-view-header-text"} label={props.titleLabel } params={props.titleLabelParams}/>
            {
                props.canEdit &&
                <EditDeleteLink className={"projects-view-header-edit-delete"} editTo={props.editTo} onDelete={props.onDelete}/>
            }
        </div>
    )
}