import React from "react";
import "./SkillsStatisticsShowMore.css";
import Translate from "../../../translate/Translate";
import {ChevronDown, ChevronUp} from "../../../svg/svg";
import {onEnterClickCreator} from "../../../../../services/util/util";

export default function SkillsStatisticsShowMore(props) {

    const onKeyDown = onEnterClickCreator(props.onClick);

    function getClassName() {
        let className = "skills-statistics-show-more";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()} onClick={props.onClick} tabIndex={0} onKeyDown={onKeyDown}>
            {
                props.showMore ?
                    <>
                        <ChevronDown/>
                        <div>
                            <Translate label={props.moreLabel}/>
                        </div>
                    </>
                    :
                    <>
                        <ChevronUp />
                        <div>
                            <Translate label={props.lessLabel}/>
                        </div>
                    </>
            }
        </div>
    )
}