import React from "react";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../store/rootReducer";
import BackLink from "../../../common/back.link/BackLink";
import ProfileHeaderText from "../../profile.header.text/ProfileHeaderText";
import { translate } from "../../../common/translate/Translate.library";
import CopyLink from "../../../common/copy.link/CopyLink";
import EditDeleteLink from "../../../common/edit.delete.link/EditDeleteLink";
import "./PortfolioViewHeader.css";

export default function PortfolioViewHeader(props) {
    const language = useSelector(getLanguage);

    return (
        <div className={"portfolio-view-header"}>
            <BackLink to={props.backTo}/>
            <div className={"portfolio-view-header-text"}>
                <ProfileHeaderText label={props.titleLabel} params={props.titleLabelParams}/>
                <div className={"portfolio-name"}>{props.name} {props.isDraft && translate("portfolio_draft_indicator", language)}</div>
            </div>
            {
                props.canEdit &&
                    <div className={"portfolio-action-buttons"}>
                        <CopyLink onClick={props.onCopy}/>
                        <EditDeleteLink editTo={props.editTo} onDelete={props.onDelete}/>
                    </div>
            }
        </div>
    )
}