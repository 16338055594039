import React from "react";
import ProfileHeaderText from "../../profile.header.text/ProfileHeaderText";
import "./PortfolioEditHeader.css";
import BackLink from "../../../common/back.link/BackLink";
import InfoLink from "../../../common/info.link/InfoLink";
import AppLinks from "../../../../resources/data/links";

export default function PortfolioEditHeader(props) {
  return (
    <div className={"portfolio-edit-header"}>
      <BackLink to={props.backTo} />
      <ProfileHeaderText
        className={"portfolio-edit-header-text"}
        label={props.titleLabel}
        params={props.titleLabelParams}
      />
      <InfoLink to={AppLinks.help_portfolio_edit} />
    </div>
  );
}