import React, {useState} from "react";
import TextKeyword from "../../common/text.keyword/TextKeyword";
import SearchButton from "../../common/search.button/SearchButton";
import "./SearchPublicParams.css";
import ListParamsContainer from "../../common/list/list.params.container/ListParamsContainer";
import {onEnterClickCreator} from "../../../services/util/util";

export default function SearchPublicParams(props) {
    const [areFiltersHidden, updateAreFiltersHidden] = useState(false);

    const onEnterClick = onEnterClickCreator(props.onSearch);

    return (
        <ListParamsContainer
            isHidden={areFiltersHidden}
            onHiddenClick={() => {
                updateAreFiltersHidden(!areFiltersHidden)
            }}
            showLabel={"search_view_show_search_filters"}
            hideLabel={"search_view_hide_search_filters"}
            className={"search-params-public"}
            onKeyDown={onEnterClick}
        >
            <TextKeyword
                id={"search-keywords"}
                label={"search_keywords_label"}
                placeholder={"search_keywords_label_placeholder"}
                onChange={(event) => {
                    props.onKeywordsChange(event.target.value)
                }}
                onClear={props.onKeywordClear}
                value={props.keywords}
            />
            <SearchButton onClick={props.onSearch}/>
        </ListParamsContainer>
    );
}
