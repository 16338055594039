import React from "react";
import Translate from "../../common/translate/Translate";
import { formatLocalDateTime } from "../../../services/date/date";
import AddToCalendar from "../program.events.calendar/AddToCalendar";
import "./ViewProgramEvents.css"

export default function ViewProgramEvents(props) {
    
    function getEventDetailsClassName(index) {
        let className = "view-program-event-details";
        if (index !== 0) {
            className += " not-first-event";
        }
        return className;
    }

    return (
        <div className="view-program-events">
            <div className="view-program-events-header">
                <Translate label="program_events_header_title_label" />
                <Translate label="program_events_header_start_time_label" />
                <Translate label="program_events_header_end_time_label" />
                <Translate label="program_events_header_location_label" />
                <Translate label="program_events_header_description_label" />
            </div>
            {
                props.events.map((event, index) => (
                    <div className={getEventDetailsClassName(index)}>
                        <div className="program-event-title">{event.title}</div>
                        <div>{formatLocalDateTime(event.startTime)}</div>
                        <div>{formatLocalDateTime(event.endTime)}</div>
                        <div className="program-event-location">{event.location}</div>
                        <div className="program-event-description">{event.description}</div>
                        <div className="program-event-add-to-calender">
                            <AddToCalendar
                                title={event.title}
                                location={event.location}
                                description={event.description}
                                startTime={event.startTime}
                                endTime={event.endTime}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    );
}