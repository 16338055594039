import React from "react";
import './SearchItemButtons.css';
import PeerButton from "../../common/peer.button/PeerButton";

export default function SearchItemButton(props) {
    return (
        <div className="search-item-buttons">
            <PeerButton
                isPeer={props.isPeer}
                onPeerRemove={props.onPeerRemove}
                disableRemovePeer={props.disableRemovePeer}
                onPeerAdd={props.onPeerAdd}
                disableAddPeer={props.disableAddPeer}
            />
        </div>
    )
}