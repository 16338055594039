import React, {useCallback, useEffect, useState} from "react";
import "./PortfolioView.css";
import PortfolioViewHeader from "../../../components/profile/portfolio/portfolio.view.header/PortfolioViewHeader";
import {createViewPath, RouteConstants} from "../../../App.library";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../../../services/ajax/ajax";
import {useDispatch, useSelector} from "react-redux";
import PortfolioViewSeriesItem from "../../../components/profile/portfolio/portfolio.view.series.item/PortfolioViewSeriesItem";
import {makeAlert} from "../../../components/common/alert/Alert";
import {getLanguage} from "../../../store/rootReducer";

export default function PortfolioView(props) {

    const dispatcher = useDispatch();
    const language = useSelector(getLanguage);
    const [portfolioData, updatePortfolioData] = useState({});

    function getPortfolioSeries() {
        if (portfolioData.series) {
            return portfolioData.series;
        }
        return [];
    }

    async function deletePortfolio() {
        await makeJSONPostRequest(makeUrl(API_PATHS.PORTFOLIO_DELETE, {portfolioId: props.match.params.portfolioId}), dispatcher);
        props.history.push(createViewPath(RouteConstants.PORTFOLIO, {userId: props.match.params.userId}));
    }

    function copyPortfolio() {
        makeJSONPostRequest(makeUrl(API_PATHS.PORTFOLIO_COPY, {portfolioId: props.match.params.portfolioId}), dispatcher)
            .then((response) => {
                props.history.push(createViewPath(RouteConstants.PORTFOLIO_EDIT, {userId: props.match.params.userId, portfolioId: response.body.portfolioId}));
            });
    }

    function onDeletePortfolio() {
        makeAlert(
            language,
            'portfolio_delete_modal_title',
            'portfolio_delete_alert_body',
            deletePortfolio
        );
    }

    const refreshPortfolioData = useCallback(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PORTFOLIO_GET, {portfolioId: props.match.params.portfolioId}), dispatcher)
            .then((response) => {
                updatePortfolioData(response.body);
            });
    }, [props.match.params.portfolioId, dispatcher]);

    useEffect(() => {
        refreshPortfolioData()
    }, [refreshPortfolioData]);


    return (
        Object.keys(portfolioData).length > 0 &&
        <div className="portfolio-view-container">
            <PortfolioViewHeader
                name={portfolioData.name}
                titleLabel={"portfolio_title"}
                backTo={createViewPath(RouteConstants.PORTFOLIO, {userId: props.match.params.userId})}
                canEdit={portfolioData.canEdit}
                onCopy={copyPortfolio}
                editTo={createViewPath(RouteConstants.PORTFOLIO_EDIT, {userId: props.match.params.userId, portfolioId: props.match.params.portfolioId})}
                onDelete={onDeletePortfolio}
                isDraft={portfolioData.draft}
            />
            <div className={"portfolio-view-description"}>
                <p>{portfolioData.description}</p>
            </div>
            <div className={"portfolio-view-series"}>
                {
                    getPortfolioSeries().map((item) => {
                        return <PortfolioViewSeriesItem
                            name={item.name}
                            files={item.files}
                            id={item.id}
                            userId={props.match.params.userId}
                            portfolioId={props.match.params.portfolioId}
                            canEditFiles={portfolioData.canEdit}
                            canMarkFilesSensitive={portfolioData.canMarkFilesSensitive}
                            onFileViewerClose={refreshPortfolioData}
                            key={item.id}/>
                    })
                }
            </div>
        </div>
    )
}