import jwt_decode from 'jwt-decode';

const TOKEN_LOCAL_STORAGE_KEY = 'Authorization';

export function setToken(response) {
    localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, response.headers.get('Authorization'));
}

export function getToken() {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
    if (!token) {
        return;
    }
    return token;
}

export function logOut() {
    localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
}

export function isAuthenticated() {
    return !!getToken();
}

function getDecodedToken() {
    if (!isAuthenticated()) {
        return undefined;
    }
    return jwt_decode(getToken());
}

export function hasPrivilege(privilegeName) {
    const tokenDecoded = getDecodedToken();
    if (tokenDecoded === undefined) {
        return false;
    }
    const privileges = tokenDecoded.userPrivileges.map(authority => authority.authority);
    return privileges && privileges.includes(privilegeName);
}

export function getUserId() {
    const tokenDecoded = getDecodedToken();
    if (tokenDecoded === undefined) {
        return undefined;
    }
    return tokenDecoded.userId;
}