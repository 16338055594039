import {displayError} from "../../components/message.panel/ducks/actions";
import {logOutAction} from "../auth/ducks/actions";

export async function handleResponseNotOkay(response, dispatcher) {
    if (response.status === 403) {
        dispatcher(logOutAction());
        return;
    }
    const errorText = await response.text();
    try {
        const json = JSON.parse(errorText);
        dispatcher(displayError(json.errors));
    } catch (error) {
        dispatcher(displayError([{label: errorText}]));
    }
}

export function handleNetworkError(error, dispatcher) {
    dispatcher(displayError([{
        label: error
    }]));
}