import React from "react";
import "./ReportsDownload.css";
import TextDatePicker from "../../common/text.date.picker/TextDatePicker";
import * as Yup from 'yup';
import {Field, Form, Formik} from "formik";
import {trackPromise} from "react-promise-tracker";
import {createOnBlurFunction} from "../../../services/formik/formik";
import TextSelect from "../../common/text.select/TextSelect";
import ButtonAccept from "../../common/button.accept/ButtonAccept";
import Translate from "../../common/translate/Translate";
import {getDateValue, setEndOfDay} from "../../../services/date/date";

export default function ReportsDownload(props) {
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{startDate: null, endDate: null, role: ''}}
            validationSchema={Yup.object().shape({
                startDate: Yup.date().nullable(),
                endDate: Yup.date().nullable(),
                role: Yup.string()
            })}
            onSubmit={(values, actions) => {
                trackPromise(
                    props.onSubmit(values)
                        .finally(() => {
                            actions.setSubmitting(false);
                        })
                );
            }}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {
                formProps => (
                    <Form
                        className={"reports-download"}
                    >
                        <div className={"reports-download-section-label"}>
                            <h2><Translate label={props.sectionLabel}/></h2>
                        </div>
                        <div className={"reports-download-input"}>
                            <Field name={"startDate"}>
                                {
                                    fieldProps => (
                                        <TextDatePicker
                                            {...fieldProps.field}
                                            selected={fieldProps.field.value && getDateValue(fieldProps.field.value)}
                                            id={"startDate"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name={"startDate"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"reports_start_date_label"}
                                            formikProps={formProps}
                                            maxDate={getDateValue()}
                                            onChange={(value) => {
                                                formProps.setFieldValue(fieldProps.field.name, value)
                                            }}
                                        />
                                    )
                                }
                            </Field>
                            <Field name={"endDate"}>
                                {
                                    fieldProps => (
                                        <TextDatePicker
                                            {...fieldProps.field}
                                            selected={fieldProps.field.value && getDateValue(fieldProps.field.value)}
                                            id={"endDate"}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            name={"endDate"}
                                            onBlur={createOnBlurFunction(formProps, fieldProps)}
                                            disabled={formProps.isSubmitting}
                                            label={"reports_end_date_label"}
                                            formikProps={formProps}
                                            maxDate={getDateValue()}
                                            onChange={(value) => {
                                                if (value) {
                                                    setEndOfDay(value);
                                                }
                                                formProps.setFieldValue(fieldProps.field.name, value)
                                            }}
                                        />
                                    )
                                }
                            </Field>
                            <Field name={"role"}>
                                {
                                    fieldProps => (
                                        <TextSelect
                                            {...fieldProps.field}
                                            options={props.roles || []}
                                            disabled={formProps.isSubmitting}
                                            hasError={fieldProps.meta.error}
                                            errorMessage={fieldProps.meta.error}
                                            id={"role"}
                                            name={"role"}
                                            label={"reports_role_label"}
                                            placeholder={"reports_role_placeholder"}
                                            formikProps={formProps}
                                        />
                                    )
                                }
                            </Field>
                            <div
                                className={"reports-download-button-container"}
                            >
                                <ButtonAccept
                                    disabled={formProps.isSubmitting}
                                    label={"reports_download_label"}
                                />
                            </div>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
}