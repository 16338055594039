import React, {useState} from "react";
import "./SkillsStatistics.css";
import TextDatePicker from "../../text.date.picker/TextDatePicker";
import ChevronShowHide from "../../chevron.show.hide/ChevronShowHide";
import SkillsStatisticsRow from "./skills.statistics.row/SkillsStatisticsRow";
import SkillsStatisticsShowMore from "./skills.statistics.show.more/SkillsStatisticsShowMore";
import SkillsStatisticsPeriodHeader
    from "./skills.statistics.period.header/SkillsStatisticsPeriodHeader";
import TextSelect from "../../text.select/TextSelect";
import {StatisticsIcon} from "../../svg/svg";
import {getDateValue} from "../../../../services/date/date";

export default function SkillsStatistics(props) {
    const [isHidden, updateIsHidden] = useState(false);
    const [showMore, updateShowMore] = useState(true);
    const INITIAL_TAGS_LENGTH = 8;

    function getTags() {
        if (props.skillTags) {
            return props.skillTags;
        }
        return [];
    }

    function listTags(tags, linearGradient) {
        return (
            tags.map((tag, index) => {
                if (index === tags.length - 1) {
                    return <SkillsStatisticsRow tag={tag} key={index} showLinearGradient={linearGradient}/>
                }
                return <SkillsStatisticsRow tag={tag} key={index}/>
            })
        );
    }

    function getTagRows() {
        const tags = getTags();
        if (tags.length <= INITIAL_TAGS_LENGTH || (tags.length > INITIAL_TAGS_LENGTH && !showMore)) {
            return listTags(tags);
        } else if (tags.length > INITIAL_TAGS_LENGTH && showMore) {
            return listTags(tags.slice(0, INITIAL_TAGS_LENGTH), true)
        }
    }

    function getDefaultSelectedEndDate() {
        if (!props.periodEndDate) {
            return getDateValue(props.maxPeriodDate);
        }
        return getDateValue(props.periodEndDate);
    }

    return (
        <div className={"skills-statistics-container"}>
            <ChevronShowHide
                isHidden={isHidden}
                onHiddenClick={() => {
                    updateIsHidden(!isHidden)
                }}
                showLabel={"skills_show_stats"}
                hideLabel={"skills_hide_stats"}
                className={"show-hide"}
            />
            {
                !isHidden &&
                <div className={"skills-statistics"}>
                    <div className={"skills-statistics-parameters"}>
                        <TextSelect
                            id={"skills-statistics-period"}
                            label={"skills_statistics_period"}
                            options={props.statisticPeriodOptions || []}
                            onChange={props.onStatisticOptionChanged}
                            value={props.statisticPeriodValue}
                            className={"skills-statistics-period"}
                        />
                        <TextDatePicker
                            label={"skills_statistics_end_date"}
                            maxDate={props.maxPeriodDate && getDateValue(props.maxPeriodDate)}
                            minDate={props.minPeriodDate && getDateValue(props.minPeriodDate)}
                            className={"skills-statistics-end-date"}
                            onChange={props.onPeriodEndDateChanged}
                            selected={getDefaultSelectedEndDate()}
                        />
                        <div className={"skills-statistics-icon"}>
                            <StatisticsIcon/>
                        </div>
                    </div>

                    <div className={"skills-statistics-graph"}>
                        <SkillsStatisticsPeriodHeader
                            periods={props.periods}
                            currentPeriod={props.statisticPeriodValue}
                            tagsLength={getTags().length}
                        />
                        {
                            getTagRows()
                        }
                    </div>
                    {
                        getTags().length > INITIAL_TAGS_LENGTH &&
                        <div className={"show-more"}>
                            <SkillsStatisticsShowMore
                                showMore={showMore}
                                moreLabel={"skills_more_stats"}
                                lessLabel={"skills_less_stats"}
                                onClick={() => {
                                    updateShowMore(!showMore);
                                }}
                            />
                        </div>
                    }
                </div>
            }
        </div>
    )
}