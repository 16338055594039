import React from "react";
import {hasPrivilege} from "../../../services/auth/auth";
import {PrivilegeConstants, RouteConstants} from "../../../App.library";
import "./OpportunitiesSubmitLink.css";
import {Link} from "react-router-dom";
import {SubmitOpportunityIcon} from "../../common/svg/svg";
import Translate from "../../common/translate/Translate";

export default function OpportunitiesSubmitLink(props) {

    function SubmitLink(props) {
        return(
            <Link to={props.to}>
                <SubmitOpportunityIcon />
                <div>
                    <Translate label={props.label} />
                </div>
            </Link>
        )
    }

    return (
        <div className={"opportunities-submit-link"}>
            {
                hasPrivilege(PrivilegeConstants.CAN_CREATE_OPPORTUNITY)
                    ? <SubmitLink to={RouteConstants.OPPORTUNITIES_CREATE} label={"opportunities_create_opportunity"}/>
                    : <SubmitLink to={RouteConstants.OPPORTUNITIES_SUBMIT} label={"opportunities_submit_opportunity"}/>
            }
        </div>
    )
}