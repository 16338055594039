import React from "react";
import "./CopyLink.css";
import {onEnterClickCreator} from "../../../services/util/util";
import {CopyItemIcon} from "../svg/svg";
import Translate from "../translate/Translate";

export default function CopyLink(props) {

    function getClassName() {
        let className = "copy-link";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    const onKeyPress = onEnterClickCreator(props.onClick);

    return (
        <div className={getClassName()} onClick={props.onClick} tabIndex={0} onKeyPress={onKeyPress}>
            <CopyItemIcon/>
            <div>
                <Translate label="copy_label"/>
            </div>
        </div>
    )
}