import React from "react";
import "./ListResult.css";
import Translate from "../translate/Translate";
import {getCurrentResultNumber, getNextId, getPreviousId} from "./ListResult.library";
import {LeftArrow} from "../svg/svg";
import {RightArrow} from "../svg/svg";

export default function ListResult(props) {
    const currentId = parseInt(props.currentId);
    const currentResultNumber = getCurrentResultNumber(currentId, getResultIds());
    const FIRST_PAGE = 1;

    function getResultIds() {
        return props.resultIds || [];
    }

    function isLeftArrowEnabled() {
        return currentResultNumber > FIRST_PAGE;
    }

    function isRightArrowEnabled() {
        return currentResultNumber < getResultIds().length;
    }

    function onBackClick() {
        if (isLeftArrowEnabled()) {
            props.onBackClick(getPreviousId(currentId, getResultIds()));
        }
    }

    function onForwardClick() {
        if (isRightArrowEnabled()) {
            props.onForwardClick(getNextId(currentId, getResultIds()));
        }
    }

    function getClassName() {
        let className = "list-result";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <div className={isLeftArrowEnabled() ? 'enabled' : ''} onClick={onBackClick} tabIndex={0}>
                <LeftArrow/>
            </div>
            <Translate
                label={props.label}
                params={{
                    currentResult: currentResultNumber,
                    totalResults: getResultIds().length
                }}
            />
            <div className={ isRightArrowEnabled() ? 'enabled' : ''} onClick={onForwardClick} tabIndex={0}>
                <RightArrow/>
            </div>
        </div>
    )
}