import React, {useState} from 'react';
import './SearchItem.css';
import UserImage from "../../user.image/UserImage";
import SearchItemNameStatement from "../search.item.name.statement/SearchItemNameStatement";
import SearchItemButton from "../search.item.buttons/SearchItemButtons";
import ListItemRounded from "../../common/list/list.item.rounded/ListItemRounded";
import {useUpdatePeer} from "../../common/peer.button/hooks/useUpdatePeer";

export default function SearchItem(props) {
    const [isPeer, updateIsPeer] = useState(props.peer);
    const [disableAddPeer, disableRemovePeer, onPeerAdd, onPeerRemove] =
        useUpdatePeer(updateIsPeer, props.id);

    return (
        <ListItemRounded className={"search-item"}
            onClick={() => { props.onItemClick(props.id)}}
        >
            <UserImage image={props.userImage} alt={"Mentor user image for " + props.name} name={props.name}/>
            <SearchItemNameStatement name={props.name} statement={props.statement} isPeer={props.isPeer}/>
            {
                props.canBePeer &&
                    <SearchItemButton
                        isPeer={isPeer}
                        onPeerAdd={onPeerAdd}
                        onPeerRemove={onPeerRemove}
                        disableAddPeer={disableAddPeer}
                        disableRemovePeer={disableRemovePeer}
                    />
            }
        </ListItemRounded>
    )
}