import React from "react";
import "./ProfileTextArea.css";
import TextArea from "../../common/text.area/TextArea";

export default function ProfileTextArea(props) {
    const {className, ...rest} = props;

    function getClassName() {
        let className = "profile-text-area";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <TextArea
                {...rest}
            />
        </div>
    )
}