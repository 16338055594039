import RegistrationLoginLayout from "../../components/layouts/registration.login/RegistrationLoginLayout";
import React from "react";
import Translate from "../../components/common/translate/Translate";
import "./RegistrationExplanation.css";

export default function RegistrationExplanation(props) {

    return (
        <RegistrationLoginLayout {...props}>
            <div className="registration-explanation-container">
                <h1><Translate label={"registration_explanation_text"}/></h1>
            </div>
            <Translate label={""}/>
        </RegistrationLoginLayout>
    )
}
