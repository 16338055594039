import React from "react";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import Translate from "../../components/common/translate/Translate";
import "./Search.css";
import {isAuthenticated} from "../../services/auth/auth";
import SearchPrivate from "./search.private/SearchPrivate";
import SearchPublic from "./search.public/SearchPublic";

export default function Search(props) {
    return (
        <BasicLayout {...props}>
            <section className="search-container">
                <div>
                    <h1><Translate label={"search_view_heading"}/></h1>
                </div>
                {
                    isAuthenticated() ?
                        <SearchPrivate {...props} /> :
                        <SearchPublic {...props} />
                }
            </section>
        </BasicLayout>
    )
}
