import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import {
  API_PATHS,
  makeJSONGetRequest,
  makeUrl,
} from '../../../services/ajax/ajax'
import { useDispatch } from 'react-redux'

export default function useGetPeersMentors() {
  const [peers, setPeers] = useState([])
  const [mentors, setMentors] = useState([])

  const { userId } = useParams()
  const dispatch = useDispatch()

  useEffect(
    () => {
      if (userId) {
        makeJSONGetRequest(
        makeUrl(API_PATHS.GET_MENTORS_AND_PEERS, { userId }),
        dispatch
      ).then((response) => {
        const { body } = response
        setPeers(body.peers.options)
        setMentors(body.mentors.options)
      })
    }},
    [
      userId, 
      setPeers, 
      setMentors,
      dispatch
    ]
  )

  return { peers, mentors }
}
