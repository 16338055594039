import {createViewPath, RouteConstants} from "../../App.library";
import {getUserId} from "../../services/auth/auth";

export function buildRequestReferenceRequestFromValues(values) {
    let opportunityLink = '';
    if (values.opportunity) {
        opportunityLink = createViewPath(RouteConstants.OPPORTUNITIES_VIEW, {id: values.opportunity});
    }

    let portfolioLink = '';
    if (values.portfolio) {
        portfolioLink = createViewPath(RouteConstants.PORTFOLIO_VIEW, {userId: getUserId(), portfolioId: values.portfolio});
    }

    return {
        mentor: values.mentor,
        opportunityLink,
        portfolioLink,
        dateNeeded: values.dateNeeded,
        contact: values.contact,
        message: values.message,
        includeCV: values.includeCV
    };
}