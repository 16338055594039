import React from "react";
import "./ProjectsListItem.css";
import {formatLocalDateTime} from "../../../../services/date/date";
import SkillTag from "../../../common/skill/skill.tag/SkillTag";
import Translate from "../../../common/translate/Translate";
import ListItemComment from "../../journal/list.item.comment/ListItemComment";
import ListItemViewer from "../../journal/list.item.viewer/ListItemViewer";

export default function ProjectsListItem(props) {
    return (
        <div className={"projects-list-item"} onClick={() => {props.onItemClick(props.id)}}>
            <div className={"projects-list-item-header"}>
                <div className={"projects-date"}>
                    <span>{props.date && formatLocalDateTime(props.date)}</span>
                </div>
            </div>
            <div className={"projects-item-section-content"}>
                <div className={"projects-item-section-name"}>
                    <Translate label={props.sectionName.toLowerCase()}/>
                </div>
                {props.sectionContent}
            </div>
            <div className={"projects-item-skills"}>
                {
                    props.skillTags && props.skillTags.length > 0 &&
                        props.skillTags.map((skill, index) => <SkillTag skillName={skill.name} key={index}/>)
                }
            </div>
            <div style={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <ListItemViewer 
                    viewerCount={props.viewerCount}
                    viewerName={props.viewerName} 
                    viewerUserImage={props.viewerUserImage} 
                />
                <ListItemComment commentCount={props.commentCount}/>
            </div>
        </div>
    )
}