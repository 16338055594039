import React from "react";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../store/rootReducer";
import useGetPeersMentors from "./useGetPeersMentors";
import { translate } from "../../common/translate/Translate.library";
import { Autocomplete } from "@material-ui/lab";
import UserImage from "../../user.image/UserImage";
import { Checkbox, Chip, TextField } from "@material-ui/core";
import "./ProfileShareMentorsPeers.css";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function ProfileShareMentorsPeers(props) {
    const language = useSelector(getLanguage);
    const { peers, mentors } = useGetPeersMentors();
    const options = getOptions();
    const selectedValues = getSelectedValues();

    function getOptions() {
        // Only show a user once even if they are a peer AND a mentor
        const options = mentors.reduce((accumulator, currentMentor) => {
            if (!accumulator.some(user => user.value === currentMentor.value)) {
                accumulator.push(currentMentor);
            }
            return accumulator;
        }, [...peers]);

        options.sort((a, b) => {
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();
            if (labelA < labelB) {
                return -1;
            }
            if (labelA > labelB) {
                return 1;
            }
            return 0;
        });
        return options;
    }

    function getSelectedValues() {
        const selectedUserIds = (props.peerValues || []).concat(props.mentorValues || []);
        return options.filter(possibleValue => selectedUserIds.includes(possibleValue.value))
    }

    function getLabel() {
        return translate(props.projectsPage ? 'profile_share_projects_title' : 'profile_share_journal_entry_title', language);
    }

    function onOptionChange(event, selectedOptions) {
        const selectedPeers = selectedOptions.filter(selectedOption => peers.some(peer => peer.value === selectedOption.value));
        const selectedMentors = selectedOptions.filter(selectedOption => mentors.some(mentor => mentor.value === selectedOption.value));
        props.setPeerValues(selectedPeers.map(peer => peer.value));
        props.setMentorValues(selectedMentors.map(mentor => mentor.value))
    }

    return (
        <div className={"profile-share-mentors-peers"}>
            <Autocomplete
                className="profile-share-mentors-peers-autocomplete"
                multiple
                disableCloseOnSelect
                disableClearable
                getOptionLabel={(option) => option.label}
                options={options}
                value={selectedValues}
                onChange={(event, newValue) => onOptionChange(event, newValue)}
                renderTags={(selectedValues) => 
                    selectedValues.map((value) => {
                        return (
                            <Chip
                                key={value.value}
                                className="profile-share-mentors-peers-selected"
                                avatar={<UserImage key={value.value} image={value.profileImage} name={value.label} alt={"Shared User Image for " + value.label}/>}
                                label={value.label}
                                variant="outlined"
                            />
                        )
                    })
                }
                renderOption={(option, { selected }) => 
                    <React.Fragment>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon/>}
                            checkedIcon={<CheckBoxIcon/>}
                            checked={selected}
                            />
                        {option.label}
                    </React.Fragment>
                }
                renderInput={(params) => 
                    <TextField {...params} variant="outlined" label={getLabel()} />
                }
            />
        </div>
    )
}