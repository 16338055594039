import React from 'react'
import {translate} from "../translate/Translate.library";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";
import "./Input.css"

export default function Input(props) {
    const language = useSelector(state => getLanguage(state));

    function getInputClassName() {
        let className = 'basic-input';
        if (props.hasError) {
            className = className + ' input-error';
        }
        return className;
    }

    return (
        <div className={getInputClassName()}>
            <input
                disabled={props.disabled}
                id={props.id}
                name={props.name}
                type={props.type}
                value={props.value}
                placeholder={translate(props.placeholder, language)}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </div>
    );
}