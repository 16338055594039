import React from "react";
import TextDatePicker from "../../common/text.date.picker/TextDatePicker";
import "./ProfileTextDatePicker.css";

export default function ProfileTextDatePicker(props) {
    const {className, ...rest} = props;

    function getClassName() {
        let className = "profile-text-date-picker";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <TextDatePicker
                {...rest}
            />
        </div>
    )
}