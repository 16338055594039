import React, {useState} from "react";
import "./FileAttachVideo.css";
import Translate from "../../translate/Translate";
import TextInput from "../../text.input/TextInput";
import ButtonAccept from "../../button.accept/ButtonAccept";
import {onEnterClickCreator} from "../../../../services/util/util";
import {getVideoToAttach} from "./FileAttachVideo.library";
import InfoLink from "../../info.link/InfoLink";

export default function FileAttachVideo(props) {

    const [name, updateName] = useState('');
    const [link, updateLink] = useState('');

    function getClassName() {
        let className = "file-attach-video";
        if (props.className) {
            className += ' ' + props.className;
        }
        return className;
    }

    function addVideoFile() {
        const videoFile = getVideoToAttach(name, link)
        if (videoFile) {
            props.onAdd(videoFile);
            updateName('');
            updateLink('');
        }
    }

    const onKeyDown = onEnterClickCreator(addVideoFile);

    return (
        <div className={getClassName()} onKeyDown={onKeyDown}>
            <div className={"file-attach-video-input-title"}>
                <Translate label={"video_file_attach_heading"}/>
                {props.infoLink && <InfoLink to={props.infoLink} style={{ marginLeft: '1rem' }} />}
            </div>
            <div className="file-attach-video-input">
                <TextInput
                    label="video_file_attach_name_label"
                    placeholder="video_file_attach_name_label_placeholder"
                    value={name}
                    disabled={props.isSubmitting}
                    onChange={(event) => updateName(event.target.value)}
                />
                <TextInput
                    label="video_file_attach_link_label"
                    placeholder="video_file_attach_link_label_placeholder"
                    value={link}
                    disabled={props.isSubmitting}
                    onChange={(event) => updateLink(event.target.value)}
                />
                <ButtonAccept
                    className={"add-video-button"}
                    label="video_file_attach_add_button_label"
                    type={"button"}
                    onClick={addVideoFile}
                />
            </div>
        </div>
    )
}