import React from "react";
import "./DatePicker.css";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useSelector} from "react-redux";
import {getLanguage} from "../../../store/rootReducer";
import {translate} from "../translate/Translate.library";

export default function DatePicker(props) {
    const language = useSelector(getLanguage);

    function getInputClassName() {
        let className = 'date-picker';
        if (props.hasError) {
            className = className + ' input-error';
        }
        return className;
    }

    // setting gpuAcceleration to false prevents it from being blurry sometimes on chrome
    return (
        <ReactDatePicker
            {...props}
            placeholderText={translate(props.placeholderText, language)}
            className={getInputClassName()}
            popperModifiers={{
                computeStyle: { gpuAcceleration: false }
            }}
        />
    )
}