import React, {useEffect, useState} from "react";
import "./About.css"
import Translate from "../../components/common/translate/Translate";
import AboutHeader from "../../components/profile/about/about.header/AboutHeader";
import {useDispatch} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../../services/ajax/ajax";
import Label from "../../components/common/label/Label";
import SkillTaggingList from "../../components/common/skill/skill.tagging.list/SkillTaggingList";

export default function About(props) {
    const profileUserId = parseInt(props.match.params.userId);
    const dispatcher = useDispatch();
    const [profileData, updateProfileData] = useState({});
    const [isSubmitting, updateIsSubmitting] = useState(false);

    async function updateCollaborationStatus(collaborationStatus) {
        updateIsSubmitting(true);
        await makeJSONPostRequest(makeUrl(API_PATHS.PROFILE_UPDATE_COLLABORATION_STATUS, {userId: profileUserId}),
            dispatcher, {openToCollaborate: collaborationStatus}).finally(() => {
            updateIsSubmitting(false);
        });
        const updatedProfile = {...profileData, openToCollaborate: collaborationStatus};
        updateProfileData(updatedProfile);
    }

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PROFILE, {userId: profileUserId}), dispatcher)
            .then((response) => {
                updateProfileData(response.body);
            });
    }, [profileUserId, dispatcher]);

    return (
        Object.keys(profileData).length > 0 &&
        <div className="about-container">
            <AboutHeader
                isOpenToCollaborate={profileData.openToCollaborate}
                updateCollaborationStatus={updateCollaborationStatus}
                canEditOpenToCollaborate={profileData.canEditOpenToCollaborate}
                canEdit={profileData.canEdit}
                disabled={isSubmitting}
                canHaveOpenToCollaborate={profileData.canHaveOpenToCollaborate}
            />
            <div className="about-info">
                <div className="about-personal-info">
                    <h2><Translate label="about_personal_info_label"/></h2>
                    <p className={profileData.personalInfo ? "about-personal-info-text" : "default_text"}>
                        {profileData.personalInfo || <Translate label="personal_info_default_text"/>}
                    </p>
                </div>
                <div className="about-artist-statement">
                    <h2><Translate label="about_artist_statement_label"/></h2>
                    <p className={profileData.artistStatement ? "about-artist-statement-text" : "default_text"}>
                        {profileData.artistStatement ? profileData.artistStatement + "”" : <Translate label="artist_statement_default_text"/>}
                    </p>
                </div>
            </div>
            {
                profileData.selectedProgramSkills.length > 0 &&
                <div className="program-skills">
                    <h2><Translate label={"about_program_skills_heading_label"}/></h2>
                    {
                        profileData.selectedProgramSkills.map(programSkill => (
                            <div className="program-skills-display">
                                <Label label={programSkill.programName} />
                                <SkillTaggingList skillTags={programSkill.skillTags} />
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}