import React, {useEffect, useState} from "react";
import "./ProjectsEdit.css";
import {loadUserTags} from "../../journal/Journal.library";
import {useDispatch} from "react-redux";
import ProjectsEditForm from "../../../components/profile/projects/projects.edit.form/ProjectsEditForm";
import {formatLocalDateTime} from "../../../services/date/date";
import {createViewPath, RouteConstants} from "../../../App.library";
import {
    createFileUploadFormData,
    createNewExternallyLinkedFileData,
    processMediaFiles
} from "../../../components/common/file.upload/FileUpload.library";
import {API_PATHS, makeJSONGetRequest, makeMultiPartFormPostRequest, makeUrl} from "../../../services/ajax/ajax";
import createDefaultProjectsData from "../../../components/profile/projects/projects.edit.form/ProjectsEditForm.library";

export default function ProjectsEdit(props) {

    const dispatcher = useDispatch();
    const [formData, updateFormData] = useState({});
    const [skillsOptions, updateSkillsOptions] = useState([]);

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PROJECT_EDIT_GET, {projectId: props.match.params.projectId}), dispatcher)
            .then((response) => {
            updateFormData(response.body);
        })
    },[dispatcher, props.match.params.projectId]);

    useEffect(() => {
        loadUserTags(props.match.params.userId, dispatcher).then((options) => {
            updateSkillsOptions(options);
        })
    }, [dispatcher, props.match.params.userId]);

    async function onSubmit(values) {
        const {files, ...rest} = values;
        const {newSketchHostedFiles, newExternallyLinkedFiles, existingFiles} = await processMediaFiles(files);
        const formData = createFileUploadFormData(newSketchHostedFiles);

        const requestData = {
            ...rest,
            externallyLinkedFiles: createNewExternallyLinkedFileData(newExternallyLinkedFiles),
            files: existingFiles
        };

        formData.append('requestData', JSON.stringify(requestData));
        await makeMultiPartFormPostRequest(
            makeUrl(API_PATHS.PROJECT_EDIT_SAVE, {projectId: props.match.params.projectId}),
            dispatcher,
            formData
        );
        props.history.push(createViewPath(RouteConstants.PROJECTS_VIEW, {userId: props.match.params.userId, projectId: props.match.params.projectId}));
    }

    return (
        <div>
            {
                Object.keys(formData).length > 0 &&
                <ProjectsEditForm
                    formData={createDefaultProjectsData(formData)}
                    titleLabel={"projects_edit_title"}
                    titleLabelParams={{dateTime: formatLocalDateTime(formData.date)}}
                    backTo={createViewPath(RouteConstants.PROJECTS_VIEW, {userId: props.match.params.userId, projectId: props.match.params.projectId})}
                    onCancel={() => {
                        props.history.push(createViewPath(RouteConstants.PROJECTS_VIEW, {userId: props.match.params.userId, projectId: props.match.params.projectId}));
                    }}
                    onSubmit={onSubmit}
                    skillsOptions={skillsOptions}
                />
            }
        </div>
    )
}