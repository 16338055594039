import React from 'react';
import "./SoundcloudEmbed.css"

const SoundcloudEmbed = ({ uri }) => {

    return (
        <div className="soundcloud-embed-container">
            <iframe
                title="soundcloud-embed"
                className="soundcloud-embed"
                allow="autoplay"
                src={`https://w.soundcloud.com/player/?url=${uri}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`}
            />
        </div>
    );
};

export default SoundcloudEmbed;