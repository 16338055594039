import React from "react";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../store/rootReducer";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Translate from "../../../common/translate/Translate";
import FileThumbnail from "../../../common/file.thumbnail/FileThumbnail";
import { DragIcon } from "../../../common/svg/svg";
import { translate } from "../../../common/translate/Translate.library";
import "./PortfolioListItem.css";

export default function PortfolioListItem(props) {
    const language = useSelector(getLanguage);
    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: props.id});
    const style = {
        transition,
        transform: CSS.Transform.toString(transform)
    }

    function getNoImageClassName() {
        let className = "no-image";
        if (props.canEdit) {
            className += " portfolio-can-edit-thumbnail"
        }
        return className;
    }

    function getPortfolioImage() {
        if (props.image) {
            return (
                <FileThumbnail className={"portfolio-image"} file={props.image}/>
            )
        }
        return (
            <div className={getNoImageClassName()}>
                <div>
                    <Translate label="portfolio_no_thumbnail"/>
                </div>
                {
                    props.canEdit &&
                    <div className={"portfolio-edit-thumbnail-link"}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.editPortfolioTo(props.id);
                    }}>
                        <Translate label="portfolio_item_edit_link"/>
                    </div>
                }
            </div>
        )
    }

    return (
        <div className={"portfolio-list-item-container"}
        ref={setNodeRef}
        style={style}
        onClick={() => { props.onClick(props.id); }}
        >
            {props.canEdit && <div className={"portfolio-list-item-drag-handle"} {...attributes} {...listeners}>
                <DragIcon />
            </div>}
            <div className={"portfolio-list-item"}>
                {getPortfolioImage()}
                <div className={"portfolio-name"}>
                    <div className={"portfolio-title-label"}>
                        <Translate label="portfolio_title"/>
                    </div>
                    <div className={"portfolio-title"}>{props.name} {props.isDraft && translate("portfolio_draft_indicator", language)}</div>
                </div>
            </div>
        </div>

    )
}