import React from 'react';
import "./OpportunitiesViewSection.css";
import Translate from "../../common/translate/Translate";
import LineSplit from '../../common/linesplit/LineSplit';

export default function OpportunitiesViewSection(props) {

    function getClassName() {
        let className = 'opportunities-view-section';
        if (props.className) {
            className += ' ' + props.className;
        }
        if (props.highlight) {
            className += ' highlight';
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <div><Translate label={props.title}/></div>
            <LineSplit string={props.content} separator={'\n'} />
        </div>
    )
}