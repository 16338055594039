import React from "react";
import "./EditDeleteLink.css";
import EditLink from "../edit.link/EditLink";
import DeleteLink from "../delete.link/DeleteLink";

export default function EditDeleteLink(props) {

    function getClassName() {
        let className = "edit-delete-link";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <EditLink to={props.editTo} />
            <DeleteLink onClick={props.onDelete}/>
        </div>
    )
}