import React, {useEffect, useState} from "react";
import "./JournalEdit.css";
import {loadUserTags} from "../Journal.library";
import {useDispatch} from "react-redux";
import JournalEditForm from "../../../components/profile/journal/journal.edit.form/JournalEditForm";
import {
    createFileUploadFormData,
    createNewExternallyLinkedFileData,
    processMediaFiles
} from "../../../components/common/file.upload/FileUpload.library";
import {API_PATHS, makeJSONGetRequest, makeMultiPartFormPostRequest, makeUrl} from "../../../services/ajax/ajax";
import {createViewPath, RouteConstants} from "../../../App.library";
import {formatLocalDateTime} from "../../../services/date/date";
import createDefaultJournalFormData
    from "../../../components/profile/journal/journal.edit.form/JournalEditForm.library";

export default function JournalEdit(props) {

    const dispatcher = useDispatch();
    const [formData, updateFormData] = useState({});
    const [skillsOptions, updateSkillsOptions] = useState([]);

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.JOURNAL_EDIT_GET, {journalId: props.match.params.journalId}),
            dispatcher).then((response) => {
                updateFormData(response.body);
        })
    }, [dispatcher, props.match.params.journalId]);

    useEffect(() => {
        loadUserTags(props.match.params.userId, dispatcher).then((options) => {
            updateSkillsOptions(options);
        })
    }, [dispatcher, props.match.params.userId]);

    async function onSubmit(values) {
        const {files, ...rest} = values;
        const {newSketchHostedFiles, newExternallyLinkedFiles, existingFiles} = await processMediaFiles(files);
        const formData = createFileUploadFormData(newSketchHostedFiles);

        const requestData = {
            ...rest,
            externallyLinkedFiles: createNewExternallyLinkedFileData(newExternallyLinkedFiles),
            files: existingFiles
        };

        formData.append('requestData', JSON.stringify(requestData));
        await makeMultiPartFormPostRequest(
            makeUrl(API_PATHS.JOURNAL_EDIT_SAVE, {journalId: props.match.params.journalId}),
            dispatcher,
            formData
        );
        props.history.push(createViewPath(RouteConstants.JOURNAL_VIEW, {userId: props.match.params.userId, journalId: props.match.params.journalId}));
    }

    return (
        <div>
            {
                Object.keys(formData).length > 0 &&
                <JournalEditForm
                    formData={createDefaultJournalFormData(formData)}
                    titleLabel={"journal_edit_title"}
                    titleLabelParams={{dateTime: formatLocalDateTime(formData.journalDate)}}
                    backTo={createViewPath(RouteConstants.JOURNAL_VIEW, {userId: props.match.params.userId, journalId: props.match.params.journalId})}
                    onCancel={() => {
                        props.history.push(createViewPath(RouteConstants.JOURNAL_VIEW, {userId: props.match.params.userId, journalId: props.match.params.journalId}));
                    }}
                    skillsOptions={skillsOptions}
                    onSubmit={onSubmit}
                    userId={props.match.params.userId}
                />
            }

        </div>
    )
}