import queryString from "query-string";
import {API_PATHS, makeJSONGetRequest} from "../../services/ajax/ajax";

export function buildListProgramsRequest(searchString) {
    const queryParameters = queryString.parse(searchString);
    return {
        pageNumber: parseInt(queryParameters.pageNumber || 1)
    }
}

export async function loadProgramTags(dispatcher) {
    const response = await makeJSONGetRequest(API_PATHS.GET_GLOBAL_AND_PROGRAM_SKILL_OPTIONS, dispatcher)
    return response.body.options;
}