import {getToken} from "../auth/auth";
import {clearMessage} from "../../components/message.panel/ducks/actions";
import {handleNetworkError, handleResponseNotOkay} from "../error/error";
import queryString from 'query-string';

const CONTEXT_ROOT = "/sketch";
const PUBLIC_ROUTE = CONTEXT_ROOT + "/api/public";
const PRIVATE_ROUTE = CONTEXT_ROOT + "/api/private";


export const API_PATHS = {
    PING: PUBLIC_ROUTE + "/ping/",
    PRIVATE_PING: PRIVATE_ROUTE + "/ping",
    FILE_GET: PUBLIC_ROUTE + "/file/{id}",
    FILE_INFO_UPDATE: PRIVATE_ROUTE + "/file/{id}/saveFileInfo",
    FILE_UPDATE_SENSITIVITY_STATUS: PRIVATE_ROUTE + "/file/{id}/updateFileSensitivityStatus",
    THUMBNAIL_GET: PUBLIC_ROUTE + "/file/{id}/thumbnail",
    VIDEO_THUMBNAIL_GET: PUBLIC_ROUTE + "/file/{id}/thumbnail/video",
    YOUTUBE_VIDEO_THUMBNAIL_GET: PUBLIC_ROUTE + "/file/{id}/thumbnail/video/youtube",
    LOGIN: PUBLIC_ROUTE + "/login",
    SIGN_UP: PUBLIC_ROUTE + "/sign-up",
    INVITE: PRIVATE_ROUTE + "/invite",
    FORGOT_PASSWORD_SEND_EMAIL: PUBLIC_ROUTE + "/forgot-password",
    FORGOT_PASSWORD_RESET_PASSWORD: PUBLIC_ROUTE + "/forgot-password/reset",
    REQUEST_LETTER_OF_REFERENCE: PRIVATE_ROUTE + "/request-reference-letter",
    GET_INVITE_ROLE_OPTIONS: PRIVATE_ROUTE + "/options/invite/role",
    GET_MENTOR_ROLE_OPTIONS: PRIVATE_ROUTE + "/options/mentor/role",
    GET_USER_ROLES: PRIVATE_ROUTE + "/options/users/role",
    GET_USER_MANAGEMENT_TYPES: PRIVATE_ROUTE + "/options/users/type",
    GET_OPPORTUNITY_TYPE_OPTIONS: PRIVATE_ROUTE + "/options/opportunity/type",
    GET_JOURNAL_SECTION_OPTIONS: PRIVATE_ROUTE + "/options/journal/section",
    GET_GLOBAL_AND_USER_SKILL_OPTIONS: PRIVATE_ROUTE + "/options/skills/{userId}/globalAndUser",
    GET_GLOBAL_AND_PROGRAM_SKILL_OPTIONS: PRIVATE_ROUTE + "/options/skills/globalAndProgram",
    GET_OPEN_OPPORTUNITY_OPTIONS: PRIVATE_ROUTE + "/options/opportunity/open",
    GET_USER_PORTFOLIO_OPTIONS: PRIVATE_ROUTE + "/options/portfolio",
    GET_USER_MENTOR_OPTIONS: PRIVATE_ROUTE + "/options/mentors",
    GET_MENTORS_AND_PEERS: PRIVATE_ROUTE + "/options/user/{userId}/peerMentors",
    GET_STATISTIC_PERIODS: PRIVATE_ROUTE + "/options/statistic/periods",
    GET_MUSIC_PLATFORM_OPTIONS: PRIVATE_ROUTE + "/options/musicPlatforms",
    MENTOR_SEARCH: PRIVATE_ROUTE + "/mentor/search",
    MENTOR_ADD: PRIVATE_ROUTE + "/mentor/{mentorUserId}/add",
    MENTOR_REMOVE: PRIVATE_ROUTE + "/mentor/{mentorUserId}/remove",
    PEER_ADD: PRIVATE_ROUTE + "/peer/{peerUserId}/add",
    PEER_REMOVE: PRIVATE_ROUTE + "/peer/{peerUserId}/remove",
    OPPORTUNITIES_LIST: PRIVATE_ROUTE + "/opportunity",
    OPPORTUNITY_GET: PRIVATE_ROUTE + "/opportunity/{id}",
    OPPORTUNITY_EDIT_GET: PRIVATE_ROUTE + "/opportunity/{id}/edit",
    OPPORTUNITY_EDIT_SUBMIT: PRIVATE_ROUTE + "/opportunity/{id}/save",
    OPPORTUNITY_SUBMIT: PUBLIC_ROUTE + "/opportunity/submit",
    OPPORTUNITY_CREATE: PRIVATE_ROUTE + "/opportunity/create",
    OPPORTUNITY_DELETE: PRIVATE_ROUTE + "/opportunity/{opportunityId}/delete",
    PROGRAMS_LIST: PRIVATE_ROUTE + "/program",
    PROGRAM_GET: PRIVATE_ROUTE + "/program/{id}",
    PROGRAM_CREATE: PRIVATE_ROUTE + "/program/create",
    PROGRAM_EDIT_GET: PRIVATE_ROUTE + "/program/{id}/edit",
    PROGRAM_EDIT_SAVE: PRIVATE_ROUTE + "/program/{id}/save",
    PROGRAM_DELETE: PRIVATE_ROUTE + "/program/{id}/delete",
    PROGRAM_REQUEST_PARTICIPATION: PRIVATE_ROUTE + "/program/{id}/requestParticipation",
    GET_PROGRAM_PARTICIPATION_STATUSES: PRIVATE_ROUTE + "/options/program/participationStatus",
    PROGRAM_PARTICIPATION_SEARCH: PRIVATE_ROUTE + "/program/{id}/searchParticipation",
    PROGRAM_UPDATE_PARTICIPATION: PRIVATE_ROUTE + "/program/{id}/updateParticipation",
    PROGRAM_EVENTS_GET: PRIVATE_ROUTE + "/program/{id}/events",
    PROGRAM_EVENTS_SAVE: PRIVATE_ROUTE + "/program/{id}/events/save",
    PRIVATE_SEARCH: PRIVATE_ROUTE + "/search",
    PUBLIC_SEARCH: PUBLIC_ROUTE + "/search",
    PROFILE_SOCIAL: PUBLIC_ROUTE + "/profile/{userId}/social",
    PROFILE: PRIVATE_ROUTE + "/profile/{userId}",
    PROFILE_ARTIST_STATEMENT: PRIVATE_ROUTE + "/profile/{userId}/artistStatement",
    PROFILE_PERSONAL_INFO: PRIVATE_ROUTE + "/profile/{userId}/personalInfo",
    PROFILE_UPDATE_COLLABORATION_STATUS: PRIVATE_ROUTE + "/profile/{userId}/updateCollaborationStatus",
    PROFILE_EDIT: PRIVATE_ROUTE + "/profile/{userId}/edit",
    PROFILE_SAVE: PRIVATE_ROUTE + "/profile/{userId}/save",
    GET_USERS_TO_MANAGE: PRIVATE_ROUTE + "/users",
    GET_USER_TO_EDIT: PRIVATE_ROUTE + "/users/{id}/edit",
    SAVE_USER: PRIVATE_ROUTE + "/users/{id}/edit",
    APPROVE_USER: PRIVATE_ROUTE + "/users/{id}/edit/approve",
    REJECT_USER: PRIVATE_ROUTE + "/users/{id}/edit/reject",
    PREFERENCES: PRIVATE_ROUTE + "/user/preferences",
    PREFERENCES_SAVE: PRIVATE_ROUTE + "/user/preferences/save",
    GET_USER_DETAILS: PRIVATE_ROUTE + "/user",
    SET_USER_AVAILABLE: PRIVATE_ROUTE + "/updateUserAvailability",
    GET_NOTIFICATIONS: PRIVATE_ROUTE + "/notification",
    DELETE_NOTIFICATION: PRIVATE_ROUTE + "/notification/{id}/delete",
    JOURNAL_LIST_SEARCH: PRIVATE_ROUTE + "/journal/{userId}/search",
    JOURNAL_CREATE: PRIVATE_ROUTE + "/journal/create",
    JOURNAL_EDIT_GET: PRIVATE_ROUTE + "/journal/{journalId}/edit",
    JOURNAL_EDIT_SAVE: PRIVATE_ROUTE + "/journal/{journalId}/save",
    JOURNAL_VIEW: PRIVATE_ROUTE + "/journal/{journalId}",
    JOURNAL_DELETE: PRIVATE_ROUTE + "/journal/{journalId}/delete",
    JOURNAL_COMMENTS: PRIVATE_ROUTE + "/journal/{journalId}/comments",
    JOURNAL_COMMENTS_DELETE: PRIVATE_ROUTE + "/journal/{journalId}/comments/{commentId}/delete",
    JOURNAL_COMMENTS_ADD: PRIVATE_ROUTE + "/journal/{journalId}/comments/add",
    JOURNAL_PORTFOLIO_ADD_MEDIA: PRIVATE_ROUTE + "/journal/{journalId}/addMediaPortfolio",
    PROJECT_LIST_SEARCH: PRIVATE_ROUTE + "/project/{userId}/search",
    PROJECT_CREATE: PRIVATE_ROUTE + "/project/create",
    PROJECT_EDIT_SAVE: PRIVATE_ROUTE + "/project/{projectId}/save",
    PROJECT_EDIT_GET: PRIVATE_ROUTE + "/project/{projectId}/edit",
    PROJECT_VIEW: PRIVATE_ROUTE + "/project/{projectId}",
    PROJECT_DELETE: PRIVATE_ROUTE + "/project/{projectId}/delete",
    PROJECT_COMMENTS: PRIVATE_ROUTE + "/project/{projectId}/comments",
    PROJECT_COMMENTS_DELETE: PRIVATE_ROUTE + "/project/{projectId}/comments/{commentId}/delete",
    PROJECT_COMMENTS_ADD: PRIVATE_ROUTE + "/project/{projectId}/comments/add",
    CALCULATE_PROJECT_STATISTICS: PRIVATE_ROUTE + "/statistics/project/{projectId}",
    PORTFOLIO_SERIES_GET: PUBLIC_ROUTE + "/portfolio/{portfolioId}/series/{seriesId}",
    PORTFOLIO_LIST: PUBLIC_ROUTE + "/portfolio/{userId}/list",
    PORTFOLIO_GET: PUBLIC_ROUTE + "/portfolio/{portfolioId}/get",
    PORTFOLIO_CREATE: PRIVATE_ROUTE + "/portfolio/create",
    PORTFOLIO_EDIT_GET: PRIVATE_ROUTE + "/portfolio/{portfolioId}/edit",
    PORTFOLIO_EDIT_SAVE: PRIVATE_ROUTE + "/portfolio/{portfolioId}/save",
    PORTFOLIO_SAVE_ORDER: PRIVATE_ROUTE + "/portfolio/saveOrder",
    PORTFOLIO_DELETE: PRIVATE_ROUTE + "/portfolio/{portfolioId}/delete",
    PORTFOLIO_COPY: PRIVATE_ROUTE + "/portfolio/{portfolioId}/copy",
    GET_GLOBAL_SKILLS_FOR_EDITING: PRIVATE_ROUTE + "/skills/global",
    ADD_GLOBAL_SKILL: PRIVATE_ROUTE + "/skills/global/add",
    REMOVE_GLOBAL_SKILL: PRIVATE_ROUTE + "/skills/global/{skillId}/remove",
    REPORT_CONTACTS: PRIVATE_ROUTE + "/report/contacts",
    REPORT_FOLIO_SUMMARY_INFO: PRIVATE_ROUTE + "/report/folioSummaryInfo",
    REPORT_OPPORTUNITIES_SUMMARY_INFO: PRIVATE_ROUTE + "/report/opportunitySummaryInfo"
};

export function makeUrl(path, params, queryStringParams) {
    let newPath = path;
    if (params) {
        Object.keys(params).forEach(paramKey => {
            newPath = newPath.replace('{' + paramKey + '}', encodeURIComponent(params[paramKey]));
        });
    }

    if (queryStringParams) {
        const string = queryString.stringify(queryStringParams)
        if (string) {
            newPath += '?' + string;
        }
    }

    return newPath;
}

function createFetchOptions(httpMethod, headersCreator) {
    return () => {
        return {
            headers: headersCreator(),
            method: httpMethod,
            cache: 'no-cache'
        }
    }
}

function createHeaders(headers) {
    return () => {
        return new Headers(headers);
    }
}

const jsonHeaders = createHeaders({
    'Content-Type': 'application/json;charset=UTF-8'
});

const multiPartFormDataHeaders = createHeaders({

});

const jsonGetRequestOptions = createFetchOptions('GET', jsonHeaders);
const jsonPostRequestOptions = createFetchOptions('POST', jsonHeaders);
const multiPartFormDataOptions = createFetchOptions('POST', multiPartFormDataHeaders);
const blobGetRequestOptions = createFetchOptions('GET', jsonHeaders);
const blobPostRequestOptions = createFetchOptions('POST', jsonHeaders);

function addAuthorization(options) {
    const token = getToken();
    if (!token) {
        return;
    }
    options.headers.set('Authorization', token);
}

function isMediaFetch(method, url) {
    return method.toLowerCase() === 'get' && url.indexOf('api/public/file') >= 0;
}

export function createNetworkCall(options) {
    return async (url, dispatcher, body, dontClearMessage) => {
        const newOptions = options();

        if (isMediaFetch(newOptions.method, url)) {
            // media is all immutable, so allow the browser to rely on its cache
            delete newOptions.cache;
        }

        if (body) {
            newOptions.body = body;
        }
        addAuthorization(newOptions);
        try {
            if (!dontClearMessage) {
                dispatcher(clearMessage());
            }
            return await fetch(new Request(url, newOptions));
        } catch (error) {
            handleNetworkError(error, dispatcher);
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }
    }
}

export function createErrorHandledNetworkCall(options) {
    const networkCall = createNetworkCall(options);
    return async (url, dispatcher, body, dontClearMessage) => {
        const response = await networkCall(url, dispatcher, body, dontClearMessage);
        if (!response.ok) {
            await handleResponseNotOkay(response, dispatcher);
            return new Promise((resolve, reject) => {
                reject(response);
            });
        }
        return response;
    }
}

function makeJSONRequest(requestOptions) {
    const networkCall = createErrorHandledNetworkCall(requestOptions);
    return async (url, dispatcher, body, dontClearMessage) => {
        const response = await networkCall(url, dispatcher, body ? JSON.stringify(body) : body, dontClearMessage);
        const json = await response.json();
        return {
            body: json,
            response
        }
    }
}

function makeMultiPartFormRequest(requestOptions) {
    const networkCall = createErrorHandledNetworkCall(requestOptions);
    return async (url, dispatcher, body, dontClearMessage) => {
        const response = await networkCall(url, dispatcher, body, dontClearMessage);
        const json = await response.json();
        return {
            body: json,
            response
        }
    }
}

function makeBlobRequest(requestOptions){
    const networkCall = createErrorHandledNetworkCall(requestOptions);
    return async (url, dispatcher, body, dontClearMessage) => {
        const response = await networkCall(url, dispatcher, body ? JSON.stringify(body) : body, dontClearMessage);
        const blob = await response.blob();
        return {
            body: blob.size > 1 ? window.URL.createObjectURL(blob) : null,
            response,
            fileName: response.headers.get('Content-Disposition').split('filename=')[1]
        }
    }
}

export const makeMultiPartFormPostRequest = makeMultiPartFormRequest(multiPartFormDataOptions);
export const makeJSONPostRequest = makeJSONRequest(jsonPostRequestOptions);
export const makeJSONGetRequest = makeJSONRequest(jsonGetRequestOptions);
export const makeBlobGetRequest = makeBlobRequest(blobGetRequestOptions);
export const makeBlobPostRequest = makeBlobRequest(blobPostRequestOptions);

//Login is a special request with it's own error handling
export const makeLoginRequest = createNetworkCall(jsonPostRequestOptions);