import React, {useEffect, useState} from "react";
import {getSketchHostedFileBlob} from "../../../services/file/file";

export default function SketchFileAudio(props) {

    const dispatch = props.dispatch;
    const [fileAudio, updateFileAudio] = useState({});
    const LoadComponent = props.onLoadComponent;

    function getClassName(props) {
        let className = "sketch-file-audio";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    function getAudio() {
        if (Object.keys(fileAudio).length <= 0 && LoadComponent) {
            return <LoadComponent/>
        }
        if (Object.keys(fileAudio).length <= 0) {
            return <></>
        }
        return <audio controls src={fileAudio}/>
    }

    useEffect(() => {
        getSketchHostedFileBlob(props.file.id, dispatch).then((response) => {
            updateFileAudio(response.body);
        });
    }, [props.file.id, dispatch]);

    return (
        <div className={getClassName(props)}>
            {getAudio()}
        </div>
    )
}