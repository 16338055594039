import React from "react";
import "./JournalViewHeader.css";
import BackLink from "../../../common/back.link/BackLink";
import ProfileHeaderText from "../../profile.header.text/ProfileHeaderText";
import EditDeleteLink from "../../../common/edit.delete.link/EditDeleteLink";

export default function JournalViewHeader(props) {
    return (
        <div className={"journal-view-header"}>
            <BackLink to={props.backTo}/>
            <ProfileHeaderText className={"journal-view-header-text"} label={props.titleLabel }/>
            {
                props.canEdit &&
                    <EditDeleteLink className={"journal-view-header-edit-delete"} editTo={props.editTo} onDelete={props.onDelete}/>
            }
        </div>
    )
}