import React, {useEffect, useState} from "react";
import "./OpportunitiesEdit.css";
import BasicLayout from "../../../components/layouts/basic.layout/BasicLayout";
import Translate from "../../../components/common/translate/Translate";
import OpportunitiesForm from "../../../components/opportunities/opportunities.form/OpportunitiesForm";
import {
    createFileUploadFormData, createNewExternallyLinkedFileData, processMediaFiles
} from "../../../components/common/file.upload/FileUpload.library";
import {API_PATHS, makeJSONGetRequest, makeMultiPartFormPostRequest, makeUrl} from "../../../services/ajax/ajax";
import {useDispatch} from "react-redux";
import {displaySuccess} from "../../../components/message.panel/ducks/actions";
import {createViewPath, RouteConstants} from "../../../App.library";
import useFetchOrStore from "../../../hooks/useFetchOrStore";
import {getOpportunityTypeOptions} from "../../../store/rootReducer";
import {setOpportunityTypeOptions} from "../../../services/select.options/ducks/actions";

export default function OpportunitiesEdit(props) {
    const dispatch = useDispatch();
    const [formData, updateFormData] = useState({});
    const [opportunityTypes] = useFetchOrStore(getOpportunityTypeOptions, fetchOpportunityTypes);

    async function fetchOpportunityTypes() {
        const response = await makeJSONGetRequest(API_PATHS.GET_OPPORTUNITY_TYPE_OPTIONS, dispatch);
        dispatch(setOpportunityTypeOptions(response.body.options));
    }

    async function onSubmit(values) {
        const {files, ...rest} = values;
        const {newSketchHostedFiles, newExternallyLinkedFiles, existingFiles} = await processMediaFiles(files);
        const formData = createFileUploadFormData(newSketchHostedFiles);

        const requestData = {
            ...rest,
            externallyLinkedFiles: createNewExternallyLinkedFileData(newExternallyLinkedFiles),
            files: existingFiles
        };
        formData.append('requestData', JSON.stringify(requestData));
        await makeMultiPartFormPostRequest(
            makeUrl(API_PATHS.OPPORTUNITY_EDIT_SUBMIT, {id: props.match.params.id}),
            dispatch,
            formData
        );
        dispatch(displaySuccess([{
            label: "opportunity_edit_success"
        }]));
        props.history.push({
            pathname: createViewPath(RouteConstants.OPPORTUNITIES_VIEW, {id: props.match.params.id}),
            state: props.location.state
        });
    }

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.OPPORTUNITY_EDIT_GET, {id: props.match.params.id}), dispatch)
            .then((response) => {
                updateFormData(response.body);
            });
    }, [props.match.params.id, dispatch]);

    return (
        <BasicLayout
            {...props}
        >
            <div className={"opportunities-edit"}>
                <div>
                    <h1><Translate label={"opportunity_edit_opportunity"}/></h1>
                </div>
                {
                    Object.keys(formData).length > 0 &&
                        <OpportunitiesForm
                            opportunityTypes={opportunityTypes || []}
                            submitLabel={"opportunity_edit_submit_label"}
                            onSubmit={onSubmit}
                            formData={formData}
                            showFileUpload={true}
                        />
                }
            </div>
        </BasicLayout>
    )
}
