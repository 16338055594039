import React, {useEffect, useState} from "react";
import "./SketchFileLink.css";
import {getSketchHostedFileBlob, isVideo, isMusicPlatform} from "../../../services/file/file";
import {constructYoutubeLink} from "../../../services/youtube/youtube";
import {constructMusicPlatformLink} from "../../common/file.upload/file.upload.music/FileAttachMusic.library"

export default function SketchFileLink(props) {
    const dispatch = props.dispatch;
    const [href, updateHref] = useState('');
    const LoadComponent = props.onLoadComponent;

    function getLink() {
        if (!href && LoadComponent) {
            return <LoadComponent />
        }
        if (!href) {
            return <></>
        }
        return <a href={href} className={props.className} rel="noopener noreferrer" target="_blank" title={props.title}>{props.children}</a>
    }

    useEffect(() => {
        if (isMusicPlatform(props.file.type)) {
            updateHref(constructMusicPlatformLink(props.file));
        } else if (!isVideo(props.file.type)) {
            getSketchHostedFileBlob(props.file.id, dispatch).then((response) => {
                updateHref(response.body);
            })
        } else {
            updateHref(constructYoutubeLink(props.file.location));
        }
    }, [props.file.type, props.file.id, props.file.location, dispatch]);

    return (
        <>
            {getLink()}
        </>
    )
}