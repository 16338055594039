export function getSkillToAdd(dropdownSkill, textSkill, options = [], existingTags = []) {
    if (dropdownSkill) {
        if (existingTags.find((tag) => tag.id === parseInt(dropdownSkill))) {
            return;
        }
        return changeOptionToSkillTag(options.find(option => {
            return option.value === parseInt(dropdownSkill);
        }));
    }
    return getTextSkill(textSkill, options, existingTags);
}

function getTextSkill(textSkill, options, existingTags) {
    if (!textSkill || !textSkill.trim()) {
        return;
    }

    if (existingTags.find((tag) => compareOptionLabel(tag.name, textSkill))) {
        return;
    }

    const existingSkill = options.find(option => {
        return compareOptionLabel(option.label, textSkill);
    });
    if (existingSkill) {
        return changeOptionToSkillTag(existingSkill);
    }
    return {
        name: textSkill.trim()
    }
}

function compareOptionLabel(label, textSkill) {
    return label.trim().toUpperCase() === textSkill.trim().toUpperCase();
}

function changeOptionToSkillTag(option) {
    return {
        id: option.value,
        name: option.label
    }
}