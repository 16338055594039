import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../store/rootReducer";
import {useDispatch} from "react-redux";
import "./PortfolioEdit.css"
import PortfolioEditForm from "../../../components/profile/portfolio/portfolio.edit.form/PortfolioEditForm";
import {formatLocalDateTime} from "../../../services/date/date";
import {createViewPath, RouteConstants} from "../../../App.library";
import {
    API_PATHS, 
    makeJSONGetRequest, 
    makeMultiPartFormPostRequest, 
    makeUrl
} from "../../../services/ajax/ajax";
import cloneDeep from "lodash/cloneDeep";
import createDefaultPortfolioFormData
    from "../../../components/profile/portfolio/portfolio.edit.form/PortfolioEditForm.library";
import { 
    createNewExternallyLinkedFileData,
    createFileUploadFormData,
    processMediaFiles
} from "../../../components/common/file.upload/FileUpload.library";
import { translate } from "../../../components/common/translate/Translate.library";

export default function PortfolioEdit(props) {

    const portfolioId = props.match.params.portfolioId;
    const language = useSelector(getLanguage);
    const dispatcher = useDispatch();
    const [formData, updateFormData] = useState({});

    function goToPortfolio() {
        props.history.push(createViewPath(RouteConstants.PORTFOLIO_VIEW, {userId: props.match.params.userId, portfolioId: portfolioId}));
    }

    async function onSubmit(values) {
        values.series.forEach(series => {
            series.files.forEach(file => {
                delete file.isSelected;
                delete file.content;
            });
        });

        if (values.thumbnail) {
            // not part of the API schema
            delete values.thumbnail.isSelected;
            delete values.thumbnail.content;
        }

        const {files, ...rest} = values
        const {
            newSketchHostedFiles, 
            newExternallyLinkedFiles, 
        } = await processMediaFiles(files)
        const formData = createFileUploadFormData(newSketchHostedFiles)

        const requestData = {
            ...rest,
            externallyLinkedFiles: createNewExternallyLinkedFileData(newExternallyLinkedFiles)
        }

        formData.append('requestData', JSON.stringify(requestData))
        
        await makeMultiPartFormPostRequest(
            makeUrl(API_PATHS.PORTFOLIO_EDIT_SAVE, { portfolioId }),
            dispatcher,
            formData
        );
        goToPortfolio();
    }

    function sanitizeFormData(formData) {
        const portfolioSeries = cloneDeep(formData.series);
        portfolioSeries.forEach(series => {
            series.files.forEach(file => file.isSelected = formData.thumbnail ? file.id === formData.thumbnail.id : false);
        });
        updateFormData({...formData, series: portfolioSeries});
    }

    useEffect(() => {
        makeJSONGetRequest(makeUrl(API_PATHS.PORTFOLIO_EDIT_GET, {portfolioId: portfolioId}), dispatcher)
            .then((response) => {
                sanitizeFormData(response.body);
            });
    }, [portfolioId, dispatcher]);

    return (
        Object.keys(formData).length > 0 &&
        <PortfolioEditForm
            formData={createDefaultPortfolioFormData(formData)}
            titleLabel={"portfolio_edit_title"}
            titleLabelParams={{
                name: formData.name,
                dateTime: formatLocalDateTime(formData.portfolioDate),
                isDraft: formData.draft ? translate("portfolio_draft_indicator", language) : ""
            }}
            backTo={createViewPath(RouteConstants.PORTFOLIO_VIEW, {userId: props.match.params.userId, portfolioId: portfolioId})}
            onCancel={goToPortfolio}
            onSubmit={onSubmit}
            userId={props.match.params.userId}
        />
    )
}