import {API_PATHS, makeJSONGetRequest, makeJSONPostRequest, makeUrl} from "../ajax/ajax";
import {createViewPath, RouteConstants} from "../../App.library";
import {isAuthenticated} from "../auth/auth";
import {setNotifications} from "./ducks/actions";

const NOTIFICATION_TYPE_MAPPING = {
    HAS_PROFILE_INVITE_ACCEPTED: {
       label: "notification_has_profile_invite_accepted",
        createUrlFunction: (parameters) => {
            return createViewPath(RouteConstants.ABOUT, {userId: parameters.userId});
        },
        onClick: (notification) => {
           return async (dispatch) => {
               await deleteNotification(notification, dispatch);
           }
        }
    },
    OTHER_INVITE_ACCEPTED: {
        label: "notification_other_invited_accepted",
        createUrlFunction: (parameters) => {
            // don't link anywhere
        },
        onClick: (notification) => {
            return async (dispatch) => {
                await deleteNotification(notification, dispatch);
            }
        }
    },
    ADDED_AS_MENTOR: {
        label: "notification_added_as_mentor",
        createUrlFunction: (parameters, language) => {
            return createViewPath(RouteConstants.ABOUT, {userId: parameters.userId});
        },
        onClick: (notification) => {
            return async (dispatch) => {
                await deleteNotification(notification, dispatch);
            }
        }
    },
    ADDED_AS_PEER: {
        label: "notification_added_as_peer",
        createUrlFunction: (parameters) => {
            return createViewPath(RouteConstants.ABOUT, {userId: parameters.userId});
        },
        onClick: (notification) => {
            return async (dispatch) => {
                await deleteNotification(notification, dispatch);
            }
        }
    },
    APPROVE_REGISTRATION_REQUEST: {
        label: "notification_approve_registration_request",
        createUrlFunction: (parameters) => {
            return createViewPath(RouteConstants.USER_MANAGEMENT, null, {userType: parameters.userType});
        },
        onClick: (notification) => {
            return async (dispatch) => {
                await deleteNotification(notification, dispatch);
            }
        }
    },
    SHARED_JOURNAL: {
        label: "notification_shared_journal",
        createUrlFunction: (parameters) => {
            return createViewPath(RouteConstants.JOURNAL_VIEW, {userId: parameters.userId, journalId: parameters.journalId});
        },
        onClick: (notification) => {
            return async (dispatch) => {
                await deleteNotification(notification, dispatch);
            }
        }
    },
    COMMENTED_ON_MY_JOURNAL: {
        label: "notification_commented_on_my_journal",
        createUrlFunction: (parameters) => {
            return createViewPath(RouteConstants.JOURNAL_VIEW, {userId: parameters.userId, journalId: parameters.journalId});
        },
        onClick: (notification) => {
            return async (dispatch) => {
                await deleteNotification(notification, dispatch);
            }
        }
    },
    COMMENTED_ON_EXISTING_DISCUSSION: {
        label: "notification_commented_on_existing_discussion",
        createUrlFunction: (parameters) => {
            return createViewPath(RouteConstants.JOURNAL_VIEW, {userId: parameters.userId, journalId: parameters.journalId});
        },
        onClick: (notification) => {
            return async (dispatch) => {
                await deleteNotification(notification, dispatch);
            }
        }
    },
    SHARED_PROJECT: {
        label: "notification_shared_project",
        createUrlFunction: (parameters) => {
            console.log('notifParams', parameters)
            return createViewPath(RouteConstants.PROJECTS_VIEW, {userId: parameters.userId, projectId: parameters.projectId});
        },
        onClick: (notification) => {
            return async (dispatch) => {
                await deleteNotification(notification, dispatch);
            }
        }
    },
    COMMENTED_ON_MY_PROJECT: {
        label: "notification_commented_on_my_project",
        createUrlFunction: (parameters) => {
            return createViewPath(RouteConstants.PROJECTS_VIEW, {userId: parameters.userId, projectId: parameters.projectId});
        },
        onClick: (notification) => {
            return async (dispatch) => {
                await deleteNotification(notification, dispatch);
            }
        }
    },
    COMMENTED_ON_EXISTING_PROJECT_DISCUSSION: {
        label: "notification_commented_on_existing_project_discussion",
        createUrlFunction: (parameters) => {
            return createViewPath(RouteConstants.PROJECTS_VIEW, {userId: parameters.userId, projectId: parameters.projectId});
        },
        onClick: (notification) => {
            return async (dispatch) => {
                await deleteNotification(notification, dispatch);
            }
        }
    }
};

async function deleteNotification(notification, dispatcher) {
    await makeJSONPostRequest(makeUrl(API_PATHS.DELETE_NOTIFICATION, {id: notification.id}), dispatcher, null, true);
    await loadNotifications(dispatcher);
}

function dispatchNotifications(dispatch, notifications) {
    dispatch(setNotifications(notifications));
}

export async function loadNotifications(dispatch) {
    if (isAuthenticated()) {
        const response = await makeJSONGetRequest(API_PATHS.GET_NOTIFICATIONS, dispatch, null, true);
        dispatchNotifications(dispatch, response.body.notifications);
    }
}

export function getNotificationLabel(notification) {
    return NOTIFICATION_TYPE_MAPPING[notification.type].label;
}

export function getUrlFromNotification(notification) {
    return NOTIFICATION_TYPE_MAPPING[notification.type].createUrlFunction(notification.parameters);
}

export function getOnClickFromNotification(notification) {
    return NOTIFICATION_TYPE_MAPPING[notification.type].onClick(notification);
}