import React from "react";
import RightImage from "../../resources/images/web-poster.jpg";
import Translate from "../common/translate/Translate";
import "./LoginRegistrationCarousel.css";

export default function LoginRegistrationCarousel(props) {
    return (
        <div className="login-registration-carousel">
            <img src={RightImage} alt={"Carousel artwork displaying student's art"} />
            <div className="branding">
                <div>
                    <div>
                        <Translate label={"carousel_branding_first"}/>
                    </div>
                    <div>
                        <Translate label={"carousel_branding_second"}/>
                    </div>
                </div>
                <div>
                    <Translate label={"carousel_branding_subtext"}/>
                </div>
            </div>
        </div>
    );
}