import React, {useState} from "react";
import TextInput from "../../../common/text.input/TextInput";
import TextSelect from "../../../common/text.select/TextSelect";
import SearchButton from "../../../common/search.button/SearchButton";
import ListParamsContainer from "../../../common/list/list.params.container/ListParamsContainer";
import "./JournalSearch.css"
import {onEnterClickCreator} from "../../../../services/util/util";

export default function JournalSearch(props) {
    const [areFiltersHidden, updateAreFiltersHidden] = useState(false);
    const onEnterClick = onEnterClickCreator(props.onSearchClick);

    return (
        <ListParamsContainer
            isHidden={areFiltersHidden}
            onHiddenClick={() => {
                updateAreFiltersHidden(!areFiltersHidden)
            }}
            showLabel={"journal_search_show_search_filters"}
            hideLabel={"journal_search_hide_search_filters"}
            className={"journal-search"}
            onKeyDown={onEnterClick}
        >
            <div className="search-params">
                <TextInput id="journal-keyword-search"
                           label="journal_search_keywords_label"
                           placeholder="journal_search_keywords_label_placeholder"
                           onChange={(event) => {props.onKeywordsChange(event.target.value)}}
                           value={props.keywords}/>
                <TextSelect id="journal-sections-search"
                            label="journal_search_section_label"
                            placeholder="journal_section_any_select_placeholder"
                            options={props.sectionOptions || []}
                            onChange={(event) => {props.onSectionChange(event.target.value)}}
                            value={props.section}/>
                <TextSelect id="journal-user-skill-search"
                            label="journal_search_user_skill_label"
                            placeholder="journal_section_any_select_placeholder"
                            options={props.skillOptions || []}
                            onChange={(event) => {props.onUserSkillTagChange(event.target.value)}}
                            value={props.userSkillTag}/>
            </div>
            <SearchButton label="journal_search_button" onClick={props.onSearchClick}/>
        </ListParamsContainer>
    )
}
