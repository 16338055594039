import React, {useEffect, useState} from "react";
import "./SketchFileImage.css"
import {getSketchHostedFileBlob} from "../../../services/file/file";

export default function SketchFileImage(props) {

    const dispatch = props.dispatch;
    const [fileImage, updateFileImage] = useState({});
    const LoadComponent = props.onLoadComponent;

    function getClassName(props) {
        let className = "sketch-file-image";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    function getImage() {
        if (Object.keys(fileImage).length <= 0 && LoadComponent) {
            return <LoadComponent/>
        }
        if (Object.keys(fileImage).length <= 0) {
            return <></>
        }
        return <img src={fileImage} alt={props.alt}/>
    }

    useEffect(() => {
        getSketchHostedFileBlob(props.file.id, dispatch).then((response) => {
            updateFileImage(response.body);
        });
    }, [props.file.id, dispatch]);

    return (
        <div className={getClassName(props)}>
            {getImage()}
        </div>
    )
}