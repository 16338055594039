import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {API_PATHS, makeJSONGetRequest, makeUrl} from "../../services/ajax/ajax";
import {createViewPath, PrivilegeConstants, RouteConstants} from "../../App.library";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import List from "../../components/common/list/List";
import Translate from "../../components/common/translate/Translate";
import ProgramListItem from "../../components/programs/program.list.item/ProgramListItem";
import {buildListProgramsRequest} from "./Programs.library";
import InfoLink from "../../components/common/info.link/InfoLink";
import {Link} from "react-router-dom";
import AppLinks from "../../resources/data/links";
import {hasPrivilege} from '../../services/auth/auth'
import {AddItemIcon} from "../../components/common/svg/svg"
import "./Programs.css";

export default function Programs(props) {

    const dispatcher = useDispatch();
    const [formData, updateFormData] = useState(buildListProgramsRequest(props.location.search));
    const [programResults, updateProgramResults] = useState({});

    useEffect(() => {
        const newFormData = buildListProgramsRequest(props.location.search);
        makeJSONGetRequest(makeUrl(API_PATHS.PROGRAMS_LIST, null, {
            ...newFormData,
            pageNumber: newFormData.pageNumber - 1,
            pageSize: 20
        }), dispatcher).then((response) => {
            updateFormData(newFormData);
            updateProgramResults(response.body);
        });
    }, [props.location.search, dispatcher]);


    function onPageClick(currentPage) {
        listPrograms(currentPage);
    }

    function listPrograms(page) {
        const searchQueryParams = {
            ...formData,
            pageNumber: page
        };
        props.history.push({
            pathname: props.history.pathname,
            search: makeUrl('', null, searchQueryParams)
        });
    }

    function onItemClick(id) {
        props.history.push({
            pathname: createViewPath(RouteConstants.PROGRAM_VIEW, {id: id}),
            state: {programIds: programResults.programIds}
        });
    }

    function updateParticipationStatus(programId, status) {
        const newProgramResults = {...programResults};
        const updatedResult = newProgramResults.programs.find((program) => program.id === programId);
        updatedResult.participationStatus = status;
        updateProgramResults(newProgramResults);
    }

    return (
        <BasicLayout
            {...props}
        >
            <div className={"programs-container"}>
                <div className={"programs-container-heading"}>
                    <div>
                        <h1>
                            <Translate label={"programs_view_heading"}/>
                        </h1>
                        <InfoLink to={AppLinks.help_programs_view} />
                    </div>
                    {
                        hasPrivilege(PrivilegeConstants.CAN_CREATE_PROGRAM) &&
                        <div className={"programs-add"}>
                            <Link to={RouteConstants.PROGRAM_CREATE}>
                                <AddItemIcon/>
                                <Translate label={"programs_create_program"} />
                            </Link>
                        </div>
                    }
                </div>
                <List
                    singularAmountLabel={"programs_amount_label_singular"}
                    pluralAmountLabel={"programs_amount_label_plural"}
                    onPageClick={onPageClick}
                    currentPage={formData.pageNumber}
                    numberOfPages={programResults.numberOfPages}
                    items={programResults.programs}
                    itemComponent={ProgramListItem}
                    totalResultsCount={programResults.totalResultsCount}
                    additionalItemProps={{
                        onItemClick,
                        updateParticipationStatus
                    }}
                />
            </div>
        </BasicLayout>
    )
}