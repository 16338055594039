import React from 'react'
import { NewTabIcon } from '../../../components/common/svg/svg'

function LinkToDetails({ url }) {
  return (
    <>
      <a 
        href={url} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="details-url"
      >
        {url}
      </a>
      <NewTabIcon
        style={{
          height: '1rem',
          width: '1rem',
          marginLeft: '0.5rem',
        }}
      />
    </>
  )
}

export default LinkToDetails
