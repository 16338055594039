import React from "react";
import {Link} from "react-router-dom";
import {ProfileEditIcon} from "../svg/svg";
import Translate from "../translate/Translate";
import "./EditLink.css";

export default function EditLink(props) {

    function getClassName() {
        let className = "edit-link";
        if (props.className) {
            className += " " + props.className;
        }
        return className;
    }

    return (
        <div className={getClassName()}>
            <Link to={props.to}>
                <ProfileEditIcon/>
                <div>
                    <Translate label="edit_label"/>
                </div>
            </Link>
        </div>
    )
}