import React, {useState} from "react";
import "./ForgotPassword.css";
import RegistrationLoginLayout from "../../components/layouts/registration.login/RegistrationLoginLayout";
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import Translate from "../../components/common/translate/Translate";
import TextInput from "../../components/common/text.input/TextInput";
import {createOnBlurFunction} from "../../services/formik/formik";
import ButtonAccept from "../../components/common/button.accept/ButtonAccept";
import {API_PATHS, makeJSONPostRequest} from "../../services/ajax/ajax";
import {useDispatch} from "react-redux";

export default function ForgotPassword(props) {

    const dispatch = useDispatch();
    const [sentEmail, updateSentEmail] = useState(false);

    async function sendForgotPasswordRequest(values) {
        await makeJSONPostRequest(API_PATHS.FORGOT_PASSWORD_SEND_EMAIL, dispatch, values);
        updateSentEmail(true)
    }

    return (
        <RegistrationLoginLayout
            {...props}
        >
            <div className={"forgot-password-container"}>
                <section>
                    {
                        !sentEmail ?
                            <>
                                <div>
                                    <h1><Translate label={"forgot_password_heading"}/></h1>
                                </div>
                                <Formik
                                    initialValues={{email: ''}}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                            .email(<Translate label={"validation_message_invalid_email"}/>)
                                            .required(<Translate label={"validation_message_required"}/>)
                                    })}
                                    onSubmit={(values, actions) => {
                                        sendForgotPasswordRequest(values).then(() => {
                                            actions.resetForm();
                                        }).finally(() => {
                                            actions.setSubmitting(false);
                                        })
                                    }}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                >
                                    {
                                        formProps => (
                                            <Form
                                                className={"forgot-password-form"}
                                            >
                                                <Field name={"email"}>
                                                    {
                                                        fieldProps => (
                                                            <TextInput
                                                                {...fieldProps.field}
                                                                id={"email"}
                                                                name={"email"}
                                                                label={"email_label"}
                                                                onBlur={createOnBlurFunction(formProps, fieldProps)}
                                                                hasError={fieldProps.meta.error}
                                                                errorMessage={fieldProps.meta.error}
                                                                disabled={formProps.isSubmitting}
                                                                formikProps={formProps}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                                <ButtonAccept className={"forgot-password-submit-button"}
                                                              disabled={formProps.isSubmitting}
                                                              label={"forgot_password_submit_button"}/>
                                            </Form>
                                        )
                                    }
                                </Formik>
                            </>
                            :
                            <>
                                <div>
                                    <h1><Translate label={"forgot_password_email_sent_heading"}/></h1>
                                </div>
                                <div className={"forgot_password_email_sent_text"}>
                                    <Translate label={"forgot_password_email_sent"}/>
                                </div>
                            </>
                    }

                </section>
            </div>
        </RegistrationLoginLayout>
    )
}