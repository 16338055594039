import React from "react";
import "./Reports.css";
import BasicLayout from "../../components/layouts/basic.layout/BasicLayout";
import ReportsDownload from "../../components/reports/reports.download/ReportsDownload";
import Translate from "../../components/common/translate/Translate";
import useFetchOrStore from "../../hooks/useFetchOrStore";
import {getUserRoleOptions} from "../../store/rootReducer";
import {API_PATHS, makeBlobPostRequest, makeJSONGetRequest} from "../../services/ajax/ajax";
import {setUserRoleOptions} from "../../services/select.options/ducks/actions";
import {useDispatch} from "react-redux";
import {downloadFile} from "../../services/file/file";
import {getBlob} from "./Reports.library";

export default function Reports(props) {

    const dispatch = useDispatch();
    const [roles] = useFetchOrStore(getUserRoleOptions, getUserRoles);

    async function getUserRoles() {
        const response = await makeJSONGetRequest(API_PATHS.GET_USER_ROLES, dispatch);
        dispatch(setUserRoleOptions(response.body.options));
    }

    async function importContactsSubmit(values) {
        const response = await makeBlobPostRequest(API_PATHS.REPORT_CONTACTS, dispatch, values);
        downloadFile(getBlob(response.body), response.fileName);
    }

    async function folioSummaryInfoSubmit(values) {
        const response = await makeBlobPostRequest(API_PATHS.REPORT_FOLIO_SUMMARY_INFO, dispatch, values);
        downloadFile(getBlob(response.body), response.fileName);
    }

    async function opportunitySummaryInfoSubmit(values) {
        const response = await makeBlobPostRequest(API_PATHS.REPORT_OPPORTUNITIES_SUMMARY_INFO, dispatch, values);
        downloadFile(getBlob(response.body), response.fileName);
    }

    return (
        <BasicLayout
            {...props}
        >
            <div>
                <h1><Translate label={"reports_header"}/></h1>
            </div>
            <div className={"reports-container"}>
                <ReportsDownload
                    sectionLabel={"reports_importing_contacts"}
                    onSubmit={importContactsSubmit}
                    roles={roles}
                />
                <ReportsDownload
                    sectionLabel={"reports_folio_summary_info"}
                    onSubmit={folioSummaryInfoSubmit}
                    roles={roles}
                />
                <ReportsDownload
                    sectionLabel={"reports_opportunity_summary_info"}
                    onSubmit={opportunitySummaryInfoSubmit}
                    roles={roles}
                />
            </div>
        </BasicLayout>
    )
}